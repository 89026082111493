import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  textRight: {
    textAlign: 'right',
  },
}));

const ConditionsInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  const updateKey = (key, newKeyName) => {
    if (Object.keys(value || {}).includes(newKeyName)) return;

    const newValue = {};

    for (const p in value) {
      if (p === 'is_repeat_loan') {
        newValue.is_repeat_loan = value[p];
      } else {
        newValue[p === key ? newKeyName : p] = value[p];
      }
    }

    onChange(newValue);
  };

  const updateValue = (key, v) => {
    const newValue = { ...(value || {}) };
    newValue[key] = v;
    onChange(newValue);
  };

  const deleteKey = key => {
    const newValue = { ...(value || {}) };
    delete newValue[key];
    onChange(newValue);
  };

  return (
    <>
      <List>
        {Object.keys(value || {}).map((item, idx) => (
          <ListItem key={idx} disableGutters>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Condition</InputLabel>
                  <Select value={item} onChange={e => updateKey(item, e.target.value)}>
                    <MenuItem disabled value="min_principal">
                      Min principal
                    </MenuItem>
                    <MenuItem disabled value="max_principal">
                      Max principal
                    </MenuItem>
                    <MenuItem disabled value="min_tenor">
                      Min tenor
                    </MenuItem>
                    <MenuItem disabled value="max_tenor">
                      Max tenor
                    </MenuItem>
                    <MenuItem value="is_repeat_loan">Is repeat loan</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {item === 'is_repeat_loan' ? (
                <Grid item xs={5}>
                  <Select fullWidth value={value.is_repeat_loan} onChange={e => updateValue(item, e.target.value)}>
                    <MenuItem value={false}>New</MenuItem>
                    <MenuItem value={true}>Repeated</MenuItem>
                  </Select>
                </Grid>
              ) : (
                <Grid item xs={5}>
                  <TextField
                    label="Value"
                    value={value[item] || ''}
                    onChange={e => updateValue(item, e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={1} className={classes.textRight}>
                <IconButton disabled={props.disabled} color="primary" size="small" onClick={() => deleteKey(item)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      <Button
        disabled={props.disabled}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => onChange({ ...(value || {}), '': '' })}>
        Add
      </Button>
    </>
  );
};

ConditionsInput.propTypes = {
  disabled: PropTypes.bool,
};

export default ConditionsInput;
