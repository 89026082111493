import React from 'react';
import { Datagrid, Filter, FunctionField, List, NumberInput, TextInput, TextField } from 'react-admin';
import { useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../Pagination';
import { formatDatetime, formatPhoneNumber } from '../../../utils/formatter';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const WebitelLogsListFilter = props => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput alwaysOn source="id" />
      <NumberInput alwaysOn label="Process ID" source="process->id" />
      <NumberInput label="User ID" source="user->id" />
      <NumberInput label="Webitel queue ID" source="webitel_queue_id" />
      <TextInput source="phone" />
    </Filter>
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const WebitelLogsList = props => {
  const query = useQuery();
  const processId = query.get('processId');
  const filterDefaultValues = processId ? { 'process->id': processId } : {};

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={filterDefaultValues}
      filters={<WebitelLogsListFilter />}
      {...props}>
      <Datagrid>
        <TextField label="ID" source="id" />
        <FunctionField
          label="User ID"
          render={({ user }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user.id} target="_blank">
              {`#${user.id}`}
            </Link>
          )}
        />
        <TextField label="Webitel queue ID" source="webitel_queue_id" />
        <FunctionField label="Dialer process ID" render={({ process }) => `#${process.id}`} />
        <FunctionField
          label="Phone"
          sortable={false}
          render={({ phone }) => (phone ? formatPhoneNumber(phone) : '---')}
        />
        <FunctionField
          label="Created at"
          source="created"
          render={({ created_at }) => (created_at ? formatDatetime(created_at) : null)}
        />
      </Datagrid>
    </List>
  );
};

export default WebitelLogsList;
