import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DeleteButton,
  Edit,
  FormWithRedirect,
  maxLength,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ListIcon from '@material-ui/icons/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import BasePreviewDialog from '../../audience/BasePreviewDialog';
import { AUDIENCE_FILTERS_IDS, CHOICES, formatChoices, formatFilter } from '../../../utils/dictionary';

import { Filter } from './Filter';

const useStyles = makeStyles(() => ({
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#c2c2c1',
  },
}));

const FormWrapper = ({ filters, save, record = {}, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.indexOf('CAN_MASS_SENDING_AUDIENCE_EDIT') !== -1;
  const [activeType, setActiveType] = useState(record.type || '');
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [filterName, setFilterName] = useState([]);
  const [filterObject, setFilterObject] = useState({});
  const [previewData, setPreviewData] = useState();
  const [previewLoading, setPreviewLoading] = useState(false);

  const handleChange = event => setFilterName(event.target.value);

  useEffect(() => {
    const defaultFilters = filters.filter(i => {
      const isFilter = record.filters?.find(j => j.id === i.id);

      return isFilter;
    });

    setFilterName(defaultFilters);
  }, [filters, record.filters]);

  useEffect(() => {
    if (!record.filters?.length) return;

    let filterObject = {};

    AUDIENCE_FILTERS_IDS.forEach(({ id, index, ...rest }) => {
      const filter = record.filters?.find(({ id: recordId }) => id === recordId);

      filterObject = {
        ...filterObject,
        [id]: form => (
          <Filter
            form={form}
            key={index}
            id={id}
            filter={filter}
            choice={formatChoices(filters.find(i => i.id === id)?.description?.allowed_values || [])}
            {...rest}
          />
        ),
      };
    });

    setFilterObject(filterObject);
  }, [filters, record.filters]);

  const getTransformFilters = filters => {
    let filtersIds = {};
    AUDIENCE_FILTERS_IDS.forEach(({ id, type }) => {
      filtersIds = { ...filtersIds, [id]: formatFilter(type, id, filters[id]) };
    });

    const newFilters = [];

    filterName.forEach(({ id }) => newFilters.push(filtersIds[id]));
    return newFilters;
  };

  const getFormDate = currentDate => {
    const year = currentDate.getFullYear();
    const mounth = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const day = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();

    return `${year}-${mounth}-${day}T03:30`;
  };

  return (
    <FormWithRedirect
      save={(
        {
          registration_date,
          name,
          type,
          user_is_blocked,
          last_application_rejection_reason,
          last_application_state,
          last_call_result_promo,
          last_call_result_unfinished_registration,
          last_call_result_unsigned_request,
          last_loan_state,
          registration_step,
          gender_filter,
          age_filter,
          employment_types,
          education_level,
          closed_loan_number,
          days_since_last_rejected_application,
          days_since_last_user_unlock,
          extension_number,
          last_loan_max_dpd,
          user_days_before_birthday,
          days_without_loan,
          last_moratorium_days,
          user_in_blacklist,
          last_sent_sms_template,
          days_since_last_sms_sent,
          last_user_activity,
          last_loan_has_active_promo_code,
          last_loan_sequence_number,
          last_loan_term,
          last_loan_type,
          user_has_do_not_call_marker,
          registration_date_from_today,
          last_loan_collection_score_group,
          last_loan_collection_score_priority,
          dpd,
          collection_group,
          last_loan_tenor,
          collector,
          days_from_the_last_call_client,
          days_from_the_last_call_tpc,
          days_from_the_last_call,
          days_from_the_last_action_client,
          days_from_the_last_action_tpc,
          days_from_the_last_action,
          days_from_last_ptp_date,
          days_from_last_ptp_created,
          ptp_stage,
          total,
          give_out_principal,
          outstanding_principal,
          last_contact_type_client_phone,
          last_contact_type_client_promise_phone,
          days_from_last_payment_date,
          last_logged_into_personal_account_general,
          last_logged_into_short_personal_account,
          last_logged_into_short_extension,
          external_agency,
          user_tag,
          last_approved_loan_amount,
          days_from_last_auto_debt_success_date,
          bank_filter,
          number_of_active_loan_days_filter,
        },
        ...rest
      ) => {
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const newRegistrationDate = registration_date?.values
          ? registration_date
          : {
              ...registration_date,
              values: {
                from: getFormDate(yesterday),
                to: getFormDate(currentDate),
              },
            };

        const params = {
          name,
          type,
          filters: getTransformFilters({
            registration_date: newRegistrationDate,
            user_is_blocked,
            last_application_rejection_reason,
            last_application_state,
            last_call_result_promo,
            last_call_result_unfinished_registration,
            last_call_result_unsigned_request,
            last_loan_state,
            registration_step,
            gender_filter,
            age_filter,
            employment_types,
            education_level,
            closed_loan_number,
            days_since_last_rejected_application,
            days_since_last_user_unlock,
            extension_number,
            last_loan_max_dpd,
            user_days_before_birthday,
            days_without_loan,
            last_moratorium_days,
            user_in_blacklist,
            last_sent_sms_template,
            days_since_last_sms_sent,
            last_user_activity,
            last_loan_has_active_promo_code,
            last_loan_sequence_number,
            last_loan_term,
            last_loan_type,
            user_has_do_not_call_marker,
            registration_date_from_today,
            last_loan_collection_score_group,
            last_loan_collection_score_priority,
            dpd,
            collection_group,
            last_loan_tenor,
            collector,
            days_from_the_last_call_client,
            days_from_the_last_call_tpc,
            days_from_the_last_call,
            days_from_the_last_action_client,
            days_from_the_last_action_tpc,
            days_from_the_last_action,
            days_from_last_ptp_date,
            days_from_last_ptp_created,
            ptp_stage,
            total,
            give_out_principal,
            outstanding_principal,
            last_contact_type_client_phone,
            last_contact_type_client_promise_phone,
            days_from_last_payment_date,
            last_logged_into_personal_account_general,
            last_logged_into_short_personal_account,
            last_logged_into_short_extension,
            external_agency,
            user_tag,
            last_approved_loan_amount,
            days_from_last_auto_debt_success_date,
            bank_filter,
            number_of_active_loan_days_filter,
          }),
        };

        save(...[{ ...params, _params: { method: 'PATCH' } }, ...rest]);
      }}
      // eslint-disable-next-line unused-imports/no-unused-vars
      validate={({ name, type, ...params }) => {
        const errors = {};
        Object.entries(params).forEach(([key, value]) => {
          const type = AUDIENCE_FILTERS_IDS.find(i => i.id === key)?.type;
          if (type === 'sign' && value?.values?.some(val => val.number === '')) {
            errors[key] = 'Value fields can not be empty';
          }
        });
        return errors;
      }}
      {...props}
      render={formProps => (
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6" gutterBottom={false}>
                  Update audience
                </Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput className={classes.mt4} source="name" validate={[maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      defaultValue={props.record?.type}
                      source="type"
                      choices={CHOICES}
                      onChange={e => setActiveType(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>Filters</InputLabel>
                      <Select
                        multiple
                        value={filterName}
                        onChange={handleChange}
                        input={<FilledInput label="Filters" />}
                        disabled={!activeType}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                            {selected.map(({ id }) => (
                              <Chip
                                className={classes.chip}
                                key={id}
                                label={filterObject[id](formProps.form).props.title}
                              />
                            ))}
                          </Box>
                        )}>
                        {filters.map(filter =>
                          filter.supported_audience_types.includes(activeType) ? (
                            <MenuItem key={filter.id} value={filter}>
                              {filterObject?.[filter?.id]?.(formProps.form)?.props?.title}
                            </MenuItem>
                          ) : null,
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      startIcon={
                        previewLoading ? <CircularProgress size={20} thickness={2} color="inherit" /> : <ListIcon />
                      }
                      onClick={() => {
                        setPreviewLoading(true);
                        const {
                          registration_date,
                          user_is_blocked,
                          last_application_rejection_reason,
                          last_application_state,
                          last_call_result_promo,
                          last_call_result_unfinished_registration,
                          last_call_result_unsigned_request,
                          last_loan_state,
                          registration_step,
                          gender_filter,
                          age_filter,
                          employment_types,
                          education_level,
                          closed_loan_number,
                          days_since_last_rejected_application,
                          days_since_last_user_unlock,
                          extension_number,
                          last_loan_max_dpd,
                          user_days_before_birthday,
                          days_without_loan,
                          last_moratorium_days,
                          user_in_blacklist,
                          last_sent_sms_template,
                          days_since_last_sms_sent,
                          last_user_activity,
                          last_loan_has_active_promo_code,
                          last_loan_sequence_number,
                          last_loan_term,
                          last_loan_type,
                          user_has_do_not_call_marker,
                          registration_date_from_today,
                          last_loan_collection_score_group,
                          last_loan_collection_score_priority,
                          dpd,
                          collection_group,
                          last_loan_tenor,
                          collector,
                          days_from_the_last_call_client,
                          days_from_the_last_call_tpc,
                          days_from_the_last_call,
                          days_from_the_last_action_client,
                          days_from_the_last_action_tpc,
                          days_from_the_last_action,
                          days_from_last_ptp_date,
                          days_from_last_ptp_created,
                          ptp_stage,
                          total,
                          give_out_principal,
                          outstanding_principal,
                          last_contact_type_client_phone,
                          last_contact_type_client_promise_phone,
                          days_from_last_payment_date,
                          last_logged_into_personal_account_general,
                          last_logged_into_short_personal_account,
                          last_logged_into_short_extension,
                          external_agency,
                          user_tag,
                          last_approved_loan_amount,
                          days_from_last_auto_debt_success_date,
                          bank_filter,
                          number_of_active_loan_days_filter,
                        } = formProps.form.getState().values;
                        const filters = getTransformFilters({
                          registration_date,
                          user_is_blocked,
                          last_application_rejection_reason,
                          last_application_state,
                          last_call_result_promo,
                          last_call_result_unfinished_registration,
                          last_call_result_unsigned_request,
                          last_loan_state,
                          registration_step,
                          gender_filter,
                          age_filter,
                          employment_types,
                          education_level,
                          closed_loan_number,
                          days_since_last_rejected_application,
                          days_since_last_user_unlock,
                          extension_number,
                          last_loan_max_dpd,
                          user_days_before_birthday,
                          days_without_loan,
                          last_moratorium_days,
                          user_in_blacklist,
                          last_sent_sms_template,
                          days_since_last_sms_sent,
                          last_user_activity,
                          last_loan_has_active_promo_code,
                          last_loan_sequence_number,
                          last_loan_term,
                          last_loan_type,
                          user_has_do_not_call_marker,
                          registration_date_from_today,
                          last_loan_collection_score_group,
                          last_loan_collection_score_priority,
                          dpd,
                          collection_group,
                          last_loan_tenor,
                          collector,
                          days_from_the_last_call_client,
                          days_from_the_last_call_tpc,
                          days_from_the_last_call,
                          days_from_the_last_action_client,
                          days_from_the_last_action_tpc,
                          days_from_the_last_action,
                          days_from_last_ptp_date,
                          days_from_last_ptp_created,
                          ptp_stage,
                          total,
                          give_out_principal,
                          outstanding_principal,
                          last_contact_type_client_phone,
                          last_contact_type_client_promise_phone,
                          days_from_last_payment_date,
                          last_logged_into_personal_account_general,
                          last_logged_into_short_personal_account,
                          last_logged_into_short_extension,
                          external_agency,
                          user_tag,
                          last_approved_loan_amount,
                          days_from_last_auto_debt_success_date,
                          bank_filter,
                          number_of_active_loan_days_filter,
                        });
                        dataProvider
                          .query('mass_sending_audiences/preview', {
                            method: 'POST',
                            body: JSON.stringify({
                              filters,
                            }),
                          })
                          .then(({ data }) => {
                            setPreviewData(data);
                            setIsPreviewDialogOpen(true);
                          })
                          .finally(() => setPreviewLoading(false));
                      }}>
                      Base preview
                    </Button>
                    <BasePreviewDialog
                      id={record.id}
                      previewList={previewData}
                      isOpened={isPreviewDialogOpen}
                      onClose={() => setIsPreviewDialogOpen(false)}
                    />
                  </Grid>
                </Grid>
              </Box>
              {filterName.map(filter => (
                <React.Fragment key={filter.id}>{filterObject[filter.id](formProps.form)}</React.Fragment>
              ))}
              <Box p={2} className={classes.btnGroup}>
                <SaveButton
                  className={classes.btn}
                  size="small"
                  icon={<ListIcon />}
                  label="Save and back to list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton
                  className={classes.btn}
                  undoable={false}
                  disabled={!isEditable}
                  basePath={formProps.basePath}
                  record={formProps.record}
                  resource={formProps.resource}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    />
  );
};

FormWrapper.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
};

FormWrapper.defaultProps = {
  filters: [],
};

const AudienceEdit = props => {
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query('mass_sending_audiences/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch(error => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error?.message) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Edit component="div" mutationMode="pessimistic" {...props}>
      <FormWrapper filters={filters} />
    </Edit>
  );
};

export default AudienceEdit;
