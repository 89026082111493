import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LoopIcon from '@material-ui/icons/Loop';
import Typography from '@material-ui/core/Typography';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Box from '@material-ui/core/Box';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import ImagesViewer from 'react-images-viewer';

import { getUserImages } from '../../../utils/getUserImages';
import noop from '../../../utils/noop';

const useStyles = makeStyles({
  rotation: {
    animation: 'rotation 2s infinite linear',
  },
  previewImage: {
    maxWidth: '100%',
    transition: 'transform 0.2s',
  },
  iconBorder: {
    borderRadius: '0px',
  },
  imageWrapper: {
    cursor: 'pointer',
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const findImage = (array = [], name) => {
  return array.includes(name);
};

const ImageZoom = ({ lastUploadTime, isExist, userId, label, handleSetTotal }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [imageAngle, setImageAngle] = useState(0);
  const [latestImage, setLatestImage] = useState(null);
  const [images, setImages] = useState([]);
  const [currImg, setCurrImg] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [stateID, setStateID] = useState(Math.random());

  useEffect(() => {
    if (isExist || lastUploadTime) {
      setLoading(true);
      dataProvider
        .getList('files', {
          pagination: { page: 1, perPage: 30 },
          sort: { field: 'created_at', order: 'DESC' },
          filter: { label, user: userId },
        })
        .then(({ data, total }) => {
          handleSetTotal(total);
          const images = getUserImages(data);
          setImages(images);
          setLatestImage(images[0].src);
        })
        .catch(error => {
          notify(`Error: ${error.message || 'User files not found'}`, 'error');
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dataProvider, isExist, lastUploadTime, notify, userId, label, stateID, handleSetTotal]);

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  if (loading) return <LoopIcon className={classes.rotation} />;

  return latestImage ? (
    <>
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <Box className={classes.imageWrapper} onClick={openImageViewer}>
              <TransformComponent>
                <img
                  alt="User document"
                  src={latestImage}
                  className={classes.previewImage}
                  style={{ transform: 'rotate(' + imageAngle + 'deg)' }}
                />
              </TransformComponent>
            </Box>
            <Box display="flex">
              <Box pt={2} flexGrow={1}>
                <ButtonGroup variant="contained" color="primary">
                  <Button onClick={zoomIn} className={classes.iconBorder} color="default">
                    <ZoomInIcon />
                  </Button>
                  <Button onClick={zoomOut} color="default">
                    <ZoomOutIcon />
                  </Button>
                  <Button onClick={resetTransform} className={classes.iconBorder} color="default">
                    <ZoomOutMapIcon />
                  </Button>
                  <Button
                    onClick={() => setImageAngle(state => state + 90)}
                    className={classes.iconBorder}
                    color="default">
                    <RotateRightIcon />
                  </Button>
                  <Button onClick={() => setStateID(Math.random())} className={classes.iconBorder} color="default">
                    <RefreshIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </>
        )}
      </TransformWrapper>
      {isViewerOpen && (
        <ImagesViewer
          isOpen={true}
          onClose={closeImageViewer}
          imgs={images}
          spinnerSize={10}
          currImg={currImg}
          onClickPrev={() => setCurrImg(prev => prev - 1)}
          onClickNext={() => setCurrImg(prev => prev + 1)}
        />
      )}
    </>
  ) : (
    <Box className={classes.centeredWrapper}>
      <Typography variant="body2">No images uploaded</Typography>
    </Box>
  );
};

ImageZoom.propTypes = {
  lastUploadTime: PropTypes.string,
  isExist: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  handleSetTotal: PropTypes.func,
};

ImageZoom.defaultProps = {
  handleSetTotal: noop,
};

export default ImageZoom;
