import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid } from 'react-admin';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import ListFilterHOC from '../../hoc/ListFilterHOC';
import ListActions from './components/ListActions';
import ListFiltersPending from './components/ListFiltersPending';
import TransferMoney from './components/TransferMoney';
import MarkAllButton from './components/MarkAllButton';
import Pagination from '../../Pagination';
import { LOANS_LIST_PENDING } from './constants';

const BulkActionButtons = props => (
  <ButtonGroup {...props}>
    <MarkAllButton {...props} />
    <TransferMoney {...props} />
  </ButtonGroup>
);

const ListPendingReady = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<BulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">{LOANS_LIST_PENDING.map(i => i)}</Datagrid>
    </List>
  );
};

ListPendingReady.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

export const LoanListPendingReady = ListFilterHOC(
  ListPendingReady,
  {
    state: 'pending',
    is_ready_for_disbursement: true,
    is_money_transferred: false,
  },
  [],
  ListFiltersPending,
);

export const LoanListPendingFailure = ListFilterHOC(
  ListPendingReady,
  {
    state: 'pending',
    money_transfer_status: 'failure',
  },
  [],
  ListFiltersPending,
);
