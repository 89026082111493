import React from 'react';
import { required } from 'react-admin';
import { Field, Form as FinalForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import { phoneNumber } from '../../utils/adapter';

const ChangePhoneNumberDialog = ({ fieldName, isOpened, onClose, onSubmit }) => {
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Change phone number</DialogTitle>
            <DialogContent>
              <DialogContentText>Please set new user phone number</DialogContentText>
              <Field
                name={fieldName}
                parse={phoneNumber.parser}
                format={v => phoneNumber.formatter(v)}
                validate={required("Can't be empty.")}>
                {({ input, meta }) => (
                  <TextField
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    id="filled-line-static"
                    label="Phone number"
                    variant="filled"
                    margin="dense"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

ChangePhoneNumberDialog.propTypes = {
  fieldName: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ChangePhoneNumberDialog;
