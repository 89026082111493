import React, { Fragment, useState } from 'react';
import { SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';

import JsonParamsInput from '../input/JsonParamsInput';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
const optionData = (value, name) => ({ value, name });
const triggerEvents = [
  optionData('user_created', 'User created'),
  optionData('user_phone_confirmed', 'User phone confirmed'),
  optionData('first_application_created', 'First application created'),
  optionData('application_created', 'Application created'),
  optionData('application_approved', 'Application approved'),
  optionData('application_rejected', 'Application rejected'),
  optionData('application_confirmed', 'Application confirmed'),
  optionData('loan_cancelled', 'Loan cancelled'),
  optionData('first_loan_activated', 'First loan activated'),
  optionData('loan_activated', 'Loan activated'),
  optionData('loan_closed', 'Loan closed'),
  optionData('loan_defaulted', 'Loan defaulted'),
];

const AddActionDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const [triggerEvent, setTriggerEvent] = useState();
  const [callbackUrl, setCallbackUrl] = useState();
  const [callbackParams, setCallbackParams] = useState({});

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add affiliate action</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <SimpleForm toolbar={false} component={Fragment}>
          <FormControl className={classes.fullWidth}>
            <InputLabel id="trigger-event-select-label">Trigger event</InputLabel>
            <Select
              labelId="trigger-event-select-label"
              value={triggerEvent || ''}
              onChange={e => setTriggerEvent(e.target.value)}>
              {triggerEvents.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={classes.my2}
            fullWidth={true}
            label="Callback URL"
            value={callbackUrl || ''}
            onChange={e => setCallbackUrl(e.target.value)}
          />
          <JsonParamsInput value={callbackParams} onChange={v => setCallbackParams(v)} label="Callback url params" />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(triggerEvent, callbackUrl, callbackParams)}
          color="primary"
          disabled={!triggerEvent || !callbackUrl || !callbackParams}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
AddActionDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default AddActionDialog;
