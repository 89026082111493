import ListFilterHOC from '../../hoc/ListFilterHOC';
import LoanListDefault from './LoanListDefault';
import ListFilters from './components/ListFilters';

const LoanListCancelled = ListFilterHOC(
  LoanListDefault,
  {
    state: 'cancelled',
  },
  [],
  ListFilters,
);

export default LoanListCancelled;
