import React from 'react';
import {
  Create,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  required,
  email,
  maxLength,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { phoneNumber } from '../../../utils/adapter';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Create new External collection agency
                    </Typography>
                    <BooleanInput
                      source="is_payments_allowed"
                      label="Payments allowed"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="status" validate={[required()]} choices={statusChoices} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="phone"
                        fullWidth
                        helperText={false}
                        placeholder={phoneNumber.placeholder}
                        parse={phoneNumber.parser}
                        format={v => phoneNumber.formatter(v)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Email"
                        source="email"
                        type="email"
                        validate={[email('Invalid email')]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput source="details" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    transform={data => ({ ...data })}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
