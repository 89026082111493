import React from 'react';

const ImportProcessHelperCollectorAssign = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/collector_assign.csv" download="import_example_collector_assign.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>Loan ID</b>, required
        </li>
        <li>
          <b>Collector ID</b>, required, use <b>0</b> for unassign collector
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperCollectorAssign;
