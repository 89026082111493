import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ReplyIcon from '@material-ui/icons/Reply';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import { emailValidator } from '../../../utils/validator';
import { getUserAttachments } from '../../../utils/getUserImages';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
  flexEnd: {
    alignSelf: 'flex-end',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
  hidden: {
    display: 'none',
  },
}));

const MailSend = ({ record }) => {
  const [loadingFile, setLoadingFile] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [mailboxes, setMailboxes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [files, setFiles] = useState([]);
  const [fromEmail, setFromEmail] = useState(null);
  const [toEmail, setToEmail] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [subject, setSubject] = useState(null);
  const [content, setContent] = useState('');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const hasInvalidFields = Object.values(errors).some(i => i !== undefined);
  const hasUnfilledFields = !fromEmail || !toEmail || !subject || !content;
  const disableSend = hasInvalidFields || hasUnfilledFields;
  const attachments = getUserAttachments(files);

  const validate = useCallback((type, value) => {
    switch (type) {
      case 'fromEmail':
      case 'toEmail': {
        let message;
        if (value.length === 0) {
          message = 'Please fill this field';
          setErrors(prevState => {
            return { ...prevState, [type]: message };
          });
          break;
        }
        if (!emailValidator(value)) {
          message = 'Invalid email address';
          setErrors(prevState => {
            return { ...prevState, [type]: message };
          });
          break;
        }
        setErrors(prevState => {
          return { ...prevState, [type]: message };
        });
        break;
      }
      case 'subject': {
        let message;
        if (value.length === 0) {
          message = 'Please fill this field';
        } else if (value.length > 0 && value.length <= 2) {
          message = 'Subject is too short';
        } else if (value.length > 255) {
          message = 'Length is too long - length should be up to 255 symbols';
        }
        setErrors(prevState => {
          return { ...prevState, [type]: message };
        });
        break;
      }
      case 'content': {
        let message;
        if (value.length === 0) {
          message = 'Please fill this field';
        } else if (value.length > 0 && value.length <= 2) {
          message = 'Message is too short';
        } else if (value.length > 10000) {
          message = 'Message is too long - should be up to 10000 symbols';
        }
        setErrors(prevState => {
          return { ...prevState, [type]: message };
        });
        break;
      }
      default:
        return true;
    }
  }, []);

  const handleAttachFile = async e => {
    if (e.target.files[0].size > 10240000) {
      return notify('Maximum file size is 10 Mb', 'error');
    }
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'file');

    await dataProvider
      .query('files/users', { method: 'POST', body: formData })
      .then(({ data }) => {
        setFiles(prevState => [...prevState, data]);
        setLoadingFile(false);
      })
      .catch(error => {
        setLoadingFile(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  const handleSendEmail = async () => {
    setSendingMail(true);
    const payload = {
      userId: record.communication?.user?.id ?? null,
      applicationId: record.communication?.application ?? null,
      loanId: record.communication?.loan ?? null,
      templateId,
      categoryId: record.communication?.category?.id ?? null,
      subject,
      content,
      fromEmail,
      toEmails: toEmail.includes(',') ? toEmail.split(',').map(item => item.trim()) : [toEmail],
      files: files.map(f => f.id),
      parentId: record.communication?.id,
    };
    await dataProvider
      .query('communication/email', {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      .then(() => {
        notify('Email successfully sent', 'success');
        setSendingMail(false);
        setToEmail(record.from_email);
        setFiles([]);
        setSubject(`RE: ${record.subject}`);
        setContent('');
        setShowForm(false);
      })
      .catch(error => {
        setSendingMail(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  useEffect(() => {
    setToEmail(record.from_email);
    validate('toEmail', record.from_email);
    setSubject(`RE: ${record.subject}`);
    validate('subject', record.subject);
  }, [record, validate]);

  useEffect(() => {
    dataProvider
      .query('email_outbox_addresses', { method: 'GET' })
      .then(({ data }) => {
        const adminEmail = data.find(i => i.email === record.to_email[0]);
        setMailboxes(data);
        setFromEmail(adminEmail?.email ?? data[0].email);
      })
      .catch(error => {
        setError(error);
      });
    dataProvider
      .query('templates?category=Email_layout', { method: 'GET' })
      .then(({ data }) => {
        setTemplates(data);
        setTemplateId(data[0].id);
      })
      .catch(error => {
        setError(error);
      });
  }, [dataProvider, record]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={3}
      className={classes.fullWidth}>
      {showForm ? (
        <>
          <Grid item xs={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="from-select-label">From</InputLabel>
              <Select
                labelId="from-select-label"
                value={fromEmail}
                onChange={e => setFromEmail(e.target.value)}
                onBlur={e => validate('fromEmail', e.target.value)}
                name="fromEmail"
                error={errors.fromEmail !== undefined}
                helperText={errors.fromEmail}>
                {mailboxes.map(i => (
                  <MenuItem key={i.id} value={i.email}>{`${i.email} <${i.name}>`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="To"
              value={toEmail}
              onChange={e => setToEmail(e.target.value)}
              onBlur={e => validate('toEmail', e.target.value)}
              name="toEmail"
              error={errors.toEmail !== undefined}
              helperText={errors.toEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              onBlur={e => validate('subject', e.target.value)}
              name="subject"
              error={errors.subject !== undefined}
              helperText={errors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="upload_file">
              <input className={classes.hidden} type="file" id="upload_file" onChange={handleAttachFile} />
              <Button
                color="primary"
                aria-label="upload file"
                className={classes.uploadIcon}
                startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                component="span">
                Attach file
              </Button>
            </label>
          </Grid>
          {files.length > 0 && (
            <Grid item xs={12}>
              <Box>
                <Typography>Attached files:</Typography>
                {attachments.map(file => (
                  <Box key={file.src} className={classes.flex}>
                    <Link href={file.src} underline="none" target="_blank" rel="noreferrer" className={classes.flex}>
                      <AttachmentIcon color="secondary" fontSize="small" />
                      {file.file_name}
                    </Link>
                    <IconButton
                      color="primary"
                      aria-label="unattach file"
                      className={classes.removeIcon}
                      component="span"
                      edge="start"
                      onClick={() => handleUnattachFile(file.id)}>
                      <CloseIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="template-select-label">Mail template</InputLabel>
              <Select labelId="template-select-label" value={templateId} onChange={e => setTemplateId(e.target.value)}>
                {templates.map(i => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Message"
              multiline
              minRows={5}
              maxRows={15}
              variant="outlined"
              value={content}
              onChange={e => setContent(e.target.value)}
              onBlur={e => validate('content', e.target.value)}
              name="content"
              error={errors.content !== undefined}
              helperText={errors.content}
            />
          </Grid>
          <Grid item xs={12} className={classes.flexEnd}>
            <ButtonGroup variant="contained" color="primary">
              <Button
                color="primary"
                startIcon={<ClearIcon />}
                variant="contained"
                onClick={() => {
                  setShowForm(false);
                  setToEmail(record.from_email);
                  setFiles([]);
                  setSubject(`RE: ${record.subject}`);
                  setContent('');
                  setErrors({});
                }}>
                Cancel
              </Button>
              <Button
                disabled={disableSend}
                color="primary"
                startIcon={sendingMail ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
                variant="contained"
                onClick={handleSendEmail}>
                Send
              </Button>
            </ButtonGroup>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} className={classes.flexEnd}>
          <Button color="primary" startIcon={<ReplyIcon />} variant="contained" onClick={() => setShowForm(true)}>
            Reply
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

MailSend.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    from_email: PropTypes.string,
    to_email: PropTypes.arrayOf(PropTypes.string),
    communication: PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
      type: PropTypes.string,
      direction: PropTypes.string,
      assigned_at: PropTypes.string,
      completed_at: PropTypes.string,
      created_at: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
      }),
      admin: PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
      }),
      loan: PropTypes.number,
      application: PropTypes.number,
      result: PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
      }),
      category: PropTypes.shape({
        id: PropTypes.number,
        group: PropTypes.string,
        username: PropTypes.string,
      }),
    }),
    subject: PropTypes.string,
    preview: PropTypes.string,
    created_at: PropTypes.string,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        hash: PropTypes.number,
        size: PropTypes.number,
        mime_type: PropTypes.string,
        file_name: PropTypes.string,
        storage_name: PropTypes.string,
        created_at: PropTypes.string,
      }),
    ),
  }),
};

export default MailSend;
