import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  required,
  maxLength,
  minValue,
  maxValue,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FilledInput from '@material-ui/core/FilledInput';
import { Filter } from './Filter';
import {
  USER_EVENT as userEvents,
  TRANSMITTER as transmitters,
  formatChoices,
  formatFilter,
  USER_EVENT_NOTIFICATION_TYPES,
  USER_EVENT_NOTIFICATIONS_FILTERS_IDS,
} from '../../../utils/dictionary';
import Autocomplete from '../../autocomplete/Autocomplete';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#C2C2C1',
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));

const FormWrapper = ({ save, filters, ...props }) => {
  const [filterName, setFilterName] = useState([]);
  const [trigger, setTrigger] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [sendFrom, setSendFrom] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [templateFilter, setTemplateFilter] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    dataProvider
      .query('global_settings/by-name/marketing_events_send_from', {})
      .then(({ data }) => setSendFrom(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    dataProvider
      .query('global_settings/by-name/marketing_events_send_to', {})
      .then(({ data }) => setSendTo(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  let filterObject = {};
  USER_EVENT_NOTIFICATIONS_FILTERS_IDS.forEach(({ id, index, ...rest }) => {
    filterObject = {
      ...filterObject,
      [id]: form => (
        <Filter
          key={index}
          id={id}
          form={form}
          choice={formatChoices(filters.find(i => i.id === id)?.description?.allowed_values || [])}
          {...rest}
        />
      ),
    };
  });

  const handleChangeFilter = event => setFilterName(event.target.value);
  const handleChangeTrigger = e => {
    setTrigger(e.target.value);
    setFilterName([]);
  };

  return (
    <FormWithRedirect
      {...props}
      save={(
        {
          name,
          is_enabled,
          trigger_event,
          interrupt_event,
          transmitter_id,
          alpha_name,
          type,
          delay_days,
          delay_hours,
          delay_minutes,
          calendar_template,
          template_id,
          affiliate,
          external_agency,
          ip_country,
          principal,
        },
        ...rest
      ) => {
        const filtersIDs = {
          affiliate: formatFilter('list', 'affiliate', affiliate),
          external_agency: formatFilter('list', 'external_agency', external_agency),
          ip_country: formatFilter('list', 'ip_country', ip_country),
          principal: formatFilter('sign', 'principal', principal),
        };
        const newFilters = [];
        filterName.forEach(i => {
          newFilters.push(filtersIDs[i.id]);
        });
        const daysInMinutes = delay_days ? delay_days * 24 * 60 : 0;
        const hoursInMinutes = delay_hours ? delay_hours * 60 : 0;
        const delayMinutes = delay_minutes ?? 0;
        const delay = daysInMinutes + hoursInMinutes + delayMinutes;
        const params = {
          name,
          is_enabled,
          trigger_event,
          interrupt_event: interrupt_event || null,
          transmitter_id,
          alpha_name: alpha_name ?? null,
          type: type ?? null,
          delay: delay || 0,
          calendar_template: calendar_template ?? null,
          template_id,
          filters: newFilters,
        };

        save(...[params, ...rest]);
      }}
      // eslint-disable-next-line unused-imports/no-unused-vars
      validate={params => {
        const { affiliate, external_agency, ip_country, principal } = params;
        const filters = { affiliate, external_agency, ip_country, principal };
        const errors = {};
        Object.entries(filters).forEach(([key, value]) => {
          const type = USER_EVENT_NOTIFICATIONS_FILTERS_IDS.find(i => i.id === key)?.type;
          if (type === 'sign' && value?.values?.some(val => val.number === '')) {
            errors[key] = 'Value fields can not be empty';
          }
        });
        return errors;
      }}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h6" gutterBottom={false}>
                        Create user notification
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <BooleanInput label="Enabled" source="is_enabled" defaultValue={false} />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="trigger_event"
                        choices={userEvents}
                        onChange={handleChangeTrigger}
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="interrupt_event" choices={userEvents} allowEmpty fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="transmitter_id"
                        onChange={e => {
                          formProps.form.change('template_id', null);
                          formProps.form.resetFieldState('template_id');
                          if (e.target.value === 'cm') {
                            setTemplateFilter(null);
                          } else if (e.target.value === 'mailer') {
                            setTemplateFilter({ category: 'Email' });
                          } else {
                            setTemplateFilter({ category: 'SMS' });
                          }
                        }}
                        choices={transmitters.filter(t => t.id !== 'cm')}
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="type"
                        validate={[required()]}
                        choices={USER_EVENT_NOTIFICATION_TYPES}
                        fullWidth
                        onChange={e => setSelectedType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.mt1}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          {selectedType === 'marketing' &&
                            `Marketing notifications will be sent between ${sendFrom} and ${sendTo}. If there are several marketing events in the queue for sending, only the last one will be sent`}
                          {selectedType === 'service' &&
                            'Service notifications are not time-limited and will be sent with a set delay'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body2" mb="0" color="textSecondary" gutterBottom>
                            Delay should be no more than 120 days in total!
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            source="delay_days"
                            label="Days"
                            min={0}
                            max={119}
                            step={1}
                            validate={[minValue(0), maxValue(119)]}
                            fullWidth
                            helperText="Maximum 119 days"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            source="delay_hours"
                            label="Hours"
                            min={0}
                            max={23}
                            step={1}
                            validate={[minValue(0), maxValue(23)]}
                            fullWidth
                            helperText="Maximum 23 hours"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            source="delay_minutes"
                            label="Minutes"
                            min={0}
                            max={59}
                            step={1}
                            validate={[minValue(0), maxValue(59)]}
                            fullWidth
                            helperText="Maximum 59 minutes"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        resource="templates"
                        source="calendar_template"
                        optionValueProp="key"
                        optionLabelProp="key"
                        filter={{ category: 'Calendar' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        resource="templates"
                        source="template_id"
                        optionValueProp="id"
                        optionLabelProp="key"
                        label="Template"
                        filter={templateFilter}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Filters</InputLabel>
                        <Select
                          multiple
                          value={filterName}
                          onChange={handleChangeFilter}
                          input={<FilledInput label="Filters" />}
                          disabled={!trigger}
                          renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                              {selected.map(({ id }) => (
                                <Chip
                                  className={classes.chip}
                                  key={id}
                                  label={filterObject[id](formProps.form)?.props?.title}
                                />
                              ))}
                            </Box>
                          )}>
                          {filters.map(filter =>
                            filter.supported_triggers.includes(trigger) ? (
                              <MenuItem key={filter.id} value={filter}>
                                {filterObject[filter.id](formProps.form)?.props?.title}
                              </MenuItem>
                            ) : null,
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {filterName.map(filter => filterObject[filter.id](formProps.form))}
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  filters: PropTypes.array,
};

export default props => {
  const notify = useNotify();
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('user_event_notifications/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch(error => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error?.message) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Create component="div" {...props}>
      <FormWrapper filters={filters} />
    </Create>
  );
};
