const openIntoNewTab = (url, downloadName = null) => {
  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  if (downloadName) {
    link.download = downloadName;
  }

  link.click();

  setTimeout(() => link.remove(), 500);
};

export default openIntoNewTab;
