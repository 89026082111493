import React, { cloneElement, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ChipField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { formatDatetime } from '../../../utils/formatter';
import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

import DialerProcessesTable from './DialerProcessesTable';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  transparent: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover:active::after': {
      backgroundColor: 'transparent',
    },
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ConfirmToDeleteDialog = ({ isOpened, onClose, onSubmit, title }) => {
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this item?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmToDeleteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

const DeleteAction = ({ record = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const deleteHandler = e => {
    e.stopPropagation();
    setLoading(true);

    dataProvider
      .delete('dialer_campaigns', { id: record.id })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton
        color="primary"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}>
        <HighlightOffIcon />
      </IconButton>

      <ConfirmToDeleteDialog
        isOpened={isOpen}
        onClose={e => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        onSubmit={deleteHandler}
        title={record.name}
      />
    </Box>
  );
};

const LogsAction = ({ record }) => {
  const classes = useStyles();

  if (!record?.processes) return null;

  const process = record.processes[record.processes.length - 1];

  return (
    <IconButton
      className={classes.transparent}
      disabled={record.processes.length === 0}
      component={Link}
      target="_blank"
      disableRipple
      to={{
        pathname: '/webitel_logs',
        search: `processId=${process.id}`,
      }}
      onClick={e => e.stopPropagation()}>
      <VisibilityIcon />
    </IconButton>
  );
};

LogsAction.propTypes = {
  record: PropTypes.shape({
    processes: PropTypes.array,
  }),
};

const DialerBasesList = props => {
  const [showProcessing, setShowProcessing] = useState(false);
  const [id, setId] = useState();

  return (
    <>
      {showProcessing && (
        <DialerProcessesTable id={id} isOpened={showProcessing} onClose={() => setShowProcessing(false)} />
      )}
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <LogsAction label="Logs" />
          <TextField source="name" />
          <FunctionField
            label="Created at"
            source="created_at"
            render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
          />
          <ChipField source="created_by_username" size="small" />
          <FunctionField
            label="Updated at"
            source="updated_at"
            render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
          />
          <TextField label="Transmitter" source="transmitter_id" />
          <TextField label="Dialer" source="params.dialer_id" />
          <FunctionField
            label="Frequency of imports"
            source="frequency_of_imports"
            render={({ params }) =>
              params?.frequency_period && `Every ${params?.frequency_period?.[0] || params?.frequency_period} min`
            }
          />
          <TextField source="start_time" />
          <TextField source="end_time" />
          <BooleanField source="is_enabled" />
          <FunctionField
            label="Last import state"
            source="last_import_state"
            render={({ processes }) => processes?.[processes?.length - 1]?.state}
          />
          <FunctionField
            label="Show processes"
            render={({ id }) => (
              <Button
                variant="contained"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  setId(id);
                  setShowProcessing(true);
                }}>
                Show
              </Button>
            )}
          />
          <DeleteAction label="Delete" />
        </Datagrid>
      </List>
    </>
  );
};

DeleteAction.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default DialerBasesList;
