import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Pagination from '../../Pagination';
import { marginZeroStyles, WEEK_DAYS } from '../../../constants';
import { formatPhoneNumber } from '../../../utils/formatter';

const useStyles = makeStyles(theme => ({
  ...marginZeroStyles,
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  root: {
    '& .column-text': {
      maxWidth: '300px',
      wordBreak: 'break-word',
      overflow: 'hidden',
    },
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const UserNotificationCampaignList = props => {
  const classes = useStyles();

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      className={classes.root}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <FunctionField
          label="Type"
          sortable={false}
          render={({ type }) => (
            <Typography variant="body2" className={classes[type]}>
              {type}
            </Typography>
          )}
        />
        <FunctionField
          source="text"
          sortable={false}
          label="Message"
          render={(record, key) => {
            if (record[key]) {
              const text = record[key].split('\n');
              const title = text.length > 1 ? text[0] : null;
              const body = text.length > 1 ? text[1] : text[0];
              return (
                <>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {title}
                  </Typography>
                  <Typography variant="body2">{body}</Typography>
                </>
              );
            } else {
              return null;
            }
          }}
        />
        <FunctionField
          label="Communication channels"
          sortable={false}
          render={({ telegram_link_url, whatsapp_link_url, phone_number }) => (
            <>
              {telegram_link_url && (
                <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                  {`Telegram: ${telegram_link_url}`}
                </Typography>
              )}
              {whatsapp_link_url && (
                <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                  {`WhatsApp: ${whatsapp_link_url}`}
                </Typography>
              )}
              {phone_number && (
                <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                  {`Phone number: ${formatPhoneNumber(phone_number, false)}`}
                </Typography>
              )}
            </>
          )}
        />
        <ChipField source="audience_id" sortable={false} size="small" />
        <FunctionField
          label="Period"
          sortable={false}
          render={record => {
            const monthDays = Array.isArray(record?.params?.month_days) ? record.params.month_days : [];
            const weekDays = Array.isArray(record?.params?.week_days) ? record.params.week_days : [];

            monthDays.sort((a, b) => {
              a = a < 0 ? a + 100 : a;
              b = b < 0 ? b + 100 : b;

              return Math.sign(a - b);
            });
            weekDays.sort();

            return (
              <>
                {monthDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of month:</b>
                    </div>
                    <div>{monthDays.map(day => (day === -1 ? 'Last' : day)).join(', ')}</div>
                  </>
                )}

                {weekDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of week:</b>
                    </div>
                    <div>{weekDays.map(day => WEEK_DAYS[day - 1] || 'UN').join(', ')}</div>
                  </>
                )}
              </>
            );
          }}
        />
        <BooleanField source="can_be_closed" sortable={false} label="Closeable?" />
        <BooleanField source="is_enabled" sortable={false} label="Enabled?" />
      </Datagrid>
    </List>
  );
};

export default UserNotificationCampaignList;
