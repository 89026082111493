import React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

import DateTimePickerInput from '../input/DateTimePickerInput';

const DateRangeFilter = ({ id, title, filter = {}, form }) => {
  return (
    <>
      <Divider />
      <Box p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={filter?.active}
              onChange={e => form.change(`${id}.active`, e.target.checked ? [id] : [])}
            />
          }
          label={title}
        />
        <DateTimePickerInput
          label="Created after"
          source={`${id}.values.from`}
          defaultValue={filter?.values?.[0]?.from}
        />
        <DateTimePickerInput
          label="Created before"
          source={`${id}.values.to`}
          before
          defaultValue={filter?.values?.[0]?.to}
        />
      </Box>
    </>
  );
};

DateRangeFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};

DateRangeFilter.defaultProps = {
  filter: {},
};

export default DateRangeFilter;
