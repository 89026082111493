import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { timezone } from '../../utils/adapter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const DateTimePickerInput = ({ initial, label, ...props }) => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    isRequired,
    meta: { touched, error },
  } = useInput({ ...props, validate: value => (props.required && !value ? 'Required' : undefined) });

  React.useEffect(() => {
    if (initial) {
      onChange(initial);
    }
  }, [initial, onChange]);

  return (
    <FormControl className={classes.fullWidth} required={isRequired} error={!!(touched && error)}>
      <DateTimePicker
        className={props.className || ''}
        clearable
        required={isRequired}
        error={!!(touched && error)}
        label={label}
        value={value ? timezone.shift(new Date(value)) : null}
        onChange={value => onChange(value ? timezone.unshift(value).toISOString() : null)}
        {...props}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

DateTimePickerInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  initial: PropTypes.string,
};

export default DateTimePickerInput;
