import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const UserAddToBlackListDialog = ({ isOpened, onClose, user, applyTransition }) => {
  const [commentText, setCommentText] = useState();
  const [error, setError] = useState(false);
  const { id, curp, first_name, last_name, phone_number, email, contact_phone_number } = user;
  const object = {
    user_id: id,
    curp,
    first_name,
    last_name,
    phone_number,
    email,
    contact_phone_number,
  };
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add comment to blocked user</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-multiline-static"
          label="Comment"
          multiline
          rows={4}
          error={error}
          variant="outlined"
          value={commentText}
          fullWidth
          onChange={e => setCommentText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (!commentText) {
              setError(true);
            } else {
              setError(false);
              applyTransition('add_black_list', { ...object, comment: commentText });
            }
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserAddToBlackListDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.number.isRequired,
  applyTransition: PropTypes.func.isRequired,
};

export default UserAddToBlackListDialog;
