import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useNotify, useDataProvider, Confirm, useRefresh } from 'react-admin';
import Box from '@material-ui/core/Box';

const ActivateButton = ({ selectedIds }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const activateExtensionRequests = async (params = {}) => {
    await dataProvider
      .query('extensions/batch_activate', { method: 'POST', body: JSON.stringify(params) })
      .then(() => {
        notify('Request was success, extended loans removed from list', 'info');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message || 'activation error'}`, 'error'));
  };

  return (
    <Box>
      <Button
        label="Activate"
        variant="contained"
        onClick={() => setConfirmDialogOpen(true)}
        color="primary"
        disabled={disabled}
      />
      <Confirm
        isOpen={confirmDialogOpen}
        title="Activate extension requests"
        content={`Are you sure to activate ${selectedIds.length} extension requests?`}
        onConfirm={() => {
          setDisabled(state => !state);
          activateExtensionRequests({ loans: selectedIds }).then(() => setDisabled(state => !state));
          setConfirmDialogOpen(false);
        }}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </Box>
  );
};

ActivateButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};

export default ActivateButton;
