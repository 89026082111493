import { BooleanField, Datagrid, List, TextField } from 'react-admin';
import React from 'react';

const CollectionContactTypeList = props => {
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <BooleanField sortable={false} source="enabled" />
        <TextField sortable={false} source="name" />
      </Datagrid>
    </List>
  );
};

export default CollectionContactTypeList;
