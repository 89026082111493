import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { grey, green, orange, blue, purple } from '@material-ui/core/colors';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useHandbook } from '../../../hooks/useHandbook';

const getFormattedDay = (year, month, day) => new Date(Date.UTC(year, month - 1, day)).toJSON().split('T')[0];

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  flexRowCenterToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(6),
  },
  tableCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  dayTableCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: '0',
    '&:last-child': {
      paddingRight: '0',
    },
  },
  label: {
    display: 'block',
    margin: 0,
    '& svg': {
      display: 'none',
    },
  },
  checked: {
    backgroundColor: grey[300],
  },
  work: {
    backgroundColor: green[300],
  },
  holiday: {
    backgroundColor: orange[300],
  },
  absence: {
    backgroundColor: 'red',
  },
  ill: {
    backgroundColor: blue[300],
  },
  vacation: {
    backgroundColor: purple[300],
  },
  checkbox: {
    padding: '5px',
  },
  marker: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const Actions = ({ onActionClick }) => {
  const classes = useStyles();

  return (
    <Box className={[classes.flexRowCenter, classes.mt4].join(' ')}>
      <Box className={classes.marker} onClick={() => onActionClick('work')}>
        <Box sx={{ width: '25px', height: '25px', backgroundColor: green[500] }} />
        <Typography>Mark as work</Typography>
      </Box>
      <Box className={classes.marker} onClick={() => onActionClick('holiday')}>
        <Box sx={{ width: '25px', height: '25px', backgroundColor: orange[500] }} />
        <Typography>Mark as holiday</Typography>
      </Box>
      <Box className={classes.marker} onClick={() => onActionClick('absence')}>
        <Box sx={{ width: '25px', height: '25px', backgroundColor: 'red' }} />
        <Typography>Mark as absent</Typography>
      </Box>
      <Box className={classes.marker} onClick={() => onActionClick('ill')}>
        <Box sx={{ width: '25px', height: '25px', backgroundColor: blue[500] }} />
        <Typography>Mark as ill</Typography>
      </Box>
      <Box className={classes.marker} onClick={() => onActionClick('vacation')}>
        <Box sx={{ width: '25px', height: '25px', backgroundColor: purple[500] }} />
        <Typography>Mark as vacation</Typography>
      </Box>
    </Box>
  );
};

Actions.propTypes = {
  onActionClick: PropTypes.func,
};

const DayCell = ({ year, month, day, admin, markedDays, onSelect }) => {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();

  const isAlreadyMarked = markedDays.find(markedDay => {
    return markedDay.admin === admin && markedDay.day === getFormattedDay(year, month, day);
  });

  const handleCheck = event => {
    const checked = event.target.checked;
    onSelect(checked, day, admin);
    setChecked(checked);
  };

  const getDayColor = () => {
    if (checked) {
      return classes.checked;
    }

    if (isAlreadyMarked) {
      return classes[isAlreadyMarked.type];
    }
  };

  return (
    <TableCell component="th" scope="row" className={`${classes.dayTableCell} ${getDayColor()}`}>
      <FormControlLabel
        onChange={handleCheck}
        className={classes.label}
        control={<Checkbox checked={checked} className={classes.checkbox} disableRipple />}
      />
    </TableCell>
  );
};

DayCell.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  day: PropTypes.number,
  admin: PropTypes.number,
  markedDays: PropTypes.array,
  onSelect: PropTypes.func,
};

const WorkSchedule = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [rolesFilter, setRolesFilter] = useState([]);
  const [collectionGroupsFilter, setCollectionGroupsFilter] = useState([]);
  const [selected, setSelected] = useState([]);
  const [markedDays, setMarkedDays] = useState([]);
  const [randomKey, setRandomKey] = useState(Math.round(Math.random() * 100));
  const [pages, setPages] = useState([]);

  const { data: roles } = useHandbook('admin_auth_roles');
  const { data: collectionGroups } = useHandbook('collection_groups');

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  useEffect(() => {
    setLoading(true);
    const days = new Date(year, month, 0).getDate();
    const dates = Array.from(Array(days), (_, index) => index + 1);
    dataProvider
      .getList('work_schedules', {
        filter: {
          'day[after]': getFormattedDay(year, month, dates[0]),
          'day[before]': getFormattedDay(year, month, dates[dates.length - 1]),
          'admin.is_blocked': false,
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data, total }) => {
        if (total > 1000) {
          const pagesNum = Math.ceil(total / 1000);
          let pages = Array.from(Array(pagesNum).keys()).slice(1);
          pages = pages.map(page => page + 1);
          setPages(pages);
        }
        setMarkedDays(data);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setLoading(false));

    setDates(dates);
    setSelected([]);
  }, [year, month, dataProvider, notify]);

  useEffect(() => {
    if (pages.length > 0) {
      const days = new Date(year, month, 0).getDate();
      const dates = Array.from(Array(days), (_, index) => index + 1);
      const promises = pages.map(item =>
        dataProvider.getList('work_schedules', {
          filter: {
            'day[after]': getFormattedDay(year, month, dates[0]),
            'day[before]': getFormattedDay(year, month, dates[dates.length - 1]),
            'admin.is_blocked': false,
          },
          pagination: { page: item, perPage: 1000 },
          sort: { field: 'id', order: 'ASC' },
        }),
      );
      Promise.all(promises)
        .then(results => setMarkedDays(prev => [...prev, ...results.flatMap(result => result.data)]))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
  }, [dataProvider, notify, year, month, pages]);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('admins', {
        filter: { 'roles.code': rolesFilter, 'collection_groups.code': collectionGroupsFilter, is_blocked: false },
        pagination: { page: 1, perPage: 1000 },
        sort: {},
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setLoading(false));
  }, [dataProvider, rolesFilter, collectionGroupsFilter, notify]);

  const getDaysFromSelectedValue = value => {
    const date = new Date(value);
    const month = date.getMonth() + 1;
    setYear(date.getFullYear());
    setMonth(month);
    setPages([]);
  };

  const handleSelectDay = (isChecked, day, adminId) => {
    const selectedTemp = [...selected];
    const dayFormatted = getFormattedDay(year, month, day);
    if (isChecked) {
      const newRecord = {
        admin: adminId,
        day: dayFormatted,
      };
      selectedTemp.push(newRecord);
    } else {
      const index = selectedTemp.findIndex(item => item.admin === adminId && item.day === dayFormatted);
      selectedTemp.splice(index, 1);
    }
    setSelected(selectedTemp);
  };

  const markDays = async type => {
    setSaving(true);

    const markedDays = selected.map(day => {
      return { ...day, type };
    });

    await dataProvider
      .query('work_schedules/items', {
        method: 'POST',
        body: JSON.stringify({ items: markedDays }),
      })
      .then(() => {
        setSelected([]);
        notify('Successfully saved', 'info');
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));

    await dataProvider
      .getList('work_schedules', {
        filter: {
          'day[after]': getFormattedDay(year, month, dates[0]),
          'day[before]': getFormattedDay(year, month, dates[dates.length - 1]),
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data, total }) => {
        if (total > 1000) {
          const pagesNum = Math.ceil(total / 1000);
          let pages = Array.from(Array(pagesNum).keys()).slice(1);
          pages = pages.map(page => page + 1);
          setPages(pages);
        }
        setMarkedDays(data);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setRandomKey(Math.round(Math.random() * 100));
        setSaving(false);
      });
  };

  const handleSelectMonthFwd = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    setPages([]);
  };

  const handleSelectMonthBack = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    setPages([]);
  };

  const handleRolesFilterChange = event => {
    setRolesFilter(event.target.value);
  };

  const handleCollectionGroupsFilterChange = event => {
    setCollectionGroupsFilter(event.target.value);
  };

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Backdrop className={classes.backdrop} open={saving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item sm={12}>
        <Box className={classes.flexRowCenterToolbar}>
          <FormControl className={classes.formControl}>
            <InputLabel id="roles-mutiple-checkbox-label">Roles</InputLabel>
            <Select
              labelId="roles-mutiple-checkbox-label"
              id="roles-mutiple-checkbox"
              multiple
              value={rolesFilter}
              onChange={handleRolesFilterChange}
              input={<Input />}
              renderValue={selected => selected.join(', ')}>
              {roles.map(role => (
                <MenuItem key={role.id} value={role.code}>
                  <Checkbox checked={rolesFilter.indexOf(role.code) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="collection-groups-mutiple-checkbox-label">Groups</InputLabel>
            <Select
              labelId="collection-groups-mutiple-checkbox-label"
              id="collection-groups-mutiple-checkbox"
              multiple
              value={collectionGroupsFilter}
              onChange={handleCollectionGroupsFilterChange}
              input={<Input />}
              renderValue={selected => selected.join(', ')}>
              {collectionGroups.map(role => (
                <MenuItem key={role.id} value={role.code}>
                  <Checkbox checked={collectionGroupsFilter.indexOf(role.code) > -1} />
                  <ListItemText primary={role.code} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.flexRowCenter}>
          <IconButton onClick={handleSelectMonthBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DatePicker
            label="Select year and month"
            value={new Date(year, month, 0)}
            views={['year', 'month']}
            onChange={value => getDaysFromSelectedValue(value)}
          />
          <IconButton onClick={handleSelectMonthFwd}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        {permissions.includes('CAN_WORK_SCHEDULE_EDIT') ? <Actions onActionClick={markDays} /> : null}
        <TableContainer component={Paper} className={classes.mt4}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={180} height={30} className={classes.tableCell}>
                  Admin name
                </TableCell>
                {dates.map(day => (
                  <TableCell width={40} height={30} key={day} align="center" className={classes.dayTableCell}>
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map(admin => (
                <TableRow key={admin.id}>
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {admin.username}
                  </TableCell>
                  {dates.map(day => (
                    <DayCell
                      key={`${year}-${month}-${day}-${admin.id}-${randomKey}`}
                      year={year}
                      month={month}
                      day={day}
                      admin={admin.id}
                      markedDays={markedDays}
                      onSelect={handleSelectDay}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default WorkSchedule;
