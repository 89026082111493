import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableSortLabel } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { green, orange, red } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { formatCurrency, formatDate, formatPhoneNumber, formatTime, formatDatetime } from '../../utils/formatter';
import { useHandbook } from '../../hooks/useHandbook';
import Call from '../call/Call';
import AudioPlayerDialog from '../audio_player/AudioPlayerDialog';
import WebitelButton from '../webitel_button/WebitelButton';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
    padding: '1px 0 0',
  },
  promiseOfPayment: {
    margin: 4,
    padding: 16,
    backgroundColor: '#FFCCCC',
    borderRadius: 4,
  },
  hiddenId: {
    display: 'none',
  },
  tableCell: {
    padding: '6px 12px',
  },
  tableCellValues: {
    margin: '0 0 2px',
  },
  orangeChip: {
    backgroundColor: orange[500],
  },
  greenChip: {
    backgroundColor: green[500],
  },
  greenLightChip: {
    backgroundColor: green[200],
  },
  redChip: {
    backgroundColor: red[500],
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));

const getContactChainValue = (contactChain, typeName, defaultValue = '---') => {
  const contact = contactChain.find(({ type }) => type === typeName);

  return contact ? contact.value : defaultValue;
};

const CollectionCallTable = ({ userId, loanId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const { data: phoneTypes } = useHandbook('phone_types');
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [record, setRecord] = useState(null);
  const [recordData, setRecordData] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  useEffect(() => {
    const filter = loanId ? { 'loan.user.id': userId, 'loan.id': loanId } : { 'loan.user.id': userId };
    dataProvider
      .getList('collection_contact_calls', {
        filter,
        pagination: { page, perPage },
        sort: { field: sortBy, order: sortOrder },
      })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    dataProvider.getOne('users', { id: userId }).then(({ data }) => {
      setUser(data);
    });
  }, [dataProvider, userId, page, perPage, sortBy, sortOrder, refreshedAt, loanId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const buildHandleClickSort = attribute => {
    return () => {
      setSortOrder(attribute === sortBy && sortOrder === 'DESC' ? 'ASC' : 'DESC');
      setSortBy(attribute);
    };
  };

  const getPtpStatusColor = status => {
    switch (status) {
      case 'in_progress':
        return classes.orangeChip;
      case 'kept':
        return classes.greenChip;
      case 'partially_kept':
        return classes.greenLightChip;
      case 'broken':
        return classes.redChip;
    }
  };

  const handleOpenPlayer = (recordId, recordData) => {
    setRecord(recordId);
    setRecordData(recordData);
    setOpenPlayer(true);
  };

  const handleClosePlayer = () => {
    setOpenPlayer(false);
    setRecordData(null);
    setRecord(null);
  };

  const hasActivePromise = loanId
    ? user?.active_promise_amount && data.some(call => call.promise_status === 'in_progress')
    : user?.active_promise_amount;

  return (
    <div className={classes.fullWidth}>
      {hasActivePromise && (
        <div className={classes.promiseOfPayment}>
          Client has active promise of payment: &nbsp;{formatCurrency(user.active_promise_amount)}, &nbsp;
          {formatDatetime(user.active_promise_date)}
        </div>
      )}

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <TableSortLabel
                active={sortBy === 'id'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('id')}>
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableCell}>Collector</TableCell>
            <TableCell className={classes.tableCell}>Call</TableCell>
            <TableCell className={classes.tableCell}>Contact</TableCell>
            <TableCell className={classes.tableCell}>Comment</TableCell>
            <TableCell className={classes.tableCell}>
              <TableSortLabel
                active={sortBy === 'promiseDate'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('promiseDate')}>
                Promise to pay
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableCell}>Promise details</TableCell>
            <TableCell className={classes.tableCell}>
              <TableSortLabel
                active={sortBy === 'createdAt'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('createdAt')}>
                Created at
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableCell}>Remind at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell className={classes.tableCell}>{row.id}</TableCell>
              <TableCell className={classes.tableCell}>
                {permissions.includes('CAN_ADMIN_EDIT') ? (
                  <Link to={`/admins/${row.collector}`}>{row.collector_username}</Link>
                ) : (
                  row.collector_username
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Box className={classes.flexRow}>
                  <Box className={classes.flexColumn}>
                    <div className={classes.tableCellValues}>
                      <b>Type</b>
                      <br />
                      {getContactChainValue(row.contact_chain, 'Call type')}
                    </div>

                    {getContactChainValue(row.contact_chain, 'Call type').includes('Email') ? (
                      <div className={classes.tableCellValues}>{row.email}</div>
                    ) : (
                      <div className={classes.tableCellValues}>
                        <b>
                          Phone &quot;
                          {phoneTypes.find(phoneType => phoneType.code === row.phone_type)?.name || 'Unknown type'}
                          &quot;
                        </b>
                        <br />
                        <Box className={classes.flexRow}>
                          <WebitelButton userPhone={row.phone_number} />
                          <Call userId={userId} userPhone={row.phone_number}>
                            {formatPhoneNumber(row.phone_number)}
                          </Call>
                        </Box>
                      </div>
                    )}
                  </Box>
                  {!getContactChainValue(row.contact_chain, 'Call type').includes('Email') && (
                    <IconButton
                      disabled={row.webitel_call === null || row.webitel_call.user_files.length === 0}
                      onClick={() => handleOpenPlayer(row.webitel_call.user_files[0], row.webitel_call)}
                      color="secondary">
                      <PlayCircleOutlineIcon />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.contact_chain
                  .filter(contact => contact.type !== 'Call type')
                  .map(contact => (
                    <div key={contact.id} className={classes.tableCellValues}>
                      <b>{contact.type}:</b>
                      <br />
                      {contact.value}
                      <span className={classes.hiddenId}>#{contact.id}</span>
                    </div>
                  ))}
                {row.motivator && (
                  <div className={classes.tableCellValues}>
                    <b>Motivator:</b>
                    <br />
                    {row.motivator_name}
                    <span className={classes.hiddenId}>#{row.motivator}</span>
                  </div>
                )}
                {row.no_payment_reason_name ? (
                  <>
                    <div className={classes.tableCellValues}>
                      <b>No payment reason</b>
                      <br />
                      {row.no_payment_reason_name}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>{row.comment || '---'}</TableCell>
              <TableCell className={classes.tableCell}>
                {!row.promise_date && !row.promise_amount ? (
                  <>---</>
                ) : (
                  <>
                    {formatDatetime(row.promise_date)}
                    <br />
                    {formatCurrency(row.promise_amount)}
                  </>
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {!row.promise_date ? (
                  <>---</>
                ) : (
                  <>
                    <div className={classes.tableCellValues}>
                      <b>Promise status</b>
                      <br />
                      <Chip size="small" label={row.promise_status} className={getPtpStatusColor(row.promise_status)} />
                    </div>
                    <div className={classes.tableCellValues}>
                      <b>Paid amount</b>
                      <br />
                      {formatCurrency(row.promise_paid_amount || 0)}
                    </div>
                    <div className={classes.tableCellValues}>
                      <b>Completed at</b>
                      <br />
                      {row.promise_completed_at ? (
                        <>
                          <div>{formatDate(row.promise_completed_at)}</div>
                          <div>{formatTime(row.promise_completed_at)}</div>
                        </>
                      ) : (
                        <>---</>
                      )}
                    </div>
                  </>
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div>{formatDate(row.created_at)}</div>
                <div>{formatTime(row.created_at)}</div>
              </TableCell>
              <TableCell className={classes.tableCell}>{formatDatetime(row.reminder_date) || '---'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {openPlayer && <AudioPlayerDialog onClose={handleClosePlayer} recordId={record} recordData={recordData} />}
    </div>
  );
};

CollectionCallTable.propTypes = {
  userId: PropTypes.number.isRequired,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default CollectionCallTable;
