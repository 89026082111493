import React, { useState } from 'react';
import { Button, useNotify, useDataProvider, Confirm, useListContext } from 'react-admin';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import TransferMoneyTransitionDialog from './TransferMoneyTransitionDialog';

const TransferMoney = ({ selectedIds }) => {
  const { data } = useListContext();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [transferMoneyDialogOpen, setTransferMoneyDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const applyTransition = async (name, params = {}, id) => {
    if (data[id].enabled_transitions && data[id].enabled_transitions.indexOf('transfer_money') !== -1) {
      await dataProvider
        .query(`loans/${id}/apply_transition`, { method: 'POST', body: JSON.stringify({ name, params }) })
        .then(() => {
          notify(`Transfer money: [loan id ${id}] please activate or cancel`, 'success');
        })
        .catch(error => notify(`Error: ${error.message || 'transfer money error'}`, 'error'));
    }
  };

  const handleConfirm = (gateway, ids) => {
    applyTransition('transfer_money', { gateway }, ids[0]).then(() => {
      ids.shift();
      if (ids.length) {
        handleConfirm(gateway, ids);
      } else {
        setDisabled(state => !state);
      }
    });
  };

  return (
    <Box>
      <Button
        label="Transfer money"
        variant="contained"
        onClick={() => setConfirmDialogOpen(true)}
        color="primary"
        disabled={disabled}
      />
      <Confirm
        isOpen={confirmDialogOpen}
        title="Transfer money"
        content={`Are you sure to disburse ${selectedIds.length} loans`}
        onConfirm={() => {
          setTransferMoneyDialogOpen(true);
          setConfirmDialogOpen(false);
        }}
        onClose={() => setConfirmDialogOpen(false)}
      />
      <TransferMoneyTransitionDialog
        isOpened={transferMoneyDialogOpen}
        onClose={() => setTransferMoneyDialogOpen(false)}
        onSubmit={gateway => {
          const ids = selectedIds.slice();
          handleConfirm(gateway, ids);
          setTransferMoneyDialogOpen(false);
          setDisabled(state => !state);
        }}
      />
    </Box>
  );
};
TransferMoney.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};
export default TransferMoney;
