import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  TextInput,
  NumberInput,
  PasswordInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  BooleanInput,
  minValue,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { phoneNumber } from '../../../utils/adapter';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const FormWrapper = props => {
  const [phone, setPhone] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    props.record.phone && setPhone(props.record.phone);
  }, [props]);

  useEffect(() => {
    if (phone?.includes('+52')) {
      setIsPhoneValid(phoneNumber.validator(phoneNumber.parser(phone)));
    } else {
      setIsPhoneValid(phoneNumber.validator(phone));
    }
  }, [phone]);

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create admin
                  </Typography>
                  <Divider />
                  <TextInput className={classes.mt4} source="username" fullWidth />
                  <PasswordInput source="password" fullWidth />
                  <TextInput
                    fullWidth
                    source="phone"
                    helperText="Optional field"
                    placeholder={phoneNumber.placeholder}
                    parse={phoneNumber.parser}
                    format={v => phoneNumber.formatter(v)}
                    onChange={event => setPhone(event.target.value)}
                  />
                  <NumberInput
                    fullWidth
                    source="webitel_extension"
                    min={0}
                    validate={minValue(0, 'The value must be a positive number')}
                    helperText="Optional field"
                  />
                  <BooleanInput
                    fullWidth
                    disabled={!isPhoneValid}
                    label="Send SMS about loans"
                    source="is_send_sms_about_loans"
                    helperText={false}
                    className={classes.titleSwitch}
                  />
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="edit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  record: PropTypes.shape({
    phone: PropTypes.string,
  }),
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
