import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';

import { useStyles } from '../styles';
import { DraggableDialog } from '../../../dialog/DraggableDialog';
import { timezone } from '../../../../utils/adapter';

const PostponeTransitionDialog = ({ isOpened, onClose, onSubmit, initialValue = null }) => {
  const classes = useStyles();
  const [postponedUntil, setPostponedUntil] = useState(initialValue ? new Date(initialValue) : null);

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Postpone application.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose a date.</DialogContentText>
        <DateTimePicker
          className={classes.fullWidth}
          value={postponedUntil ? timezone.shift(postponedUntil) : null}
          onChange={value => setPostponedUntil(value ? timezone.unshift(value) : null)}
          clearable
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(postponedUntil)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

PostponeTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string]),
};

export default PostponeTransitionDialog;
