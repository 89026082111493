import { SETTINGS_TIMEZONE_CHANGED } from '../actionTypes';

export default (previousState = {}, { type, payload }) => {
  switch (type) {
    case SETTINGS_TIMEZONE_CHANGED:
      localStorage.setItem('admin_timezone', payload);
      return { ...previousState, timezone: payload };
    default:
      return previousState;
  }
};
