import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const DatePickerInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    meta,
  } = useInput(props);
  const { touched, error } = meta;

  return (
    <KeyboardDatePicker
      className={[classes.fullWidth, props.className || ''].join(' ')}
      label={props.label}
      value={value || null}
      onChange={value => onChange(value)}
      format={props.dateFormat || 'yyyy-MM-dd'}
      error={!!(touched && error)}
      helperText={touched && error}
      {...props}
    />
  );
};

DatePickerInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default DatePickerInput;
