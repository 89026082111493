import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FileCopy } from '@material-ui/icons';

import { rowData } from '../utils';
import { useStyles } from '../styles';
import copyToClipboard from '../../../../utils/copyToClipboard';
import { formatCurrency, formatDatetime, formatDate } from '../../../../utils/formatter';
import WebitelButton from '../../../webitel_button/WebitelButton';
import FeeAccordion from './FeeAccordion';
import FuturePaymentAmount from './FuturePaymentAmount';

const LoanInfo = ({ record }) => {
  const [agenciesList, setAgenciesList] = useState([]);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };

  const infoRows = [
    rowData(
      'ID',
      <Box className={classes.flexRow}>
        <WebitelButton loanId={record.id} />
        {record.id}
      </Box>,
    ),
    rowData('Contract number', record.contract_number),
    rowData('Product', record.product_name),
    rowData('Promocode', record.promo_code),
    rowData(
      'Discount details',
      record.discount_details?.map(discount => {
        return (
          <p style={{ marginTop: 0, marginBottom: '2px' }} key={discount}>
            {discount}
          </p>
        );
      }),
    ),
    rowData('Discount amount', formatCurrency(record.discount_amount)),
    rowData(
      'Short link to personal account',
      <Box>
        <IconButton onClick={() => copyText(record.loan_personal_account_short_link_uri)}>
          <FileCopy />
        </IconButton>
        {record.loan_personal_account_short_link_uri}
      </Box>,
    ),
    rowData(
      'Short link to extension',
      <Box>
        <IconButton onClick={() => copyText(record.extension_short_link_uri)}>
          <FileCopy />
        </IconButton>
        {record.extension_short_link_uri}
      </Box>,
    ),
    rowData('Peerberry status', record.peerberry_status),
    record.peerberry_status === 'error' && rowData('Peerberry error', record.peerberry_error),
    rowData('Assigned collector name', record.assigned_collector),
    rowData(
      'Assigned collection agency',
      record.external_agency_id ? (
        <Chip
          className={classes.redChip}
          label={agenciesList?.find(agency => agency.id === record.external_agency_id)?.name}
        />
      ) : null,
    ),
    rowData('Collection score priority', record.collection_score_priority),
    rowData('Principal', formatCurrency(record.principal)),
    rowData('Tenor', record.tenor),
    rowData('State', record.state),
    record.state === 'cancelled' ? rowData('Cancellation reason', record.cancel_reason) : null,
    rowData('Created at', formatDatetime(record.created_at)),
    rowData('Disbursed at', record.disbursed_at && formatDatetime(record.disbursed_at)),
    rowData('Maturity date', record.matured_at && formatDatetime(record.matured_at)),
    rowData('Closed at', record.closed_at && formatDatetime(record.closed_at)),
    rowData('Days past due', record.days_past_due),
    rowData('Number of extensions', record.number_of_extensions),
    rowData('Bank account', record.account_number),
    rowData('Loan type', record.is_repeat ? 'Repeat' : 'New'),
    record.state === 'defaulted'
      ? rowData('Extension min payment', 'Extension is not allowed')
      : record.is_extension_request_allowed
        ? rowData(
            'Extension min payment',
            record.allowed_extension_tenors_price &&
              Object.keys(record.allowed_extension_tenors_price).map(i => {
                return (
                  <Chip
                    style={{ marginLeft: '2px', marginTop: '2px' }}
                    key={i}
                    variant="outlined"
                    size="small"
                    label={`${i} - ${formatCurrency(record.allowed_extension_tenors_price[i])}`}
                  />
                );
              }),
          )
        : rowData('Extension min payment', 'Extension is not allowed'),
    rowData('Active promise of payment date', formatDate(record.active_promise_date)),
    rowData('Active promise of payment amount', formatCurrency(record.active_promise_amount)),
  ];

  const calculationRows = [
    rowData('Principal', formatCurrency(record.accrued_principal)),
    rowData('Commission', formatCurrency(record.accrued_commission)),
    record.accrued_extension_commission === 0
      ? null
      : rowData('Extension commission', formatCurrency(record.accrued_extension_commission)),
    rowData('Interest', formatCurrency(record.accrued_interest)),
    rowData('PDI', formatCurrency(record.accrued_past_due_interest)),
    record.accrued_consulting_fee === 0
      ? null
      : rowData('Consulting fee', formatCurrency(record.accrued_consulting_fee)),
    record.accrued_consulting_fee_add === 0
      ? null
      : rowData('Consulting fee (ADD)', formatCurrency(record.accrued_consulting_fee_add)),
    record.accrued_service_fee === 0 ? null : rowData('Service fee', formatCurrency(record.accrued_service_fee)),
    record.accrued_service_fee_add === 0
      ? null
      : rowData('Service fee (ADD)', formatCurrency(record.accrued_service_fee_add)),
    record.accrued_late_payment_fee === 0
      ? null
      : rowData('Late payment fee', formatCurrency(record.accrued_late_payment_fee)),
    record.accrued_overpayment === 0 ? null : rowData('Overpayment', formatCurrency(record.accrued_overpayment)),
    rowData('Total', formatCurrency(record.accrued_total)),
  ];

  return (
    <Paper>
      <Typography variant="h6" gutterBottom={false} className={classes.paper}>
        Loan
      </Typography>
      <Divider />
      <Table className={classes.table} size="small">
        <TableBody>
          {infoRows.map(row => {
            if (!row) {
              return null;
            }

            return (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Typography variant="h6" gutterBottom={false} className={classes.paper}>
        Today&apos;s total
      </Typography>
      <Divider />
      <Table className={classes.table} size="small">
        <TableBody>
          {calculationRows.map(row => {
            if (!row) {
              return null;
            }
            return (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <FeeAccordion record={record} />
      <FuturePaymentAmount record={record} />
    </Paper>
  );
};

LoanInfo.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    contract_number: PropTypes.string,
    product_name: PropTypes.string,
    discount_details: PropTypes.array,
    discount_amount: PropTypes.number,
    loan_personal_account_short_link_uri: PropTypes.string,
    payment_link_uri: PropTypes.string,
    assigned_collector: PropTypes.string,
    external_agency_id: PropTypes.number,
    collection_score_priority: PropTypes.oneOfType([PropTypes.string]),
    state: PropTypes.string,
    cancel_reason: PropTypes.oneOfType([PropTypes.string]),
    created_at: PropTypes.string,
    disbursed_at: PropTypes.string,
    matured_at: PropTypes.string,
    closed_at: PropTypes.string,
    days_past_due: PropTypes.number,
    number_of_extensions: PropTypes.number,
    account_number: PropTypes.string,
    allowed_extension_tenors_price: PropTypes.object,
    active_promise_date: PropTypes.oneOfType([PropTypes.string]),
    active_promise_amount: PropTypes.oneOfType([PropTypes.number]),
    accrued_principal: PropTypes.number,
    accrued_commission: PropTypes.number,
    accrued_extension_commission: PropTypes.number,
    accrued_interest: PropTypes.number,
    accrued_past_due_interest: PropTypes.number,
    accrued_consulting_fee: PropTypes.number,
    accrued_consulting_fee_add: PropTypes.number,
    accrued_service_fee: PropTypes.number,
    accrued_service_fee_add: PropTypes.number,
    accrued_late_payment_fee: PropTypes.number,
    accrued_overpayment: PropTypes.number,
    accrued_total: PropTypes.number,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
    extension_short_link_uri: PropTypes.string,
    peerberry_status: PropTypes.string,
    peerberry_error: PropTypes.string,
    is_extension_request_allowed: PropTypes.bool,
  }),
};

export default LoanInfo;
