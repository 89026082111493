import React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props) {
  const { data, index, style } = props;
  if (data[index]) {
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  }
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, page, setPage, maxPage, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const listRef = React.createRef();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemSize = smUp ? 36 : 48;
  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const scrollTo = listRef => {
    const scrollTop = listRef.current && listRef.current._outerRef.scrollTop;
    const clientHeight = listRef.current && listRef.current._outerRef.clientHeight;
    const scrollHeight = listRef.current && listRef.current._outerRef.scrollHeight;

    if (scrollHeight && scrollHeight - (scrollTop + clientHeight) < 1) {
      if (page < maxPage) {
        setPage(page => page + 1);
      }
    }
  };
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          ref={listRef}
          onScroll={() => scrollTo(listRef)}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.element,
  page: PropTypes.number,
  setPage: PropTypes.func,
  maxPage: PropTypes.number,
};

export default ListboxComponent;
