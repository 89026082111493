import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#C2C2C1',
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  pt0: {
    paddingTop: 0,
  },
  py0: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  notice: {
    padding: '12px 0 0',
    color: '#777777',
    fontSize: '12px',
  },
}));
