import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textAlignEnd: {
    marginTop: theme.spacing(5),
    textAlign: 'end',
  },
}));

const CheckFileResultsDialog = ({ isOpened, onReject, onApprove, rejectedLoansList, nonexistentLoansList }) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpened} onClose={onReject} fullWidth maxWidth="sm">
      <DialogTitle>File check results</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <b>Loans do not fulfil the conditions:</b>
        </Typography>
        <Typography variant="body1">{[...rejectedLoansList, ...nonexistentLoansList].join(', ')}</Typography>
        <Typography variant="body1" className={classes.textAlignEnd}>
          Upload a new file?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onApprove} color="primary">
          Yes
        </Button>
        <Button onClick={onReject} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CheckFileResultsDialog.propTypes = {
  isOpened: PropTypes.bool,
  onReject: PropTypes.func,
  onApprove: PropTypes.func,
  rejectedLoansList: PropTypes.array,
  nonexistentLoansList: PropTypes.array,
};

CheckFileResultsDialog.defaultProps = {
  rejectedLoansList: [],
  nonexistentLoansList: [],
};

export default CheckFileResultsDialog;
