import * as React from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Button, IconButton, Tooltip, Box, CircularProgress } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  stack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '15px',
  },
}));

const UpdateList = () => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCapture = event => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const handleSubmit = () => {
    setLoading(true);
    dataProvider
      .postImage('sat_blacklist_upload', selectedFile)
      .then(() => notify('File uploaded', 'success'))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        setSelectedFile(null);
        refresh();
      });
  };

  return (
    <Box className={classes.stack}>
      <input accept="text/csv" id="fileInput" type="file" onChange={handleCapture} hidden />
      <Tooltip title="Select file">
        <label htmlFor="fileInput">
          <IconButton color="primary" aria-label="upload file" component="span">
            <BackupIcon fontSize="medium" />
          </IconButton>
        </label>
      </Tooltip>
      {loading ? <CircularProgress /> : <label>{selectedFile ? selectedFile.name : 'Select file ...'}</label>}
      <Button onClick={() => handleSubmit()} color="primary" variant="contained" size="small" disabled={!selectedFile}>
        Upload file
      </Button>
    </Box>
  );
};

export default UpdateList;
