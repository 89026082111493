import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  Toolbar,
  BooleanInput,
  required,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const canEdit = permissions.includes('CAN_EXTENSION_SETTINGS_EDIT');
  const isEditable = canEdit && props.record.product_is_actual;

  return (
    <FormWithRedirect
      save={(
        { is_repeated, principal_from, principal_to, principal_pay, commission, tenor_from, tenor_to },
        ...rest
      ) => {
        save(
          ...[
            {
              is_repeated,
              principal_from,
              principal_to,
              principal_pay,
              commission,
              tenor_from,
              tenor_to,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit extension
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <BooleanInput
                        helperText={false}
                        disabled={!isEditable}
                        label="Is repeated"
                        source="is_repeated"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Tenor from"
                        source="tenor_from"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Tenor to"
                        source="tenor_to"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Principal from"
                        source="principal_from"
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Principal to"
                        source="principal_to"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Principal pay"
                        source="principal_pay"
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        fullWidth
                        disabled={!isEditable}
                        label="Commission"
                        source="commission"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    disabled={!isEditable}
                  />
                  <DeleteButton
                    undoable={false}
                    disabled={!canEdit}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    product_is_actual: PropTypes.bool,
  }),
};

const ExtensionEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default ExtensionEdit;
