import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import BooleanFilter from '../../audience/BooleanFilter';
import DateRangeFilter from '../../audience/DateRangeFilter';
import ListFilter from '../../audience/ListFilter';
import OperationSignFilter from '../../audience/OperationSignFilter';
import OperationEqualFilter from '../../audience/OperationEqualFilter';

export const Filter = ({ type, ...props }) => {
  useEffect(() => {
    if (props.form) {
      props.form.change(`${props.id}.active`, props?.filter?.active ? [props.id] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props?.filter?.active]);

  const components = {
    boolean: <BooleanFilter {...props} />,
    date_range: <DateRangeFilter {...props} />,
    list: <ListFilter {...props} />,
    sign: <OperationSignFilter {...props} />,
    equal: <OperationEqualFilter {...props} />,
  };

  return components[type] || null;
};

Filter.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  filter: PropTypes.object,
};
