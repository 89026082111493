const getCatalogValue = (catalog, key) => {
  if (!key) {
    return '-';
  }

  const value = catalog[key];
  return `${key} (${value ?? 'Valor no encontrado'})`;
};

export default getCatalogValue;
