import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  DeleteButton,
  Edit,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  usePermissions,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Autocomplete from '../../autocomplete/Autocomplete';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { PHONE_CONTACT, TRANSMITTER } from '../../../utils/dictionary';
import { time as timeValidator } from '../../../utils/validator';
import { useHandbook } from '../../../hooks/useHandbook';
import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS } from '../../../constants';

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const [usePhoneBook, setUsePhoneBook] = useState(record.params?.process?.use_phone_book);
  const [params, setParams] = useState(record.params?.process?.phone_book_filters);
  const [weekDays, setWeekDays] = useState(record.params?.week_days || []);
  const [monthDays, setMonthDays] = useState(record.params?.month_days || []);
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [daysMode, setDaysMode] = useState(
    Array.isArray(record.params?.month_days) && record.params.month_days.length > 0 ? DAYS_MODE_DOM : DAYS_MODE_DOW,
  );
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [templateFilter, setTemplateFilter] = useState(null);

  const [errors, setErrors] = useState({});
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_MASS_SENDING_EDIT');

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');
  const phoneStatusChoices = [
    { id: '', name: 'Any' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  const phoneValidationChoices = [
    { id: '', name: 'Any' },
    { id: 'MOBILE', name: 'Mobile' },
    { id: 'LANDLINE', name: 'Landline' },
    { id: 'VOIP', name: 'Voip' },
    { id: 'OTHER', name: 'Other' },
    { id: 'PREPAID', name: 'Prepaid' },
    { id: 'INVALID', name: 'Invalid' },
  ];

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  const showOptions = value => {
    setUsePhoneBook(value);
    setParams([]);
  };

  const addItem = () => {
    setParams([
      ...params,
      {
        id: Date.now(),
        type: null,
        relation: null,
        source: null,
        status: null,
        validation: null,
      },
    ]);
  };

  const setSelectValue = (id, type, value) => {
    setParams([
      ...params.map(item => {
        if (item.id === id && type === 'type' && value !== 'contact_person') {
          item[type] = value;
          item.relation = null;
        } else if (item.id === id) {
          item[type] = value;
        }
        return item;
      }),
    ]);
  };

  const removeItem = id => {
    setParams([...params.filter(item => item.id !== id)]);
  };

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        const validationErrors = {};
        if (daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        save(
          ...[
            {
              ...data,
              params: {
                ...data.params,
                week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
                month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
                process: {
                  ...data?.params?.process,
                  phone_book_filters: params,
                  use_phone_book: usePhoneBook,
                },
                ...rest,
              },
            },
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" gutterBottom={false}>
                      Update mass sending campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput label="Enabled" source="is_enabled" />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        helperText="Format: hh:mm:ss"
                        source="start_time"
                        validate={[required(), value => timeValidator(value)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        resource="mass_sending_audiences"
                        source="audience_id"
                        optionValueProp="id"
                        optionLabelProp="name"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        onChange={e => {
                          formProps.form.change('template_id', null);
                          formProps.form.resetFieldState('template_id');
                          formProps.form.change('channel', null);
                          setIsCmSelected(e.target.value === 'cm');
                          if (e.target.value === 'cm') {
                            setTemplateFilter(null);
                          } else if (e.target.value === 'mailer') {
                            setTemplateFilter({ category: 'Email' });
                          } else {
                            setTemplateFilter({ category: 'SMS' });
                          }
                        }}
                        source="transmitter_id"
                        optionText="name"
                        choices={TRANSMITTER}
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    {isCmSelected ? (
                      <FormDataConsumer>
                        {() => (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                resource="cm_templates"
                                source="template_id"
                                optionValueProp="template_id"
                                optionLabelProp="name"
                                label="Template"
                                filter={{ is_active: true, is_pending: false }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <SelectInput
                                helperText={false}
                                source="channel"
                                optionText="channel_id"
                                optionValue="channel_id"
                                choices={cmProfilesList}
                                fullWidth
                                validate={[required()]}
                              />
                            </Grid>
                          </>
                        )}
                      </FormDataConsumer>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="templates"
                          source="template_id"
                          optionValueProp="id"
                          optionLabelProp="key"
                          label="Template"
                          filter={templateFilter}
                          required
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        allowEmpty
                        source="phone_contact"
                        choices={PHONE_CONTACT}
                        label="Phone results"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup row className={classes.mb2}>
                        <FormControlLabel
                          value={DAYS_MODE_DOW}
                          control={<Radio />}
                          label="Days of week"
                          checked={daysMode === DAYS_MODE_DOW}
                          onChange={() => {
                            setDaysMode(DAYS_MODE_DOW);
                            setMonthDays([]);
                            setErrors({});
                          }}
                        />

                        <FormControlLabel
                          value={DAYS_MODE_DOM}
                          control={<Radio />}
                          label="Days of month"
                          checked={daysMode === DAYS_MODE_DOM}
                          onChange={() => {
                            setDaysMode(DAYS_MODE_DOM);
                            setWeekDays([]);
                            setErrors({});
                          }}
                        />
                      </RadioGroup>

                      {daysMode === DAYS_MODE_DOW && (
                        <>
                          <div>
                            {WEEK_DAYS.map((day, idx) => (
                              <FormControlLabel
                                key={day}
                                control={
                                  <Checkbox
                                    onChange={() => onChangeWeekDay(idx + 1)}
                                    checked={weekDays.includes(idx + 1)}
                                  />
                                }
                                label={day}
                              />
                            ))}
                          </div>
                          <div>
                            {errors.week_days && (
                              <Typography color="error" variant="caption">
                                {errors.week_days}
                              </Typography>
                            )}
                          </div>
                        </>
                      )}

                      {daysMode === DAYS_MODE_DOM && (
                        <>
                          <div className={classes.domList}>
                            {MONTH_DAYS.map(({ value, label }) => (
                              <div key={value} className={classes.domItem}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() => onChangeMonthDay(value)}
                                      checked={monthDays.includes(value)}
                                    />
                                  }
                                  label={label}
                                />
                              </div>
                            ))}
                          </div>
                          <div>
                            {errors.month_days && (
                              <Typography color="error" variant="caption">
                                {errors.month_days}
                              </Typography>
                            )}
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" gutterBottom={false}>
                        IMPORTANT! Please note that the sending campaign will not work until the next day at the
                        earliest! For example, if today is Monday and the campaign is now set to send on Monday, then it
                        will not send today.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider className={classes.my2} />
                <>
                  <FormControlLabel
                    control={<Checkbox checked={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />}
                    label="Use alternative contacts"
                  />

                  {usePhoneBook && (
                    <Button onClick={addItem} color="primary" variant="contained">
                      add
                    </Button>
                  )}
                </>

                {usePhoneBook ? (
                  <Box padding={2}>
                    {params.map(item => (
                      <Grid container alignItems={'center'} spacing={2} key={item.id}>
                        <Grid item xs={12} sm={4}>
                          <SelectInput
                            key={`contact-sources-input-${phoneSourceChoices.length}`}
                            helperText={false}
                            label={'Contact source'}
                            source={`contact_source->${item.id}`}
                            validate={[required()]}
                            choices={phoneSourceChoices}
                            onChange={e => setSelectValue(item.id, 'source', e.target.value)}
                            defaultValue={item.source}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <SelectInput
                            key={`contact-types-input-${phoneTypeChoices.length}`}
                            helperText={false}
                            label={'Contact type'}
                            source={`contact_type->${item.id}`}
                            validate={[required()]}
                            choices={phoneTypeChoices}
                            onChange={e => setSelectValue(item.id, 'type', e.target.value)}
                            defaultValue={item.type}
                            fullWidth
                          />
                        </Grid>
                        {item.type === 'contact_person' ? (
                          <Grid item xs={12} sm={3}>
                            <SelectInput
                              key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                              emptyValue={null}
                              helperText={false}
                              validate={[required()]}
                              label={'Contact relation'}
                              source={`contact_relation->${item.id}`}
                              choices={contactRelationshipChoices}
                              defaultValue={item.relation}
                              onChange={e => setSelectValue(item.id, 'relation', e.target.value)}
                              fullWidth
                            />
                          </Grid>
                        ) : null}

                        <Grid item xs={12} sm={3}>
                          <SelectInput
                            key={`contact-status-input-${phoneStatusChoices.length}`}
                            helperText={false}
                            label={'Contact status'}
                            source={`contact_status->${item.id}`}
                            choices={phoneStatusChoices}
                            onChange={e => setSelectValue(item.id, 'status', e.target.value)}
                            defaultValue={item.status}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <SelectInput
                            key={`contact-validation-input-${phoneValidationChoices.length}`}
                            helperText={false}
                            label={'Contact validation'}
                            source={`contact_validation->${item.id}`}
                            choices={phoneValidationChoices}
                            onChange={e => setSelectValue(item.id, 'validation', e.target.value)}
                            defaultValue={item.validation}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <Button onClick={() => removeItem(item.id)} color="primary" variant="contained">
                            <Delete />
                          </Button>
                        </Grid>
                        <Divider className={classes.my2} />
                      </Grid>
                    ))}
                  </Box>
                ) : null}
                <Divider className={classes.my2} />
                <Box display="flex" justifyContent="space-between">
                  <SaveButton
                    disabled={!isEditable}
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    transform={data => ({
                      ...data,
                      audience: data.audience_id,
                      template: data.template_id,
                      params: {
                        ...(data.params ?? {}),
                        process: {
                          ...(data?.params?.process ?? {}),
                          phone_book_filters: params,
                          alpha_name: data.alpha_name,
                          use_phone_book: usePhoneBook,
                        },
                      },
                    })}
                  />
                  <DeleteButton
                    undoable={false}
                    disabled={!isEditable}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    params: PropTypes.shape({
      process: PropTypes.shape({
        use_phone_book: PropTypes.bool,
        phone_book_filters: PropTypes.array,
        alpha_name: PropTypes.string,
      }),
      week_days: PropTypes.array,
      month_days: PropTypes.array,
    }),
    template_id: PropTypes.number,
    transmitter_id: PropTypes.string,
  }),
};

const MassSendingCampaignEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default MassSendingCampaignEdit;
