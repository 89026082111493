import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { timezone } from '../../utils/adapter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const AddIncomeDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const classes = useStyles();
  const [paymentType, setPaymentType] = useState(null);
  const [amount, setAmount] = useState(null);
  const [paidVia, setPaidVia] = useState(null);
  const [receivedAt, setReceivedAt] = useState(null);
  const [time, setTime] = useState(false);

  const correctTime = new Date(record && record.disbursed_at) < receivedAt;
  const soldLoan = record.state === 'sold';

  useEffect(() => {
    if (isOpened) {
      setPaymentType(null);
      setAmount(null);
      setPaidVia(null);
      setReceivedAt(new Date());
      setTime(false);
    }
  }, [isOpened]);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add loan income</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="income-payment-type-input-label">Payment type</InputLabel>
          <Select
            labelId="income-payment-type-input-label"
            id="income-payment-type-input"
            margin="normal"
            label="Payment type"
            fullWidth
            value={paymentType || ''}
            onChange={e => setPaymentType(e.target.value)}>
            {soldLoan && <MenuItem value="sold">Sold</MenuItem>}
            <MenuItem value="full_repayment" disabled={soldLoan}>
              Full repayment
            </MenuItem>
            <MenuItem value="partial_repayment" disabled={soldLoan}>
              Partial repayment
            </MenuItem>
            <MenuItem value="repayment_for_extension" disabled={soldLoan}>
              Repayment for extension
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="number"
          margin="normal"
          fullWidth
          label="Amount"
          value={amount || ''}
          placeholder="0.00"
          onChange={e => setAmount(parseFloat(e.target.value))}
        />
        <TextField
          type="text"
          margin="normal"
          fullWidth
          label="Paid via"
          value={paidVia || ''}
          placeholder=""
          onChange={e => setPaidVia(e.target.value)}
        />
        {time ? (
          <DateTimePicker
            label="Received at"
            className={classes.fullWidth}
            value={receivedAt && timezone.shift(receivedAt)}
            onChange={value => setReceivedAt(timezone.unshift(value))}
          />
        ) : (
          <DatePicker
            label="Received at"
            className={classes.fullWidth}
            value={receivedAt && timezone.shift(receivedAt)}
            onChange={value => {
              setReceivedAt(timezone.unshift(value));
            }}
          />
        )}
        <FormControlLabel
          control={<Checkbox checked={time} onChange={() => setTime(() => !time)} />}
          label="Change income time"
        />
      </DialogContent>
      <DialogActions>
        {receivedAt && !correctTime && (
          <Typography variant="caption" color="error">
            Payment date is earlier than the loan disbursement date
          </Typography>
        )}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(amount, receivedAt, paidVia, paymentType)}
          color="primary"
          disabled={!paymentType || !amount || !receivedAt || !correctTime}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddIncomeDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    disbursed_at: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default AddIncomeDialog;
