import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ReadyForDisbursementTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => (
  <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Confirm.</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to {record.is_ready_for_disbursement ? 'cancel' : 'confirm'} readiness for disbursement?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
      {record.is_ready_for_disbursement && (
        <Button onClick={() => onSubmit(false)} color="primary">
          Cancel
        </Button>
      )}
      {!record.is_ready_for_disbursement && (
        <Button onClick={() => onSubmit(true)} color="primary">
          Confirm
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

ReadyForDisbursementTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    is_ready_for_disbursement: PropTypes.bool,
  }),
};

export default ReadyForDisbursementTransitionDialog;
