import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';

import { useStyles } from '../styles';
import { timezone } from '../../../../utils/adapter';

const CloseTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [closureDate, setClosureDate] = useState(new Date());

  const classes = useStyles();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Close loan</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose closure date</DialogContentText>
        <DateTimePicker
          className={classes.fullWidth}
          value={timezone.shift(closureDate)}
          onChange={value => setClosureDate(timezone.unshift(value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(closureDate)} color="primary" disabled={!closureDate}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CloseTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CloseTransitionDialog;
