import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { timezones } from '../utils';

const ReportTimezone = ({ classes, value, touched, error, onChange, required = false }) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.fullWidth} error={!!(touched && error && error.timezone)} required={required}>
        <InputLabel id="timezone-select-label">Timezone</InputLabel>
        <Select
          labelId="timezone-select-label"
          value={value && value.timezone ? value.timezone : ''}
          onChange={e => onChange({ ...(value || {}), timezone: e.target.value })}>
          {timezones.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {touched && error && error.timezone && <FormHelperText>{error.timezone}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

ReportTimezone.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  touched: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ReportTimezone;
