import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, useUnselectAll } from 'react-admin';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import AssignCollectorTransitionDialog from './AssignCollectorTransitionDialog';
import AssignAgencyTransitionDialog from './AssignAgencyTransitionDialog';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
}));

const CollectionBulkActionButtons = props => {
  const [isMassCollectorAssignDialogOpened, setIsMassCollectorAssignDialogOpened] = React.useState(false);
  const [isMassAgencyAssignDialogOpened, setIsMassAgencyAssignDialogOpened] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();
  const classes = useStyles();

  const handleClose = () => {
    setIsMassCollectorAssignDialogOpened(false);
    setIsMassAgencyAssignDialogOpened(false);
  };

  const handleCollectorSubmit = collectorId => {
    setLoading(true);
    dataProvider
      .query('loans/collectors', {
        method: 'POST',
        body: JSON.stringify({
          loans: props.selectedIds,
          collector_id: collectorId,
        }),
      })
      .then(() => {
        notify('Collector succesfully assigned', 'success');
        unselectAll('loans');
        dispatch(refreshView());
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        handleClose();
        setLoading(false);
      });
  };

  const handleAgencySubmit = agencyId => {
    setLoading(true);
    dataProvider
      .query('loans/external_agency', {
        method: 'POST',
        body: JSON.stringify({
          loans: props.selectedIds,
          external_agency_id: agencyId,
        }),
      })
      .then(() => {
        notify('External agency succesfully assigned', 'success');
        unselectAll('loans');
        dispatch(refreshView());
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        handleClose();
        setLoading(false);
      });
  };

  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );

  return (
    <Box className={classes.buttons}>
      <Button
        onClick={() => setIsMassCollectorAssignDialogOpened(true)}
        disabled={props.selectedIds.length === 0}
        variant="outlined">
        Assign collector
      </Button>
      <Button
        onClick={() => setIsMassAgencyAssignDialogOpened(true)}
        disabled={props.selectedIds.length === 0}
        variant="outlined">
        Assign collection agency
      </Button>
      <AssignCollectorTransitionDialog
        isOpened={isMassCollectorAssignDialogOpened}
        onClose={handleClose}
        onSubmit={collectorId => handleCollectorSubmit(collectorId)}
      />
      <AssignAgencyTransitionDialog
        isOpened={isMassAgencyAssignDialogOpened}
        onClose={handleClose}
        onSubmit={collectorId => handleAgencySubmit(collectorId)}
      />
    </Box>
  );
};

CollectionBulkActionButtons.propTypes = {
  selectedIds: PropTypes.array,
};

export default CollectionBulkActionButtons;
