import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReportFilterByDate = ({ classes, value, touched, error, onChange, required = false }) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl
        className={classes.fullWidth}
        error={!!(touched && error && error.filter_by_date)}
        required={required}>
        <InputLabel id="sorton-select-label">Filter by date</InputLabel>
        <Select
          labelId="sorton-select-label"
          value={value && value.filter_by_date ? value.filter_by_date : ''}
          onChange={e => onChange({ ...(value || {}), filter_by_date: e.target.value })}>
          <MenuItem value="create">Create</MenuItem>
          <MenuItem value="decision">Decision</MenuItem>
        </Select>
        {touched && error && error.filter_by_date && <FormHelperText>{error.filter_by_date}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

ReportFilterByDate.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  touched: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ReportFilterByDate;
