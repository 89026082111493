import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

import { formatDatetime, formatPhoneNumber } from '../../utils/formatter';
import { TitleCloseDialog } from '../dialog/Dialog';

const OldPhonesDialog = ({ userId, isOpen, onClose }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [oldNumbers, setOldNumbers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      dataProvider
        .getList('user_change_histories', {
          filter: { 'user.id': userId, has_changed_phone_number: true },
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data, total }) => {
          setOldNumbers(data);
          setTotal(total);
        })
        .catch(error => {
          notify(`Error: ${error.message || 'phones downloads was failure'}`, 'error');
        })
        .finally(() => setLoading(false));
    }
  }, [dataProvider, isOpen, notify, page, perPage, setOldNumbers, userId]);

  return (
    <TitleCloseDialog open={isOpen} close={onClose} title={'Old phone numbers'} fullWidth>
      {loading ? (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Initiator</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Phone before</TableCell>
                <TableCell>Phone after</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {oldNumbers.map((item, key) => {
                return (
                  <TableRow key={key + '_log'}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.initiator_name}</TableCell>
                    <TableCell>{formatDatetime(item.created_at)}</TableCell>
                    <TableCell>
                      {item.before.profile?.phoneNumber && formatPhoneNumber(item.before.profile.phoneNumber)}
                    </TableCell>
                    <TableCell>
                      {item.before.profile?.phoneNumber && formatPhoneNumber(item.after.profile.phoneNumber)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={(e, page) => setPage(page + 1)}
            onRowsPerPageChange={e => {
              setPerPage(parseInt(e.target.value, 10));
              setPage(1);
            }}
          />
        </>
      )}
    </TitleCloseDialog>
  );
};

OldPhonesDialog.propTypes = {
  userId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default OldPhonesDialog;
