export default async function copyToClipboard(content = '') {
  try {
    if (!content) {
      return;
    }

    if (typeof document === 'undefined') {
      return;
    }

    if (window?.navigator?.clipboard?.writeText) {
      await window.navigator.clipboard.writeText(content);
    } else {
      const $textarea = document.createElement('textarea');
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

      $textarea.value = ' ';
      $textarea.style.display = 'block';
      $textarea.style.position = 'absolute';
      $textarea.style.left = '-9999px';
      $textarea.style.top = '-9999px';
      $textarea.style.overflow = 'hidden';
      $textarea.style.width = '1px';
      $textarea.style.height = '1px';
      $textarea.style.opacity = '0';
      $textarea.setAttribute('readonly', '');

      document.body.appendChild($textarea);

      $textarea.value = `${content}`;

      $textarea.select();

      document.execCommand('copy');

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      setTimeout(() => document.body.removeChild($textarea), 50);
    }
  } catch (err) {
    console.error(err);
  }
}
