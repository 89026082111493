import React from 'react';
import {
  Create,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  NumberInput,
  useDataProvider,
  required,
  minValue,
  maxValue,
  SelectInput,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ruleTip: {
    marginTop: '0.5rem',
  },
}));

const FormWrapper = props => {
  const [productList, setProductList] = React.useState([]);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data));
  }, [dataProvider]);

  const PRODUCT_CHOICES = productList.filter(item => item.is_actual).map(item => ({ id: item.id, name: item.name }));
  const TEXT_COLOR_CHOICES = [
    { id: 'success', name: 'Success' },
    { id: 'info', name: 'Info' },
    { id: 'warning', name: 'Warning' },
    { id: 'error', name: 'Error' },
  ];

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create rule
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="product_id"
                        validate={[required()]}
                        choices={PRODUCT_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        validate={[required()]}
                        helperText={false}
                        multiline
                        label="Message"
                        source="text"
                        fullWidth
                        resettable
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="text_color"
                        validate={[required()]}
                        choices={TEXT_COLOR_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Min amount"
                        source="min_amount"
                        min={0}
                        max={20000}
                        step={1}
                        validate={[minValue(0), maxValue(20000)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Max amount"
                        source="max_amount"
                        min={0}
                        max={20000}
                        step={1}
                        validate={[minValue(0), maxValue(20000)]}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.ruleTip} variant="body2" gutterBottom={false}>
                    {`Important: Min amount < Approved amount <= Max amount (so the value specified at the beginning of the range  is not subject to the rule)!`}
                  </Typography>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    disabled={!formProps.valid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const LoanHeaderRulesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default LoanHeaderRulesCreate;
