import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const RefreshPopover = ({ onClose, onSubmit, anchor, row }) => {
  return (
    <Popover
      open
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      <Paper>
        <Box p={2}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              onClose();
              onSubmit(row.id, 'renew', row.params?.dialer_id, false);
            }}>
            Renew
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              onClose();
              onSubmit(row.id, 'renew', row.params?.dialer_id, true);
            }}>
            Force
          </Button>
        </Box>
      </Paper>
    </Popover>
  );
};

RefreshPopover.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.number,
    params: PropTypes.shape({
      dialer_id: PropTypes.number,
    }),
  }),
  anchor: PropTypes.element,
};

export default RefreshPopover;
