export default function snakeToHuman(code, defaultValue = '---') {
  if (!code) {
    return defaultValue;
  }

  let name = code;

  name = name.replaceAll(/[-_\s]+/g, ' ');
  name = name.trim();
  name = name.charAt(0).toUpperCase() + name.slice(1);

  return name;
}
