import React from 'react';
import { useField } from 'react-final-form';
import { useNotify, useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';

import { useStyles } from '../styles';

const AudienceReportParamsInput = () => {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [defaultOptions, setDefaultOptions] = React.useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const {
    input: { value, onChange, ...inputProps },
    meta: { touched, error },
  } = useField('audience_report');

  const handleChange = (_event, newValue) => {
    setSelectedOptions(newValue);
    const newValues = newValue.map(item => item.id);
    onChange({ audience: newValues });
    setInputValue('');
  };

  const fetchOptions = async input => {
    const options = {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
      filter: { name: input },
    };
    setLoading(true);
    try {
      const { data } = await dataProvider.getList('mass_sending_audiences', options);
      setOptions(prevOptions => {
        const selectedOptions = prevOptions.filter(option => value.audience?.includes(option.id));
        return [...selectedOptions, ...data.filter(item => !value.audience?.includes(item.id))];
      });
    } catch ({ message }) {
      notify(`Error: ${message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOptions = React.useMemo(
    () => debounce(fetchOptions, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (inputValue.length >= 2) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions(defaultOptions);
    }
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [inputValue, debouncedFetchOptions, defaultOptions]);

  React.useEffect(() => {
    const options = {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
    };
    dataProvider.getList('mass_sending_audiences', options).then(({ data }) => {
      setDefaultOptions(data);
      setOptions(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!open) {
      setOptions(defaultOptions);
    }
  }, [open, defaultOptions]);

  return (
    <Grid item xs={12}>
      <MuiAutocomplete
        {...inputProps}
        fullWidth
        multiple
        open={open}
        onOpen={() => {
          setOpen(true);
          setOptions(defaultOptions);
        }}
        onClose={() => {
          setOpen(false);
          setOptions([]);
        }}
        inputValue={inputValue}
        onInputChange={(_event, newValue, reason) => {
          if (reason === 'input') {
            setInputValue(newValue);
          }
        }}
        value={value && value.audience ? [...selectedOptions.filter(option => value.audience.includes(option.id))] : []}
        onChange={handleChange}
        getOptionLabel={option => option?.name || ''}
        options={options}
        loading={loading}
        noOptionsText={
          inputValue.length < 2 ? 'Use search input above...' : 'There are no templates with the same name'
        }
        renderInput={params => (
          <TextField
            {...params}
            margin="dense"
            label="Audience"
            variant="filled"
            size="small"
            required
            error={touched && Boolean(error)}
            helperText={touched && error && error.audience ? error.audience : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              key={option.id}
              className={classes.chip}
              label={selectedOptions.find(item => item.id === option.id)?.name}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </Grid>
  );
};

export default AudienceReportParamsInput;
