import { timezoneOffsetLabel } from '../../../utils/adapter';

export const timezones = [
  { value: 'America/Mexico_City', label: 'America/Mexico_City (UTC-06:00)' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk (UTC+03:00)' },
  {
    value: 'Europe/Kiev',
    label: (() => {
      const month = new Date().getMonth();

      return 'Europe/Kiev ' + (month >= 3 && month <= 9 ? '(UTC+03:00)' : '(UTC+02:00)');
    })(),
  },
  {
    value: 'Local',
    label: (() => {
      const offset = new Date().getTimezoneOffset() * -1;

      return `Local (UTC${timezoneOffsetLabel(offset)})`;
    })(),
  },
  { value: 'UTC', label: 'UTC' },
];

export const validators = {
  affiliate_credits: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  summary_report: ({ start_date, end_date, calculation_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!calculation_date) {
      errors.calculation_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  income_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  income_outsourcing_report: ({
    payment_start,
    payment_end,
    timezone,
    date_format,
    impersonalized_potfolio,
    columns,
  }) => {
    const errors = {};

    if (!payment_start) {
      errors.payment_start = 'Required';
    }
    if (!payment_end) {
      errors.payment_end = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (impersonalized_potfolio && columns.length === 0) {
      errors.columns = 'Should have at least one column selected';
    }

    return errors;
  },
  outsourcing_register_report: ({ start_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  collection_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  application_report: ({ start_date, end_date, timezone, date_format, filter_by_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!filter_by_date) {
      errors.filter_by_date = 'Required';
    }

    return errors;
  },
  cashflow_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  security_report: ({ start_date, end_date, calculation_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!calculation_date) {
      errors.calculation_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  transaction_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  extension_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  notification_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  local_notification_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  security_application_report: ({ start_date, end_date, timezone, date_format, filter_by_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!filter_by_date) {
      errors.filter_by_date = 'Required';
    }

    return errors;
  },
  promo_codes_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  unfinished_registration_report: ({ timezone, date_format }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  affiliate_event_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  closed_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  verification_staff_statistics_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  promo_code_sms_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  collection_email_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  collection_assign_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  external_agency_assignment_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  collection_call_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  accounting_loan_report: ({ report_period, report_date, timezone }) => {
    const errors = {};

    if (!report_period) {
      errors.report_period = 'Required';
    }
    if (!report_date) {
      errors.report_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }

    return errors;
  },
  accounting_paid_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  accounting_unpaid_report: ({ timezone, date_format, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  accounting_total_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  sales_report: ({ timezone, date_format, impersonalized_potfolio, columns, min_dpd, max_dpd }) => {
    const errors = {};

    if (typeof min_dpd === 'undefined') {
      errors.min_dpd = 'Required';
    }
    if (typeof max_dpd === 'undefined') {
      errors.max_dpd = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (impersonalized_potfolio && columns.length === 0) {
      errors.columns = 'Should have at least one column selected';
    }

    return errors;
  },
  client_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  compliance_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  credit_bureau_confirmation_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  credit_bureau_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  paid_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  payment_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  stp_bank_account_by_loan_id_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  verification_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  audience_report: ({ audience }) => {
    const errors = {};

    if (!audience || audience.length < 1) {
      errors.audience = 'Required';
    }

    return errors;
  },
  invoicing_accounting_loans_granted_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  invoicing_accounting_extension_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  invoicing_accounting_past_due_interest_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  invoicing_accounting_discount_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  invoicing_accounting_paid_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  invoicing_accounting_cancellation_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  loan_inconsistency_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  income_email_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
};
