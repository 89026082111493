import { useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import qs from 'qs';

/** @type Map<string, Promise> */
const promiseStorage = new Map();

function buildUrl(source, query = {}) {
  const queryStr = qs.stringify({ items_per_page: 100, ...query, page: 1 });
  const querySign = source.includes('?') ? '&' : '?';

  return `${source}${querySign}${queryStr}`;
}

export function invalidateCache(source) {
  promiseStorage.delete(source);

  for (const key of promiseStorage.keys()) {
    if (key.startsWith(`${source}/`)) {
      promiseStorage.delete(key);
    }

    if (key.startsWith(`${source}?`)) {
      promiseStorage.delete(key);
    }
  }
}

export function useHandbook(source, query = {}) {
  const dataProvider = useDataProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const url = buildUrl(source, query);

  useEffect(() => {
    function collect() {
      if (promiseStorage.has(url)) {
        return promiseStorage.get(url);
      }

      const promise = new Promise(resolve => {
        dataProvider.fetch(url, { method: 'GET' }).then(response => {
          resolve(response.data);
        });
      });

      promiseStorage.set(url, promise);

      return promise;
    }

    function load() {
      setIsLoading(true);

      return collect().then(rows => {
        setIsLoading(false);
        setData(rows);
      });
    }

    load();
  }, [dataProvider, url]);

  return {
    isLoading,
    data,
    choices: data.map(({ code, name }) => ({ id: code, name })),
    reload: () => invalidateCache(source),
  };
}
