import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  Toolbar,
  SelectInput,
  minValue,
  required,
  useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  ruleTip: {
    marginTop: '0.5rem',
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [productList, setProductList] = React.useState([]);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data));
  }, [dataProvider]);

  const PRODUCT_CHOICES = productList.map(item => ({ id: item.id, name: item.name }));

  return (
    <FormWithRedirect
      save={({ score_from, score_to, rate, loan_number_from, loan_number_to }, ...rest) => {
        save(
          ...[
            {
              id: props.record.id,
              score_from,
              score_to,
              rate,
              loan_number_from,
              loan_number_to,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit rate rule
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="product_id"
                        disabled
                        choices={PRODUCT_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Loan number from"
                        source="loan_number_from"
                        min={0}
                        step={1}
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Loan number to"
                        source="loan_number_to"
                        min={0}
                        step={1}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Score from"
                        source="score_from"
                        min={0}
                        step={1}
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Score to"
                        source="score_to"
                        min={0}
                        step={1}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText="Value format should be like 0.025 (2.5%/100)"
                        label="Rate"
                        source="rate"
                        min={0}
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.ruleTip} variant="body2" gutterBottom={false}>
                    {`Important: Score from < Approved score <= Score to (so the value specified at the beginning of the range  is not subject to the rule)!`}
                  </Typography>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    disabled={!formProps.valid}
                  />
                  <DeleteButton
                    undoable={false}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
};

const LoanRateRulesEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default LoanRateRulesEdit;
