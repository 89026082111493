import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import { useStyles } from '../styles';
import { timezone } from '../../../../utils/adapter';

const SellLoanTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [agencyId, setAgencyId] = useState();
  const [sellPrice, setSellPrice] = useState(null);
  const [sellDate, setSellDate] = useState(null);

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgencies(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  const AGENCIES_CHOICES = agencies
    .filter(agency => agency.status === 'active')
    .map(item => ({ id: item.id, name: item.name }));

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Sell loan to external agency</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select external collection agency from the list.</DialogContentText>
        <>
          <Select
            id="assign-collector-select"
            value={agencyId || ''}
            onChange={e => setAgencyId(e.target.value)}
            className={classes.fullWidth}>
            {AGENCIES_CHOICES.map(agency => (
              <MenuItem key={agency.id} value={agency.id}>
                {agency.name}
              </MenuItem>
            ))}
          </Select>
          <DatePicker
            label="Sell date"
            format="dd MMMM yyyy"
            className={classes.mt2}
            fullWidth
            value={sellDate ? timezone.shift(sellDate) : null}
            onChange={value => setSellDate(timezone.setTime(timezone.unshift(value), 0, 0, 0))}
          />
          <TextField
            onChange={e => setSellPrice(e.target.value)}
            className={classes.mt2}
            type="number"
            label="Sell price, MXN"
            size="small"
            value={sellPrice}
            fullWidth
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit({ id: agencyId, sell_date: sellDate, sell_price: sellPrice })}
          color="primary"
          disabled={!agencyId}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SellLoanTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SellLoanTransitionDialog;
