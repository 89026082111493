import React from 'react';
import { Create, TextInput, BooleanInput, SaveButton, FormWithRedirect, required, maxLength } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import DateTimePickerInput from '../../input/DateTimePickerInput';

import ConditionsInput from './ConditionsInput';
import DiscountsInput from './DiscountsInput';

const useStyles = makeStyles(() => ({
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  const discountsValidation = value => {
    if (!value) {
      return 'Required';
    }
    if (value.some(discount => discount.filter(i => i !== '').length !== 3)) {
      return 'Every field must be filled';
    }
    return undefined;
  };

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Create promo code
                    </Typography>
                    <BooleanInput source="is_enabled" helperText={false} className={classes.titleSwitch} />
                  </Grid>
                  <Divider />
                </Box>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="code" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="campaign" validate={maxLength(255)} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput label="Valid from" source="valid_from" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput label="Valid till" source="valid_till" />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Conditions
                  </Typography>
                  <Divider />
                  <ConditionsInput source="conditions" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Discounts
                  </Typography>
                  <Divider />
                  <DiscountsInput source="discounts" validate={[required('Required'), discountsValidation]} />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="space-between">
                <SaveButton
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
