import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  textRight: {
    textAlign: 'right',
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
}));

const DiscountsInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    isRequired,
    meta: { touched, error },
  } = useInput(props);

  const updateItem = (idx, item) => {
    const newValue = [...(value || [])];
    newValue[idx] = item;
    onChange(newValue);
  };

  return (
    <>
      <List>
        {(value || []).map((item, idx) => (
          <ListItem key={idx} disableGutters>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={5}>
                <FormControl fullWidth required={isRequired} error={!!(touched && error)}>
                  <InputLabel>Transaction type</InputLabel>
                  <Select
                    value={item[0]}
                    onChange={e => updateItem(idx, [e.target.value, item[1], item[2]])}
                    disabled={props.disabled}>
                    <MenuItem value="interest">Interest</MenuItem>
                    <MenuItem value="commission">Commission</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth required={isRequired} error={!!(touched && error)}>
                  <InputLabel>Discount type</InputLabel>
                  <Select
                    value={item[1]}
                    onChange={e => updateItem(idx, [item[0], e.target.value, item[2]])}
                    disabled={props.disabled}>
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percent">Percent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Value"
                  required={isRequired}
                  error={!!(touched && error)}
                  disabled={props.disabled}
                  value={item[2] || ''}
                  onChange={e => updateItem(idx, [item[0], item[1], e.target.value])}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} className={classes.textRight}>
                <IconButton
                  disabled={props.disabled}
                  color="primary"
                  size="small"
                  onClick={() => onChange((value || []).filter((_item, _idx) => _idx !== idx))}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      {!!(touched && error) && (
        <FormHelperText className={classes.mb1} error>
          {error}
        </FormHelperText>
      )}
      <Button
        disabled={props.disabled}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => onChange([...(value || []), ['', '', '']])}>
        Add
      </Button>
    </>
  );
};

DiscountsInput.propTypes = {
  disabled: PropTypes.bool,
};

export default DiscountsInput;
