import React from 'react';
import { Create, SaveButton, Toolbar, FormWithRedirect, TextInput, required, email } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create outgoing mailbox
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <TextInput source="email" validate={[required(), email()]} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput source="name" validate={[required()]} fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const OutgoingMailboxCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default OutgoingMailboxCreate;
