import { green, orange, yellow } from '@material-ui/core/colors';

export const getPTPStatusColor = record => {
  switch (record?.status) {
    case 'in_progress':
      return yellow[300];
    case 'kept':
      return green[400];
    case 'partially_kept':
      return green[200];
    case 'broken':
      return orange[300];
    default:
      return '#FFFFFF';
  }
};
