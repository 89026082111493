import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import IconClear from '@material-ui/icons/Clear';
import IconDone from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { useHandbook } from '../../../hooks/useHandbook';
import { useCollectionGroups } from '../../../hooks/useCollectionGroups';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '5px',
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput
        label="Collection group"
        source="collection_groups->code"
        choices={props.collectiongroups?.map(({ code }) => ({ id: code, name: code }))}
        alwaysOn
      />
      <SelectInput
        alwaysOn
        label="Admin type"
        source="role_permission"
        choices={[
          { id: 'CAN_BE_APPLICATION_VERIFIER', name: 'Verifier' },
          { id: 'CAN_BE_COLLECTION_SPECIALIST', name: 'Collector' },
          { id: 'CAN_BE_PHONE_CALL_OPERATOR', name: 'Phone operator' },
        ]}
      />
      <NumberInput label="Webitel extension" source="webitel_extension" alwaysOn />
      <TextInput label="Username" source="username" />
      <SelectInput
        label="Status"
        source="is_blocked"
        choices={[
          { id: 'true', name: 'Is blocked' },
          { id: 'false', name: 'Is active' },
        ]}
      />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const roleName = (role, roles) => roles.find(item => item.code === role)?.name || 'Unknown';

const hasUserOneOfRoues = (userRoleCodes, searchRoleCodes) => {
  if (Array.isArray(userRoleCodes) === false || Array.isArray(searchRoleCodes) === false) {
    return false;
  }

  for (const userRoleCode of userRoleCodes) {
    if (searchRoleCodes.includes(userRoleCode)) {
      return true;
    }
  }

  return false;
};

const AdminList = props => {
  const { data: collectionGroups } = useCollectionGroups();
  const { data: roles } = useHandbook('admin_auth_roles', { items_per_page: 100 });

  const classes = useStyles();

  const collectionSpecialistRoles = roles
    .filter(({ permissions }) => permissions.includes('CAN_BE_COLLECTION_SPECIALIST'))
    .map(({ code }) => code);
  const phoneCallOperatorRoles = roles
    .filter(({ permissions }) => permissions.includes('CAN_BE_PHONE_CALL_OPERATOR'))
    .map(({ code }) => code);
  const applicationVerifierRoles = roles
    .filter(({ permissions }) => permissions.includes('CAN_BE_APPLICATION_VERIFIER'))
    .map(({ code }) => code);

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter collectiongroups={collectionGroups} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="username" />
        <FunctionField
          label="Roles"
          className={classes.flex}
          render={record =>
            record.roles.map((role, idx) => <Chip key={idx} size="small" label={roleName(role, roles)} />)
          }
        />
        <FunctionField
          label="Collection groups"
          className={classes.flex}
          render={record => record.collection_groups?.map(group => <Chip key={group} size="small" label={group} />)}
        />
        <TextField source="webitel_extension" />
        <FunctionField
          label="Is verifier"
          render={record =>
            hasUserOneOfRoues(record.roles, applicationVerifierRoles) ? (
              <IconDone fontSize="small" />
            ) : (
              <IconClear fontSize="small" />
            )
          }
        />
        <FunctionField
          label="Is collector"
          render={record =>
            hasUserOneOfRoues(record.roles, collectionSpecialistRoles) ? (
              <IconDone fontSize="small" />
            ) : (
              <IconClear fontSize="small" />
            )
          }
        />
        <FunctionField
          label="Is phone operator"
          render={record =>
            hasUserOneOfRoues(record.roles, phoneCallOperatorRoles) ? (
              <IconDone fontSize="small" />
            ) : (
              <IconClear fontSize="small" />
            )
          }
        />
        <BooleanField source="is_blocked" />
      </Datagrid>
    </List>
  );
};

export default AdminList;
