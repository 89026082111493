export const getFormStyles = theme => {
  return {
    paper: {
      padding: theme.spacing(2),
    },
    my2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    domList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gridTemplateColumns: 'repeat',
    },
    domItem: {
      flexBasis: '80px',
      flexShrink: 0,
    },
  };
};

export const ALLOCATION_CHOICES = [
  { id: 1, name: 'Forever' },
  { id: 2, name: 'For number of days' },
  { id: 3, name: 'Until the date' },
];

export const dateValidation = value => {
  // eslint-disable-next-line eqeqeq
  if (value == 'Invalid Date') {
    return 'Invalid date';
  }
  return undefined;
};
