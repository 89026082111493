import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from '../styles';

const AssignCollectorTransitionDialog = ({ isOpened, onClose, onSubmit, collectionGroup }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [collectorId, setCollectorId] = useState('');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .getList('admins', {
        filter: {
          role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
          'collection_groups.code': collectionGroup,
          is_blocked: false,
          pagination: false,
        },
        pagination: {},
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, collectionGroup]);

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Assign collector</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select collector</DialogContentText>
        <Select
          id="assign-collector-select"
          value={collectorId}
          onChange={e => setCollectorId(e.target.value)}
          className={classes.fullWidth}>
          {Object.keys(admins).map(idx => (
            <MenuItem key={idx} value={admins[idx].id}>
              {admins[idx].username}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(collectorId)} color="primary" disabled={!collectorId}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignCollectorTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  collectionGroup: PropTypes.string,
};

export default AssignCollectorTransitionDialog;
