import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { useStyles } from '../styles';
import { DraggableDialog } from '../../../dialog/DraggableDialog';

const StartProcessingTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const [decisionEngineId, setDecisionEngineId] = useState();

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Start processing.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please choose decision engine.</DialogContentText>
        <Select
          id="decision-engine-select"
          value={decisionEngineId || ''}
          onChange={e => setDecisionEngineId(e.target.value)}
          className={classes.fullWidth}>
          <MenuItem value="manual">Manual</MenuItem>
          <MenuItem value="aventus-de">Aventus DE</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(decisionEngineId)} color="primary" disabled={!decisionEngineId}>
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

StartProcessingTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default StartProcessingTransitionDialog;
