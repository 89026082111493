import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: '1rem',
  },
  heading: {
    backgroundColor: '#f36039fa',
    color: '#060606',
  },
  title: {
    color: '#FFFFFF',
  },
  detailsContainer: {
    gap: theme.spacing(2),
  },
  details: {
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
  },
}));

const BlackListMatch = ({ findInBlackList, username }) => {
  const classes = useStyles();
  const matchedField = 'Main phone number is listed in blacklisted user field: ';

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="black-list-match-panel-content"
          id="black-list-match-panel-header">
          <Typography className={classes.title}>User data is matched with blacklisted user</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.detailsContainer}>
            {findInBlackList.map(item => (
              <Paper key={item.id} variant="outlined" className={classes.details}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Typography variant="body2">
                    <b>Blacklisted User ID: </b>
                    {item.user && <Link href={'#users/' + item.user}>{`#${item.user}`}</Link>}
                  </Typography>
                  {item.phoneNumber === username && (
                    <Typography variant="body2">
                      <b>{matchedField}</b>Phone number
                    </Typography>
                  )}
                  {item.contactPhoneNumber === username && (
                    <Typography variant="body2">
                      <b>{matchedField}</b>Contact phone number
                    </Typography>
                  )}
                  {item.companyPhoneNumber === username && (
                    <Typography variant="body2">
                      <b>{matchedField}</b>Company phone number
                    </Typography>
                  )}
                </Grid>
              </Paper>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

BlackListMatch.propTypes = {
  findInBlackList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      phoneNumber: PropTypes.string,
      contactPhoneNumber: PropTypes.string,
      companyPhoneNumber: PropTypes.string,
    }),
  ),
  username: PropTypes.string,
};

BlackListMatch.defaultProps = {
  findInBlackList: [
    {
      id: 0,
      user: '',
      phoneNumber: '',
      contactPhoneNumber: '',
      companyPhoneNumber: '',
    },
  ],
  username: '',
};

export default BlackListMatch;
