import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  Filter,
  TextInput,
  SelectInput,
  NumberInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ListFilterHOC from '../../hoc/ListFilterHOC';
import ListActions from './components/ListActions';
import Pagination from '../../Pagination';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import DateFilterInput from '../../filter/DateFilterInput';
import QuickFilterInput from '../../filter/QuickFilterInput';
import { marginZeroStyles } from '../../../constants';
import { formatDatetime } from '../../../utils/formatter';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFiltersExternalAgency = ({ statefilterchoices, ...props }) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <AsyncSelectInput
        label="External agency"
        source="external_agency->id"
        query={{
          resource: 'external_agencies',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <NumberInput label="Phone number" source="user->profile->phone_number" />
      {statefilterchoices.length ? <SelectInput label="Status" source="state" choices={statefilterchoices} /> : null}
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput
        label="Loan type"
        source="is_repeat"
        choices={[
          { id: 'false', name: 'New' },
          { id: 'true', name: 'Repeated' },
        ]}
      />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
    </Filter>
  );
};

ListFiltersExternalAgency.propTypes = {
  statefilterchoices: PropTypes.array,
};

const ListExternalAgency = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [agenciesList, setAgenciesList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, notify]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">
        <TextField key="id" source="id" />
        <FunctionField
          key="name"
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
              {`${user_first_name} ${user_last_name} #${user_id}`}
            </Link>
          )}
        />
        <TextField key="user_curp" label="CURP" source="user_curp" sortable={false} />
        <FunctionField
          key="external_agency_id"
          label="External agency"
          render={({ external_agency_id }) =>
            agenciesList.find(agency => agency.id === external_agency_id)?.name ?? external_agency_id
          }
        />
        <NumberField key="principal" source="principal" options={{ style: 'currency', currency: 'MXN' }} />,
        <NumberField key="tenor" source="tenor" />
        <ChipField key="state" size="small" source="state" sortable={false} />
        <FunctionField
          key="is_repeat"
          label="Loan type"
          source="is_repeat"
          sortable={false}
          render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
        />
        <FunctionField
          key="created_at"
          label="Created at"
          source="created_at"
          render={({ created_at }) => formatDatetime(created_at)}
        />
        <FunctionField
          key="disbursed_at"
          label="Disbursed at"
          source="disbursed_at"
          render={({ disbursed_at }) => formatDatetime(disbursed_at)}
        />
        <FunctionField
          key="matured_at"
          label="Matured at"
          source="matured_at"
          render={({ matured_at }) => formatDatetime(matured_at)}
        />
        <TextField key="account_number" source="account_number" label={'Account number'} sortable={false} />,
        <FunctionField
          key="button_tab"
          render={record => (
            <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

ListExternalAgency.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

const LoanListExternalAgency = ListFilterHOC(
  ListExternalAgency,
  {
    _state: ['active', 'defaulted'],
    external_agency_has: true,
  },
  [
    { id: 'active', name: 'Active' },
    { id: 'defaulted', name: 'Defaulted' },
  ],
  ListFiltersExternalAgency,
);

export default LoanListExternalAgency;
