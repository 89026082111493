import { formatDatetime } from './formatter';
import { getApiEntrypoint } from './env';

const getFileSrc = (fileName, storageName) => {
  const token = localStorage.getItem('admin_access_token');
  const entrypoint = getApiEntrypoint();
  return `${entrypoint}/files/${storageName}/${fileName}?access_token=${token}`;
};

export const getUserImages = data => {
  return data.map(item => {
    return {
      src: getFileSrc(item.file_name, item.storage_name),
      caption: `Created at: ${formatDatetime(item.created_at)}`,
    };
  });
};

export const getUserAttachments = data => {
  return data.map(item => {
    const fileName = item.file_name.split('/');
    return {
      id: item.id,
      src: getFileSrc(item.file_name, item.storage_name),
      caption: `Created at: ${formatDatetime(item.created_at)}`,
      label: item.label,
      file_name: fileName[fileName.length - 1],
    };
  });
};
