import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

const CommunicationResultCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="code" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

CommunicationResultCreate.propTypes = {};

export default CommunicationResultCreate;
