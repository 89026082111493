import React from 'react';
import { useField } from 'react-final-form';
import { useNotify, useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';

import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const CollectionAssignReportParamsInput = () => {
  const [externalAgencyList, setExternalAgencyList] = React.useState([]);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [defaultOptions, setDefaultOptions] = React.useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('collection_assign_report');

  const handleChangeAdmins = (_event, newValue) => {
    setSelectedOptions(newValue);
    const newValues = newValue.map(item => item.id);
    onChange({ ...(value || {}), assign_admin: newValues, assigned_company: [] });
    setInputValue('');
  };

  const fetchOptions = async input => {
    const options = {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
      filter: { username: input },
    };
    setLoading(true);
    try {
      const { data } = await dataProvider.getList('admins', options);
      setOptions(prevOptions => {
        const selectedOptions = prevOptions.filter(option => value.assign_admin?.includes(option.id));
        return [...selectedOptions, ...data.filter(item => !value.assign_admin?.includes(item.id))];
      });
    } catch ({ message }) {
      notify(`Error: ${message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOptions = React.useMemo(
    () => debounce(fetchOptions, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (inputValue.length >= 2) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions(defaultOptions);
    }
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [inputValue, debouncedFetchOptions, defaultOptions]);

  React.useEffect(() => {
    if (!openAutocomplete) {
      setOptions(defaultOptions);
    }
  }, [openAutocomplete, defaultOptions]);

  React.useEffect(() => {
    const options = {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
    };
    dataProvider
      .getList('admins', options)
      .then(({ data }) => {
        setDefaultOptions(data);
        setOptions(data);
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      });
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => {
        setExternalAgencyList(data.map(item => ({ id: item.id, name: item.name })));
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, notify]);

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          startLabel="Date of assigned start from"
          endLabel="Date of assigned start to"
        />
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MuiAutocomplete
            fullWidth
            multiple
            open={openAutocomplete}
            onOpen={() => {
              setOpenAutocomplete(true);
              setOptions(defaultOptions);
            }}
            onClose={() => {
              setOpenAutocomplete(false);
              setOptions([]);
            }}
            inputValue={inputValue}
            onInputChange={(_event, newValue, reason) => {
              if (reason === 'input') {
                setInputValue(newValue);
              }
            }}
            value={
              value && value.assign_admin
                ? [...selectedOptions.filter(option => value.assign_admin.includes(option.id))]
                : []
            }
            onChange={handleChangeAdmins}
            getOptionLabel={option => option?.username || ''}
            options={options}
            loading={loading}
            noOptionsText={
              inputValue.length < 2 ? 'Use search input above...' : 'There are no admins with the same name'
            }
            renderInput={params => (
              <TextField
                {...params}
                margin="dense"
                label="Assign collector name"
                variant="standard"
                size="small"
                error={touched && Boolean(error) && error.assign_admin}
                helperText={touched && error && error.assign_admin ? error.assign_admin : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  key={option.id}
                  className={classes.chip}
                  label={selectedOptions.find(item => item.id === option.id)?.username}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.assigned_company)}>
            <InputLabel id="assign-company-select-label">Assign agency name</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="assign-company-select-label"
              value={value && value.assigned_company ? [...value.assigned_company] : []}
              onChange={e => onChange({ ...(value || {}), assigned_company: e.target.value, assign_admin: [] })}
              renderValue={selected => (
                <Box className={classes.chipBox}>
                  {selected.map(id => (
                    <Chip
                      className={classes.chip}
                      key={id}
                      label={externalAgencyList.find(externalAgency => externalAgency.id === id)?.name}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {externalAgencyList.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.assigned_company && <FormHelperText>{error.assigned_company}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      <div className={classes.notice}>
        Keep in mind that you can specify &quot;Assign collector name&quot; <b>OR</b> &quot;Assign agency name&quot;,{' '}
        <b>but not both</b>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="min-dpd-number"
            label="Min days past due"
            type="number"
            onChange={e => onChange({ ...(value || {}), min_dpd: +e.target.value })}
            fullWidth
            error={!!(touched && error && error.min_dpd)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="max-dpd-number"
            label="Max days past due"
            type="number"
            onChange={e => onChange({ ...(value || {}), max_dpd: +e.target.value })}
            fullWidth
            error={!!(touched && error && error.max_dpd)}
          />
        </Grid>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default CollectionAssignReportParamsInput;
