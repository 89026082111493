import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, useUnselectAll } from 'react-admin';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssignVerifierTransitionDialog from './AssignVerifierTransitionDialog';

const ApplicationsBulkActionButtons = props => {
  const [isMassAssignVerifierDialogOpened, setIsMassAssignVerifierDialogOpened] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();

  const handleClose = () => setIsMassAssignVerifierDialogOpened(false);

  const handleSubmit = verifierId => {
    setLoading(true);
    dataProvider
      .query('applications/verifiers', {
        method: 'POST',
        body: JSON.stringify({
          applications: props.selectedIds,
          verifier_id: verifierId,
        }),
      })
      .then(() => {
        notify('Verifier succesfully assigned', 'success');
        unselectAll('applications');
        dispatch(refreshView());
        handleClose();
        setLoading(false);
      })
      .catch(error => {
        handleClose();
        notify(`Error: ${error.message}`, 'error');
        setLoading(false);
      });
  };

  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );

  return (
    <>
      <Button
        onClick={() => setIsMassAssignVerifierDialogOpened(true)}
        disabled={props.selectedIds.length === 0}
        variant="outlined">
        Assign verifier
      </Button>
      <AssignVerifierTransitionDialog
        isOpened={isMassAssignVerifierDialogOpened}
        onClose={handleClose}
        onSubmit={verifierId => handleSubmit(verifierId)}
      />
    </>
  );
};

ApplicationsBulkActionButtons.propTypes = {
  selectedIds: PropTypes.array,
};

export default ApplicationsBulkActionButtons;
