import React from 'react';
import {
  Create,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  BooleanInput,
  NumberInput,
  useDataProvider,
  required,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const [productList, setProductList] = React.useState([]);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data));
  }, [dataProvider]);

  const PRODUCT_CHOICES = productList.filter(item => item.is_actual).map(item => ({ id: item.id, name: item.name }));

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create comission
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <BooleanInput helperText={false} label="Is repeated" source="is_repeated" initialValue={false} />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="product_id"
                        validate={[required()]}
                        choices={PRODUCT_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput helperText={false} label="Tenor from" source="tenor_from" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput helperText={false} label="Tenor to" source="tenor_to" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Principal from"
                        source="principal_from"
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput helperText={false} label="Principal to" source="principal_to" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Principal pay"
                        source="principal_pay"
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput helperText={false} label="Commission" source="commission" fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const ExtensionCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default ExtensionCreate;
