import React from 'react';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ExtensionIcon from '@material-ui/icons/Extension';
import PublicIcon from '@material-ui/icons/Public';
import Subject from '@material-ui/icons/Subject';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Photo from '@material-ui/icons/Photo';
import DialerSip from '@material-ui/icons/DialerSip';
import TimerIcon from '@material-ui/icons/Timer';

import { MARKETING_EVENTS_SEND_FROM_OPTIONS, MARKETING_EVENTS_SEND_TO_OPTIONS } from '../../../utils/dictionary';

export const GLOBAL_SETTINGS_OPTIONS_MAP = {
  auto_start_de: {
    title: 'Autostart DE',
    icon: <AccountTreeIcon />,
    options: [
      { id: 1, name: 'Manual' },
      { id: 2, name: 'Aventus DE' },
    ],
  },
  auto_start_juicy_score_config: {
    title: 'Juicy score',
    icon: <FingerprintIcon />,
    options: [
      { id: 1, name: 'only on first clients' },
      { id: 2, name: 'for all clients' },
    ],
  },
  verifier_auto_assignment_config: {
    title: 'Verifier auto assignment',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  geolocation_config: {
    title: 'Geolocation',
    icon: <PublicIcon />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  log_notification: {
    title: 'Log notification',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  regula_required_on_registration: {
    title: 'Is regula response required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  main_phone_aws_validate_on_registration: {
    title: 'Main user phone AWS validation required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  contact_phone_aws_validate_on_registration: {
    title: 'Contact user phone AWS validation required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  allow_send_real_sms: {
    title: 'Allow send real sms',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  uma_value: {
    title: 'UMA',
    icon: <Subject />,
  },
  euro_currency: {
    title: 'Euro currency',
    icon: <Subject />,
  },
  count_phone_numbers_recovery: {
    title: 'Count phone numbers recovery',
    icon: <Subject />,
  },
  referral_value: {
    title: 'Referral value',
    icon: <Subject />,
  },
  annual_disbursement_limit: {
    title: 'Annual disbursement limit',
    icon: <Subject />,
  },
  client_tracking_disbursement_limit: {
    title: 'Client tracking disbursement limit',
    icon: <Subject />,
  },
  need_stp_cep_before_disbursement: {
    title: 'Need stp cep before disbursement',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  create_request_limiter: {
    title: 'Enable limiter for create request',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  sat_blacklist_check: {
    title: 'Sat blacklist check',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  stp_cep_name_similar_check: {
    title: 'Percent similarity for name STP check',
    icon: <Subject />,
    options: [
      { id: 50, name: '50%' },
      { id: 55, name: '55%' },
      { id: 60, name: '60%' },
      { id: 65, name: '65%' },
      { id: 70, name: '70%' },
      { id: 75, name: '75%' },
      { id: 80, name: '80%' },
      { id: 85, name: '85%' },
      { id: 90, name: '90%' },
      { id: 95, name: '95%' },
      { id: 100, name: '100%' },
    ],
  },

  regula_similar_check_replace_enabled: {
    title: 'Enable regula name replace',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  regula_similar_check_replace: {
    title: 'Regula name replace if similarity more than',
    icon: <Subject />,
    options: [
      { id: 60, name: '60%' },
      { id: 65, name: '65%' },
      { id: 70, name: '70%' },
      { id: 75, name: '75%' },
      { id: 80, name: '80%' },
      { id: 85, name: '85%' },
      { id: 90, name: '90%' },
      { id: 95, name: '95%' },
    ],
  },

  stp_cep_check_overdue: {
    title: 'Overdue in minute from STP check to send money',
    icon: <Subject />,
    options: [
      { id: 10, name: '10' },
      { id: 11, name: '11' },
      { id: 12, name: '12' },
      { id: 13, name: '13' },
      { id: 14, name: '14' },
      { id: 15, name: '15' },
      { id: 16, name: '16' },
      { id: 17, name: '17' },
      { id: 18, name: '18' },
      { id: 19, name: '19' },
      { id: 20, name: '20' },
    ],
  },
  promise_complete_amount_part: {
    title: 'Promise complete amount part',
    icon: <Subject />,
    options: [
      { id: 50, name: '50%' },
      { id: 55, name: '55%' },
      { id: 60, name: '60%' },
      { id: 65, name: '65%' },
      { id: 70, name: '70%' },
      { id: 75, name: '75%' },
      { id: 80, name: '80%' },
      { id: 85, name: '85%' },
      { id: 90, name: '90%' },
      { id: 95, name: '95%' },
      { id: 100, name: '100%' },
    ],
  },
  webitel_validate_call_on_registration: {
    title: 'Webitel validate call on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  default_sms_transmitter: {
    title: 'Default SMS transmitter',
    icon: <SettingsInputAntenna />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Amazon SNS' },
      { id: 7, name: 'Airmovil' },
    ],
  },
  repeat_sms_transmitter: {
    title: 'SMS transmitter for repeat SMS',
    icon: <SettingsInputAntenna />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Amazon SNS' },
      { id: 7, name: 'Airmovil' },
    ],
  },
  collection_auto_assign_consider_loans: {
    title: 'Consider loans already assigned to collectors',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Not use old loans' },
      { id: 1, name: 'Use principal from old loans' },
      { id: 2, name: 'Use total from old loans' },
    ],
  },
  collection_auto_assign_sort_loans: {
    title: 'Sort loans before auto assign',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Sort by dpd and sum' },
      { id: 1, name: 'Sort by sum' },
    ],
  },
  default_dialer_transmitter: {
    title: 'Default dialer transmitter',
    icon: <DialerSip />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Webitel' },
    ],
  },
  default_auto_money_transfer: {
    title: 'Default auto money transfer',
    icon: <AttachMoney />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Openpay' },
      { id: 3, name: 'Conekta' },
      { id: 4, name: 'STP' },
      { id: 5, name: 'Unlimit' },
    ],
  },
  default_charge_provider: {
    title: 'Default charge provider',
    icon: <AttachMoney />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Openpay' },
      { id: 3, name: 'Conekta' },
      { id: 4, name: 'STP' },
      { id: 5, name: 'Unlimit' },
    ],
  },
  auto_money_transfer_config: {
    title: 'Auto money transfer',
    icon: <AttachMoney />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
    nested_settings: {
      auto_money_transfer_auto_approved_new: {
        title: 'Auto-approved new customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_auto_approved_repeat: {
        title: 'Auto-approved repeat customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_manual_approved_new: {
        title: 'Manually approved new customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_manual_approved_repeat: {
        title: 'Manually approved repeat customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      uma_value: {
        title: 'UMA Value',
        icon: <Subject />,
      },
      euro_currency: {
        title: 'Euro currency',
        icon: <Subject />,
      },
      count_phone_numbers_recovery: {
        title: 'Count phone numbers recovery',
        icon: <Subject />,
      },
      referral_value: {
        title: 'Referral value',
        icon: <Subject />,
      },
      annual_disbursement_limit: {
        title: 'Annual disbursement limit',
        icon: <Subject />,
      },
      client_tracking_disbursement_limit: {
        title: 'Client tracking disbursement limit',
        icon: <Subject />,
      },
    },
  },
  admin_password_lifetime: {
    title: 'Admin password lifetime',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Do not change' },
      { id: 1, name: '2 weeks' },
      { id: 2, name: '1 month' },
      { id: 3, name: '2 months' },
      { id: 4, name: '3 months' },
    ],
  },
  register_payment_method_after_dpd: {
    title: 'Register payment method after DPD',
    icon: <Subject />,
    options: [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
      { id: 6, name: '6' },
      { id: 7, name: '7' },
      { id: 8, name: '8' },
      { id: 9, name: '9' },
      { id: 10, name: '10' },
    ],
  },
  change_sms_provider_after_error_count: {
    title: 'Change sms provider after error count',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Don\t change provider' },
      { id: 5, name: '5' },
      { id: 10, name: '10' },
      { id: 20, name: '20' },
      { id: 30, name: '30' },
      { id: 40, name: '40' },
      { id: 50, name: '50' },
      { id: 60, name: '60' },
      { id: 70, name: '70' },
      { id: 80, name: '80' },
      { id: 90, name: '90' },
      { id: 100, name: '100' },
      { id: 150, name: '150' },
      { id: 200, name: '200' },
    ],
  },
  auto_debit_percent_write_off: {
    title: 'AutoDebit percent write-off',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Disable write-off' },
      { id: 1, name: '1%' },
      { id: 2, name: '2%' },
      { id: 3, name: '3%' },
      { id: 4, name: '4%' },
      { id: 5, name: '5%' },
      { id: 6, name: '6%' },
      { id: 7, name: '7%' },
      { id: 8, name: '8%' },
      { id: 9, name: '9%' },
      { id: 10, name: '10%' },
      { id: 11, name: '11%' },
      { id: 12, name: '12%' },
      { id: 13, name: '13%' },
      { id: 14, name: '14%' },
      { id: 15, name: '15%' },
      { id: 16, name: '16%' },
      { id: 17, name: '17%' },
      { id: 18, name: '18%' },
      { id: 19, name: '19%' },
      { id: 20, name: '20%' },
      { id: 21, name: '21%' },
      { id: 22, name: '22%' },
      { id: 23, name: '23%' },
      { id: 24, name: '24%' },
      { id: 25, name: '25%' },
    ],
  },
  selfie_photo_liveness_system: {
    title: 'Selfie photo liveness-system',
    icon: <Photo />,
    options: [
      { id: 'hyperverge', name: 'Hyperverge' },
      { id: 'ozforensics', name: 'Ozforensics' },
    ],
  },
  related_phones_add_by: {
    title: 'Related phones added by',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Client' },
      { id: 1, name: 'Client + Admin' },
      { id: 2, name: 'Client + Credit history bureau' },
      { id: 3, name: 'Client + Admin + Credit history bureau' },
      { id: 4, name: 'All' },
    ],
  },
  related_phones_search_phone_by: {
    title: 'Related phones search phone by',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Client' },
      { id: 1, name: 'Client + Admin' },
      { id: 2, name: 'Client + Credit history bureau' },
      { id: 3, name: 'Client + Admin + Credit history bureau' },
    ],
  },
  send_first_sms_for_country: {
    title: 'Send first sms for country',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Mexico' },
      { id: 1, name: 'Any' },
    ],
  },
  send_repeat_sms_for_country: {
    title: 'Send repeat sms for country',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Mexico' },
      { id: 1, name: 'Any' },
    ],
  },
  use_ipinfo_for_get_country: {
    title: 'Use ipinfo for get country',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Disable' },
      { id: 1, name: 'Enable' },
    ],
  },
  auto_extended_all: {
    title: 'Auto extended all',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Disable' },
      { id: 1, name: 'Enable' },
    ],
  },
  load_invoicing_reports: {
    title: 'Load invoicing reports',
    icon: <Photo />,
    options: [
      { id: 0, name: 'Disable' },
      { id: 1, name: 'Enable' },
    ],
  },
  expire_extension: {
    title: 'Expire extension after days',
    icon: <Subject />,
    options: [
      { id: 0, name: '-' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
      { id: 6, name: '6' },
      { id: 7, name: '7' },
      { id: 8, name: '8' },
      { id: 9, name: '9' },
      { id: 10, name: '10' },
    ],
  },
  marketing_events_send_from: {
    title: 'Marketing events: send from',
    icon: <TimerIcon />,
    options: MARKETING_EVENTS_SEND_FROM_OPTIONS,
  },
  marketing_events_send_to: {
    title: 'Marketing events: send to',
    icon: <TimerIcon />,
    options: MARKETING_EVENTS_SEND_TO_OPTIONS,
  },
  show_loan_info_limit_value: {
    title: 'Show loan info limit value',
    icon: <Subject />,
  },
};
