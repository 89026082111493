import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const AddAffiliatePostbackDialog = ({ isOpened, onClose, onSubmit }) => {
  const [state, setState] = useState({
    target_url: null,
    params: {
      send: false,
      loan_id: null,
      application_id: null,
    },
  });

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth={'xs'} fullWidth>
      <DialogTitle id="form-dialog-title">Send postback request.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <TextField
          fullWidth
          label="Target URL"
          value={state.target_url || ''}
          onChange={event => setState({ ...state, target_url: event.target.value })}
        />
        <TextField
          fullWidth
          label="Application id"
          value={state.params.application_id || ''}
          onChange={event => setState({ ...state, params: { ...state.params, application_id: event.target.value } })}
        />
        <TextField
          fullWidth
          label="Loan id"
          value={state.params.loan_id || ''}
          onChange={event => setState({ ...state, params: { ...state.params, loan_id: event.target.value } })}
        />
        <Box mt={2}>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.params.send}
                    onChange={event => {
                      setState({ ...state, params: { ...state.params, send: event.target.checked } });
                    }}
                  />
                }
                label="Send request"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(state)} color="primary" disabled={!state.target_url}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAffiliatePostbackDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddAffiliatePostbackDialog;
