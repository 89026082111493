import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DateTimePicker } from '@material-ui/pickers';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { timezone } from '../../utils/adapter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const UserChangeMoratoriumTransitionDialog = ({ isOpened, onClose, onSubmit, value }) => {
  const classes = useStyles();
  const [moratoriumTillDate, setMoratoriumTillDate] = useState(value ?? null);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change moratorium.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose moratorium till date.</DialogContentText>
        <DateTimePicker
          className={classes.fullWidth}
          value={moratoriumTillDate ? timezone.shift(new Date(moratoriumTillDate)) : null}
          onChange={value => setMoratoriumTillDate(value ? timezone.unshift(value) : null)}
          clearable
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(moratoriumTillDate)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserChangeMoratoriumTransitionDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default UserChangeMoratoriumTransitionDialog;
