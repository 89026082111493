import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const PreviewDialog = ({ isOpened, onClose, previewList }) => (
  <Dialog open={isOpened} onClose={onClose}>
    <DialogTitle>Campaign loans list</DialogTitle>
    <DialogContent>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Loan ID</TableCell>
            <TableCell align="right">Discount, MXN</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previewList?.map(row => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

PreviewDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  previewList: PropTypes.array,
};

PreviewDialog.defaultProps = {
  previewList: [],
};

export default PreviewDialog;
