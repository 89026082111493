import * as React from 'react';
import { Children, cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { HideOnScroll, UserMenu } from 'react-admin';
import { AppBar as MuiAppBar, IconButton, Toolbar, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { toggleSidebar, useTranslate } from 'ra-core';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' },
);
const AppBar = props => {
  const { children, className, color = 'secondary', logout, open, header, userMenu, ...rest } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar disableGutters variant={isXSmall ? 'regular' : 'dense'} className={classes.toolbar}>
          <Typography className={classes.marginLeft}>{header}</Typography>
          <Tooltip
            title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
              _: 'Open/Close menu',
            })}
            enterDelay={500}>
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}>
              <MenuIcon
                classes={{
                  root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          {Children.count(children) === 0 ? (
            <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
          ) : (
            children
          )}
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};
AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
  header: PropTypes.string,
  title: PropTypes.string,
};
AppBar.defaultProps = {
  userMenu: <UserMenu />,
};
export default AppBar;
