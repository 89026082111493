import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, useUnselectAll } from 'react-admin';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
}));

const BulkActionButtons = props => {
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();
  const classes = useStyles();

  const handleRead = () => {
    setLoading(true);
    dataProvider
      .query('admin_notifications/read', {
        method: 'POST',
        body: JSON.stringify({
          notifications: props.selectedIds,
        }),
      })
      .then(() => {
        notify('Messages marked as read', 'success');
        unselectAll('admin_notifications');
        dispatch(refreshView());
        document.dispatchEvent(new Event('check-admin-notifications'));
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUnread = () => {
    setLoading(true);
    dataProvider
      .query('admin_notifications/unread', {
        method: 'POST',
        body: JSON.stringify({
          notifications: props.selectedIds,
        }),
      })
      .then(() => {
        notify('Messages marked as unread', 'success');
        unselectAll('admin_notifications');
        dispatch(refreshView());
        document.dispatchEvent(new Event('check-admin-notifications'));
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );

  return (
    <Box className={classes.buttons}>
      <Button onClick={handleRead} disabled={props.selectedIds.length === 0} variant="outlined">
        Mark as read
      </Button>
      <Button onClick={handleUnread} disabled={props.selectedIds.length === 0} variant="outlined">
        Mark as unread
      </Button>
    </Box>
  );
};

BulkActionButtons.propTypes = {
  selectedIds: PropTypes.array,
};

export default BulkActionButtons;
