import React from 'react';
import PropTypes from 'prop-types';
import { useRedirect, usePermissions } from 'react-admin';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const Actions = ({ record, appView = true }) => {
  const redirect = useRedirect();
  const { permissions = [] } = usePermissions();

  const allowSeeClientPage = record.user_id && permissions.includes('CAN_USER_VIEW');
  const allowSeeLoanPage = record.loan_id && permissions.includes('CAN_LOAN_VIEW');

  if (!allowSeeClientPage && !allowSeeLoanPage) {
    return null;
  }

  return (
    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
      {allowSeeClientPage && <Button onClick={() => redirect(`/users/${record.user_id}/edit`)}>Edit user</Button>}
      {allowSeeLoanPage && <Button onClick={() => redirect(`/loans/${record.loan_id}/show`)}>Show loan</Button>}
      {appView ? (
        <Button onClick={() => redirect(`/applications_verification/${record.id}/show`)}>Verification view</Button>
      ) : (
        <Button onClick={() => redirect(`/applications/${record.id}/show`)}>Application view</Button>
      )}
    </ButtonGroup>
  );
};

Actions.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.number,
    loan_id: PropTypes.number,
    id: PropTypes.number,
  }),
  appView: PropTypes.bool,
};

export default Actions;
