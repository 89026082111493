import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid, TextField, FunctionField, Filter, NumberInput, TextInput } from 'react-admin';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { formatDatetime, formatPhoneNumber } from '../../../utils/formatter';
import Pagination from '../../Pagination';
import { ListActions } from '../user/UserList';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const ArchiveListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="User ID" source="user->id" alwaysOn />
      <NumberInput label="Phone number" source="phone_number" />
      <TextInput label="Archiving author" source="author" />
      <TextInput label="Reason for archiving" source="note" />
    </Filter>
  );
};

export const ArchiveClientsList = ({ ...props }) => {
  const showFullPhone = !props.permissions?.includes('CAN_SEE_FULL_PHONE');
  return (
    <>
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ArchiveListFilter />}
        actions={<ListActions />}
        {...props}>
        <Datagrid>
          <TextField label="ID" source="id" />
          <FunctionField
            label="Phone number"
            source="phone_number"
            render={(record, key) => record[key] && formatPhoneNumber(record[key], showFullPhone)}
          />
          <FunctionField
            label="Date of duplicate registration"
            source="user_created_at"
            render={(record, key) => record[key] && formatDatetime(record[key])}
          />
          <FunctionField
            label="Archiving date"
            source="created_at"
            render={(record, key) => record[key] && formatDatetime(record[key])}
          />
          <TextField label="Archiving author" source="author" />
          <TextField label="Reason for archiving" source="note" />
          <FunctionField
            source="user_id"
            label="Actions"
            render={(record, key) => (
              <Link href={`#users/${record[key]}`} underline="none" target="_blank" rel="noreferrer">
                <IconButton onClick={e => e.stopPropagation()}>
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Link>
            )}
            key="button_tab"
          />
        </Datagrid>
      </List>
    </>
  );
};

ArchiveClientsList.propTypes = {
  permissions: PropTypes.array,
};
