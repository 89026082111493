import React, { useCallback, useState, useEffect } from 'react';
import { usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import { symbolsValidator } from '../../utils/validator';
import { phoneNumber as phoneNumberValidator } from '../../utils/adapter';
import { useHandbook } from '../../hooks/useHandbook';
import { CONTACT_STATUS_LIST, CONTACT_VALIDATION_STATE_LIST } from '../../utils/dictionary';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));
const AddUserContactsDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValidator.formatter());
  const [name, setName] = useState();
  const [phoneType, setPhoneType] = useState();
  const [phoneRelation, setPhoneRelation] = useState(null);
  const [phoneSource, setPhoneSource] = useState();
  const [status, setStatus] = useState('active');
  const [validationState, setValidationState] = useState(null);
  const [note, setNote] = useState();
  const [errors, setErrors] = useState({});

  const { data: contactRelationships } = useHandbook('phone_relations');
  const { data: phoneTypes } = useHandbook('phone_types');
  const { data: phoneSources } = useHandbook('phone_sources');

  const canEdit = permissions.includes('CAN_PHONE_BOOK_EDIT_STATUS');

  useEffect(() => {
    if (phoneSources.length) {
      const admin = phoneSources.find(item => item.code === 'admin');
      admin && setPhoneSource(admin.code);
    }
  }, [phoneSources]);

  const validate = useCallback((type, value) => {
    if (type === 'phone' && !phoneNumberValidator.validator(phoneNumberValidator.parser(value))) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: 'Invalid phone number',
        };
      });
    } else if (type === 'name' && symbolsValidator(value, 2, 255) !== undefined) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: symbolsValidator(value, 2, 255),
        };
      });
    } else {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: undefined,
        };
      });
    }
  }, []);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add user contacts.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Phone number"
              value={phoneNumber}
              onChange={e =>
                setPhoneNumber(phoneNumberValidator.formatter(phoneNumberValidator.parser(e.target.value), false))
              }
              autoComplete={false}
              onBlur={e => validate('phone', e.target.value)}
              name={'phone'}
              error={errors.phone !== undefined}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              name={'name'}
              error={errors.name !== undefined}
              helperText={errors.name}
              fullWidth
              label="Name"
              value={name || ''}
              onChange={e => setName(e.target.value)}
              onBlur={e => validate('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="locale-select-label">Contact Phone Type</InputLabel>
              <Select
                labelId="locale-select-label"
                value={phoneType || ''}
                onChange={e => {
                  setPhoneType(e.target.value);
                  if (e.target.value !== 'contact_person') {
                    setPhoneRelation(null);
                  }
                }}>
                {phoneTypes.map(i => (
                  <MenuItem key={i.code} value={i.code}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="locale-select-label">Contact Phone Source</InputLabel>
              <Select
                labelId="locale-select-label"
                value={phoneSource || ''}
                disabled
                onChange={e => setPhoneSource(e.target.value)}>
                {phoneSources.map(i => (
                  <MenuItem key={i.code} value={i.code}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {phoneType === 'contact_person' ? (
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="locale-select-label">Contact Phone Relation</InputLabel>
                <Select
                  labelId="locale-select-label"
                  value={phoneRelation || ''}
                  onChange={e => setPhoneRelation(e.target.value)}>
                  {contactRelationships.map(i => (
                    <MenuItem key={i.code} value={i.code}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {canEdit && (
            <>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="add-user-contact-status-label">Status</InputLabel>
                  <Select
                    labelId="add-user-contact-status-label"
                    value={status || ''}
                    onChange={e => setStatus(e.target.value)}>
                    {CONTACT_STATUS_LIST.map(({ code, label }) => (
                      <MenuItem key={code} value={code}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="add-user-contact-validation-state-label" shrink>
                    Validation
                  </InputLabel>
                  <Select
                    labelId="add-user-contact-validation-state-label"
                    value={validationState || ''}
                    onChange={e => setValidationState(e.target.value)}
                    displayEmpty
                    renderValue={value => {
                      return CONTACT_VALIDATION_STATE_LIST.find(({ code }) => code === value)?.label || 'None';
                    }}>
                    <MenuItem value={null}>None</MenuItem>
                    {CONTACT_VALIDATION_STATE_LIST.map(({ code, label }) => (
                      <MenuItem key={code} value={code}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              margin="normal"
              multiline
              fullWidth
              label="Note"
              value={note || ''}
              onChange={e => setNote(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            onSubmit(
              phoneNumberValidator.parser(phoneNumber),
              name,
              phoneType,
              note,
              phoneRelation,
              phoneSource,
              status,
              validationState,
            )
          }
          color="primary"
          disabled={
            !phoneNumber ||
            !name ||
            !phoneType ||
            !phoneSource ||
            errors.name ||
            errors.phone ||
            errors.status ||
            errors.validation_state
          }>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserContactsDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddUserContactsDialog;
