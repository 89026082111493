import React from 'react';

const ImportProcessHelperUserTags = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/user_tags.csv" download="import_example_user_tags.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>user_tags_name</b>, required
        </li>
        <li>
          <b>user_id</b>, optional if the loan_id is filled in
        </li>
        <li>
          <b>loan_id</b>, optional if the user_id is filled in
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperUserTags;
