import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ChangePasswordDialog = ({ isOpened, onClose, onSubmit }) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isTempPassword, setIsTempPassword] = useState(true);

  const disableButton = newPassword !== repeatNewPassword || newPassword.length === 0 || repeatNewPassword.length === 0;

  const handleClose = () => {
    setNewPassword('');
    setRepeatNewPassword('');
    setIsTempPassword(true);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({ new_password: newPassword, set_as_temporary_password: isTempPassword });
    setNewPassword('');
    setRepeatNewPassword('');
    setIsTempPassword(true);
  };

  return (
    <Dialog open={isOpened} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change password</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter a new password</DialogContentText>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          value={newPassword}
          label="Password"
          onChange={e => setNewPassword(e.target.value)}
        />
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          value={repeatNewPassword}
          label="Repeat password"
          onChange={e => setRepeatNewPassword(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox checked={isTempPassword} onChange={() => setIsTempPassword(!isTempPassword)} color="primary" />
          }
          label="Change password after first login"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={disableButton}>
          Change password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  collectionGroup: PropTypes.string,
};

export default ChangePasswordDialog;
