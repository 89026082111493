import { isValid } from 'date-fns';

import getAdminTimezone from './getAdminTimezone';

const numberFormat = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'MXN',
});

const numberFormatNarrow = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'MXN',
  currencyDisplay: 'narrowSymbol',
});

const dateFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: getAdminTimezone(),
  });

const datetimeFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: getAdminTimezone(),
  });

const timeFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: getAdminTimezone(),
  });

export const formatCurrency = (value, narrowSymbol = false, defaultValue = '---') => {
  if (typeof value === 'number') {
    return narrowSymbol ? numberFormatNarrow.format(value) : numberFormat.format(value);
  }

  return defaultValue;
};
export const formatDate = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return dateFormat().format(new Date(value));
};

export const formatDatetime = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return datetimeFormat().format(new Date(value));
};

export const formatTime = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return timeFormat().format(new Date(value));
};

export const formatPhoneNumber = (value, masked = false, defaultValue = '---') => {
  let number = value;

  if (!number) {
    return defaultValue;
  }

  if (typeof number !== 'string' || number.length === 0) {
    return defaultValue;
  }
  if (number.startsWith('+52')) {
    number = number.replace('+52', '');
  }
  if (number.length >= 11) {
    return `+52 ${number.slice(0, 3)} ${masked ? '****' : number.slice(3, 7)} ${number.slice(7)}`;
  } else if (number.length === 10) {
    return `+52 ${number.slice(0, 2)} ${masked ? '****' : number.slice(2, 6)} ${number.slice(6)}`;
  } else if (number.length >= 7) {
    return `+52 ${number.slice(0, 2)} ${masked ? '****' : number.slice(2, 6)} ${number.slice(6)}`;
  } else if (number.length >= 3) {
    return `+52 ${number.slice(0, 2)} ${number.slice(2)}`;
  } else {
    return `+52 ${number}`;
  }
};

export const calculateAge = (birth_month, birth_day, birth_year, today_date) => {
  const today_year = today_date.getFullYear();
  const today_month = today_date.getMonth();
  const today_day = today_date.getDate();
  let age = today_year - birth_year;

  if (today_month < birth_month) {
    age--;
  }
  if (today_month === birth_month && today_day < birth_day) {
    age--;
  }
  return age;
};

export const nameTransform = name => {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  } else {
    return null;
  }
};

export const convertToISOString = (dateString, setEndOfDay) => {
  const [day, month, year] = dateString.split('.').map(Number);
  const date = new Date(year, month - 1, day);
  setEndOfDay && date.setHours(23, 59, 59, 0);
  const pad = num => num.toString().padStart(2, '0');
  const isoString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.000Z`;
  return isoString;
};

export const getToday = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = now.getFullYear();
  return `${day}.${month}.${year}`;
};

export const convertToShortString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};
