import React from 'react';

const ImportProcessHelperSold = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/sold.csv" download="import_example_sold.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>Loan ID</b>, required
        </li>
        <li>
          <b>Collection agency ID</b>, required
        </li>
        <li>
          <b>Sell price</b>, number
        </li>
        <li>
          <b>Sell date</b>, required, format <b>2024-01-01</b> or <b>2024-01-01T00:00:00Z</b>
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperSold;
