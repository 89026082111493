import React from 'react';

const ListFilterHOC = (WrappedComponent, filter, statefilterchoices = [], listfilters) => {
  return props => {
    return (
      <WrappedComponent filter={filter} statefilterchoices={statefilterchoices} listfilters={listfilters} {...props} />
    );
  };
};

export default ListFilterHOC;
