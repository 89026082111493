import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  Edit,
  List,
  sanitizeListRestProps,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useRedirect,
  useRefresh,
  usePermissions,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import MUITextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CollectionMotivatorFormDialog from '../../dialog/CollectionMotivatorFormDialog';
import noop from '../../../utils/noop';
import { invalidateCache } from '../../../hooks/useHandbook';

const useStyles = makeStyles(() => ({
  formToolbar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const CollectionMotivatorChildrenActions = ({ className, onOpenCreateModal, ...rest }) => {
  const handleClick = e => {
    e.preventDefault();

    onOpenCreateModal();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button color="primary" size="small" type="button" onClick={handleClick} startIcon={<IconAdd />}>
        Create
      </Button>
    </TopToolbar>
  );
};

CollectionMotivatorChildrenActions.propTypes = {
  className: PropTypes.string,
  onOpenCreateModal: PropTypes.func,
};

CollectionMotivatorChildrenActions.defaultProps = {
  onOpenCreateModal: noop,
};

const loadMotivatorRecord = (dataProvider, id) => {
  return new Promise((resolve, reject) => {
    if (!id) {
      return resolve(null);
    }

    dataProvider
      .getOne('collection_motivators', { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
};

const CollectionMotivatorToolbar = ({ ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleSuccessDelete = () => {
    invalidateCache('collection_motivators');

    redirect('/collection_motivators');
  };

  return (
    <Toolbar {...props} className={classes.formToolbar}>
      <SaveButton label="Save" />
      <DeleteButton label="Delete" undoable={false} onSuccess={handleSuccessDelete} />
    </Toolbar>
  );
};

const CollectionMotivatorFormWrapper = ({ save, record, onLoadRecord, ...props }) => {
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [parent, setParent] = useState(null);
  const [isParentLoaded, setIsParentLoaded] = useState(false);

  const isEditable = permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT');

  useEffect(() => {
    onLoadRecord(record);
  }, [record, onLoadRecord]);

  useEffect(() => {
    loadMotivatorRecord(dataProvider, record.parent).then(parentRecord => {
      setParent(parentRecord);
      setIsParentLoaded(true);
    });
  }, [record, dataProvider]);

  return (
    <SimpleForm
      save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])}
      toolbar={<CollectionMotivatorToolbar />}
      {...props}>
      <MUITextField
        label="Parent motivator"
        variant="filled"
        margin="dense"
        disabled
        value={isParentLoaded ? (parent ? `${parent.name} #${parent.id}` : '--- None ---') : '--- Loading ---'}
      />
      <br />
      <TextInput disabled source="id" />
      <TextInput disabled={!isEditable} source="name" />
    </SimpleForm>
  );
};

CollectionMotivatorFormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    parent: PropTypes.number,
  }),
  onLoadRecord: PropTypes.func,
};

const CollectionMotivatorEdit = props => {
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [record, setRecord] = useState(null);

  const refresh = useRefresh();

  const handleOpenCreateModal = () => {
    setIsCreateModalOpened(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpened(false);
  };

  const handleSubmitCreateModal = () => {
    refresh();
    setIsCreateModalOpened(false);
  };

  return (
    <>
      <Edit mutationMode="pessimistic" {...props}>
        <CollectionMotivatorFormWrapper onLoadRecord={setRecord} />
      </Edit>

      {record && record.parent === null && (
        <>
          <List
            bulkActionButtons={false}
            filter={{ parent: props.id }}
            resource={props.resource}
            basePath={props.basePath}
            actions={<CollectionMotivatorChildrenActions onOpenCreateModal={handleOpenCreateModal} />}
            empty={false}
            title="&nbsp;">
            <Datagrid rowClick="edit">
              <TextField source="id" />
              <BooleanField sortable={false} source="enabled" />
              <TextField sortable={false} source="name" />
            </Datagrid>
          </List>

          <CollectionMotivatorFormDialog
            defaultParent={parseInt(`${props.id}`)}
            isOpened={isCreateModalOpened}
            onClose={handleCloseCreateModal}
            onSubmit={handleSubmitCreateModal}
          />
        </>
      )}
    </>
  );
};

CollectionMotivatorEdit.propTypes = {
  id: PropTypes.string,
  resource: PropTypes.string,
  basePath: PropTypes.string,
};

export default CollectionMotivatorEdit;
