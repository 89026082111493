import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';
import PropTypes from 'prop-types';

import { formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: '1rem',
  },
  heading: {
    backgroundColor: '#f50057',
    color: '#060606',
  },
  title: {
    color: '#FFFFFF',
  },
  details: {
    padding: theme.spacing(2),
  },
}));

const BlackList = ({ createdBy, createdAt, comment, reason }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="black-list-panel-content"
          id="black-list-panel-header">
          <Typography className={classes.title}>User in blacklist</Typography>
          <BlockIcon style={{ color: 'white', paddingLeft: '0.5rem' }} />
        </AccordionSummary>
        <AccordionDetails>
          <Paper variant="outlined" className={classes.details}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Typography variant="body2">
                <b>Comment: </b>
                {comment}
              </Typography>
              <Typography variant="body2">
                <b>Created at: </b>
                {formatDatetime(createdAt)}
              </Typography>
              <Typography variant="body2">
                <b>Created by: </b>
                {createdBy}
              </Typography>
              <Typography variant="body2">
                <b>Reason: </b>
                {reason}
              </Typography>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

BlackList.propTypes = {
  createdBy: PropTypes.string,
  createdAt: PropTypes.string,
  comment: PropTypes.string,
  reason: PropTypes.string,
};

BlackList.defaultProps = {
  createdBy: '',
  createdAt: '',
  comment: '',
  reason: '',
};

export default BlackList;
