import React from 'react';

const ImportProcessHelperNotification = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/notification.csv" download="import_example_notification.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>User ID</b>, required
        </li>
        <li>
          <b>Template id</b>, required
        </li>
        <li>
          <b>Scheduled at</b>, required, format <b>2024-01-01T00:00:00Z</b>, for immediate sending use keyword{' '}
          <b>now</b>
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperNotification;
