import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';

const test = [
  { propname: 'FIXED', year: '1992' },
  { propname: 'PERCENT', year: '1990' },
];
const dictionary = {
  total: 0,
  options: test,
};

export const withDictionary = WrappedComponent => {
  const AutoCompleteDictionary = ({ ...props }) => {
    return <WrappedComponent {...props} result={dictionary} />;
  };
  return AutoCompleteDictionary;
};
export const withServer = WrappedComponent => {
  const AutoCompleteServer = ({ perOnePage, resource, filter, ...props }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [result, setResult] = useState({
      options: [],
      total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const perPage = perOnePage;
    const maxPage = Math.ceil(result.total / perPage);

    useEffect(() => {
      setLoading(state => !state);
      dataProvider
        .getList(resource, {
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
          filter,
        })
        .then(({ data, total }) => setResult({ options: [...result.options, ...data], total }))
        .catch(({ message }) => notify(`Error: ${message}`, 'error'))
        .then(() =>
          setTimeout(() => {
            setLoading(state => !state);
          }, 1000),
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
      <WrappedComponent result={result} loading={loading} maxPage={maxPage} setPage={setPage} page={page} {...props} />
    );
  };

  AutoCompleteServer.propTypes = {
    perOnePage: PropTypes.number,
    resource: PropTypes.string,
    filter: PropTypes.object,
  };

  return AutoCompleteServer;
};
