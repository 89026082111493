import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

import { formatDatetime } from '../../utils/formatter';
import snakeToHuman from '../../utils/snakeToHuman';

const ExternalAgencyAssignTable = ({ loanId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [assignLog, setAssignLog] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const tableRows = ['Assignment type', 'Agency name', 'Assigned by', 'Assigned at', 'Stop at'];

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('external_agency_logs', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setAssignLog(data);
        setTotal(total);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));

    dataProvider
      .getList('external_agencies', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => setAgencies(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableRows.map(row => (
                <TableCell key={row}>{row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assignLog.map(row => (
              <TableRow key={row.id}>
                <TableCell>{snakeToHuman(row.type)}</TableCell>
                <TableCell>
                  {row.external_agency_id ? agencies.find(agency => agency.id === row.external_agency_id)?.name : '---'}
                </TableCell>
                <TableCell>
                  {row.created_by_admin_id && `Admin ${row.created_by_admin_username}#${row.created_by_admin_id}`}
                  {row.created_by_campaign_id &&
                    `Campaign ${row.created_by_campaign_name}#${row.created_by_campaign_id}`}
                  {!row.created_by_admin_id && !row.created_by_campaign_id && 'System'}
                </TableCell>
                <TableCell>{formatDatetime(row.created_at, '---')}</TableCell>
                <TableCell>{formatDatetime(row.stopped_at, '---')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={e => {
            setPerPage(+(e.target.value, 10));
            setPage(1);
          }}
        />
      </div>
    </>
  );
};

ExternalAgencyAssignTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default ExternalAgencyAssignTable;
