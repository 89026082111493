export const rowData = (name, value) => ({ name, value });

export const transactionsListTransform = data => {
  const newData = data.map(item => ({
    ...item,
    grouped_transactions: [],
  }));

  const idMap = new Map(newData.map(item => [item.id, item]));

  for (let i = newData.length - 1; i >= 0; i--) {
    const item = newData[i];
    if (item.id !== item.original_transaction_id) {
      const originalItem = idMap.get(item.original_transaction_id);
      if (originalItem) {
        originalItem.grouped_transactions.push(item);
        newData.splice(i, 1);
      }
    }
  }

  newData.forEach(item => {
    if (item.grouped_transactions.length > 0) {
      item.grouped_transactions.push({ ...item });

      if (item.grouped_transactions.length % 2 === 0) {
        item.amount = 0;
      }
    }

    item.grouped_transactions.sort((a, b) => a.id - b.id);
  });

  return newData;
};
