import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { DraggableDialog } from '../../dialog/DraggableDialog';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  modalTitle: {
    cursor: 'move',
  },
}));

const AssignAgencyTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [collectorId, setCollectorId] = useState();

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    isOpened &&
      dataProvider
        .getList('external_agencies', {
          filter: { status: 'active' },
          pagination: { page: 1, perPage: 300 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => setAgencies(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false));
  }, [dataProvider, isOpened]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Assign external agency
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please select external agency from the list</DialogContentText>
        {loading ? (
          <CircularProgress />
        ) : (
          <Select
            id="assign-collector-select"
            value={collectorId || ''}
            onChange={e => setCollectorId(e.target.value)}
            className={classes.fullWidth}>
            {agencies.map(admin => (
              <MenuItem key={admin.id} value={admin.id}>
                {admin.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(collectorId)} color="primary" disabled={!collectorId}>
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

AssignAgencyTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AssignAgencyTransitionDialog;
