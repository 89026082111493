import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  green: {
    color: '#25d366',
  },
  grey: {
    color: theme.palette.grey[400],
  },
}));

const WhatsAppButton = ({ phoneNumber, disabled }) => {
  const classes = useStyles();
  const whatsAppLink = `https://api.whatsapp.com/send?phone=52${phoneNumber}`;

  return (
    <IconButton onClick={() => window.open(whatsAppLink, '_blank')} disabled={disabled}>
      <WhatsAppIcon className={disabled ? classes.grey : classes.green} />
    </IconButton>
  );
};

WhatsAppButton.propTypes = {
  phoneNumber: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WhatsAppButton;
