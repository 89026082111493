import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  textRight: {
    textAlign: 'right',
  },
}));

const ParamItem = ({ isEditable, paramKey, paramValue, onChangeKey, onChangeValue, onDeleteParam }) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={5}>
          <TextField
            disabled={!isEditable}
            fullWidth
            label="Key"
            value={paramKey}
            onChange={e => onChangeKey(paramKey, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            disabled={!isEditable}
            fullWidth
            label="Value"
            value={paramValue}
            onChange={e => onChangeValue(paramKey, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2} className={classes.textRight}>
          <IconButton disabled={!isEditable} color="primary" size="small" onClick={() => onDeleteParam(paramKey)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
};

ParamItem.propTypes = {
  isEditable: PropTypes.bool,
  paramKey: PropTypes.string,
  paramValue: PropTypes.string,
  onChangeKey: PropTypes.func,
  onChangeValue: PropTypes.func,
  onDeleteParam: PropTypes.func,
};

const JsonParamsInput = ({ isEditable = true, label, ...props }) => {
  const { input } = useInput(props);
  const { value = null, onChange } = typeof props.onChange === 'function' ? props : input;
  const objValue = typeof value === 'object' ? value : {};

  const onChangeParamKey = (oldParamKey, newParamKey) => {
    if (Object.prototype.hasOwnProperty.call(objValue, newParamKey)) {
      return;
    }

    const newObjValue = {};

    for (const p in objValue) {
      newObjValue[p === oldParamKey ? newParamKey : p] = objValue[p];
    }

    onChange(newObjValue);
  };

  const onChangeParamValue = (paramKey, newParamValue) => {
    const newObjValue = { ...objValue };
    newObjValue[paramKey] = newParamValue;
    onChange(newObjValue);
  };

  const onDeleteParam = paramKey => {
    const newObjValue = { ...objValue };
    delete newObjValue[paramKey];
    onChange(newObjValue);
  };

  return (
    <>
      <Typography variant="subtitle1">{label}</Typography>
      <List disablePadding>
        {Object.keys(objValue).map((paramKey, idx) => (
          <ParamItem
            isEditable={isEditable}
            key={idx}
            paramKey={paramKey}
            paramValue={objValue[paramKey]}
            onChangeKey={onChangeParamKey}
            onChangeValue={onChangeParamValue}
            onDeleteParam={onDeleteParam}
          />
        ))}
        <Button
          disabled={!isEditable}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onChange({ ...objValue, '': '' })}>
          Add
        </Button>
      </List>
    </>
  );
};

JsonParamsInput.propTypes = {
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default JsonParamsInput;
