import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid } from 'react-admin';

import ListActions from './components/ListActions';
import Pagination from '../../Pagination';
import { LOAN_LIST_ROWS } from './constants';

const LoanListDefault = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">{LOAN_LIST_ROWS.map(i => i)}</Datagrid>
    </List>
  );
};

LoanListDefault.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

export default LoanListDefault;
