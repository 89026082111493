import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import PropTypes from 'prop-types';
import { communicationCategoryGroupChoices } from '../../../constants';

const FormWrapper = ({ save, ...props }) => (
  <SimpleForm save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])} {...props}>
    <TextInput disabled source="id" />
    <TextInput source="name" validate={[required()]} />
    <SelectInput source="group" validate={[required()]} choices={communicationCategoryGroupChoices} />
  </SimpleForm>
);

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const CommunicationCategoryEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default CommunicationCategoryEdit;
