import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInput, useQuery, useNotify, useRefresh } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: theme.spacing(20),
  },
  resetButton: {
    color: theme.palette.action.active,
    fontSize: '1.5rem',
    padding: '12px',
    marginRight: theme.spacing(1),
  },
}));

const AsyncSelectInput = ({
  query: { resource, payload },
  mapper,
  label,
  type = 'getList',
  additionalData,
  resettable = false,
  ...props
}) => {
  const [newPayload, setNewPayload] = useState(payload);
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const {
    input: { value, onChange },
  } = useInput(props);

  const { data, loading, error, loaded, total } = useQuery({ type, resource, payload: newPayload });

  const loadMore = e => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;

    if (bottom && total > data.length && !!newPayload.filter.items_per_page) {
      setNewPayload({
        ...newPayload,
        filter: {
          ...newPayload.filter,
          items_per_page: newPayload.filter.items_per_page + 10,
        },
      });
    }
  };

  const handleReset = () => {
    onChange('');
    refresh();
  };

  if (loading && !loaded) return <CircularProgress />;
  if (error) notify(`Error: ${error.message}`, 'error');

  const newData = [...data, ...(additionalData || [])];

  return (
    <>
      {resettable && (
        <Tooltip title="Reset selection">
          <IconButton size="small" className={classes.resetButton} onClick={handleReset} edge="end">
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      )}
      <FormControl variant="filled" margin="dense" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          autoWidth
          className={classes.select}
          MenuProps={{
            PaperProps: {
              onScroll: loadMore,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            style: {
              maxHeight: 400,
            },
            getContentAnchorEl: null,
          }}>
          {(newData || [])
            .map(item => mapper(item))
            .map(({ value, name }, idx) => (
              <MenuItem key={idx} value={value}>
                {name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText></FormHelperText>
      </FormControl>
    </>
  );
};

AsyncSelectInput.propTypes = {
  query: PropTypes.shape({
    resource: PropTypes.string,
    payload: PropTypes.object,
  }),
  mapper: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  additionalData: PropTypes.array,
  resettable: PropTypes.bool,
};

export default AsyncSelectInput;
