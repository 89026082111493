import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from '../styles';
import { useProducts } from '../../../../hooks/useProducts';
import { withServer } from '../../../select/withWrapper';
import SelectAutoComplete from '../../../select/SelectAutoComplete';

const AutoComplete = withServer(SelectAutoComplete);

const ChangeTermsTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const [productId, setProductId] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [tenor, setTenor] = useState(null);
  const [displayedPrincipal, setDisplayedPrincipal] = useState(null);
  const [displayedTenor, setDisplayedTenor] = useState(null);
  const [promoCode, setPromoCode] = useState(null);

  const { data: products } = useProducts();
  const classes = useStyles();

  useEffect(() => {
    if (record) {
      setProductId(record.product_id);
      setPrincipal(record.principal);
      setTenor(record.tenor);
      setDisplayedPrincipal(record.principal);
      setDisplayedTenor(record.tenor);
      setPromoCode(record.promo_code);
    }
  }, [record]);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change terms</DialogTitle>
      <DialogContent>
        <DialogContentText>To change loan terms, please fill following fields</DialogContentText>
        <FormControl className={classes.fullWidth}>
          <InputLabel id="productId-select-label">Product</InputLabel>
          <Select
            labelId="productId-select-label"
            value={productId || ''}
            onChange={e => {
              setProductId(e.target.value);
              const product = products.find(product => e.target.value === product.id);
              if (product) {
                setPrincipal(
                  Math.round(
                    Math.min(Math.max(product.principal.min, principal), product.principal.max) /
                      product.principal.step,
                  ) * product.principal.step,
                );
                setTenor(
                  Math.round(Math.min(Math.max(product.tenor.min, tenor), product.tenor.max) / product.tenor.step) *
                    product.tenor.step,
                );
              }
            }}>
            {products.map(product => (
              <MenuItem key={product.id} value={product.id} disabled={product.isDisabled}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={classes.mt2} id="principal-slider" gutterBottom>
          Principal: {`${displayedPrincipal / 1e3}k`}
        </Typography>
        <Slider
          defaultValue={record.principal}
          valueLabelFormat={value => `${value / 1e3}k`}
          aria-labelledby="principal-slider"
          valueLabelDisplay="auto"
          step={products.find(product => productId === product.id)?.principal.step || 1000}
          marks
          min={products.find(product => productId === product.id)?.principal.min || 2000}
          max={products.find(product => productId === product.id)?.principal.max || 50000}
          onChangeCommitted={(ev, value) => setPrincipal(value)}
          onChange={(ev, value) => setDisplayedPrincipal(value)}
        />
        <Typography id="tenor-slider" gutterBottom>
          Tenor: {displayedTenor} days
        </Typography>
        <Slider
          defaultValue={record.tenor}
          aria-labelledby="tenor-slider"
          valueLabelDisplay="auto"
          step={record.is_repeat ? 5 : products.find(product => productId === product.id)?.tenor.step || 10}
          marks
          min={products.find(product => productId === product.id)?.tenor.min || 10}
          max={products.find(product => productId === product.id)?.tenor.max || 30}
          onChangeCommitted={(ev, value) => setTenor(value)}
          onChange={(ev, value) => setDisplayedTenor(value)}
        />
        <AutoComplete
          perOnePage={30}
          resource="promo_codes"
          label="Promo codes"
          filter={{ is_enabled: true }}
          value={promoCode}
          setValue={setPromoCode}
          prop={'code'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(productId, principal, tenor, promoCode === record.promo_code ? null : promoCode)}
          color="primary"
          disabled={!(productId && principal && tenor)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeTermsTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
  }),
};

export default ChangeTermsTransitionDialog;
