import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Field, Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { required, useDataProvider } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

import noop from '../../utils/noop';
import { useCollectionMotivators } from '../../hooks/useCollectionMotivators';

const useStyles = makeStyles(() => ({
  form: {
    width: 400,
  },
}));

const CREATE_ROOT_MOTIVATOR_VALUE = -1;

const CollectionMotivatorFormDialog = ({ defaultParent, isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { data: collectionParentMotivators, reload } = useCollectionMotivators();

  const handleSubmit = values => {
    const data = {
      parent: values.parent && values.parent > 0 ? values.parent : null,
      name: values.name,
    };

    dataProvider
      .fetch('collection_motivators', {
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then(() => {
        reload();
        onSubmit();
      });
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <FinalForm
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>Create new motivator</DialogTitle>
            <DialogContent>
              <Field
                name="parent"
                defaultValue={defaultParent || CREATE_ROOT_MOTIVATOR_VALUE}
                validate={required('Can not be empty.')}>
                {({ input, meta }) => (
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="motivator-parent-label">Motivator parent</InputLabel>

                    {collectionParentMotivators.length > 0 && (
                      <Select
                        id="motivator-parent-input"
                        margin="dense"
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={e => {
                          input.onChange(e.target.value ? parseInt(e.target.value) : null);
                        }}
                        fullWidth>
                        <MenuItem key={CREATE_ROOT_MOTIVATOR_VALUE} value={CREATE_ROOT_MOTIVATOR_VALUE}>
                          -- Create root motivator --
                        </MenuItem>

                        {collectionParentMotivators.map(motivator => (
                          <MenuItem key={motivator.id} value={motivator.id}>
                            {motivator.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {meta.touched && meta.error && (
                      <FormHelperText error={meta.touched && meta.error}>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="name" validate={required('Can not be empty.')}>
                {({ input, meta }) => (
                  <TextField
                    type="text"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    id="motivator-name-input"
                    label="Motivator name"
                    variant="filled"
                    margin="dense"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CollectionMotivatorFormDialog.propTypes = {
  defaultParent: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CollectionMotivatorFormDialog.defaultProps = {
  onClose: noop,
  onSubmit: noop,
};

export default CollectionMotivatorFormDialog;
