import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
  useDataProvider,
  useNotify,
  useRefresh,
  TextInput,
  SelectInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { formatDatetime } from '../../../utils/formatter';
import { CHOICES } from '../../../utils/dictionary';
import customDataProvider from '../../../dataProvider';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Created by" source="created_by" />
      <TextInput label="Name" source="name" />
      <SelectInput source="type" choices={CHOICES} />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ConfirmToDeleteDialog = ({ isOpened, onClose, onSubmit, title }) => {
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this item?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmToDeleteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

const AudienceActions = ({ record = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const deleteHandler = e => {
    e.stopPropagation();

    dataProvider
      .delete('mass_sending_audiences', { id: record.id })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        refresh();
      });
  };

  const downloadHandler = e => {
    e.stopPropagation();
    setLoading(true);
    customDataProvider
      .downloadFile(`mass_sending_audiences/${record.id}/download`)
      .then(({ data: objUrl }) => {
        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `mass_sending_audience_id_${record.id}.csv`;
        link.click();
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setLoading(false));
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton color="primary" onClick={downloadHandler}>
        <CloudDownloadIcon />
      </IconButton>
      <IconButton
        color="primary"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}>
        <HighlightOffIcon />
      </IconButton>

      <ConfirmToDeleteDialog
        isOpened={isOpen}
        onClose={e => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        onSubmit={deleteHandler}
        title={record.name}
      />
    </Box>
  );
};

AudienceActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <FunctionField
        label="Created by"
        source="createdBy.username"
        render={({ created_by_username }) => created_by_username}
      />
      <FunctionField
        label="Created at"
        source="created_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <FunctionField
        label="Updated at"
        source="updated_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <AudienceActions />
    </Datagrid>
  </List>
);
