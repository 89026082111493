import React from 'react';
import { useRedirect } from 'react-admin';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PropTypes from 'prop-types';

const LoanActions = ({ permissions, record: { user_id, application_id } }) => {
  const redirect = useRedirect();

  const canViewUser = permissions.includes('CAN_USER_VIEW') && !!user_id;
  const canViewApplication = permissions.includes('CAN_APPLICATION_VIEW') && !!application_id;

  if (!canViewUser && !canViewApplication) {
    return null;
  }

  return (
    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
      {canViewUser && <Button onClick={() => redirect(`/users/${user_id}/edit`)}>Edit user</Button>}
      {canViewApplication && (
        <Button onClick={() => redirect(`/applications/${application_id}/show`)}>Show application</Button>
      )}
    </ButtonGroup>
  );
};

LoanActions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  record: PropTypes.shape({
    user_id: PropTypes.number,
    application_id: PropTypes.number,
  }),
};

export default LoanActions;
