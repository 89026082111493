import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberInput,
  FunctionField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import { formatDatetime } from '../../../utils/formatter';
import { marginZeroStyles } from '../../../constants';

import UpdateList from './UpdateList';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  stack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '15px',
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="SAT number" source="sat_number" alwaysOn />
      <TextInput label="RFC" source="rfc" />
      <TextInput label="State" source="state" />
      <TextInput label="Name" source="name" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Updated before" source="updated_at|before" before />
      <DateFilterInput label="Updated after" source="updated_at|after" after />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
      <UpdateList />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const SatBlackList = props => {
  const classes = useStyles();

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'sat_number', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions className={classes.stack} />}
      {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="sat_number" />
        <TextField source="rfc" label="RFC" />
        <TextField source="name" />
        <TextField source="state" />
        <TextField source="global_sat_presumption_date" sortable={false} />
        <TextField source="proposed_sat_publication_date" sortable={false} />
        <TextField source="global_dof_date" sortable={false} />
        <TextField source="proposed_dof_publication_date" sortable={false} />
        <TextField source="global_sat_taxpayer_date" sortable={false} />
        <TextField source="sat_publication_date" sortable={false} />
        <TextField source="global_dof_taxpayer_date" sortable={false} />
        <TextField source="dof_date" sortable={false} />
        <TextField source="global_ending_sat_date" sortable={false} />
        <TextField source="ending_sat_date" sortable={false} />
        <TextField source="ending_dof_date" sortable={false} />
        <TextField source="ending_publication_dof_date" sortable={false} />
        <TextField source="global_positive_sat_date" sortable={false} />
        <TextField source="positive_sat_date" sortable={false} />
        <TextField source="global_positive_dof_date" sortable={false} />
        <TextField source="positive_dof_date" sortable={false} />
        <FunctionField source="updated_at" render={(record, key) => record[key] && formatDatetime(record[key])} />
        <FunctionField source="created_at" render={(record, key) => record[key] && formatDatetime(record[key])} />
      </Datagrid>
    </List>
  );
};

export default SatBlackList;
