import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  ChipField,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';
import { formatDatetime } from '../../../utils/formatter';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Loan ID" source="loan->id" alwaysOn />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const MoneyTransferList = props => {
  const getRowStyle = record => {
    switch (record.status) {
      case 'failure':
        return { backgroundColor: red[300] };
      case 'success':
        return { backgroundColor: green[300] };
      case 'unknown':
        return { backgroundColor: grey[300] };
      default:
        return null;
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'failure':
        return <Chip size="small" label={status} icon={<ErrorOutlineIcon />} />;
      case 'success':
        return <Chip size="small" label={status} icon={<DoneAllIcon />} />;
      case 'unknown':
        return <Chip size="small" color="secondary" label={status} icon={<HelpOutlineIcon />} />;
      default:
        return <Chip size="small" label={status} />;
    }
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick={null} rowStyle={getRowStyle}>
        <TextField source="id" />
        <TextField label="Loan ID" source="loan_id" />
        <FunctionField
          label="Triggered by"
          render={({ triggered_by_admin_id, triggered_by_admin_username }) =>
            triggered_by_admin_id ? `#${triggered_by_admin_id} ${triggered_by_admin_username}` : null
          }
        />
        <TextField source="gateway" />
        <ChipField size="small" source="state" />
        <FunctionField label="status" render={({ status }) => getStatus(status)} />
        <TextField source="status" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Updated at"
          source="updated_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Scheduled at"
          source="scheduled_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Finished at"
          source="finished_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Error"
          source="error"
          render={(record, key) => (record[key] ? <Typography variant="caption">{record[key]}</Typography> : null)}
        />
      </Datagrid>
    </List>
  );
};

export default MoneyTransferList;
