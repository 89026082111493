import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const ReportEmpty = () => (
  <Grid item xs={12}>
    <Typography variant="body2" gutterBottom={false}>
      No parameters for this report
    </Typography>
  </Grid>
);

export default ReportEmpty;
