import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  childrenBox: {
    overflow: 'auto',
  },
}));

export const TitleCloseDialog = ({ children, open, close, title, ...props }) => {
  const style = useStyles();
  return (
    <Dialog open={open} onClose={() => close(v => !v)} {...props}>
      <Grid container className={style.header}>
        <Grid item xs={10} sm={10}>
          <Typography variant="h6" className={style.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Box textAlign="right">
            <IconButton onClick={() => close(v => !v)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box className={style.childrenBox}>{children}</Box>
    </Dialog>
  );
};

TitleCloseDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
