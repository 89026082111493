import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { formatPhoneNumber } from '../../utils/formatter';

const BasePreviewDialog = ({ isOpened, onClose, previewList }) => {
  const [listToShow, setListToShow] = useState(20);

  const handleShowMore = () => {
    setListToShow(previewList?.length);
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle>Base preview</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Phone number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previewList?.slice(0, listToShow)?.map(row => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.phone_number && formatPhoneNumber(row.phone_number)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {listToShow < previewList?.length && (
          <Button onClick={handleShowMore} color="primary">
            + {previewList?.length - listToShow} more users
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

BasePreviewDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  previewList: PropTypes.array,
};

BasePreviewDialog.defaultProps = {
  previewList: [],
};

export default BasePreviewDialog;
