import React from 'react';
import {
  Create,
  BooleanInput,
  TextInput,
  SaveButton,
  NumberInput,
  Toolbar,
  FormWithRedirect,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create rejection reason.
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput fullWidth label="Name" source="name" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput source="moratorium_in_days" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <BooleanInput label="Enabled" source="is_enabled" initialValue={true} />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
