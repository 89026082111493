export const rowData = (name, value) => ({ name, value });

export const verifierRow = (row, state, name) => {
  if (row === 'aventus-de' && (state === 'approved' || state === 'confirmed')) {
    return 'Auto Disbursement';
  } else if (row === 'aventus-de' && state === 'rejected') {
    return 'Auto reject';
  } else {
    return name;
  }
};
