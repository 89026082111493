import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const LinearProgressField = ({ source, record = {} }) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress variant="determinate" value={Math.round(100 * record[source])} />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2" color="textSecondary">{`${Math.round(100 * record[source])}%`}</Typography>
    </Box>
  </Box>
);

LinearProgressField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

export default LinearProgressField;
