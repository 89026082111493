import React from 'react';
import { Edit, SimpleForm, TextInput, usePermissions } from 'react-admin';
import PropTypes from 'prop-types';

const FormWrapper = ({ save, ...props }) => {
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_TEMPLATE_EDIT');

  return (
    <SimpleForm save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])} {...props}>
      <TextInput disabled source="id" />
      <TextInput disabled source="code" />
      <TextInput disabled={!isEditable} source="name" />
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const HandbookEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default HandbookEdit;
