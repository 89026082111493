import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDataProvider, useNotify } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import { debounce, isEqual } from 'lodash';

const titleCase = s => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d));

/**
 * Autocomplete component that provides suggestions based on user input.
 *
 * @param {string} resource - The resource to fetch data from.
 * @param {string} source - Field name in form.
 * @param {object} sort - The sorting options for fetched data.
 * @param {boolean} required - Flag indicating if the field is required.
 * @param {number} page - The current page of data.
 * @param {number} perPage - The number of items per page.
 * @param {object} filter - Additional filtering criteria.
 * @param {string} label - The label for the autocomplete field.
 * @param {string} variant - The variant of the autocomplete field.
 * @param {string} size - The size of the autocomplete field.
 * @param {string} optionValueProp - The property to use as the value of the selected option.
 * @param {string} optionLabelProp - The property to use as the label of the options.
 * @param {boolean} multiple - Allow multiple selections if true.
 * @return {JSX.Element} The Autocomplete component.
 */

const Autocomplete = ({
  resource,
  source,
  sort,
  required,
  page = 1,
  perPage = 20,
  filter = null,
  label,
  variant = 'filled',
  size = 'small',
  optionValueProp = 'key',
  optionLabelProp = 'name',
  margin = 'dense',
  helperText = '',
  requiredMessage = 'Required',
  onFieldChange = () => {},
  disableParams = null,
  multiple = false,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueObject, setValueObject] = React.useState(multiple ? [] : null); // Ensure valueObject is an array for multiple
  const [defaultOptions, setDefaultOptions] = React.useState([]);
  const [prevFilter, setPrevFilter] = React.useState(filter);

  const form = useForm();
  const {
    input: { value, onChange, ...inputProps },
    meta: { touched, error },
  } = useField(source, {
    validate: value => (required && !value ? requiredMessage : undefined),
  });

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue, reason) => {
    onFieldChange(event);
    setValueObject(multiple ? [] : null);
    if (reason === 'clear' || newValue === null || newValue === undefined) {
      onChange(null);
      form.change(source, null);
    } else {
      if (multiple) {
        const selectedValues = newValue.map(item => item[optionValueProp]);
        onChange(selectedValues);
        form.change(source, selectedValues);
        setValueObject(newValue);
      } else {
        onChange(newValue[optionValueProp]);
        form.change(source, newValue[optionValueProp]);
        setValueObject(newValue);
      }
      setInputValue('');
    }
  };

  const fetchOptions = async input => {
    const options = {
      pagination: { page, perPage },
      sort: sort ?? { field: 'id', order: 'DESC' },
      filter: { ...filter, [optionLabelProp]: input },
    };
    setLoading(true);
    try {
      const { data } = await dataProvider.getList(resource, options);
      const fetchedOptions = data.map(item => ({
        ...item,
        [optionLabelProp]: item[optionLabelProp],
      }));
      setOptions(fetchedOptions);
    } catch ({ message }) {
      notify(`Error: ${message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOptions = React.useMemo(
    () => debounce(fetchOptions, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  React.useEffect(() => {
    if (inputValue.length >= 2) {
      debouncedFetchOptions(inputValue);
    }
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [inputValue, debouncedFetchOptions]);

  React.useEffect(() => {
    if (!isEqual(filter, prevFilter)) {
      const options = {
        pagination: { page: 1, perPage: 20 },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: filter ?? {},
      };
      dataProvider.getList(resource, options).then(({ data }) => {
        const options = data.map(item => ({
          ...item,
          [optionLabelProp]: item[optionLabelProp],
        }));
        setDefaultOptions(options);
        setOptions(options);
        setPrevFilter(filter);
      });
    }
  }, [filter, prevFilter, dataProvider, resource, sort, optionLabelProp]);

  React.useEffect(() => {
    if (resource) {
      const options = {
        pagination: { page: 1, perPage: 20 },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: filter ?? {},
      };
      dataProvider.getList(resource, options).then(({ data }) => {
        const options = data.map(item => ({
          ...item,
          [optionLabelProp]: item[optionLabelProp],
        }));
        setDefaultOptions(options);
        setOptions(options);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (value) {
      const options = {
        pagination: { page: 1, perPage: 20 },
        sort: sort ?? { field: 'id', order: 'DESC' },
        filter: { ...filter, [optionValueProp]: value },
      };
      dataProvider.getList(resource, options).then(({ data }) => {
        const items = data.map(item => ({ ...item, [optionLabelProp]: item[optionLabelProp] }));
        if (data.length > 1) {
          const valueObject = multiple
            ? data.filter(item => value.includes(item.id))
            : data.find(item => item[optionValueProp] === value);
          setValueObject(valueObject);
        } else {
          setValueObject(multiple ? data : items[0]);
        }
      });
    } else {
      setValueObject(multiple ? [] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    setOptions(defaultOptions);
  }, [open, defaultOptions]);

  const getDisabledPromocodes = option => {
    if (disableParams) {
      if (resource === 'promo_codes') {
        const { principal, tenor, isRepeatedLoan } = disableParams;
        const { max_principal, min_principal, max_tenor, min_tenor, is_repeat_loan } = option.conditions;

        let matchByPrincipal = true;
        if (max_principal !== undefined || min_principal !== undefined) {
          if (min_principal === undefined) {
            matchByPrincipal = principal <= max_principal;
          } else if (max_principal === undefined) {
            matchByPrincipal = principal >= min_principal;
          } else {
            matchByPrincipal = max_principal >= principal && principal >= min_principal;
          }
        }

        let matchByTenor = true;
        if (max_tenor !== undefined || min_tenor !== undefined) {
          if (min_tenor === undefined) {
            matchByTenor = tenor <= max_tenor;
          } else if (max_tenor === undefined) {
            matchByTenor = tenor >= min_tenor;
          } else {
            matchByTenor = max_tenor >= tenor && tenor >= min_tenor;
          }
        }

        if (typeof is_repeat_loan === 'boolean') {
          const matchByRepeat = is_repeat_loan === isRepeatedLoan;
          return !matchByPrincipal || !matchByTenor || !matchByRepeat;
        } else {
          return !matchByPrincipal || !matchByTenor;
        }
      }
    }
    return false;
  };

  return (
    <MuiAutocomplete
      {...inputProps}
      fullWidth
      open={open}
      multiple={multiple}
      onOpen={() => {
        setOpen(true);
        setInputValue('');
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(_event, newValue, reason) => {
        if (reason === 'input') {
          setInputValue(newValue);
        }
      }}
      value={multiple ? valueObject || [] : valueObject ? valueObject[optionLabelProp] : null}
      onChange={handleChange}
      getOptionSelected={(option, value) => option[optionValueProp] === value[optionValueProp]}
      getOptionDisabled={getDisabledPromocodes}
      getOptionLabel={option => (typeof option === 'string' ? option : option?.[optionLabelProp] || '')}
      options={options}
      loading={loading}
      noOptionsText={inputValue.length < 2 ? 'Use search input above...' : 'There are no templates with the same name'}
      renderInput={params => (
        <TextField
          {...params}
          margin={margin}
          label={label ?? titleCase(source)}
          variant={variant}
          size={size}
          required={required}
          error={touched && Boolean(error)}
          helperText={touched && error ? (typeof error === 'string' ? error : JSON.stringify(error)) : helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  required: PropTypes.bool,
  page: PropTypes.number,
  perPage: PropTypes.number,
  filter: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  optionValueProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  requiredMessage: PropTypes.string,
  onFieldChange: PropTypes.func,
  disableParams: PropTypes.object,
  multiple: PropTypes.bool, // Add the prop type for multiple
};

export default Autocomplete;
