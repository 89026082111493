import React from 'react';
import PropTypes from 'prop-types';
import { DateInput, Edit, SaveButton, DeleteButton, SimpleForm, TextInput, Toolbar, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { invalidateCache } from '../../../hooks/useHandbook';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const HolidayEditToolbar = ({ isEditable, ...props }) => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton label="Save" redirect="list" disabled={!isEditable} sx={{ mr: 2 }} />
      <DeleteButton undoable={false} disabled={!isEditable} />
    </Toolbar>
  );
};

HolidayEditToolbar.propTypes = {
  isEditable: PropTypes.bool,
};

const FormWrapper = ({ save, ...props }) => {
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.indexOf('CAN_HOLIDAY_EDIT') !== -1;

  const handleSave = (data, ...rest) => {
    save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
    invalidateCache('templates');
  };

  return (
    <SimpleForm toolbar={<HolidayEditToolbar isEditable={isEditable} />} save={handleSave} {...props}>
      <TextInput disabled={true} source="id" />
      <TextInput label="Holiday" source="title" />
      <DateInput label="Date" source="day" />
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const HolidayEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default HolidayEdit;
