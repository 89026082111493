import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import PropTypes from 'prop-types';

const FormWrapper = ({ save, ...props }) => (
  <SimpleForm save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])} {...props}>
    <TextInput disabled source="id" />
    <TextInput source="name" validate={[required()]} />
    <TextInput source="code" validate={[required()]} />
  </SimpleForm>
);

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const CommunicationResultEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default CommunicationResultEdit;
