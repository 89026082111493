import { useDataProvider, useNotify } from 'react-admin';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import formatISO from 'date-fns/formatISO';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import UserConfirmTransitionDialog from '../../dialog/UserConfirmTransitionDialog';
import UserAddToBlackListDialog from '../../dialog/UserAddToBlackListDialog';
import UserChangeMoratoriumTransitionDialog from '../../dialog/UserChangeMoratoriumTransitionDialog';
import UserConfirmationDialog from '../../dialog/UserConfirmationDialog';

const useStyles = makeStyles(theme => ({
  mb4: {
    marginBottom: theme.spacing(4),
  },
  textRight: {
    textAlign: 'right',
  },
  red: {
    backgroundColor: '#f50057',
    '&:hover': {
      backgroundColor: 'rgb(206 11 81)',
    },
  },
  green: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
}));

const UserEditTransitions = props => {
  const { userId, transitions, refresh, moratoriumTill } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [disabled, setDisabled] = useState(false);
  const [isConfirmTransitionDialogOpened, setIsConfirmTransitionDialogOpened] = useState(false);
  const [isBlockTransitionDialogOpened, setIsBlockTransitionDialogOpened] = useState(false);
  const [isActivateTransitionDialogOpened, setIsActivateTransitionDialogOpened] = useState(false);
  const [isChangeMoratoriumTransitionDialogOpened, setIsChangeMoratoriumTransitionDialogOpened] = useState(false);
  const [addToBlockListDialogOpened, setAddToBlockListDialogOpened] = useState(false);
  const classes = useStyles();
  if (!transitions) return null;

  const applyTransition = (name, params = {}) => {
    setDisabled(value => !value);
    dataProvider
      .query(`users/${userId}/apply_transition`, { method: 'POST', body: JSON.stringify({ name, params }) })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled(value => !value));
  };

  return (
    <>
      <Grid container className={classes.mb4} justifyContent="space-between">
        <Grid item xs={6}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            {transitions.indexOf('confirm') !== -1 && (
              <Button onClick={() => setIsConfirmTransitionDialogOpened(true)} disabled={disabled}>
                Confirm
              </Button>
            )}
            {transitions.indexOf('block') !== -1 && (
              <Button onClick={() => setIsBlockTransitionDialogOpened(true)} disabled={disabled}>
                Block
              </Button>
            )}
            {transitions.indexOf('activate') !== -1 && (
              <Button onClick={() => setIsActivateTransitionDialogOpened(true)} disabled={disabled}>
                Activate
              </Button>
            )}
            {transitions.indexOf('change_moratorium') !== -1 && (
              <Button onClick={() => setIsChangeMoratoriumTransitionDialogOpened(true)} disabled={disabled}>
                Change Moratorium
              </Button>
            )}
            {transitions.indexOf('refresh_selfie_matches') !== -1 && (
              <Button onClick={() => applyTransition('refresh_selfie_matches')} disabled={disabled}>
                Refresh selfie matches
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid item xs={6} className={classes.textRight}>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            {transitions.indexOf('otp_unblock') !== -1 && (
              <Button className={classes.red} onClick={() => applyTransition('otp_unblock')} disabled={disabled}>
                Unblock OTP
              </Button>
            )}
            {transitions.indexOf('add_black_list') !== -1 && (
              <Button className={classes.red} onClick={() => setAddToBlockListDialogOpened(true)} disabled={disabled}>
                Add to black list
              </Button>
            )}
            {transitions.indexOf('remove_black_list') !== -1 && (
              <Button
                className={classes.green}
                onClick={() => applyTransition('remove_black_list')}
                disabled={disabled}>
                Remove from black list
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
      {isConfirmTransitionDialogOpened && (
        <UserConfirmTransitionDialog
          isOpened={isConfirmTransitionDialogOpened}
          onClose={() => setIsConfirmTransitionDialogOpened(false)}
          onSubmit={confirmationCode => {
            setIsConfirmTransitionDialogOpened(false);
            applyTransition('confirm', { confirmation_code: confirmationCode });
          }}
        />
      )}
      {addToBlockListDialogOpened && (
        <UserAddToBlackListDialog
          user={props}
          isOpened={addToBlockListDialogOpened}
          onClose={() => setAddToBlockListDialogOpened(false)}
          applyTransition={applyTransition}
        />
      )}
      {isChangeMoratoriumTransitionDialogOpened && (
        <UserChangeMoratoriumTransitionDialog
          isOpened={isChangeMoratoriumTransitionDialogOpened}
          onClose={() => setIsChangeMoratoriumTransitionDialogOpened(false)}
          onSubmit={moratoriumTillDate => {
            setIsChangeMoratoriumTransitionDialogOpened(false);
            applyTransition('change_moratorium', {
              moratorium_till: moratoriumTillDate ? formatISO(moratoriumTillDate) : null,
            });
          }}
          value={moratoriumTill}
        />
      )}
      {isBlockTransitionDialogOpened && (
        <UserConfirmationDialog
          title="Block user"
          message="Are you sure you want to block this user?"
          onClose={() => setIsBlockTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsBlockTransitionDialogOpened(false);
            applyTransition('block');
          }}
        />
      )}
      {isActivateTransitionDialogOpened && (
        <UserConfirmationDialog
          title="Activate user"
          message="Are you sure you want to activate this user?"
          onClose={() => setIsActivateTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsActivateTransitionDialogOpened(false);
            applyTransition('activate');
          }}
        />
      )}
    </>
  );
};

UserEditTransitions.propTypes = {
  userId: PropTypes.number.isRequired,
  transitions: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  moratoriumTill: PropTypes.string,
};

export default UserEditTransitions;
