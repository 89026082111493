import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification, defaultTheme, useDataProvider } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(() => ({
  layout: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    backgroundRepeat: 'no-repeat',
  },
  header: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    minWidth: '300px',
    marginTop: '6em',
  },
  formWrapper: {
    padding: '0 1em 1em 1em',
  },
}));

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [newPassValid, setNewPassValid] = useState(true);

  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const disabledSignIn = !username.length || !password.length || loading;
  const disabledChange = !oldPassword.length || !newPassword.length || !repeatNewPassword.length || loading;

  const onLogin = e => {
    setLoading(true);
    e.preventDefault();
    login({ username, password })
      .catch(e => {
        if (e.message === 'Change your password.') {
          setChangePass(true);
        }
        notify(e.message, 'error');
      })
      .finally(() => setLoading(false));
  };

  const onChangePassword = async () => {
    setLoading(true);

    await dataProvider
      .query('admins/password', {
        method: 'PATCH',
        body: JSON.stringify({ username, old_password: oldPassword, new_password: newPassword }),
      })
      .then(() => notify('Success: password successfully changed', 'success'))
      .catch(error => notify(`Error: ${error.message}`, 'error'));

    await login({ username, password: newPassword })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Box className={classes.layout}>
        {changePass ? (
          <Card className={classes.card}>
            <Box className={classes.header}>
              <Avatar>
                <LockIcon />
              </Avatar>
            </Box>
            <Box className={classes.formWrapper}>
              <Box marginTop="1em">
                <TextField
                  label="Old password"
                  type="password"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box marginTop="1em">
                <TextField
                  label="New password"
                  type="password"
                  value={newPassword}
                  onChange={e => {
                    e.target.value === repeatNewPassword && setNewPassValid(true);
                    setNewPassword(e.target.value);
                  }}
                  onBlur={e => {
                    e.target.value !== repeatNewPassword && setNewPassValid(false);
                  }}
                  error={!newPassValid}
                  helperText={!newPassValid && 'Passwords are not the same'}
                  fullWidth
                />
              </Box>
              <Box marginTop="1em">
                <TextField
                  label="Repeat new password"
                  type="password"
                  value={repeatNewPassword}
                  onChange={e => {
                    e.target.value === newPassword && setNewPassValid(true);
                    setRepeatNewPassword(e.target.value);
                  }}
                  fullWidth
                  error={!newPassValid}
                  helperText={!newPassValid && 'Passwords are not the same'}
                  onBlur={e => {
                    e.target.value !== newPassword && setNewPassValid(false);
                  }}
                />
              </Box>
            </Box>
            <CardActions>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
                onClick={onChangePassword}
                color="primary"
                fullWidth
                disabled={disabledChange || repeatNewPassword !== newPassword}>
                Change password
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card className={classes.card}>
            <Box className={classes.header}>
              <Avatar>
                <LockIcon />
              </Avatar>
            </Box>
            <Box className={classes.formWrapper}>
              <Box marginTop="1em">
                <TextField label="Username" value={username} onChange={e => setUsername(e.target.value)} fullWidth />
              </Box>
              <Box marginTop="1em">
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  fullWidth
                />
              </Box>
            </Box>
            <CardActions>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
                onClick={onLogin}
                color="primary"
                fullWidth
                disabled={disabledSignIn}>
                Sign in
              </Button>
            </CardActions>
          </Card>
        )}
        <Notification />
      </Box>
    </ThemeProvider>
  );
};

export default LoginPage;
