export const getUserId = () => {
  try {
    const user = JSON.parse(localStorage.getItem('admin_fields'));
    if (user && user.id) {
      return user.id;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return null;
};
