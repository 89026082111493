import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Show, useDataProvider, useNotify, useRedirect, usePermissions } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
  halfWidth: {
    width: 'calc(50% - 8px)',
    margin: '0 0 8px',
  },
}));

const strategyIdChoices = [{ id: 'PDL_MX_v1', name: 'PDL_MX_v1' }];

const createObject = (minDays, maxDays, interestRate) => {
  const prolongationDailyInterestRate = {};
  for (let day = minDays; day <= maxDays; day++) {
    prolongationDailyInterestRate[day] = interestRate;
  }
  return prolongationDailyInterestRate;
};

const Layout = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [agreementTemplates, setAgreementTemplates] = useState([]);
  const [name, setName] = useState(null);
  const [startegyId, setStrategyId] = useState(null);
  const [minPrincipal, setMinPrincipal] = useState(null);
  const [maxPrincipal, setMaxPrincipal] = useState(null);
  const [firstLoanMinPrincipal, setFirstLoanMinPrincipal] = useState(null);
  const [firstLoanMaxPrincipal, setFirstLoanMaxPrincipal] = useState(null);
  const [principalStep, setPrincipalStep] = useState(null);
  const [minTenor, setMinTenor] = useState(null);
  const [maxTenor, setMaxTenor] = useState(null);
  const [firstLoanMinTenor, setFirstLoanMinTenor] = useState(null);
  const [firstLoanMaxTenor, setFirstLoanMaxTenor] = useState(null);
  const [tenorStep, setTenorStep] = useState(null);
  const [comission, setComission] = useState(null);
  const [firstLoanComission, setFirstLoanComission] = useState(null);
  const [dailyInterestRate, setDailyInterestRate] = useState(null);
  const [firstLoanDailyInterestRate, setFirstLoanDailyInterestRate] = useState(null);
  const [minExtensionDays, setMinExtensionDays] = useState(null);
  const [maxExtensionDays, setMaxExtensionDays] = useState(null);
  const [latePaymentFeeAddMaxTerm, setLatePaymentFeeAddMaxTerm] = useState(null);
  const [gracePeriod, setGracePeriod] = useState(null);
  const [graceSum, setGraceSum] = useState(null);
  const [minDaysToAllowExtReq, setMinDaysToAllowExtReq] = useState(null);
  const [maxDaysToAllowExtReq, setMaxDaysToAllowExtReq] = useState(null);
  const [defaultedLoanLimit, setDefaultedLoanLimit] = useState(null);
  const [loanAgreementTemplateCode, setLoanAgreementTemplateCode] = useState(null);
  const [extensionLoanAgreementTemplateCode, setExtensionLoanAgreementTemplateCode] = useState(null);
  const [dpdTreshold, setDpdTreshold] = useState(null);
  const [extensionDpdTreshold, setExtensionDpdTreshold] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions = [] } = usePermissions();

  const canEdit = permissions.includes('CAN_PRODUCT_EDIT');

  useEffect(() => {
    dataProvider
      .query('templates?page=1&items_per_page=200&category=pdf', { method: 'GET' })
      .then(({ data }) => setAgreementTemplates(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  useEffect(() => {
    if (record) {
      setName(record.name);
      setStrategyId(record.strategy_id);
      setMinPrincipal(record.min_principal?.toString());
      setMaxPrincipal(record.max_principal?.toString());
      setFirstLoanMinPrincipal(record.first_loan_min_principal?.toString());
      setFirstLoanMaxPrincipal(record.first_loan_max_principal?.toString());
      setPrincipalStep(record.principal_step?.toString());
      setMinTenor(record.min_tenor?.toString());
      setMaxTenor(record.max_tenor?.toString());
      setFirstLoanMinTenor(record.first_loan_min_tenor?.toString());
      setFirstLoanMaxTenor(record.first_loan_max_tenor?.toString());
      setTenorStep(record.tenor_step?.toString());
      setComission(record.commission?.toString());
      setFirstLoanComission(record.first_loan_commission?.toString());
      setDailyInterestRate((record.daily_interest_rate * 100).toString());
      setFirstLoanDailyInterestRate((record.first_loan_daily_interest_rate * 100).toString());
      const extensionDays = Object.keys(record.prolongation_daily_interest_rate);
      setMinExtensionDays(extensionDays[0].toString());
      setMaxExtensionDays(extensionDays[extensionDays.length - 1].toString());
      setLatePaymentFeeAddMaxTerm(record.late_payment_fee_add_max_term);
      setGracePeriod(record.grace_period?.toString());
      setGraceSum(record.grace_sum?.toString());
      setMinDaysToAllowExtReq(record.min_days_to_allow_request_extension?.toString());
      setMaxDaysToAllowExtReq(record.max_days_to_allow_request_extension?.toString());
      setDefaultedLoanLimit(record.mark_loan_as_defaulted_after_days?.toString());
      setLoanAgreementTemplateCode(record.loan_agreement_template_code);
      setExtensionLoanAgreementTemplateCode(record.extension_loan_agreement_template_code);
      setDpdTreshold(record.dpd_threshold?.toString());
      setExtensionDpdTreshold(record.extension_dpd_threshold?.toString());
    }
  }, [record]);

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const handleChangeName = e => {
    removeError('name');
    setName(e.target.value || null);
  };

  const handleChangeStrategyId = e => {
    removeError('strategyId');
    setStrategyId(e.target.value || null);
  };

  const handleChangeMinPrincipal = e => {
    removeError('minPrincipal', 'maxPrincipal');
    setMinPrincipal(e.target.value || null);
  };

  const handleChangeMaxPrincipal = e => {
    removeError('minPrincipal', 'maxPrincipal');
    setMaxPrincipal(e.target.value || null);
  };

  const handleChangeFirstLoanMinPrincipal = e => {
    removeError('firstLoanMinPrincipal', 'firstLoanMaxPrincipal');
    setFirstLoanMinPrincipal(e.target.value || null);
  };

  const handleChangeFirstLoanMaxPrincipal = e => {
    removeError('firstLoanMinPrincipal', 'firstLoanMaxPrincipal');
    setFirstLoanMaxPrincipal(e.target.value || null);
  };

  const handleChangePrincipalStep = e => {
    removeError('principalStep');
    setPrincipalStep(e.target.value || null);
  };

  const handleChangeMinTenor = e => {
    removeError('minTenor', 'maxTenor');
    setMinTenor(e.target.value || null);
  };

  const handleChangeMaxTenor = e => {
    removeError('minTenor', 'maxTenor');
    setMaxTenor(e.target.value || null);
  };

  const handleChangeFirstLoanMinTenor = e => {
    removeError('firstLoanMinTenor', 'firstLoanMaxTenor');
    setFirstLoanMinTenor(e.target.value || null);
  };

  const handleChangeFirstLoanMaxTenor = e => {
    removeError('firstLoanMinTenor', 'firstLoanMaxTenor');
    setFirstLoanMaxTenor(e.target.value || null);
  };

  const handleChangeTenorStep = e => {
    removeError('tenorStep');
    setTenorStep(e.target.value || null);
  };

  const handleChangeComission = e => {
    removeError('comission');
    setComission(e.target.value || null);
  };

  const handleChangeFirstLoanComission = e => {
    removeError('firstLoanComission');
    setFirstLoanComission(e.target.value || null);
  };

  const handleChangeDailyInterestRate = e => {
    removeError('dailyInterestRate');
    setDailyInterestRate(e.target.value || null);
  };

  const handleChangeFirstLoanDailyInterestRate = e => {
    removeError('firstLoanDailyInterestRate');
    setFirstLoanDailyInterestRate(e.target.value || null);
  };

  const handleChangeMinExtensionDays = e => {
    removeError('minExtensionDays', 'maxExtensionDays');
    setMinExtensionDays(e.target.value || null);
  };

  const handleChangeMaxExtensionDays = e => {
    removeError('minExtensionDays', 'maxExtensionDays');
    setMaxExtensionDays(e.target.value || null);
  };

  const handleChangeLatePaymentFeeAddMaxTerm = e => {
    removeError('latePaymentFeeAddMaxTerm');
    setLatePaymentFeeAddMaxTerm(e.target.value || null);
  };

  const handleChangeGracePeriod = e => {
    removeError('gracePeriod');
    setGracePeriod(e.target.value || null);
  };

  const handleChangeGraceSum = e => {
    removeError('graceSum');
    setGraceSum(e.target.value || null);
  };

  const handleChangeMinDaysToAllowExtReq = e => {
    removeError('minDaysToAllowExtReq', 'maxDaysToAllowExtReq');
    setMinDaysToAllowExtReq(e.target.value || null);
  };

  const handleChangeMaxDaysToAllowExtReq = e => {
    removeError('minDaysToAllowExtReq', 'maxDaysToAllowExtReq');
    setMaxDaysToAllowExtReq(e.target.value || null);
  };

  const handleChangeDefaultedLoanLimit = e => {
    removeError('defaultedLoanLimit');
    setDefaultedLoanLimit(e.target.value || null);
  };

  const handleChangeLoanAgreementTemplateCode = e => {
    removeError('loanAgreementTemplateCode');
    setLoanAgreementTemplateCode(e.target.value || null);
  };

  const handleChangeExtensionLoanAgreementTemplateCode = e => {
    removeError('extensionLoanAgreementTemplateCode');
    setExtensionLoanAgreementTemplateCode(e.target.value || null);
  };

  const handleChangeDpdTreshold = e => {
    removeError('dpdTreshold');
    setDpdTreshold(e.target.value || null);
  };

  const handleChangeExtensionDpdTreshold = e => {
    removeError('extensionDpdTreshold');
    setExtensionDpdTreshold(e.target.value || null);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Name field is required';
    } else {
      if (name === record.name) {
        formErrors.name = 'Change please product version';
      }
    }

    if (!startegyId) {
      formErrors.startegyId = 'Strategy ID field is required';
    }

    if (!minPrincipal) {
      formErrors.minPrincipal = 'Min principal field is required';
    }

    if (!maxPrincipal) {
      formErrors.maxPrincipal = 'Max principal field is required';
    }

    if (minPrincipal && maxPrincipal) {
      if (+minPrincipal > +maxPrincipal) {
        formErrors.maxPrincipal = 'Max principal must be greater than min principal';
        formErrors.minPrincipal = 'Min principal must be less than max principal';
      }

      if (minPrincipal === maxPrincipal) {
        formErrors.maxPrincipal = 'Min principal and max principal must be different';
        formErrors.minPrincipal = 'Min principal and max principal must be different';
      }

      if (+minPrincipal === 0 || +maxPrincipal === 0) {
        formErrors.maxPrincipal = 'Min principal and max principal must be greater than 0';
        formErrors.minPrincipal = 'Min principal and max principal must be greater than 0';
      }
    }

    if (!firstLoanMinPrincipal) {
      formErrors.firstLoanMinPrincipal = 'First loan min principal field is required';
    }

    if (!firstLoanMaxPrincipal) {
      formErrors.firstLoanMaxPrincipal = 'First loan max principal field is required';
    }

    if (firstLoanMinPrincipal && firstLoanMaxPrincipal) {
      if (+firstLoanMinPrincipal > +maxPrincipal) {
        formErrors.firstLoanMaxPrincipal = 'First loan Max principal must be greater than First loan min principal';
        formErrors.firstLoanMinPrincipal = 'First loan Min principal must be less than First loan max principal';
      }

      if (firstLoanMinPrincipal === firstLoanMaxPrincipal) {
        formErrors.firstLoanMaxPrincipal = 'First loan Min principal and First loan max principal must be different';
        formErrors.firstLoanMinPrincipal = 'First loan Min principal and First loan max principal must be different';
      }

      if (+firstLoanMinPrincipal === 0 || +firstLoanMaxPrincipal === 0) {
        formErrors.firstLoanMaxPrincipal =
          'First loan Min principal and First loan max principal must be greater than 0';
        formErrors.firstLoanMinPrincipal =
          'First loan Min principal and First loan max principal must be greater than 0';
      }
    }

    if (!principalStep) {
      formErrors.principalStep = 'Principal step field is required';
    } else {
      if (principalStep % 50 !== 0) {
        formErrors.principalStep = 'Principal step must be multiple on 50';
      }
    }

    if (!minTenor) {
      formErrors.minTenor = 'Min tenor field is required';
    }

    if (!maxTenor) {
      formErrors.maxTenor = 'Max tenor field is required';
    }

    if (minTenor && maxTenor) {
      if (+minTenor > +maxTenor) {
        formErrors.maxTenor = 'Max tenor must be greater than min tenor';
        formErrors.minTenor = 'Min tenor must be less than max tenor';
      }

      if (minTenor === maxTenor) {
        formErrors.maxTenor = 'Min tenor and max tenor must be different';
        formErrors.minTenor = 'Min tenor and max tenor must be different';
      }

      if (+minTenor === 0 || +maxTenor === 0) {
        formErrors.maxTenor = 'Min tenor and max tenor must be greater than 0';
        formErrors.minTenor = 'Min tenor and max tenor must be greater than 0';
      }
    }

    if (!firstLoanMinTenor) {
      formErrors.firstLoanMinTenor = 'First loan min tenor field is required';
    }

    if (!firstLoanMaxTenor) {
      formErrors.firstLoanMaxTenor = 'First loan max tenor field is required';
    }

    if (firstLoanMinTenor && firstLoanMaxTenor) {
      if (+firstLoanMinTenor > +maxTenor) {
        formErrors.firstLoanMaxTenor = 'First loan Max tenor must be greater than First loan min tenor';
        formErrors.firstLoanMinTenor = 'First loan Min tenor must be less than First loan max tenor';
      }

      if (firstLoanMinTenor === firstLoanMaxTenor) {
        formErrors.firstLoanMaxTenor = 'First loan Min tenor and First loan max tenor must be different';
        formErrors.firstLoanMinTenor = 'First loan Min tenor and First loan max tenor must be different';
      }

      if (+firstLoanMinTenor === 0 || +firstLoanMaxTenor === 0) {
        formErrors.firstLoanMaxTenor = 'First loan Min tenor and First loan max tenor must be greater than 0';
        formErrors.firstLoanMinTenor = 'First loan Min tenor and First loan max tenor must be greater than 0';
      }
    }

    if (!tenorStep) {
      formErrors.tenorStep = 'Tenor step field is required';
    }

    if (tenorStep === 0) {
      formErrors.tenorStep = 'Tenor step must be greater than zero';
    }

    if (!comission) {
      formErrors.comission = 'Comission field is required';
    }

    if (!firstLoanComission) {
      formErrors.firstLoanComission = 'First loan comission field is required';
    }

    if (!dailyInterestRate) {
      formErrors.dailyInterestRate = 'Daily interest rate field is required';
    }

    if (!firstLoanDailyInterestRate) {
      formErrors.firstLoanDailyInterestRate = 'First loan daily interest rate field is required';
    }

    if (!minExtensionDays) {
      formErrors.minExtensionDays = 'Min extension days field is required';
    }

    if (!maxExtensionDays) {
      formErrors.maxExtensionDays = 'Max extension days field is required';
    }

    if (minExtensionDays && maxExtensionDays) {
      if (+minExtensionDays > +maxExtensionDays) {
        formErrors.maxExtensionDays = 'Max extension days must be greater than min extension days';
        formErrors.minExtensionDays = 'Min extension days must be less than max extension days';
      }

      if (minExtensionDays === maxExtensionDays) {
        formErrors.maxExtensionDays = 'Min extension days and max extension days must be different';
        formErrors.minExtensionDays = 'Min extension days and max extension days must be different';
      }

      if (+minExtensionDays === 0 || +maxExtensionDays === 0) {
        formErrors.maxExtensionDays = 'Min extension days and max extension days must be greater than 0';
        formErrors.minExtensionDays = 'Min extension days dayspal and max extension days must be greater than 0';
      }
    }

    if (!latePaymentFeeAddMaxTerm) {
      formErrors.latePaymentFeeAddMaxTerm = 'Late payment fee add max term field is required';
    }

    if (!gracePeriod) {
      formErrors.gracePeriod = 'Grace period field is required';
    }

    if (!graceSum) {
      formErrors.graceSum = 'Grace sum field is required';
    }

    if (!minDaysToAllowExtReq) {
      formErrors.minDaysToAllowExtReq = 'Min days to allow request extension field is required';
    }

    if (!maxDaysToAllowExtReq) {
      formErrors.maxDaysToAllowExtReq = 'Max days to allow request extension field is required';
    }

    if (minDaysToAllowExtReq && maxDaysToAllowExtReq) {
      if (+minDaysToAllowExtReq > +maxDaysToAllowExtReq) {
        formErrors.maxDaysToAllowExtReq =
          'Max days to allow request extension must be greater than min days to allow request extension';
        formErrors.minDaysToAllowExtReq =
          'Min days to allow request extension must be less than max days to allow request extension';
      }

      if (minDaysToAllowExtReq === maxDaysToAllowExtReq) {
        formErrors.maxDaysToAllowExtReq =
          'Min days to allow request extension and max days to allow request extension must be different';
        formErrors.minDaysToAllowExtReq =
          'Min days to allow request extension and max days to allow request extension must be different';
      }
    }

    if (!defaultedLoanLimit) {
      formErrors.defaultedLoanLimit = 'Mark loan as defaulted after days field is required';
    } else {
      if (+defaultedLoanLimit === 0) {
        formErrors.defaultedLoanLimit = 'Mark loan as defaulted after days must be greater than 0';
      }
    }

    if (!loanAgreementTemplateCode) {
      formErrors.loanAgreementTemplateCode = 'Loan agreement template code field is required';
    }

    if (!extensionLoanAgreementTemplateCode) {
      formErrors.extensionLoanAgreementTemplateCode = 'Extension loan agreement template code field is required';
    }

    if (!dpdTreshold) {
      formErrors.dpdTreshold = 'DPD Treshold field is required';
    } else {
      if (+dpdTreshold === 0) {
        formErrors.dpdTreshold = 'DPD Treshold must be greater than 0';
      }
    }

    if (!extensionDpdTreshold) {
      formErrors.extensionDpdTreshold = 'Extension DPD Treshold field is required';
    } else {
      if (+extensionDpdTreshold === 0) {
        formErrors.extensionDpdTreshold = 'Extension DPD Treshold must be greater than 0';
      }
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const prolongationDailyInterestRate = createObject(
        +minExtensionDays,
        +maxExtensionDays,
        +dailyInterestRate / 100,
      );
      const data = {
        code: record.code,
        name,
        strategy_id: startegyId,
        min_principal: +minPrincipal,
        max_principal: +maxPrincipal,
        first_loan_min_principal: +firstLoanMinPrincipal,
        first_loan_max_principal: +firstLoanMaxPrincipal,
        principal_step: +principalStep,
        min_tenor: +minTenor,
        max_tenor: +maxTenor,
        first_loan_min_tenor: +firstLoanMinTenor,
        first_loan_max_tenor: +firstLoanMaxTenor,
        tenor_step: +tenorStep,
        commission: +comission,
        first_loan_commission: +firstLoanComission,
        daily_interest_rate: +dailyInterestRate / 100,
        first_loan_daily_interest_rate: +firstLoanDailyInterestRate / 100,
        prolongation_daily_interest_rate: prolongationDailyInterestRate,
        late_payment_fee: 0,
        late_payment_fee_add_rate: 0.04,
        late_payment_fee_add_step: 1,
        late_payment_fee_add_max_term: +latePaymentFeeAddMaxTerm,
        grace_period: +gracePeriod,
        grace_sum: +graceSum,
        min_days_to_allow_request_extension: +minDaysToAllowExtReq,
        max_days_to_allow_request_extension: +maxDaysToAllowExtReq,
        day_of_delay_to_cancel_promo_code_discount: 0,
        mark_loan_as_defaulted_after_days: +defaultedLoanLimit,
        loan_agreement_template_code: loanAgreementTemplateCode,
        extension_loan_agreement_template_code: extensionLoanAgreementTemplateCode,
        dpd_threshold: +dpdTreshold,
        extension_dpd_threshold: +extensionDpdTreshold,
      };

      dataProvider
        .query('products', { method: 'POST', body: JSON.stringify(data) })
        .then(() => {
          setLoading(false);
          redirect('/products');
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Update product
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom={false}>
                  NOTE: updating this product implies the creation of a new product!
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Code"
                    value={record.code}
                    disabled
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    multiline
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name || 'Do not forget to change this field!'}
                    size="small"
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl className={classes.formControl} variant="filled" size="small" disabled={!canEdit}>
                    <InputLabel id="strategy-id-select-label">Strategy ID</InputLabel>
                    <Select
                      labelId="strategy-id-select-input"
                      value={startegyId || ''}
                      onChange={handleChangeStrategyId}
                      error={!!errors.startegyId}>
                      {strategyIdChoices.map(s => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.startegyId && <FormHelperText error>{errors.startegyId}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Min principal"
                    value={minPrincipal ?? ''}
                    onChange={handleChangeMinPrincipal}
                    error={!!errors.minPrincipal}
                    helperText={errors.minPrincipal}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Max principal"
                    value={maxPrincipal ?? ''}
                    onChange={handleChangeMaxPrincipal}
                    error={!!errors.maxPrincipal}
                    helperText={errors.maxPrincipal}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan min principal"
                    value={firstLoanMinPrincipal ?? ''}
                    onChange={handleChangeFirstLoanMinPrincipal}
                    error={!!errors.firstLoanMinPrincipal}
                    helperText={errors.firstLoanMinPrincipal}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan max principal"
                    value={firstLoanMaxPrincipal ?? ''}
                    onChange={handleChangeFirstLoanMaxPrincipal}
                    error={!!errors.firstLoanMaxPrincipal}
                    helperText={errors.firstLoanMaxPrincipal}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Principal step"
                    value={principalStep ?? ''}
                    onChange={handleChangePrincipalStep}
                    error={!!errors.principalStep}
                    helperText={errors.principalStep}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0, step: 50 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.halfWidth}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Min tenor"
                    value={minTenor ?? ''}
                    onChange={handleChangeMinTenor}
                    error={!!errors.minTenor}
                    helperText={errors.minTenor}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Max tenor"
                    value={maxTenor ?? ''}
                    onChange={handleChangeMaxTenor}
                    error={!!errors.maxTenor}
                    helperText={errors.maxTenor}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan min tenor"
                    value={firstLoanMinTenor ?? ''}
                    onChange={handleChangeFirstLoanMinTenor}
                    error={!!errors.firstLoanMinTenor}
                    helperText={errors.firstLoanMinTenor}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan max tenor"
                    value={firstLoanMaxTenor ?? ''}
                    onChange={handleChangeFirstLoanMaxTenor}
                    error={!!errors.firstLoanMaxTenor}
                    helperText={errors.firstLoanMaxTenor}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Tenor step"
                    value={tenorStep ?? ''}
                    onChange={handleChangeTenorStep}
                    error={!!errors.tenorStep}
                    helperText={errors.tenorStep}
                    size="small"
                    InputProps={{
                      inputProps: { min: 1, step: 1 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.halfWidth}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Comission"
                    value={comission ?? ''}
                    onChange={handleChangeComission}
                    error={!!errors.comission}
                    helperText={errors.comission}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan comission"
                    value={firstLoanComission ?? ''}
                    onChange={handleChangeFirstLoanComission}
                    error={!!errors.firstLoanComission}
                    helperText={errors.firstLoanComission}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Daily interest rate"
                    value={dailyInterestRate ?? ''}
                    onChange={handleChangeDailyInterestRate}
                    error={!!errors.dailyInterestRate}
                    helperText={errors.dailyInterestRate || '% per day'}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0, max: 10, step: 0.1 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="First loan daily interest rate"
                    value={firstLoanDailyInterestRate ?? ''}
                    onChange={handleChangeFirstLoanDailyInterestRate}
                    error={!!errors.firstLoanDailyInterestRate}
                    helperText={errors.firstLoanDailyInterestRate || '% per day'}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0, max: 10, step: 0.1 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Min extension days"
                    value={minExtensionDays ?? ''}
                    onChange={handleChangeMinExtensionDays}
                    error={!!errors.minExtensionDays}
                    helperText={errors.minExtensionDays}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Max extension days"
                    value={maxExtensionDays ?? ''}
                    onChange={handleChangeMaxExtensionDays}
                    error={!!errors.maxExtensionDays}
                    helperText={errors.maxExtensionDays}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Late payment fee add max term"
                    value={latePaymentFeeAddMaxTerm ?? ''}
                    onChange={handleChangeLatePaymentFeeAddMaxTerm}
                    error={!!errors.latePaymentFeeAddMaxTerm}
                    helperText={errors.latePaymentFeeAddMaxTerm}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Grace period"
                    value={gracePeriod ?? ''}
                    onChange={handleChangeGracePeriod}
                    error={!!errors.gracePeriod}
                    helperText={errors.gracePeriod}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Grace sum"
                    value={graceSum ?? ''}
                    onChange={handleChangeGraceSum}
                    error={!!errors.graceSum}
                    helperText={errors.graceSum}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Min days to allow request extension"
                    value={minDaysToAllowExtReq ?? ''}
                    onChange={handleChangeMinDaysToAllowExtReq}
                    error={!!errors.minDaysToAllowExtReq}
                    helperText={errors.minDaysToAllowExtReq}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Max days to allow request extension"
                    value={maxDaysToAllowExtReq ?? ''}
                    onChange={handleChangeMaxDaysToAllowExtReq}
                    error={!!errors.maxDaysToAllowExtReq}
                    helperText={errors.maxDaysToAllowExtReq}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Mark loan as defaulted after days"
                    value={defaultedLoanLimit ?? ''}
                    onChange={handleChangeDefaultedLoanLimit}
                    error={!!errors.defaultedLoanLimit}
                    helperText={errors.defaultedLoanLimit}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} variant="filled" size="small" disabled={!canEdit}>
                    <InputLabel id="loan-agreement-template-code-select-label">Loan agreement template code</InputLabel>
                    <Select
                      labelId="loan-agreement-template-code-select-input"
                      value={loanAgreementTemplateCode || ''}
                      onChange={handleChangeLoanAgreementTemplateCode}
                      error={!!errors.loanAgreementTemplateCode}>
                      {agreementTemplates.map(t => (
                        <MenuItem key={t.id} value={t.key}>
                          {t.key}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.loanAgreementTemplateCode && (
                      <FormHelperText error>{errors.loanAgreementTemplateCode}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} variant="filled" size="small" disabled={!canEdit}>
                    <InputLabel id="extension-loan-agreement-template-code-select-label">
                      Extension loan agreement template code
                    </InputLabel>
                    <Select
                      labelId="extension-loan-agreement-template-code-select-input"
                      value={extensionLoanAgreementTemplateCode || ''}
                      onChange={handleChangeExtensionLoanAgreementTemplateCode}
                      error={!!errors.extensionLoanAgreementTemplateCode}>
                      {agreementTemplates.map(t => (
                        <MenuItem key={t.id} value={t.key}>
                          {t.key}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.extensionLoanAgreementTemplateCode && (
                      <FormHelperText error>{errors.extensionLoanAgreementTemplateCode}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider className={classes.my2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="DPD Treshold"
                    value={dpdTreshold ?? ''}
                    onChange={handleChangeDpdTreshold}
                    error={!!errors.dpdTreshold}
                    helperText={errors.dpdTreshold}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="filled"
                    label="Extension DPD Treshold"
                    value={extensionDpdTreshold ?? ''}
                    onChange={handleChangeExtensionDpdTreshold}
                    error={!!errors.extensionDpdTreshold}
                    helperText={errors.extensionDpdTreshold}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    className={classes.formControl}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="space-between">
              <Button
                disabled={!canEdit}
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

Layout.propTypes = {
  record: PropTypes.shape({
    code: PropTypes.string,
    commission: PropTypes.number,
    created_at: PropTypes.string,
    daily_interest_rate: PropTypes.number,
    day_of_delay_to_cancel_promo_code_discount: PropTypes.number,
    dpd_threshold: PropTypes.number,
    extension_dpd_threshold: PropTypes.number,
    extension_loan_agreement_template_code: PropTypes.string,
    fines_imposion_days: PropTypes.array,
    first_loan_commission: PropTypes.number,
    first_loan_daily_interest_rate: PropTypes.number,
    first_loan_max_principal: PropTypes.number,
    first_loan_max_tenor: PropTypes.number,
    first_loan_min_principal: PropTypes.number,
    first_loan_min_tenor: PropTypes.number,
    grace_period: PropTypes.number,
    grace_sum: PropTypes.number,
    id: PropTypes.number,
    is_actual: PropTypes.bool,
    is_enabled: PropTypes.bool,
    late_payment_fee: PropTypes.number,
    late_payment_fee_add_max_term: PropTypes.number,
    late_payment_fee_add_rate: PropTypes.number,
    late_payment_fee_add_step: PropTypes.number,
    loan_agreement_template_code: PropTypes.string,
    mark_loan_as_defaulted_after_days: PropTypes.number,
    max_days_to_allow_request_extension: PropTypes.number,
    max_principal: PropTypes.number,
    max_tenor: PropTypes.number,
    min_days_to_allow_request_extension: PropTypes.number,
    min_principal: PropTypes.number,
    min_tenor: PropTypes.number,
    name: PropTypes.string,
    principal_step: PropTypes.number,
    strategy_id: PropTypes.string,
    tenor_step: PropTypes.number,
    prolongation_daily_interest_rate: PropTypes.object,
  }),
};

const ProductEdit = props => (
  <Show component="div" {...props}>
    <Layout />
  </Show>
);

export default ProductEdit;
