import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider, usePermissions, useRefresh } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';

import { formatDatetime } from '../../../utils/formatter';
import RefreshPopover from './RefreshPopover';

const DialerProcessesTable = ({ isOpened, onClose, id = null }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [refreshedAt, setRefreshedAt] = useState();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [processes, setProcesses] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [PopoverAnchor, setPopoverAnchor] = useState(null);
  const [anchorRow, setAnchorRow] = useState(null);

  const openPopover = (event, rowId) => {
    setPopoverAnchor(event.currentTarget);
    setAnchorRow(rowId);
  };

  const applyTransition = (id, name, params, isForce) => {
    setLoading(true);
    dataProvider
      .query(`dialer_processes/${id}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({
          name,
          params: {
            ...params,
            params: [params],
            skip_id_restore: isForce,
          },
        }),
      })
      .then(() => setRefreshedAt(new Date().valueOf()))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  useEffect(() => {
    dataProvider
      .getList('dialer_processes', {
        filter: { campaign: id },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setProcesses(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, id, page, perPage, refreshedAt]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Dialog open={isOpened} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Dialer processes</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Last error</TableCell>
              <TableCell>Scheduled at</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell>Finished at</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processes?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.campaign_id}</TableCell>
                <TableCell>
                  <Chip size="small" label={row.state} />
                </TableCell>
                <TableCell>{row.last_error}</TableCell>
                <TableCell>{row.scheduled_at && formatDatetime(row.scheduled_at)}</TableCell>
                <TableCell>{row.updated_at && formatDatetime(row.updated_at)}</TableCell>
                <TableCell>{row.finished_at && formatDatetime(row.finished_at)}</TableCell>
                <TableCell align="right">
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      <IconButton
                        color="primary"
                        size="small"
                        disabled={
                          row.enabled_transitions.indexOf('renew') === -1 ||
                          permissions.indexOf('CAN_AGREEMENT_EDIT') === -1 ||
                          loading ||
                          index !== 0
                        }
                        onClick={e => openPopover(e, row.id)}>
                        <RefreshIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        size="small"
                        disabled={row.enabled_transitions.indexOf('stop') === -1 || loading}
                        onClick={() => applyTransition(row.id, 'stop')}>
                        <StopIcon />
                      </IconButton>
                    </>
                  )}
                  {Boolean(PopoverAnchor) && anchorRow === row.id && (
                    <RefreshPopover
                      onClose={() => {
                        setPopoverAnchor(null);
                        setAnchorRow(null);
                      }}
                      onSubmit={applyTransition}
                      anchor={PopoverAnchor}
                      row={row}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={e => {
            setPerPage(parseInt(e.target.value, 10));
            setPage(1);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

DialerProcessesTable.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
};

export default DialerProcessesTable;
