export const directionChoices = [
  { id: 'inbound', name: 'Inbound' },
  { id: 'outbound', name: 'Outbound' },
];

export const causeChoices = [
  { id: 'UNSPECIFIED', name: 'UNSPECIFIED' },
  { id: 'UNALLOCATED_NUMBER', name: 'UNALLOCATED NUMBER' },
  { id: 'NO_ROUTE_TRANSIT_NET', name: 'NO ROUTE TRANSIT NET' },
  { id: 'NO_ROUTE_DESTINATION', name: 'NO ROUTE DESTINATION' },
  { id: 'CHANNEL_UNACCEPTABLE', name: 'CHANNEL UNACCEPTABLE' },
  { id: 'CALL_AWARDED_DELIVERED', name: 'CALL AWARDED DELIVERED' },
  { id: 'NORMAL_CLEARING', name: 'NORMAL CLEARING' },
  { id: 'USER_BUSY', name: 'USER BUSY' },
  { id: 'NO_USER_RESPONSE', name: 'NO USER RESPONSE' },
  { id: 'NO_ANSWER', name: 'NO ANSWER' },
  { id: 'SUBSCRIBER_ABSENT', name: 'SUBSCRIBER ABSENT' },
  { id: 'CALL_REJECTED', name: 'CALL REJECTED' },
  { id: 'NUMBER_CHANGED', name: 'NUMBER CHANGED' },
  { id: 'REDIRECTION_TO_NEW_DESTINATION', name: 'REDIRECTION TO NEW DESTINATION' },
  { id: 'EXCHANGE_ROUTING_ERROR', name: 'EXCHANGE ROUTING ERROR' },
  { id: 'DESTINATION_OUT_OF_ORDER', name: 'DESTINATION OUT OF ORDER' },
  { id: 'INVALID_NUMBER_FORMAT', name: 'INVALID NUMBER FORMAT' },
  { id: 'FACILITY_REJECTED', name: 'FACILITY REJECTED' },
  { id: 'RESPONSE_TO_STATUS_ENQUIRY', name: 'RESPONSE TO STATUS ENQUIRY' },
  { id: 'NORMAL_UNSPECIFIED', name: 'NORMAL UNSPECIFIED' },
  { id: 'NORMAL_CIRCUIT_CONGESTION', name: 'NORMAL CIRCUIT CONGESTION' },
  { id: 'NETWORK_OUT_OF_ORDER', name: 'NETWORK OUT OF ORDER' },
  { id: 'NORMAL_TEMPORARY_FAILURE', name: 'NORMAL TEMPORARY FAILURE' },
  { id: 'SWITCH_CONGESTION', name: 'SWITCH CONGESTION' },
  { id: 'ACCESS_INFO_DISCARDED', name: 'ACCESS INFO DISCARDED' },
  { id: 'REQUESTED_CHAN_UNAVAIL', name: 'REQUESTED CHAN UNAVAIL' },
  { id: 'PRE_EMPTED', name: 'PRE EMPTED' },
  { id: 'FACILITY_NOT_SUBSCRIBED', name: 'FACILITY NOT SUBSCRIBED' },
  { id: 'OUTGOING_CALL_BARRED', name: 'OUTGOING CALL BARRED' },
  { id: 'INCOMING_CALL_BARRED', name: 'INCOMING CALL BARRED' },
  { id: 'BEARERCAPABILITY_NOTAUTH', name: 'BEARERCAPABILITY NOTAUTH' },
  { id: 'BEARERCAPABILITY_NOTAVAIL', name: 'BEARERCAPABILITY NOTAVAIL' },
  { id: 'SERVICE_UNAVAILABLE', name: 'SERVICE UNAVAILABLE' },
  { id: 'BEARERCAPABILITY_NOTIMPL', name: 'BEARERCAPABILITY NOTIMPL' },
  { id: 'CHAN_NOT_IMPLEMENTED', name: 'CHAN NOT IMPLEMENTED' },
  { id: 'FACILITY_NOT_IMPLEMENTED', name: 'FACILITY NOT IMPLEMENTED' },
  { id: 'SERVICE_NOT_IMPLEMENTED', name: 'SERVICE NOT IMPLEMENTED' },
  { id: 'INVALID_CALL_REFERENCE', name: 'INVALID CALL REFERENCE' },
  { id: 'INCOMPATIBLE_DESTINATION', name: 'INCOMPATIBLE DESTINATION' },
  { id: 'INVALID_MSG_UNSPECIFIED', name: 'INVALID MSG UNSPECIFIED' },
  { id: 'MANDATORY_IE_MISSING', name: 'MANDATORY IE MISSING' },
  { id: 'MESSAGE_TYPE_NONEXIST', name: 'MESSAGE TYPE NONEXIST' },
  { id: 'WRONG_MESSAGE', name: 'WRONG MESSAGE' },
  { id: 'IE_NONEXIST', name: 'IE NONEXIST' },
  { id: 'INVALID_IE_CONTENTS', name: 'INVALID IE CONTENTS' },
  { id: 'WRONG_CALL_STATE', name: 'WRONG CALL STATE' },
  { id: 'RECOVERY_ON_TIMER_EXPIRE', name: 'RECOVERY ON TIMER EXPIRE' },
  { id: 'MANDATORY_IE_LENGTH_ERROR', name: 'MANDATORY IE LENGTH ERROR' },
  { id: 'PROTOCOL_ERROR', name: 'PROTOCOL ERROR' },
  { id: 'INTERWORKING', name: 'INTERWORKING' },
  { id: 'ORIGINATOR_CANCEL', name: 'ORIGINATOR CANCEL' },
  { id: 'CRASH', name: 'CRASH' },
  { id: 'SYSTEM_SHUTDOWN', name: 'SYSTEM SHUTDOWN' },
  { id: 'LOSE_RACE', name: 'LOSE RACE' },
  { id: 'MANAGER_REQUEST', name: 'MANAGER REQUEST' },
  { id: 'BLIND_TRANSFER', name: 'BLIND TRANSFER' },
  { id: 'ATTENDED_TRANSFER', name: 'ATTENDED TRANSFER' },
  { id: 'ALLOTTED_TIMEOUT', name: 'ALLOTTED TIMEOUT' },
  { id: 'USER_CHALLENGE', name: 'USER CHALLENGE' },
  { id: 'MEDIA_TIMEOUT', name: 'MEDIA TIMEOUT' },
  { id: 'PICKED_OFF', name: 'PICKED OFF' },
  { id: 'USER_NOT_REGISTERED', name: 'USER NOT REGISTERED' },
  { id: 'PROGRESS_TIMEOUT', name: 'PROGRESS TIMEOUT' },
  { id: 'GATEWAY_DOWN', name: 'GATEWAY DOWN' },
];
