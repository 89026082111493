import React from 'react';
import { useField } from 'react-final-form';
import { useNotify, useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const CollectionAssignReportParamsInput = () => {
  const [externalAgencyList, setExternalAgencyList] = React.useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const ASSIGNMENT_STATES = [
    { code: 'active', label: 'Active' },
    { code: 'inactive', label: 'Inactive' },
  ];

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('external_agency_assignment_report');

  React.useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setExternalAgencyList(data.map(item => ({ id: item.id, name: item.name }))))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          startLabel="Date of assigned start from"
          endLabel="Date of assigned start to"
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.assignment_state)}>
            <InputLabel id="assignment_state">State of assignment</InputLabel>
            <Select
              labelId="assignment_state"
              value={value && value.assignment_state ? value.assignment_state : ''}
              onChange={e => onChange({ ...(value || {}), assignment_state: e.target.value })}>
              {ASSIGNMENT_STATES.map(({ code, label }) => (
                <MenuItem key={code} value={code}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.assignment_state && <FormHelperText>{error.assignment_state}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.collection_agency)}>
            <InputLabel id="assign-company-select-label">Assign agency name</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="assign-company-select-label"
              value={value && value.collection_agency ? [...value.collection_agency] : []}
              onChange={e => onChange({ ...(value || {}), collection_agency: e.target.value })}
              renderValue={selected => (
                <Box className={classes.chipBox}>
                  {selected.map(id => (
                    <Chip
                      className={classes.chip}
                      key={id}
                      label={externalAgencyList.find(externalAgency => externalAgency.id === id)?.name}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {externalAgencyList.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.collection_agency && <FormHelperText>{error.collection_agency}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
    </>
  );
};

export default CollectionAssignReportParamsInput;
