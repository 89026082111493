import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';

import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';
import { CALL_CENTER_USER_STATUS_CLOSED_LOANS } from '../../../../utils/dictionary';
import { timezone } from '../../../../utils/adapter';

const ClosedLoanReportParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('closed_loan_report');

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.last_call_result)}>
            <InputLabel id="last_call_result">Last call result</InputLabel>
            <Select
              labelId="last_call_result"
              value={value && value.last_call_result ? value.last_call_result : ''}
              onChange={e => onChange({ ...(value || {}), last_call_result: e.target.value })}>
              {CALL_CENTER_USER_STATUS_CLOSED_LOANS.map(i => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.last_call_result && <FormHelperText>{error.last_call_result}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.day_without_loan)}>
            <InputLabel id="day_without_loan">Day without loan</InputLabel>
            <Select
              labelId="day_without_loan"
              value={value && value.day_without_loan ? value.day_without_loan : ''}
              onChange={e => onChange({ ...(value || {}), day_without_loan: e.target.value })}>
              {Array.from(new Array(60).keys()).map(i => (
                <MenuItem value={i + 1} key={i + '_day_without_loan'}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.day_without_loan && <FormHelperText>{error.day_without_loan}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Last call date"
            format="MMMM dd, yyyy"
            className={classes.fullWidth}
            value={value && value.last_call_date ? timezone.shift(value.last_call_date) : null}
            onChange={v =>
              onChange({ ...(value || {}), last_call_date: timezone.setTime(timezone.unshift(v), 0, 0, 0) })
            }
            error={!!(touched && error && error.last_call_date)}
            helperText={touched && error && error.last_call_date}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClosedLoanReportParamsInput;
