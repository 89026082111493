import React, { useState } from 'react';
import { Show, useRefresh, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import LoanTransitions from './components/LoanTransitions';
import LoanInfo from './components/LoanInfo';
import ScheduledTransactions from './components/ScheduledTransactions';
import ExecutedTransactions from './components/ExecutedTransactions';
import LoanAdditionalInfo from './components/LoanAdditionalInfo';
import LoanActions from './components/LoanActions';
import { useStyles } from './styles';
import TabbedInfoCard from '../../user/TabbedInfoCard';

const Layout = ({ record }) => {
  const [refreshedAt, setRefreshedAt] = useState();

  const classes = useStyles();
  const onRefresh = useRefresh();
  const { permissions = [] } = usePermissions();

  const refresh = () => {
    setRefreshedAt(Date.now());
    onRefresh();
  };

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} justifyContent="space-between">
        {permissions.includes('CAN_LOAN_EDIT') && (
          <LoanTransitions record={record} refreshedAt={refreshedAt} refresh={refresh} />
        )}
        <LoanActions permissions={permissions} record={record} />
      </Grid>
      <Grid item xs={12}>
        <LoanAdditionalInfo permissions={permissions} record={record} refreshedAt={refreshedAt} refresh={refresh} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {permissions.includes('CAN_USER_VIEW') ? (
          <TabbedInfoCard user_id={record.user_id} inactive={record.state === 'sold'} />
        ) : (
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom={true}>
              User info
            </Typography>
            <Typography variant="body1">-- You do not have sufficient rights to view customer details --</Typography>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoanInfo record={record} />
      </Grid>
      {permissions.includes('CAN_TRANSACTION_VIEW') && (
        <>
          <Grid item xs={12} sm={4}>
            <ScheduledTransactions loanId={record.id} refreshedAt={refreshedAt} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ExecutedTransactions
              record={record}
              refreshedAt={refreshedAt}
              refresh={refresh}
              isActionsDisabled={
                permissions.indexOf('CAN_TRANSACTION_EDIT') === -1 ||
                ['active', 'defaulted'].indexOf(record.state) === -1
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

Layout.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
  }),
};

const LoanShow = props => (
  <Show component="div" {...props}>
    <Layout />
  </Show>
);

export default LoanShow;
