import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../styles';
import ReportEmpty from '../components/ReportEmpty';

const InvoicingLoanReportParamsInput = () => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('invoicing_loan_report');

  return (
    <>
      <Grid container spacing={4}>
        <ReportEmpty classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
    </>
  );
};

export default InvoicingLoanReportParamsInput;
