import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  TopToolbar,
  NumberInput,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { formatPhoneNumber, formatDatetime } from '../../../utils/formatter';
import DateFilterInput from '../../filter/DateFilterInput';
import Call from '../../call/Call';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Phone number" source="phone_number" />
      <DateFilterInput label="Confirmed before" source="confirmed_at|before" before />
      <DateFilterInput label="Confirmed after" source="confirmed_at|after" after />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
    </Filter>
  );
};

const LeadsList = props => {
  const showFullPhone = !props.permissions?.includes('CAN_SEE_FULL_PHONE');
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          source="phone_number"
          render={(record, key) =>
            record[key] && <Call userPhone={record[key]}>{formatPhoneNumber(String(record[key]), showFullPhone)}</Call>
          }
        />
        <FunctionField
          source="registration_step"
          label="Step"
          render={(record, key) => record[key] && <Chip size="small" label={record[key]} />}
        />
        <FunctionField source="confirmed_at" render={(record, key) => record[key] && formatDatetime(record[key])} />
        <FunctionField source="created_at" render={(record, key) => record[key] && formatDatetime(record[key])} />
        <TextField source="sms_count" />
        <TextField source="push_count" />
        <TextField source="total_notification_count" />
      </Datagrid>
    </List>
  );
};

LeadsList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default LeadsList;
