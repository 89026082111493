import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReportDateFormat = ({ classes, value, touched, error, onChange, required = false }) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.fullWidth} error={!!(touched && error && error.date_format)} required={required}>
        <InputLabel id="date-format-select-label">Date format</InputLabel>
        <Select
          labelId="date-format-select-label"
          value={value && value.date_format ? value.date_format : ''}
          onChange={e => onChange({ ...(value || {}), date_format: e.target.value })}>
          <MenuItem value="Y-m-d\TH:i:sP">RFC3339 (Y-m-d\TH:i:sP)</MenuItem>
          <MenuItem value="Y-m-d H:i:s">Y-m-d H:i:s</MenuItem>
        </Select>
        {touched && error && error.date_format && <FormHelperText>{error.date_format}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

ReportDateFormat.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  touched: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default ReportDateFormat;
