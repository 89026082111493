import { differenceInMinutes } from 'date-fns';
import { red, green, orange, yellow, deepPurple, pink, blue } from '@material-ui/core/colors';

export const getRequestDuration = record => {
  const approvedInMinutes = differenceInMinutes(
    new Date(),
    record.resolved_at ? new Date(record.resolved_at) : new Date(),
  );
  // RED: ADE error
  if (record?.aventus_decision_engine_state === 'error') {
    return red[300];
  }

  // GREEN: application confirmed and loan is activated
  if (record?.state === 'confirmed' && record?.loan_id && record?.loan_state === 'active') {
    return green[300];
  }

  // ORANGE: application confirmed and loan is not activated
  if (record?.state === 'confirmed' && record?.loan_id && record?.loan_state === 'pending') {
    return orange[300];
  }

  // YELLOW: application is pending, manual verification and verifier is not assigned
  if (
    ['pending', 'processing'].includes(record?.state) &&
    record?.decision_engine_id === 'manual' &&
    !record?.verifier_id
  ) {
    return yellow[300];
  }

  // VIOLET: application is pending, manual verification and verifier is assigned
  if (
    ['pending', 'processing'].includes(record?.state) &&
    record?.decision_engine_id === 'manual' &&
    record?.verifier_id
  ) {
    return deepPurple[300];
  }

  // PINK: application is approved more than 5 minutes ago but not confirmed
  if (record?.state === 'approved' && approvedInMinutes > 5 && !record?.confirmed_at) {
    return pink[300];
  }

  // BLUE: application is approved more than 5 minutes ago but not confirmed
  if (record?.state === 'verification_control') {
    return blue[300];
  }

  return '#FFFFFF';
};
