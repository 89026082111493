import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import CollapsableRow from './CollapsableRow';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const DirectDebitTable = ({ userId, loanId, refreshedAt }) => {
  const [autoDebits, setAutoDebits] = useState([]);
  const [autoDebitsIds, setAutoDebitsIds] = useState([]);
  const [autoDebitRequests, setAutoDebitRequests] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('auto_debits', {
        filter: userId ? { user: userId } : { loan: loanId },
        pagination: { page, perPage },
        sort: { field: 'created_at', order: 'DESC' },
      })
      .then(({ data, total }) => {
        const autoDebitsIds = data.map(d => d.id);
        setAutoDebitsIds(autoDebitsIds);
        setAutoDebits(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  useEffect(() => {
    if (autoDebitsIds.length) {
      setLoading(true);
      dataProvider
        .getList('auto_debit_requests', {
          filter: { 'auto_debit.id': autoDebitsIds },
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'id', order: 'ASC' },
        })
        .then(({ data }) => {
          setAutoDebitRequests(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [dataProvider, autoDebitsIds]);

  useEffect(() => {
    if (autoDebitRequests.length) {
      setLoading(true);
      const debits = autoDebits.map(d => {
        return { ...d, requests: [] };
      });
      autoDebitRequests.forEach(request => {
        const debitIndex = debits.findIndex(d => d.id === request.auto_debit_id);
        if (debitIndex !== -1) {
          debits[debitIndex].requests.push(request);
        }
      });
      setAutoDebits(debits);
      setLoading(false);
    }
  }, [autoDebitRequests, refreshedAt]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(autoDebits)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Loan ID</TableCell>
            <TableCell>Campaign ID</TableCell>
            <TableCell>Strategy ID</TableCell>
            <TableCell>Admin ID</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Confirmed at</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {autoDebits.map(row => (
            <CollapsableRow key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

DirectDebitTable.propTypes = {
  userId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default DirectDebitTable;
