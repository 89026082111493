import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Error, useDataProvider, useNotify, usePermissions } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import RejectTransitionDialog from '../../../dialog/RejectTransitionDialog';
import AddCancelNoteDialog from '../../../note/AddCancelNoteDialog';
import { getUserId } from '../../../../utils/authentication';

import AssignVerifierTransitionDialog from './AssignVerifierTransitionDialog';
import AssignCheckingVerifierTransitionDialog from './AssignCheckingVerifierTransitionDialog';
import StartProcessingTransitionDialog from './StartProcessingTransitionDialog';
import ManualVerificationDialog from './ManualVerificationDialog';
import PostponeTransitionDialog from './PostponeTransitionDialog';
import ApproveTransitionDialog from './ApproveTransitionDialog';
import ConfirmTransitionDialog from './ConfirmTransitionDialog';
import RestartAdeDialog from './RestartAdeDialog';

const ApplicationTransitions = ({ record, refreshedAt, refresh, appCanBeRestarted }) => {
  const [transitions, setTransitions] = useState();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState();
  const [isStartProcessingTransitionDialogOpened, setIsStartProcessingTransitionDialogOpened] = useState(false);
  const [isManualVerificationDialogOpened, setIsManualVerificationDialogOpened] = useState(false);
  const [isAssignVerifierTransitionDialogOpened, setIsAssignVerifierTransitionDialogOpened] = useState(false);
  const [isAssignCheckVerifierTransitionDialogOpened, setIsAssignCheckVerifierTransitionDialogOpened] = useState(false);
  const [isPostponeTransitionDialogOpened, setIsPostponeTransitionDialogOpened] = useState(false);
  const [isApproveTransitionDialogOpened, setIsApproveTransitionDialogOpened] = useState(false);
  const [isControlApproveTransitionDialogOpened, setIsControlApproveTransitionDialogOpened] = useState(false);
  const [isRejectTransitionDialogOpened, setIsRejectTransitionDialogOpened] = useState(false);
  const [isConfirmTransitionDialogOpened, setIsConfirmTransitionDialogOpened] = useState(false);
  const [isCancelNoteDialogOpen, setIsCancelNoteDialogOpen] = useState(false);
  const [isRestartAdeDialogOpen, setIsRestartAdeDialogOpen] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const dialogIsOpened =
    isStartProcessingTransitionDialogOpened ||
    isManualVerificationDialogOpened ||
    isAssignVerifierTransitionDialogOpened ||
    isPostponeTransitionDialogOpened ||
    isApproveTransitionDialogOpened ||
    isRejectTransitionDialogOpened ||
    isConfirmTransitionDialogOpened ||
    isCancelNoteDialogOpen ||
    isRestartAdeDialogOpen ||
    isControlApproveTransitionDialogOpened ||
    isAssignCheckVerifierTransitionDialogOpened;

  useEffect(() => {
    dataProvider
      .query(`applications/${record.id}/transitions`, { method: 'GET' })
      .then(({ data }) => {
        setTransitions(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, record.id, refreshedAt]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Error />;
  }

  if (!transitions) {
    return null;
  }

  const applyTransition = (name, params = {}) => {
    setDisabled(state => !state);
    dataProvider
      .query(`applications/${record.id}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled(state => !state));
  };

  const handleRestartADE = () => {
    dataProvider
      .query(`applications/${record.id}/restart_ade`, { method: 'POST' })
      .then(() => {
        notify('ADE restarted', 'success');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
        {record.state === 'processing' &&
          record.decision_engine_id === 'manual' &&
          record.verifier_id &&
          getUserId() === record.verifier_id && (
            <Button onClick={() => setIsManualVerificationDialogOpened(true)} disabled={disabled || dialogIsOpened}>
              Manual verification
            </Button>
          )}
        {transitions.includes('start_processing') && (
          <Button
            onClick={() => !setIsStartProcessingTransitionDialogOpened(true)}
            disabled={disabled || dialogIsOpened}>
            Start processing
          </Button>
        )}
        {transitions.includes('assign_verifier') && (
          <Button onClick={() => setIsAssignVerifierTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Assign verifier
          </Button>
        )}
        {record.state === 'verification_control' && permissions.includes('CAN_APPLICATION_EDIT') && (
          <Button
            onClick={() => setIsAssignCheckVerifierTransitionDialogOpened(true)}
            disabled={disabled || dialogIsOpened}>
            Assign checking verifier
          </Button>
        )}
        {transitions.includes('postpone') && (
          <Button onClick={() => setIsPostponeTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Postpone
          </Button>
        )}
        {transitions.includes('approve') && (
          <Button onClick={() => setIsApproveTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Approve
          </Button>
        )}
        {transitions.includes('verification_approve') && (
          <Button onClick={() => setIsControlApproveTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Control Approve
          </Button>
        )}
        {transitions.includes('reject') && (
          <Button onClick={() => setIsRejectTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Reject
          </Button>
        )}
        {permissions.includes('CAN_APPLICATION_CONFIRM') && transitions.includes('confirm') && (
          <Button onClick={() => setIsConfirmTransitionDialogOpened(true)} disabled={disabled || dialogIsOpened}>
            Confirm
          </Button>
        )}
        {transitions.includes('cancel') && (
          <Button onClick={() => setIsCancelNoteDialogOpen(true)} disabled={disabled || dialogIsOpened}>
            Cancel
          </Button>
        )}
        {transitions.includes('reset') && (
          <Button onClick={() => applyTransition('reset')} disabled={disabled}>
            Reset
          </Button>
        )}
        {permissions.includes('CAN_APPLICATION_EDIT') &&
          permissions.includes('CAN_APPLICATION_RESTART_ADE') &&
          appCanBeRestarted && (
            <Button onClick={() => setIsRestartAdeDialogOpen(true)} disabled={dialogIsOpened}>
              Restart ADE
            </Button>
          )}
      </ButtonGroup>
      {isStartProcessingTransitionDialogOpened && (
        <StartProcessingTransitionDialog
          isOpened={isStartProcessingTransitionDialogOpened}
          onClose={() => setIsStartProcessingTransitionDialogOpened(false)}
          onSubmit={decisionEngineId => {
            setIsStartProcessingTransitionDialogOpened(false);
            applyTransition('start_processing', { decision_engine_id: decisionEngineId });
          }}
        />
      )}
      <AddCancelNoteDialog
        isOpened={isCancelNoteDialogOpen}
        onClose={() => setIsCancelNoteDialogOpen(false)}
        onSubmit={message => {
          applyTransition('cancel', { cancellation_reason: message });
          setIsCancelNoteDialogOpen(false);
        }}
      />
      {isManualVerificationDialogOpened && (
        <ManualVerificationDialog
          applicationId={record.id}
          isOpened={isManualVerificationDialogOpened}
          onClose={() => setIsManualVerificationDialogOpened(false)}
          onSubmit={results => {
            setIsManualVerificationDialogOpened(false);
            Promise.allSettled(
              Object.keys(results).map(stepId =>
                dataProvider.update('manual_verification_steps', {
                  id: stepId,
                  data: { result: results[stepId] || null },
                }),
              ),
            )
              .then(() => refresh())
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      )}
      {isAssignVerifierTransitionDialogOpened && (
        <AssignVerifierTransitionDialog
          isOpened={isAssignVerifierTransitionDialogOpened}
          onClose={() => setIsAssignVerifierTransitionDialogOpened(false)}
          onSubmit={verifierId => {
            setIsAssignVerifierTransitionDialogOpened(false);
            applyTransition('assign_verifier', { verifier_id: verifierId });
          }}
        />
      )}
      {isAssignCheckVerifierTransitionDialogOpened && (
        <AssignCheckingVerifierTransitionDialog
          record={record}
          isOpened={isAssignCheckVerifierTransitionDialogOpened}
          onClose={() => setIsAssignCheckVerifierTransitionDialogOpened(false)}
          onSubmit={() => refresh()}
        />
      )}
      {isPostponeTransitionDialogOpened && (
        <PostponeTransitionDialog
          isOpened={isPostponeTransitionDialogOpened}
          onClose={() => setIsPostponeTransitionDialogOpened(false)}
          onSubmit={postponed_until => {
            setIsPostponeTransitionDialogOpened(false);
            applyTransition('postpone', { postponed_until });
          }}
          initialValue={record.postponed_until}
        />
      )}
      {isApproveTransitionDialogOpened && (
        <ApproveTransitionDialog
          record={record}
          isOpened={isApproveTransitionDialogOpened}
          onClose={() => setIsApproveTransitionDialogOpened(false)}
          onSubmit={payload => {
            setIsApproveTransitionDialogOpened(false);
            applyTransition('approve', payload);
          }}
        />
      )}
      {isControlApproveTransitionDialogOpened && (
        <ApproveTransitionDialog
          record={record}
          isOpened={isControlApproveTransitionDialogOpened}
          onClose={() => setIsControlApproveTransitionDialogOpened(false)}
          onSubmit={payload => {
            setIsControlApproveTransitionDialogOpened(false);
            applyTransition('verification_approve', payload);
          }}
        />
      )}
      {isRejectTransitionDialogOpened && (
        <RejectTransitionDialog
          title={'Reject application.'}
          text={'Please select rejection reason.'}
          isOpened={isRejectTransitionDialogOpened}
          onClose={() => setIsRejectTransitionDialogOpened(false)}
          onSubmit={rejection_reason_code => {
            setIsRejectTransitionDialogOpened(false);
            applyTransition('reject', { rejection_reason_code });
          }}
        />
      )}
      {isConfirmTransitionDialogOpened && (
        <ConfirmTransitionDialog
          isOpened={isConfirmTransitionDialogOpened}
          onClose={() => setIsConfirmTransitionDialogOpened(false)}
          onSubmit={confirmationCode => {
            setIsConfirmTransitionDialogOpened(false);
            applyTransition('confirm', { confirmation_code: confirmationCode });
          }}
        />
      )}
      {isRestartAdeDialogOpen && (
        <RestartAdeDialog
          title={'Restart Decision Engine'}
          text={'Are you sure you want to restart the process?'}
          isOpened={isRestartAdeDialogOpen}
          onClose={() => setIsRestartAdeDialogOpen(false)}
          onSubmit={() => {
            setIsRestartAdeDialogOpen(false);
            handleRestartADE();
          }}
        />
      )}
    </>
  );
};

ApplicationTransitions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    decision_engine_id: PropTypes.string,
    verifier_id: PropTypes.number,
    postponed_until: PropTypes.string,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
  appCanBeRestarted: PropTypes.bool,
};

export default ApplicationTransitions;
