function validateCardNumber(value) {
  if (typeof value !== 'string') {
    return false;
  }

  value = value.replace(/\D/g, '');

  if (value.length < 12 || value.length > 19) {
    return false;
  }

  let nCheck = 0;
  let bEven = false;

  for (let n = value.length - 1; n >= 0; n--) {
    let nDigit = parseInt(value.charAt(n), 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export default validateCardNumber;
