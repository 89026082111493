const convertMinutesToDelay = minutes => {
  const delay_days = Math.floor(minutes / (24 * 60));
  const delay_hours = Math.floor((minutes % (24 * 60)) / 60);
  const delay_minutes = minutes % 60;

  return {
    delay_days,
    delay_hours,
    delay_minutes,
  };
};

export default convertMinutesToDelay;
