import { useEffect, useState } from 'react';

import { useHandbook } from './useHandbook';

export function useCollectionGroups() {
  const { isLoading, data: originalData, reload } = useHandbook('collection_groups', { 'order[id]': 'desc' });

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      originalData.map(product => ({
        id: product.id,
        code: product.code,
        name: product.code,
        dpd_from: product.dpd_from,
        dpd_to: product.dpd_to,
      })),
    );
  }, [originalData]);

  return {
    isLoading,
    data,
    choices: data,
    reload,
  };
}
