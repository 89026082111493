import React from 'react';
import PropTypes from 'prop-types';
import { Create, SelectInput, SaveButton, FormWithRedirect, usePermissions } from 'react-admin';
import { FormSpy } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { sortBy } from 'lodash';

import { TIMEZONE_OFFSET } from '../../../utils/dictionary';

import InputBuilder from './components/InputBuilder';
import { useStyles } from './styles';
import { validators } from './utils';

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const roleToChoices = [
    { role: 'CAN_REPORT_AFFILIATE_CREDITS', value: { id: 'affiliate_credits', name: 'Affiliate credits' } },
    { role: 'CAN_REPORT_INCOME', value: { id: 'income_report', name: 'Incomes' } },
    { role: 'CAN_REPORT_SUMMARY', value: { id: 'summary_report', name: 'Summary report' } },
    { role: 'CAN_REPORT_LOAN', value: { id: 'loan_report', name: 'Loans' } },
    { role: 'CAN_REPORT_CLOSED_LOAN', value: { id: 'closed_loan_report', name: 'Closed loan report' } },
    { role: 'CAN_REPORT_COLLECTION_LOAN', value: { id: 'collection_loan_report', name: 'Collection loan report' } },
    { role: 'CAN_REPORT_COMPLIANCE', value: { id: 'compliance_report', name: 'Compliance report' } },
    { role: 'CAN_REPORT_APPLICATION', value: { id: 'application_report', name: 'Applications' } },
    { role: 'CAN_REPORT_CASHFLOW_REPORT', value: { id: 'cashflow_report', name: 'Cashflow report' } },
    { role: 'CAN_REPORT_SECURITY_REPORT', value: { id: 'security_report', name: 'Security report' } },
    { role: 'CAN_REPORT_CLIENT', value: { id: 'client_report', name: 'Client report' } },
    { role: 'CAN_REPORT_TRANSACTION', value: { id: 'transaction_report', name: 'Transaction report' } },
    { role: 'CAN_REPORT_EXTENSION_REPORT', value: { id: 'extension_report', name: 'Extension report' } },
    { role: 'CAN_REPORT_NOTIFICATION', value: { id: 'notification_report', name: 'Notification report' } },
    {
      role: 'CAN_REPORT_LOCAL_NOTIFICATION',
      value: { id: 'local_notification_report', name: 'Local notification report' },
    },
    {
      role: 'CAN_REPORT_SECURITY_APPLICATION',
      value: { id: 'security_application_report', name: 'Security application report' },
    },
    { role: 'CAN_REPORT_PROMO_CODES', value: { id: 'promo_codes_report', name: 'Promo codes' } },
    {
      role: 'CAN_REPORT_UNFINISHED_REGISTRATION',
      value: { id: 'unfinished_registration_report', name: 'Unfinished registration report' },
    },
    { role: 'CAN_REPORT_AFFILIATE_EVENT', value: { id: 'affiliate_event_report', name: 'Affiliate event report' } },
    {
      role: 'CAN_REPORT_VERIFICATION_STAFF_STATISTICS',
      value: { id: 'verification_staff_statistics_report', name: 'Verification staff statistics report' },
    },
    { role: 'CAN_COLLECTION_EMAIL_REPORT', value: { id: 'collection_email_report', name: 'Collection email report' } },
    { role: 'CAN_REPORT_PROMO_CODE_SMS_REPORT', value: { id: 'promo_code_sms_report', name: 'Promo code sms report' } },
    {
      role: 'CAN_REPORT_COLLECTION_ASSIGN',
      value: { id: 'collection_assign_report', name: 'Collection assign report' },
    },
    {
      role: 'CAN_REPORT_EXTERNAL_AGENCY_ASSIGNMENT',
      value: { id: 'external_agency_assignment_report', name: 'External agency assignment report' },
    },
    { role: 'CAN_REPORT_COLLECTION_CALL', value: { id: 'collection_call_report', name: 'Collection call report' } },
    { role: 'CAN_REPORT_ACCOUNTING_LOAN', value: { id: 'accounting_loan_report', name: 'Loans Granted report' } },
    { role: 'CAN_REPORT_PAID_LOAN', value: { id: 'accounting_paid_report', name: 'Accounting paid report' } },
    { role: 'CAN_REPORT_UNPAID_LOAN', value: { id: 'accounting_unpaid_report', name: 'Accounting unpaid report' } },
    { role: 'CAN_REPORT_TOTAL_LOAN', value: { id: 'accounting_total_report', name: 'Accounting total report' } },
    { role: 'CAN_REPORT_CREDIT_BUREAU', value: { id: 'credit_bureau_report', name: 'Credit bureau report' } },
    {
      role: 'CAN_REPORT_CREDIT_BUREAU_CONFIRMATION',
      value: { id: 'credit_bureau_confirmation_report', name: 'Credit bureau confirmation report' },
    },
    { role: 'CAN_REPORT_OUTSOURCING', value: { id: 'sales_report', name: 'Sales report' } },
    { role: 'CAN_REPORT_PAYMENT', value: { id: 'payment_report', name: 'Payment report' } },
    { role: 'CAN_REPORT_BALANCE', value: { id: 'balance_report', name: 'Balance report' } },
    { role: 'CAN_REPORT_INVOICING_LOAN', value: { id: 'invoicing_loan_report', name: 'Invoicing loan report' } },
    { role: 'CAN_REPORT_PAID_LOANS', value: { id: 'paid_loan_report', name: 'Paid loan report' } },
    {
      role: 'CAN_REPORT_STP_BANK_ACCOUNT_BY_LOAN_ID',
      value: { id: 'stp_bank_account_by_loan_id_report', name: 'STP banks accounts by Loan ID' },
    },
    { role: 'CAN_REPORT_AUDIENCE', value: { id: 'audience_report', name: 'Audience report' } },
    { role: 'CAN_REPORT_VERIFICATION', value: { id: 'verification_report', name: 'Verification report' } },
    {
      role: 'CAN_REPORT_OUTSOURCING_REGISTER',
      value: { id: 'outsourcing_register_report', name: 'Outsourcing register report' },
    },
    {
      role: 'CAN_REPORT_INCOME_OUTSOURCING',
      value: { id: 'income_outsourcing_report', name: 'Outsourcing income report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_loans_granted_report', name: 'Invoicing accounting loans granted report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_extension_report', name: 'Invoicing accounting extensions report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_past_due_interest_report', name: 'Invoicing accounting PDI report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_discount_report', name: 'Invoicing accounting discounts report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_paid_loan_report', name: 'Invoicing accounting paid loans report' },
    },
    {
      role: 'CAN_REPORT_INVOICING_ACCOUNTING',
      value: { id: 'invoicing_accounting_cancellation_report', name: 'Invoicing accounting cancellation report' },
    },
    {
      role: 'CAN_REPORT_INCONSISTENCY',
      value: { id: 'loan_inconsistency_report', name: 'Loan inconstistency report' },
    },
    { role: 'CAN_REPORT_INCOME_EMAIL', value: { id: 'income_email_report', name: 'Income email report' } },
  ];

  const choices = [];

  for (const value of roleToChoices) {
    if (permissions.includes(value.role)) {
      choices[choices.length] = value.value;
    }
  }
  const sortedChoices = sortBy(choices, 'name');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create report
          </Typography>
          <Divider />
          <FormWithRedirect
            validate={({ builder_id, ...params }) => {
              const errors = validators[builder_id] && validators[builder_id](params[builder_id] || {});
              return errors && Object.keys(errors).length ? { [builder_id]: errors } : {};
            }}
            save={({ builder_id, ...options }, ...rest) => {
              const params = options?.[builder_id] ? { ...options[builder_id] } : {};

              if (
                params.timezone &&
                params.start_date instanceof Date &&
                params.end_date instanceof Date &&
                TIMEZONE_OFFSET[params.timezone]
              ) {
                const offset = TIMEZONE_OFFSET[params.timezone]() + new Date().getTimezoneOffset();
                const startDate = new Date(new Date(params.start_date).valueOf() - offset * 60 * 1000);
                const endDate = new Date(new Date(params.end_date).valueOf() - offset * 60 * 1000);
                params.start_date = startDate.toISOString();
                params.end_date = endDate.toISOString();
                if (params.timezone === 'Local') {
                  params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                }
              }

              if (
                builder_id === 'summary_report' &&
                params.timezone &&
                params.calculation_date instanceof Date &&
                TIMEZONE_OFFSET[params.timezone]
              ) {
                const offset = TIMEZONE_OFFSET[params.timezone]() + new Date().getTimezoneOffset();
                const calculationDate = new Date(new Date(params.calculation_date).valueOf() - offset * 60 * 1000);

                params.calculation_date = calculationDate.toISOString();
              }

              save(...[{ builder_id, params }, ...rest]);
            }}
            {...props}
            render={formProps => (
              <>
                <SelectInput
                  helperText={false}
                  className={classes.fullWidth}
                  source="builder_id"
                  choices={sortedChoices}
                />
                <Typography variant="h6" gutterBottom={false}>
                  Params
                </Typography>
                <FormSpy subscription={{ touched: true, values: {} }}>
                  {({ values: { builder_id: builderId } }) => <InputBuilder builderId={builderId} />}
                </FormSpy>
                <Divider className={classes.my2} />
                <SaveButton
                  label="Submit"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ReportCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default ReportCreate;
