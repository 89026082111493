import React from 'react';
import ReactDOM from 'react-dom';
import { Admin, defaultTheme, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PublicIcon from '@material-ui/icons/Public';
import WeekendIcon from '@material-ui/icons/Weekend';
import AssessmentIcon from '@material-ui/icons/Assessment';
import UpdateIcon from '@material-ui/icons/Update';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailIcon from '@material-ui/icons/Mail';
import BlockIcon from '@material-ui/icons/Block';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { createTheme } from '@material-ui/core/styles';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import LinkIcon from '@material-ui/icons/Link';
import BuildIcon from '@material-ui/icons/Build';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as Sentry from '@sentry/react';
import CallIcon from '@material-ui/icons/Call';
import ExtensionIcon from '@material-ui/icons/Extension';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import StorageIcon from '@material-ui/icons/Storage';

import AppResource from './components/Resource';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import customReducers from './redux/reducers';
import initialState from './redux/initialState';
import Layout from './components/Layout';
import Dashboard from './components/pages/dashboard/Dashboard';
import { UserList, UsersFiltered } from './components/pages/user/UserList';
import UserEdit from './components/pages/user/UserEdit';
import AdminList from './components/pages/admin/AdminList';
import AdminEdit from './components/pages/admin/AdminEdit';
import AdminCreate from './components/pages/admin/AdminCreate';
import ApplicationList, { VerificationControlApplicationList } from './components/pages/application/ApplicationList';
import ApplicationShow from './components/pages/application/ApplicationShow';
import ApplicationVerificationShow from './components/pages/application/ApplicationVerificationShow';
import ManualVerificationList, {
  ListRepeatFilter,
  ManualVerificationAll,
} from './components/pages/application/ManualVerificationList';
import LoanList from './components/pages/loan/LoanList';
import ListFilterHOC from './components/hoc/ListFilterHOC';
import ListExtensionRequest from './components/pages/loan/LoanListExtensionRequest';
import LoanListExternalAgency from './components/pages/loan/LoanListExternalAgency';
import LoanListSold from './components/pages/loan/LoanListSold';
import LoanListPendingNotReady from './components/pages/loan/LoanListPendingNotReady';
import { LoanListPendingReady, LoanListPendingFailure } from './components/pages/loan/LoanListPendingReady';
import LoanListCancelled from './components/pages/loan/LoanListCancelled';
import LoanListMoneyTransferFailed from './components/pages/loan/LoanListMoneyTransferFailed';
import LoanShow from './components/pages/loan/LoanShow';
import CollectionList from './components/pages/collection/LoanList';
import CollectionPTPList from './components/pages/collection/CollectionPTPList';
import TemplateList from './components/pages/template/TemplateList';
import TemplateEdit from './components/pages/template/TemplateEdit';
import TemplateCreate from './components/pages/template/TemplateCreate';
import AffiliateList from './components/pages/affiliate/AffiliateList';
import AffiliateEdit from './components/pages/affiliate/AffiliateEdit';
import AffiliateCreate from './components/pages/affiliate/AffiliateCreate';
import ReportList from './components/pages/report/ReportList';
import ReportCreate from './components/pages/report/ReportCreate';
import ImportProcessCreate from './components/pages/import_process/ImportProcessCreate';
import ImportProcessList from './components/pages/import_process/ImportProcessList';
import PromoCodeList from './components/pages/promo_code/PromoCodeList';
import PromoCodeCreate from './components/pages/promo_code/PromoCodeCreate';
import ShortLinkCreate from './components/pages/short_links/ShortLinkCreate';
import ShortLinkEdit from './components/pages/short_links/ShortLinkEdit';
import ShortLinksList from './components/pages/short_links/ShortLinksList';
import PromoCodeEdit from './components/pages/promo_code/PromoCodeEdit';
import AudienceCreate from './components/pages/audience/AudienceCreate';
import AudienceList from './components/pages/audience/AudienceList';
import AudienceEdit from './components/pages/audience/AudienceEdit';
import UserEventNotificationList from './components/pages/user_event_notification/UserEventNotificationList';
import UserEventNotificationCreate from './components/pages/user_event_notification/UserEventNotificationCreate';
import UserEventNotificationEdit from './components/pages/user_event_notification/UserEventNotificationEdit';
import MassSendingProcessList from './components/pages/mass_sending_process/MassSendingProcessList';
import MassSendingProcessCreate from './components/pages/mass_sending_process/MassSendingProcessCreate';
import MassSendingCampaignList from './components/pages/mass_sending_campaign/MassSendingCampaignList';
import MassSendingCampaignCreate from './components/pages/mass_sending_campaign/MassSendingCampaignCreate';
import MassSendingCampaignEdit from './components/pages/mass_sending_campaign/MassSendingCampaignEdit';
import BlackList from './components/pages/blacklist/BlackList';
import BlackListEdit from './components/pages/blacklist/BlackListEdit';
import BlackListCreate from './components/pages/blacklist/BlackListCreate';
import LeadsList from './components/pages/user/LeadsList';
import AutoAssignmentList from './components/pages/auto_assignment/AutoAssignmentList';
import AutoAssignmentCreate from './components/pages/auto_assignment/AutoAssignmentCreate';
import {
  CallCenterList,
  CallCenterListClosedLoans,
  CallCenterListUnsignedRequests,
  ListFilterUnsigned,
  CallCenterListFiltersClosed,
} from './components/pages/callcenter/CallCenterList';
import { ClientsWithPromoCodeList } from './components/pages/callcenter/ClientsWithPromoCodeList';
import { DuplicatesList } from './components/pages/callcenter/DuplicatesList';
import { ArchiveClientsList } from './components/pages/callcenter/ArchiveClientsList';
import DialerBasesList from './components/pages/dialer_bases/DialerBasesList';
import DialerBasesCreate from './components/pages/dialer_bases/DialerBasesCreate';
import DialerBasesEdit from './components/pages/dialer_bases/DialerBasesEdit';
import RejectionReasonsList from './components/pages/rejection_reasons/RejectionReasonsList';
import RejectionReasonsCreate from './components/pages/rejection_reasons/RejectionReasonsCreate';
import RejectionReasonsEdit from './components/pages/rejection_reasons/RejectionReasonsEdit';
import HandbookList from './components/pages/handbook/HandbookList';
import HandbookEdit from './components/pages/handbook/HandbookEdit';
import HandbookCreate from './components/pages/handbook/HandbookCreate';
import CollectionMotivatorList from './components/pages/collection_motivators/CollectionMotivatorList';
import CollectionMotivatorEdit from './components/pages/collection_motivators/CollectionMotivatorEdit';
import CollectionContactTypeList from './components/pages/collection_contact_types/CollectionContactTypeList';
import CollectionContactTypeEdit from './components/pages/collection_contact_types/CollectionContactTypeEdit';
import CollectionContactTypeValueEdit from './components/pages/collection_contact_type_values/CollectionContactTypeValueEdit';
import CollectionActionTreeList from './components/pages/collection_action_tree/CollectionActionTreeList';
import HolidayList from './components/pages/holiday/HolidayList';
import HolidayEdit from './components/pages/holiday/HolidayEdit';
import HolidayCreate from './components/pages/holiday/HolidayCreate';
import RoleList from './components/pages/role/RoleList';
import RoleEdit from './components/pages/role/RoleEdit';
import RoleCreate from './components/pages/role/RoleCreate';
import CollectionGroupList from './components/pages/collection_groups/CollectionGroupList';
import Settings from './components/pages/settings/Settings';
import WorkSchedule from './components/pages/work_schedule/WorkSchedule';
import CollectionIncomesList from './components/pages/collection_incomes/CollectionIncomesList';
import { NotificationList } from './components/pages/notification/NotificationList';
import PhoneBook from './components/pages/phonebook/PhoneBook';
import ExternalAgenciesList from './components/pages/collection_external_agencies/ExternalAgenciesList';
import ExternalAgenciesCreate from './components/pages/collection_external_agencies/ExternalAgenciesCreate';
import ExternalAgenciesEdit from './components/pages/collection_external_agencies/ExternalAgenciesEdit';
import ExternalAgencyCampaignList from './components/pages/external_agency_campaign/ExternalAgencyCampaignList';
import ExternalAgencyCampaignCreate from './components/pages/external_agency_campaign/ExternalAgencyCampaignCreate';
import ExternalAgencyCampaignEdit from './components/pages/external_agency_campaign/ExternalAgencyCampaignEdit';
import SatBlackList from './components/pages/sat_blacklist/SatBlackList';
import WebitelCallsList from './components/pages/webitel_calls/WebitelCallsList';
import AdminNotificationsList from './components/pages/admin_notifications/AdminNotificationsList';
import DefaultVariablesList from './components/pages/default_variables/DefaultVariablesList';
import DefaultVariablesEdit from './components/pages/default_variables/DefaultVariablesEdit';
import ComissionsList from './components/pages/comissions/ComissionsList';
import ComissionsEdit from './components/pages/comissions/ComissionsEdit';
import ComissionsCreate from './components/pages/comissions/ComissionsCreate';
import ExtensionsList from './components/pages/extension/ExtensionsList';
import ExtensionEdit from './components/pages/extension/ExtensionEdit';
import ExtensionCreate from './components/pages/extension/ExtensionCreate';
import MoneyTransferList from './components/pages/money_transfer/MoneyTransferList';
import CommunicationCategoryList from './components/pages/communication_categories/CommunicationCategoryList';
import CommunicationCategoryCreate from './components/pages/communication_categories/CommunicationCategoryCreate';
import CommunicationCategoryEdit from './components/pages/communication_categories/CommunicationCategoryEdit';
import OutgoingMailboxList from './components/pages/outgoing_mailboxes/OutgoingMailboxList';
import OutgoingMailboxCreate from './components/pages/outgoing_mailboxes/OutgoingMailboxCreate';
import OutgoingMailboxEdit from './components/pages/outgoing_mailboxes/OutgoingMailboxEdit';
import CommunicationResultList from './components/pages/communication_results/CommunicationResultList';
import CommunicationResultEdit from './components/pages/communication_results/CommunicationResultEdit';
import CommunicationResultCreate from './components/pages/communication_results/CommunicationResultCreate';
import { IncomeMailList, OutcomeMailList } from './components/pages/mail/MailList';
import MailEdit from './components/pages/mail/MailEdit';
import Watermark from './components/watermark/Watermark';
import LoginPage from './LoginPage';
import DebitStrategiesList from './components/pages/debit_strategies/DebitStrategiesList';
import DebitStrategiesCreate from './components/pages/debit_strategies/DebitStrategiesCreate';
import DebitStrategiesEdit from './components/pages/debit_strategies/DebitStrategiesEdit';
import DebitCampaignList from './components/pages/debit_campaigns/DebitCampaignList';
import DebitCampaignCreate from './components/pages/debit_campaigns/DebitCampaignCreate';
import DebitCampaignEdit from './components/pages/debit_campaigns/DebitCampaignEdit';
import DebitRequestsList from './components/pages/debit_requests/DebitRequestsList';
import LoanTermRulesList from './components/pages/loan_term_rules/LoanTermRulesList';
import LoanTermRulesCreate from './components/pages/loan_term_rules/LoanTermRulesCreate';
import LoanTermRulesEdit from './components/pages/loan_term_rules/LoanTermRulesEdit';
import LoanHeaderRulesList from './components/pages/loan_header_rules/LoanHeaderRulesList';
import LoanHeaderRulesCreate from './components/pages/loan_header_rules/LoanHeaderRulesCreate';
import LoanHeaderRulesEdit from './components/pages/loan_header_rules/LoanHeaderRulesEdit';
import LoanRateRulesList from './components/pages/loan_rate_rules/LoanRateRulesList';
import LoanRateRulesCreate from './components/pages/loan_rate_rules/LoanRateRulesCreate';
import LoanRateRulesEdit from './components/pages/loan_rate_rules/LoanRateRulesEdit';
import UserNotificationCampaignList from './components/pages/user_notification_campaign/UserNotificationCampaignList';
import UserNotificationCampaignCreate from './components/pages/user_notification_campaign/UserNotificationCampaignCreate';
import UserNotificationCampaignEdit from './components/pages/user_notification_campaign/UserNotificationCampaignEdit';
import ProductList from './components/pages/product/ProductList';
import ProductEdit from './components/pages/product/ProductEdit';
import WebitelLogsList from './components/pages/webitel_logs/WebitelLogsList';
import DiscountCampaignList from './components/pages/discount_campaign/DiscountCampaignList';
import DiscountCampaignCreate from './components/pages/discount_campaign/DiscountCampaignCreate';
import DiscountCampaignShow from './components/pages/discount_campaign/DiscountCampaignShow';
import DiscountCampaignEdit from './components/pages/discount_campaign/DiscountCampaignEdit';

const theme = createTheme({
  ...defaultTheme,
  sidebar: {
    width: 280,
    closedWidth: 55,
  },
});

const ListFirstManual = ListFilterHOC(ManualVerificationList, { is_repeat: false });
const ListRepeatManual = ListFilterHOC(ManualVerificationList, { is_repeat: true }, [], ListRepeatFilter);
const ManualVerified = ListFilterHOC(ManualVerificationAll, { decision_engine_id: 'manual' });
const ListUncompleted = ListFilterHOC(CallCenterList, { has_application: false });
const ListClosed = ListFilterHOC(CallCenterListClosedLoans, { closedLoans: true }, [], CallCenterListFiltersClosed);
const ListUnsignedRequests = ListFilterHOC(
  CallCenterListUnsignedRequests,
  { state: 'approved' },
  [],
  ListFilterUnsigned,
);

const MoneyTransferFailedUsersList = UsersFiltered(UserList, { has_failed_money_transfer: true });

const rootEl = document.createElement('div');
document.body.appendChild(rootEl);

ReactDOM.render(<Watermark bottom="30px" right="30px" />, rootEl);

const App = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Admin
      theme={theme}
      layout={Layout}
      dashboard={Dashboard}
      authProvider={authProvider}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      customReducers={customReducers}
      initialState={initialState}
      disableTelemetry>
      {(permissions, key) => [
        permissions.includes('CAN_USER_VIEW') && (
          <Resource
            key={key}
            name="users"
            icon={UserIcon}
            list={UserList}
            edit={UserEdit}
            options={{ label: 'Clients', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_USER_VIEW') && (
          <AppResource
            key={key}
            name="users_money_transfer_failed"
            source="users"
            icon={UserIcon}
            list={MoneyTransferFailedUsersList}
            edit={UserEdit}
            options={{ label: 'Money transfer failed', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_LEAD_VIEW') && (
          <Resource
            key={key}
            name="leads"
            icon={RemoveCircleIcon}
            list={LeadsList}
            options={{ label: 'Leads', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_BLACK_LIST_EDIT') && (
          <Resource
            name="black_lists"
            icon={BlockIcon}
            list={BlackList}
            edit={BlackListEdit}
            create={BlackListCreate}
            options={{ label: 'AGIS Blacklist', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_SAT_BLACKLIST_EDIT') && (
          <AppResource
            key={key}
            name="sat_blacklists"
            source="sat_blacklists"
            icon={HighlightOffIcon}
            list={SatBlackList}
            edit={false}
            options={{ label: 'SAT Blacklist', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_USER_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="uncompleted_registration"
            source="users"
            icon={UserIcon}
            list={ListUncompleted}
            edit={UserEdit}
            options={{ label: 'Uncompleted registration', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="closed_loans"
            source="loans"
            icon={AssignmentIcon}
            list={ListClosed}
            show={LoanShow}
            options={{ label: 'Closed loans', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="unsigned_requests"
            source="applications"
            icon={AssignmentIcon}
            list={ListUnsignedRequests}
            show={ApplicationShow}
            options={{ label: 'Unsigned requests', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_USER_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="clients_with_promo_code"
            source="users"
            icon={UserIcon}
            list={ClientsWithPromoCodeList}
            edit={UserEdit}
            create={false}
            options={{ label: 'Clients with promo code', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_DUPLICATES_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="duplicates"
            source="duplicates"
            icon={UserIcon}
            list={DuplicatesList}
            edit={false}
            create={false}
            options={{ label: 'Duplicates', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_DUPLICATES_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="duplicate_registrations"
            source="duplicate_registrations"
            icon={UserIcon}
            list={ArchiveClientsList}
            edit={false}
            create={false}
            options={{ label: 'Archive clients', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <Resource
            key={key}
            name="applications"
            icon={AssignmentIcon}
            list={ApplicationList}
            show={ApplicationShow}
            options={{
              label: 'Applications',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="applications_verification_control"
            source="applications"
            icon={AssignmentIcon}
            list={VerificationControlApplicationList}
            show={ApplicationShow}
            options={{
              label: 'Verification control',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="applications_verification"
            source="applications"
            show={ApplicationVerificationShow}
            options={{
              label: 'Verification',
              menuGroup: 'hidden',
            }}
          />
        ),
        permissions.includes('CAN_MANUAL_VERIFICATIONS_VIEW') && (
          <AppResource
            key={key}
            name="manual_verification_all"
            source="applications"
            icon={AssignmentIcon}
            list={ManualVerified}
            show={ApplicationShow}
            options={{
              label: 'Manual verification',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="manual_verification"
            source="applications"
            icon={AssignmentIcon}
            list={ListFirstManual}
            show={ApplicationShow}
            options={{
              label: 'First manual verif',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="repeat_manual_verification"
            source="applications"
            icon={AssignmentIcon}
            list={ListRepeatManual}
            show={ApplicationShow}
            options={{
              label: 'Repeat manual verif',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <Resource
            key={key}
            name="loans"
            icon={AssignmentTurnedInIcon}
            list={LoanList}
            show={LoanShow}
            options={{ label: 'Loans', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="in_progress"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={LoanListPendingNotReady}
            show={LoanShow}
            options={{ label: 'In progress', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && permissions.includes('CAN_LOAN_TRANSITION_TRANSFER_MONEY') && (
          <AppResource
            key={key}
            name="ready_for_disbursement"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={LoanListPendingReady}
            show={LoanShow}
            options={{ label: 'Ready for disbursement', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="failure"
            source="loans"
            icon={SmsFailedIcon}
            list={LoanListPendingFailure}
            show={LoanShow}
            options={{ label: 'Failure', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="cancelled"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={LoanListCancelled}
            show={LoanShow}
            options={{ label: 'Cancelled', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="sold"
            source="loans"
            icon={AssignmentReturnIcon}
            list={LoanListSold}
            show={LoanShow}
            options={{ label: 'Sold', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="money_transfer_failed"
            source="loans"
            icon={AssignmentReturnIcon}
            list={LoanListMoneyTransferFailed}
            show={LoanShow}
            options={{ label: 'Money transfer failed', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="external_agency"
            source="loans"
            icon={AssignmentReturnIcon}
            list={LoanListExternalAgency}
            show={LoanShow}
            options={{ label: 'External agency', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="collection"
            source="loans"
            icon={AssignmentLateIcon}
            list={CollectionList}
            show={LoanShow}
            options={{ label: 'Collection', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <Resource
            key={key}
            name="promise_to_pays"
            icon={AssignmentLateIcon}
            list={CollectionPTPList}
            options={{ label: 'Collection PTP', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="extension_request"
            source="loans"
            icon={UpdateIcon}
            list={ListExtensionRequest}
            show={LoanShow}
            options={{ label: 'Extensions', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            icon={SearchIcon}
            name="phone_books"
            source="phone_books"
            list={PhoneBook}
            options={{ label: 'Phone books', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="education"
            source="education"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Education', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="employments"
            source="employments"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Employments', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="marital_statuses"
            source="marital_statuses"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Marital statuses', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="phone_relations"
            source="phone_relations"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Phone relations', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="phone_sources"
            source="phone_sources"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Phone sources', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="phone_types"
            source="phone_types"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Phone types', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="resident_types"
            source="resident_types"
            list={HandbookList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && HandbookEdit}
            options={{ label: 'Resident types', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_REJECTION_REASONS_EDIT') && (
          <AppResource
            key={key}
            name="rejection_reasons"
            source="rejection_reasons"
            list={RejectionReasonsList}
            create={RejectionReasonsCreate}
            edit={RejectionReasonsEdit}
            options={{ label: 'Rejection reasons', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="communication_categories"
            source="communication_categories"
            list={CommunicationCategoryList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && CommunicationCategoryCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && CommunicationCategoryEdit}
            options={{ label: 'Communication categories', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_HANDBOOK_VIEW') && (
          <AppResource
            key={key}
            name="communication_results"
            source="communication_results"
            list={CommunicationResultList}
            create={permissions.includes('CAN_HANDBOOK_EDIT') && CommunicationResultCreate}
            edit={permissions.includes('CAN_HANDBOOK_EDIT') && CommunicationResultEdit}
            options={{ label: 'Communication results', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && (
          <AppResource
            key={key}
            name="collection_action_tree"
            source="collection_action_tree"
            list={CollectionActionTreeList}
            edit={false}
            options={{ label: 'Action tree', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_VIEW') && (
          <AppResource
            key={key}
            name="collection_motivators"
            source="collection_motivators"
            list={CollectionMotivatorList}
            edit={permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && CollectionMotivatorEdit}
            options={{ label: 'Motivators', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_VIEW') && (
          <AppResource
            key={key}
            name="collection_contact_types"
            source="collection_contact_types"
            list={CollectionContactTypeList}
            edit={permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && CollectionContactTypeEdit}
            options={{ label: 'Contact types', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_GROUP_VIEW') && (
          <AppResource
            key={key}
            name="collection_groups"
            source="collection_groups"
            list={CollectionGroupList}
            options={{ label: 'Collection groups', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_VIEW_INCOME_PAYMENTS') && (
          <AppResource
            key={key}
            name="incomes"
            source="incomes"
            icon={AttachMoneyIcon}
            list={CollectionIncomesList}
            options={{ label: 'Incomes', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_EXTERNAL_AGENCY_VIEW') && (
          <AppResource
            key={key}
            name="external_agencies"
            source="external_agencies"
            icon={AccountBalanceIcon}
            list={ExternalAgenciesList}
            create={permissions.includes('CAN_EXTERNAL_AGENCY_EDIT') && ExternalAgenciesCreate}
            edit={permissions.includes('CAN_EXTERNAL_AGENCY_EDIT') && ExternalAgenciesEdit}
            options={{ label: 'External agencies', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_DEBIT_STRATEGY_VIEW') && (
          <AppResource
            key={key}
            name="auto_debit_strategies"
            source="auto_debit_strategies"
            icon={AttachMoneyIcon}
            list={DebitStrategiesList}
            create={DebitStrategiesCreate}
            edit={DebitStrategiesEdit}
            options={{ label: 'Auto debit strategies', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_DEBIT_CAMPAIGN_VIEW') && (
          <AppResource
            key={key}
            name="auto_debit_campaigns"
            source="auto_debit_campaigns"
            icon={AttachMoneyIcon}
            list={DebitCampaignList}
            create={DebitCampaignCreate}
            edit={DebitCampaignEdit}
            options={{ label: 'Auto debit campaigns', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_DEBITS_VIEW') && (
          <AppResource
            key={key}
            name="auto_debits"
            source="auto_debits"
            icon={AttachMoneyIcon}
            list={DebitRequestsList}
            options={{ label: 'Direct debit requests', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_DISCOUNT_CAMPAIGN_VIEW') ? (
          <Resource
            name="discount_campaigns"
            icon={StorageIcon}
            list={DiscountCampaignList}
            create={permissions.includes('CAN_DISCOUNT_CAMPAIGN_EDIT') ? DiscountCampaignCreate : null}
            show={DiscountCampaignShow}
            edit={permissions.includes('CAN_DISCOUNT_CAMPAIGN_EDIT') ? DiscountCampaignEdit : null}
            options={{ label: 'Discount campaigns', menuGroup: 'collection' }}
          />
        ) : null,
        permissions.includes('CAN_NOTIFICATION_ALL_VIEW') && (
          <Resource
            key={key}
            icon={MailIcon}
            name="notifications"
            source="notifications"
            list={NotificationList}
            edit={false}
            options={{ label: 'Notifications' }}
          />
        ),
        permissions.includes('CAN_EMAIL_VIEW') && (
          <AppResource
            key={key}
            icon={AlternateEmailIcon}
            name="income"
            source="emails"
            list={IncomeMailList}
            edit={MailEdit}
            options={{ label: 'Income', menuGroup: 'mails' }}
          />
        ),
        permissions.includes('CAN_EMAIL_VIEW') && (
          <AppResource
            key={key}
            icon={AlternateEmailIcon}
            name="outcome"
            source="emails"
            list={OutcomeMailList}
            edit={MailEdit}
            options={{ label: 'Outcome', menuGroup: 'mails' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <Resource
            name="admin_notifications"
            source="admin_notifications"
            edit={false}
            icon={NotificationsIcon}
            list={AdminNotificationsList}
            options={{ label: 'Collection alerts' }}
          />
        ),
        permissions.includes('CAN_IMPORT_PROCESS_VIEW') ? (
          <Resource
            name="import_processes"
            icon={CloudUploadIcon}
            list={ImportProcessList}
            create={permissions.includes('CAN_IMPORT_PROCESS_EDIT') ? ImportProcessCreate : null}
            options={{ label: 'Imports', menuGroup: 'management' }}
          />
        ) : null,
        permissions.includes('CAN_REPORT_VIEW') ? (
          <Resource
            name="reports"
            icon={AssessmentIcon}
            list={ReportList}
            create={permissions.includes('CAN_REPORT_EDIT') ? ReportCreate : null}
            options={{ label: 'Reports', menuGroup: 'management' }}
          />
        ) : null,
        permissions.includes('CAN_ADMIN_VIEW') ? (
          <Resource
            name="admins"
            icon={SupervisorAccountIcon}
            list={AdminList}
            edit={AdminEdit}
            create={permissions.includes('CAN_ADMIN_EDIT') ? AdminCreate : null}
            options={{ label: 'Admins', menuGroup: 'management' }}
          />
        ) : null,
        permissions.includes('CAN_ROLE_EDIT') ? (
          <Resource
            name="admin_auth_roles"
            icon={SupervisorAccountIcon}
            list={RoleList}
            edit={RoleEdit}
            create={permissions.includes('CAN_ROLE_EDIT') ? RoleCreate : null}
            options={{ label: 'Roles', menuGroup: 'management' }}
          />
        ) : null,
        permissions.includes('CAN_ADMIN_VIEW') ? (
          <Resource
            name="money_transfers"
            icon={MonetizationOnIcon}
            list={MoneyTransferList}
            edit={false}
            create={false}
            options={{ label: 'Money transfers', menuGroup: 'management' }}
          />
        ) : null,
        permissions.includes('CAN_TEMPLATE_VIEW') ? (
          <Resource
            name="templates"
            icon={TextFormatIcon}
            list={TemplateList}
            edit={TemplateEdit}
            create={permissions.includes('CAN_TEMPLATE_EDIT') ? TemplateCreate : null}
            options={{ label: 'Templates', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_PRODUCT_VIEW') ? (
          <Resource
            name="products"
            icon={StorageIcon}
            list={ProductList}
            show={ProductEdit}
            options={{ label: 'Products', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_OUTGOING_MAILBOXES_SETTINGS_VIEW') ? (
          <Resource
            name="email_outbox_addresses"
            icon={AlternateEmailIcon}
            list={OutgoingMailboxList}
            edit={OutgoingMailboxEdit}
            create={OutgoingMailboxCreate}
            options={{ label: 'Outgoing mailboxes', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_AFFILIATE_VIEW') ? (
          <Resource
            name="affiliates"
            icon={PublicIcon}
            list={AffiliateList}
            edit={AffiliateEdit}
            create={permissions.includes('CAN_AFFILIATE_EDIT') ? AffiliateCreate : null}
            options={{ label: 'Affiliates', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_MASS_SENDING_VIEW') ? (
          <Resource
            name="mass_sending_campaigns"
            icon={MailIcon}
            list={MassSendingCampaignList}
            edit={MassSendingCampaignEdit}
            create={permissions.includes('CAN_MASS_SENDING_EDIT') ? MassSendingCampaignCreate : null}
            options={{ label: 'Mass sending campaigns', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_USER_NOTIFICATION_CAMPAIGN_VIEW') ? (
          <Resource
            name="user_notification_campaigns"
            icon={MailIcon}
            list={UserNotificationCampaignList}
            edit={UserNotificationCampaignEdit}
            create={permissions.includes('CAN_USER_NOTIFICATION_CAMPAIGN_EDIT') ? UserNotificationCampaignCreate : null}
            options={{ label: 'User notification campaigns', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_EXTERNAL_AGENCY_CAMPAIGNS_VIEW') && (
          <Resource
            name="external_agency_campaigns"
            icon={BuildIcon}
            list={ExternalAgencyCampaignList}
            edit={ExternalAgencyCampaignEdit}
            create={permissions.includes('CAN_EXTERNAL_AGENCY_CAMPAIGNS_EDIT') && ExternalAgencyCampaignCreate}
            options={{ label: 'External agency assignment', menuGroup: 'settings' }}
          />
        ),
        permissions.includes('CAN_AUTO_ASSIGNMENT_VIEW') ? (
          <Resource
            name="auto_assignments"
            icon={DirectionsRunIcon}
            list={AutoAssignmentList}
            create={permissions.includes('CAN_AUTO_ASSIGNMENT_EDIT') ? AutoAssignmentCreate : null}
            options={{ label: 'Auto assignment', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_HOLIDAY_VIEW') ? (
          <Resource
            name="holidays"
            icon={WeekendIcon}
            list={HolidayList}
            edit={HolidayEdit}
            create={permissions.includes('CAN_HOLIDAY_EDIT') ? HolidayCreate : null}
            options={{ label: 'Holidays', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_DIALER_VIEW') ? (
          <Resource
            name="dialer_campaigns"
            icon={BuildIcon}
            list={DialerBasesList}
            create={permissions.includes('CAN_DIALER_EDIT') ? DialerBasesCreate : null}
            edit={permissions.includes('CAN_DIALER_EDIT') ? DialerBasesEdit : null}
            options={{ label: 'Dialer bases', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_DEFAULT_VARIABLES_VIEW') ? (
          <Resource
            name="default_variables"
            icon={BuildIcon}
            list={DefaultVariablesList}
            edit={DefaultVariablesEdit}
            options={{ label: 'Default variables', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_COMMISSION_SETTINGS_VIEW') ? (
          <Resource
            name="commission_settings"
            icon={BuildIcon}
            list={ComissionsList}
            edit={ComissionsEdit}
            create={permissions.includes('CAN_COMMISSION_SETTINGS_EDIT') ? ComissionsCreate : null}
            options={{ label: 'Comissions', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_EXTENSION_SETTINGS_VIEW') ? (
          <Resource
            name="extension_settings"
            icon={ExtensionIcon}
            list={ExtensionsList}
            edit={ExtensionEdit}
            create={permissions.includes('CAN_EXTENSION_SETTINGS_EDIT') ? ExtensionCreate : null}
            options={{ label: 'Extensions', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_LOAN_TERM_RULE_EDIT') ? (
          <Resource
            name="loan_term_rules"
            icon={BuildIcon}
            list={LoanTermRulesList}
            edit={LoanTermRulesEdit}
            create={LoanTermRulesCreate}
            options={{ label: 'Loan approval terms', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_LOAN_HEADER_RULE_EDIT') ? (
          <Resource
            name="loan_header_rules"
            icon={BuildIcon}
            list={LoanHeaderRulesList}
            edit={LoanHeaderRulesEdit}
            create={LoanHeaderRulesCreate}
            options={{ label: 'Loan approval messages', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_LOAN_RATE_RULE_EDIT') ? (
          <Resource
            name="loan_rate_rules"
            icon={BuildIcon}
            list={LoanRateRulesList}
            edit={LoanRateRulesEdit}
            create={LoanRateRulesCreate}
            options={{ label: 'Loan rate rules', menuGroup: 'settings' }}
          />
        ) : null,
        permissions.includes('CAN_PROMO_CODE_VIEW') ? (
          <Resource
            name="promo_codes"
            icon={LocalOfferIcon}
            list={PromoCodeList}
            edit={PromoCodeEdit}
            create={permissions.includes('CAN_PROMO_CODE_EDIT') ? PromoCodeCreate : null}
            options={{ label: 'Promo codes', menuGroup: 'marketing' }}
          />
        ) : null,
        permissions.includes('CAN_USER_EVENT_NOTIFICATION_VIEW') ? (
          <Resource
            name="user_event_notifications"
            icon={NotificationsIcon}
            list={UserEventNotificationList}
            edit={UserEventNotificationEdit}
            create={permissions.includes('CAN_USER_EVENT_NOTIFICATION_EDIT') ? UserEventNotificationCreate : null}
            options={{ label: 'User notifications', menuGroup: 'marketing' }}
          />
        ) : null,
        permissions.includes('CAN_MASS_SENDING_VIEW') ? (
          <Resource
            name="mass_sending_processes"
            icon={MailIcon}
            list={MassSendingProcessList}
            create={permissions.includes('CAN_MASS_SENDING_EDIT') ? MassSendingProcessCreate : null}
            options={{ label: 'Mass sending processes', menuGroup: 'marketing' }}
          />
        ) : null,
        permissions.includes('CAN_SHORT_LINK_VIEW') ? (
          <Resource
            name="short_links"
            icon={LinkIcon}
            list={ShortLinksList}
            edit={ShortLinkEdit}
            create={permissions.includes('CAN_SHORT_LINK_EDIT') ? ShortLinkCreate : null}
            options={{ label: 'Short links', menuGroup: 'marketing' }}
          />
        ) : null,
        permissions.includes('CAN_MASS_SENDING_AUDIENCE_VIEW') ||
        permissions.includes('CAN_MASS_SENDING_AUDIENCE_EDIT') ? (
          <Resource
            name="mass_sending_audiences"
            icon={AssignmentIcon}
            list={AudienceList}
            edit={AudienceEdit}
            create={permissions.includes('CAN_MASS_SENDING_AUDIENCE_EDIT') ? AudienceCreate : null}
            options={{ label: 'Audiences', menuGroup: 'marketing' }}
          />
        ) : null,
        permissions.includes('CAN_BLACK_LIST_EDIT') ? (
          <Resource
            name="black_lists"
            icon={BlockIcon}
            list={BlackList}
            edit={BlackListEdit}
            create={BlackListCreate}
            options={{ label: 'Blacklist' }}
          />
        ) : null,
        permissions.includes('CAN_WEBITEL_CALLS_VIEW') && (
          <Resource
            key={key}
            icon={CallIcon}
            name="calls"
            source="calls"
            list={WebitelCallsList}
            edit={false}
            options={{ label: 'Calls' }}
          />
        ),
        <Resource
          key={key}
          icon={BuildIcon}
          name="settings"
          list={Settings}
          edit={false}
          create={false}
          options={{ label: 'Settings', menuGroup: 'settings' }}
        />,
        permissions.includes('CAN_WORK_SCHEDULE_VIEW') ? (
          <Resource
            key={key}
            icon={ScheduleIcon}
            name="work_schedule"
            list={WorkSchedule}
            edit={false}
            create={false}
            options={{ label: 'Work schedule', menuGroup: 'settings' }}
          />
        ) : null,
        <Resource key={key} name="manual_verification_steps" options={{ menuGroup: 'hidden' }} />,
        permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && (
          <AppResource
            key={key}
            name="collection_contact_type_values"
            source="collection_contact_type_values"
            list={false}
            edit={CollectionContactTypeValueEdit}
            options={{ menuGroup: 'hidden' }}
          />
        ),
        permissions.includes('CAN_EMAIL_VIEW') && (
          <Resource key={key} name="emails" options={{ menuGroup: 'hidden' }} />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="applications_verification"
            source="applications"
            show={ApplicationVerificationShow}
            options={{
              label: 'Verification',
              menuGroup: 'hidden',
            }}
          />
        ),
        permissions.includes('CAN_WEBITEL_LOG_VIEW') && (
          <AppResource
            key={key}
            name="webitel_logs"
            source="webitel_logs"
            list={WebitelLogsList}
            options={{ menuGroup: 'hidden' }}
          />
        ),
      ]}
    </Admin>
  </MuiPickersUtilsProvider>
);
export default Sentry.withErrorBoundary(App, { fallback: <p>an error has occurred</p> });
