import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  Toolbar,
  SelectInput,
  minValue,
  maxValue,
  required,
  useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  ruleTip: {
    marginTop: '0.5rem',
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [productList, setProductList] = React.useState([]);
  const [minTerm, setMinTerm] = React.useState(0);
  const [maxTerm, setMaxTerm] = React.useState(60);
  const [minAmount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(50000);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data));
  }, [dataProvider]);

  React.useEffect(() => {
    if (productList.length) {
      const product = productList.find(p => p.id === props.record.product_id);
      const minTerm = props.record.type === 'first' ? product.first_loan_min_tenor : product.min_tenor;
      const maxTerm = props.record.type === 'first' ? product.first_loan_max_tenor : product.max_tenor;
      const minAmount = props.record.type === 'first' ? product.first_loan_min_principal : product.min_principal;
      const maxAmount = props.record.type === 'first' ? product.first_loan_max_principal : product.max_principal;
      setMinTerm(minTerm);
      setMaxTerm(maxTerm);
      setMinAmount(minAmount);
      setMaxAmount(maxAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  const PRODUCT_CHOICES = productList.map(item => ({ id: item.id, name: item.name }));
  const TYPE_CHOICES = [
    { id: 'first', name: 'First' },
    { id: 'repeat', name: 'Repeat' },
  ];

  return (
    <FormWithRedirect
      save={({ min_term, max_term, min_amount, max_amount }, ...rest) => {
        save(
          ...[
            {
              id: props.record.id,
              min_term,
              max_term,
              min_amount,
              max_amount,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit rule
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="product_id"
                        disabled
                        choices={PRODUCT_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput helperText={false} source="type" disabled choices={TYPE_CHOICES} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Min term"
                        source="min_term"
                        min={minTerm}
                        max={maxTerm}
                        step={1}
                        validate={[required(), minValue(minTerm), maxValue(maxTerm)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Max term"
                        source="max_term"
                        min={minTerm}
                        max={maxTerm}
                        step={1}
                        validate={[required(), minValue(minTerm), maxValue(maxTerm)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Min amount"
                        source="min_amount"
                        min={minAmount}
                        max={maxAmount}
                        step={1}
                        validate={[minValue(minAmount), maxValue(maxAmount)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Max amount"
                        source="max_amount"
                        min={minAmount}
                        max={maxAmount}
                        step={1}
                        validate={[minValue(minAmount), maxValue(maxAmount)]}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.ruleTip} variant="body2" gutterBottom={false}>
                    {`Important: Min amount < Approved amount <= Max amount (so the value specified at the beginning of the range  is not subject to the rule)!`}
                  </Typography>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    disabled={!formProps.valid}
                  />
                  <DeleteButton
                    undoable={false}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
    product_id: PropTypes.string,
    id: PropTypes.string,
  }),
};

const LoanTermRulesEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default LoanTermRulesEdit;
