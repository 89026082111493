import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

import ListFilterHOC from '../../hoc/ListFilterHOC';
import ListActions from './components/ListActions';
import ListFiltersPending from './components/ListFiltersPending';
import Pagination from '../../Pagination';
import { LOANS_LIST_PENDING } from './constants';

const theme = createTheme();

const ListPendingNotReady = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid
        rowClick="show"
        rowStyle={() => {
          return {
            backgroundColor: theme.palette.error.light,
          };
        }}>
        {LOANS_LIST_PENDING.map(i => i)}
      </Datagrid>
    </List>
  );
};

ListPendingNotReady.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

const LoanListPendingNotReady = ListFilterHOC(
  ListPendingNotReady,
  {
    state: 'pending',
    is_ready_for_disbursement: false,
  },
  [],
  ListFiltersPending,
);

export default LoanListPendingNotReady;
