import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { green, orange, red, deepPurple, pink, yellow, blue } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  flexColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  box: {
    textAlign: 'end',
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  markerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  marker: {
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '25px',
    height: '25px',
  },
}));

const ApplicationColorLegend = () => {
  const [show, setShow] = useState(false);
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Button
        color="primary"
        size="small"
        onClick={() => setShow(!show)}
        startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
        {show ? 'Hide legend' : 'Show legend'}
      </Button>
      {show && (
        <Box className={[classes.flexColumnStart, classes.mt2].join(' ')}>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: red[300] }} />
            <Typography variant="body2">ADE finished with error</Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: green[300] }} />
            <Typography variant="body2">Application confirmed and loan is activated</Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: orange[300] }} />
            <Typography variant="body2">Application confirmed and loan is not activated</Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: yellow[300] }} />
            <Typography variant="body2">
              Application is pending, manual verification and verifier is not assigned
            </Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: deepPurple[300] }} />
            <Typography variant="body2">
              Application is pending, manual verification and verifier is assigned
            </Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: pink[300] }} />
            <Typography variant="body2">
              Application is approved more than 5 minutes ago, but still not confirmed
            </Typography>
          </Box>
          <Box className={classes.markerContainer}>
            <Box className={classes.marker} sx={{ backgroundColor: blue[300] }} />
            <Typography variant="body2">Application is under verification control</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ApplicationColorLegend;
