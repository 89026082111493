import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  Filter,
  NumberInput,
  SelectInput,
  TextInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput
        label="Product state"
        alwaysOn
        source="product->is_actual"
        choices={[
          { id: 'true', name: 'Actual' },
          { id: 'false', name: 'Inactual' },
        ]}
      />
      <TextInput label="Product code" source="product->code" />
      <NumberInput label="Product ID" source="product->id" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ComissionsList = props => {
  const getRowStyle = record => {
    if (!record.product_is_actual) {
      return { backgroundColor: grey[100] };
    }
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit" rowStyle={getRowStyle}>
        <TextField source="id" />
        <FunctionField label="Product" render={({ product_id, product_code }) => `${product_code} #${product_id}`} />
        <BooleanField source="is_repeated" />
        <TextField source="principal_from" />
        <TextField source="principal_to" />
        <TextField source="tenor_from" />
        <TextField source="tenor_to" />
        <TextField source="score_from" />
        <TextField source="score_to" />
        <TextField source="commission_principal_percent" label="Comission principal, %" />
        <TextField source="commission" />
        <TextField source="crossed_commission" />
      </Datagrid>
    </List>
  );
};

export default ComissionsList;
