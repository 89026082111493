import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Edit, TextInput, SaveButton, Toolbar, FormWithRedirect, required, maxLength } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';

const useStyles = makeStyles(theme => ({
  pr1: {
    paddingRight: theme.spacing(1),
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const [amounts, setAmounts] = useState([]);

  const classes = useStyles();

  const handleAddAmount = () => {
    const list = [...amounts];
    list.push(null);
    setAmounts(list);
  };

  const handleRemoveAmount = index => {
    const list = [...amounts];
    list.splice(index, 1);
    setAmounts(list);
  };

  const handleChangeAmount = (event, index) => {
    const list = [...amounts];
    if (event.target.value.includes('-')) {
      list[index] = Math.abs(+event.target.value);
    } else {
      list[index] = +event.target.value;
    }
    setAmounts(list);
  };

  useEffect(() => {
    if (record.amounts.length) {
      setAmounts(record.amounts);
    }
  }, [record]);

  return (
    <FormWithRedirect
      save={({ title }, ...rest) => {
        save(
          ...[
            {
              title,
              amounts,
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h5">Create debit strategy</Typography>
                  <Divider />
                  <Box pt={3}>
                    <TextInput source="title" label="Strategy name" validate={[required(), maxLength(255)]} fullWidth />
                  </Box>
                  <Box pb={3}>
                    <Typography variant="h6">Debit amounts</Typography>
                  </Box>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {amounts.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography
                          className={classes.pr1}
                          variant="body1"
                          component="span">{`${index + 1}.`}</Typography>
                        <TextField
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          onChange={event => handleChangeAmount(event, index)}
                          onKeyPress={event => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                          value={item ?? ''}
                        />
                        <IconButton color="primary" onClick={() => handleRemoveAmount(index)}>
                          <RemoveCircleOutlineTwoTone />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                  <Box pt={3}>
                    <Button onClick={handleAddAmount} variant="contained" color="primary">
                      Add amount
                    </Button>
                  </Box>
                </Box>
                <Toolbar>
                  <SaveButton
                    disabled={!amounts.length || amounts.some(v => !v) || !formProps.valid}
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    id: PropTypes.number,
    amounts: PropTypes.array,
    title: PropTypes.string,
  }),
};

const DebitStrategiesEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default DebitStrategiesEdit;
