import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const AddNoteDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [message, setMessage] = useState();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add user note.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <FormControl className={classes.fullWidth}>
          <InputLabel id="label-select-label">Label</InputLabel>
          <Select labelId="label-select-label" value={label || ''} onChange={e => setLabel(e.target.value)}>
            <MenuItem value="info">Info</MenuItem>
            <MenuItem value="verification">Verification</MenuItem>
            <MenuItem value="collection">Collection</MenuItem>
            <MenuItem value="call_center">Call center</MenuItem>
            <MenuItem value="telesales">Telesales</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          multiline={true}
          rows="3"
          fullWidth={true}
          label="Message"
          value={message || ''}
          onChange={e => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(label, message)} color="primary" disabled={!label || !message}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddNoteDialog;
