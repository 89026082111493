import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

const Regula = ({ object, setObject }) => {
  const ACTION_OPTIONS = {
    process: [
      <FormControl fullWidth key="process">
        <InputLabel id="label-select-label">File</InputLabel>
        <Select
          labelId="label-select-label"
          value={object.engine_data.label || ''}
          onChange={e => setObject({ ...object, engine_data: { label: e.target.value } })}>
          {' '}
          {[
            { value: 'id_card', name: 'ID Card (front)' },
            { value: 'id_card_back_side', name: 'ID Card (back)' },
          ].map(i => (
            <MenuItem key={i.value} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="label-select-label">Action type</InputLabel>
        <Select
          labelId="label-select-label"
          value={object.action || ''}
          onChange={e => setObject({ ...object, action: e.target.value })}>
          {' '}
          {[{ value: 'process', name: 'Process' }].map(i => (
            <MenuItem key={i.value} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {ACTION_OPTIONS[object.action || 'process'].map(i => i)}
    </>
  );
};

Regula.propTypes = {
  object: PropTypes.object,
  setObject: PropTypes.func,
};

export const AddOcrRequestDialog = ({ isOpened, onClose, onSubmit }) => {
  const [object, setObject] = useState({
    engine: 'regula',
    action: 'process',
    engine_data: {
      label: null,
    },
  });

  const ENGINE_OPTIONS = {
    regula: [<Regula key={'regula'} object={object} setObject={setObject} />],
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle id="form-dialog-title">Select engine</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="label-select-label">Engine</InputLabel>
          <Select
            labelId="label-select-label"
            value={object.engine || ''}
            onChange={e => {
              setObject({ ...object, engine: e.target.value });
            }}>
            <MenuItem value="regula">Regula</MenuItem>
          </Select>
        </FormControl>
        {ENGINE_OPTIONS[object.engine || 'regula'].map(i => i)}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(object)}
          color="primary"
          disabled={(() => {
            if (!object.engine_data.label) {
              return true;
            }
          })()}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddOcrRequestDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
