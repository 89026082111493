import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TabPanel from '../../hoc/TabPanel';
import NotificationTable from '../../notification/NotificationTable';
import DebounceButton from '../../button/DebounceButton';
import NoteTable from '../../note/NoteTable';
import AgreementTable from '../../agreement/AgreementTable';
import { UserContactsTable } from '../../tables';
import ApplicationTable from '../../application/ApplicationTable';
import LoanTable from '../../loan/LoanTable';
import ChangeHistoryTable from '../../change_history/ChangeHistoryTable';
import SessionsTable from '../../sessions/SessionsTable';
import SelfieMatchesTable from '../../user/SelfieMatchesTable';
import IdCardMatchesTable from '../../user/IdCardMatchesTable';
import Kyc from '../../engines/kyc/Kyc';
import UserApiData from '../../engines/user_api_data/UserApiData';
import Ocr from '../../engines/ocr/Ocr';
import { CallCenterLogTable } from '../callcenter/CallCenterList';
import DuplicateRegistrationTable from '../../duplicate_registration/DuplicateRegistrationTable';
import FacebookTable from '../../facebook/FacebookTable';
import { AddOcrRequestDialog } from '../../engines/ocr/AddOcrRequestDialog';
import AddNotificationDialog from '../../notification/AddNotificationDialog';
import { AddUserContactsDialog } from '../../dialog';
import AddNoteDialog from '../../note/AddNoteDialog';
import CollectionCallTable from '../../collection_call/CollectionCallTable';
import CollectionCallFormDialog from '../../dialog/CollectionCallFormDialog';
import ShortLinkTable from '../../short_link/ShortLinkTable';
import AddUserApiDataDialog from '../../engines/user_api_data/AddUserApiDataDialog';
import ReportTable from '../../credit_bureau/ReportTable';
import AllCallsTable from '../../call/AllCallsTable';
import WazzupMessagesTable from '../../wazzup/WazzupMessagesTable';
import ReferralProgramTable from '../../referral_program/ReferralProgramTable';
import MailTable from '../../mail/MailTable';
import BankAccountsTable from '../../bank_accounts/BankAccountsTable';
import DirectDebitTable from '../../direct_debit/DirectDebitTable';
import AccountMessageTable from '../../account_message/AccountMessageTable';
import AddAccountMessageDialog from '../../account_message/AddAccountMessageDialog';

const UserEditAdditionalInfo = ({ record }) => {
  const [refreshedAt, setRefreshedAt] = useState(null);
  const [isNotificationDialogOpened, setIsNotificationDialogOpened] = useState(false);
  const [isCollectionCallDialogOpened, setIsCollectionCallDialogOpened] = useState(false);
  const [isNoteDialogOpened, setIsNoteDialogOpened] = useState(false);
  const [isOcrRequestDialogOpened, setIsOcrRequestDialogOpened] = useState(false);
  const [isUserContactsDialogOpened, setIsUserContactsDialogOpened] = useState(false);
  const [isUserApiDataDialogOpened, setIsUserApiDataDialogOpened] = useState(false);
  const [isAccountMessageDialogOpened, setIsAccountMessageDialogOpened] = useState(false);
  const [selfieMatches, setSelfieMatches] = useState(0);
  const [idCardMatches, setIdCardMatches] = useState(0);
  const [clickToCallPhone, setClickToCallPhone] = useState(null);
  const [counters, setCounters] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const hasDNDTag = record.tags.length > 0 && record.tags.find(tag => tag.name.includes('not disturb'));
  const loans = counters?.loans ?? 0;

  const showWarningBlock = () =>
    hasDNDTag ? (
      <Alert variant="filled" severity="error">
        <Typography>Note that the client has the DO NOT DISTURB tag!</Typography>
      </Alert>
    ) : null;

  useEffect(() => {
    if (record.id) {
      dataProvider
        .query(`users/${record.id}/relation_counters`, { method: 'GET' })
        .then(({ data }) => setCounters(data))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permissions.includes('CAN_MATCHES_VIEW')) {
      dataProvider
        .getList('aws_rekognition_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setSelfieMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });

      dataProvider
        .getList('aws_rekognition_id_card_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setIdCardMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }
  }, [permissions, dataProvider, notify, record.id]);

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider
      .query(`notifications/${notificationId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => setRefreshedAt(Date.now()))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider
      .query(`agreements/${agreementId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => setRefreshedAt(Date.now()))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleCloseCollectionCallDialog = () => {
    setIsCollectionCallDialogOpened(false);
    setClickToCallPhone(null);
  };

  const handleSubmitCollectionCallDialog = () => {
    setRefreshedAt(Date.now());
    setIsCollectionCallDialogOpened(false);
    setClickToCallPhone(null);
  };

  const onCallHandler = phone => {
    setIsCollectionCallDialogOpened(true);
    setClickToCallPhone(phone);
  };

  return (
    <>
      <TabPanel
        labels={[
          '✖',
          `Notifications|${counters?.notifications ?? 0}`,
          `Mails|${counters?.mails ?? 0}`,
          `WhatsApp messages|${counters?.whatsapp_messages ?? 0}`,
          `Account messages|${counters?.account_messages ?? 0}`,
          `Actions|${counters?.actions ?? 0}`,
          `Notes|${counters?.notes ?? 0}`,
          `Agreements|${counters?.agreements ?? 0}`,
          `Contacts|${counters?.contacts ?? 0}`,
          `Applications|${counters?.applications ?? 0}`,
          `Loans|${counters?.loans ?? 0}`,
          `Direct debits|${counters?.direct_debits ?? 0}`,
          `Referrals|${counters?.referrals ?? 0}`,
          'Changes',
          `Sessions|${counters?.sessions ?? 0}`,
          `Bank accounts|${counters?.bank_accounts ?? 0}`,
          `Selfie matches|${selfieMatches}`,
          `Id card matches|${idCardMatches}`,
          `KYC|${counters?.kyc_requests ?? 0}`,
          `Api data|${counters?.user_api_data ?? 0}`,
          `Credit bureau|${counters?.credit_bureaus ?? 0}`,
          `OCR|${counters?.ocr_requests ?? 0}`,
          `Call center|${counters?.call_center ?? 0}`,
          `Duplicates|${counters?.duplicates ?? 0}`,
          'Facebook',
          `Short links|${counters?.short_links ?? 0}`,
          `All calls|${counters?.all_calls ?? 0}`,
        ]}>
        <></>
        {permissions.includes('CAN_NOTIFICATION_VIEW') ? (
          <Fragment key="notifications">
            <NotificationTable
              userId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyNotificationTransition}
              inactive={record.has_sold_loans}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_NOTIFICATION_EDIT') ? (
                  <Button disabled={record.has_sold_loans} onClick={() => setIsNotificationDialogOpened(true)}>
                    Add
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_EMAIL_VIEW') ? (
          <Fragment key="mails">
            <MailTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_WAZZUP_MESSAGES_VIEW') ? (
          <Fragment key="wazzup_messages">
            <WazzupMessagesTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_ACCOUNT_MESSAGES_VIEW') ? (
          <Fragment key="account_messages">
            <AccountMessageTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_ACCOUNT_MESSAGES_EDIT') ? (
                  <Button onClick={() => setIsAccountMessageDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_COLLECTION_CALL_VIEW') ? (
          <Fragment key="calls">
            {showWarningBlock()}
            <CollectionCallTable userId={record.id} refreshedAt={refreshedAt} permissions={permissions} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_COLLECTION_CALL_EDIT') && permissions.includes('CAN_PHONE_BOOK_VIEW') ? (
                  <Button onClick={() => setIsCollectionCallDialogOpened(true)} disabled={loans === 0}>
                    Add
                    {loans === 0 ? ' (no loans)' : ''}
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_NOTE_VIEW') ? (
          <Fragment key="notes">
            <NoteTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_NOTE_EDIT') ? (
                  <Button onClick={() => setIsNoteDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_AGREEMENT_VIEW') ? (
          <Fragment key="agreements">
            <AgreementTable userId={record.id} refreshedAt={refreshedAt} onTransition={applyAgreementTransition} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_PHONE_BOOK_VIEW') ? (
          <Fragment key="contacts">
            {showWarningBlock()}
            <UserContactsTable
              userId={record.id}
              refreshedAt={refreshedAt}
              setRefreshedAt={setRefreshedAt}
              onCall={onCallHandler}
              hasSoldLoans={record.has_sold_loans}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_PHONE_BOOK_EDIT') ? (
                  <Button onClick={() => setIsUserContactsDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_APPLICATION_VIEW') ? (
          <Fragment key="applications">
            <ApplicationTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_LOAN_VIEW') ? (
          <Fragment key="loans">
            <LoanTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_DEBITS_VIEW') ? (
          <Fragment key="direct_debits">
            <DirectDebitTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <Fragment key="referral">
            <ReferralProgramTable record={record} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <Fragment key="changes">
            <ChangeHistoryTable
              entityId={record.id}
              entityField="user"
              refreshedAt={refreshedAt}
              endpoint="user_change_histories"
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <Fragment key="sessions">
            <SessionsTable userId={record.id} refreshedAt={refreshedAt} returnSimilar={false} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <Fragment key="bank_accounts">
            <BankAccountsTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_MATCHES_VIEW') ? (
          <Fragment key="selfie_matches">
            <SelfieMatchesTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_MATCHES_VIEW') ? (
          <Fragment key="id_card_matches">
            <IdCardMatchesTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_KYC_REQUEST_VIEW') ? (
          <Fragment key="kyc">
            <Kyc userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_KYC_REQUEST_EDIT') ? (
                  <Button
                    onClick={() => {
                      dataProvider
                        .create('kyc_requests', {
                          data: {
                            user: `/api/users/${record.id}`,
                            engine: 'seon_io',
                          },
                        })
                        .then(() => setRefreshedAt(Date.now()))
                        .catch(error => notify(`Error: ${error.message}`, 'error'));
                    }}>
                    Add
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_API_DATA_VIEW') ? (
          <Fragment key="UserApiData">
            <UserApiData userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_USER_API_DATA_EDIT') && (
                  <Button onClick={() => setIsUserApiDataDialogOpened(true)}>Add</Button>
                )}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_CREDIT_BUREAU_REPORT_VIEW') ? (
          <Fragment key="UserApiData">
            <ReportTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_OCR_REQUEST_VIEW') ? (
          <Fragment key="ocr">
            <Ocr userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
                {permissions.includes('CAN_OCR_REQUEST_EDIT') && (
                  <Button onClick={() => setIsOcrRequestDialogOpened(true)}>Add</Button>
                )}
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_PHONE_CALL_VIEW') ? (
          <Fragment key="call_center_log">
            <CallCenterLogTable filter={{ 'user.id': record.id }} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_DUPLICATES_VIEW') ? (
          <Fragment key="duplicates">
            <DuplicateRegistrationTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <Fragment key="facebook">
            <FacebookTable record={record} />
          </Fragment>
        ) : null}
        {permissions.includes('CAN_SHORT_LINK_LOG_VIEW') ? (
          <Fragment key="short_links">
            <ShortLinkTable userId={record.id} refreshedAt={refreshedAt} returnSimilar={false} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </Fragment>
        ) : null}
        {permissions.includes('CAN_WEBITEL_CALLS_VIEW') ? (
          <>
            <AllCallsTable userId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => setRefreshedAt(Date.now())}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
      </TabPanel>
      {permissions.includes('CAN_OCR_REQUEST_VIEW') ? (
        <AddOcrRequestDialog
          isOpened={isOcrRequestDialogOpened}
          onClose={() => setIsOcrRequestDialogOpened(false)}
          onSubmit={object => {
            setIsOcrRequestDialogOpened(false);
            dataProvider
              .create('ocr_requests', { data: { user: `/api/users/${record.id}`, ...object } })
              .then(() => setRefreshedAt(Date.now()))
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      ) : null}
      {permissions.includes('CAN_NOTIFICATION_EDIT') && isNotificationDialogOpened ? (
        <AddNotificationDialog
          onClose={() => setIsNotificationDialogOpened(false)}
          onSubmit={(transmitterId, destination, templateId, message, subject) => {
            dataProvider
              .query(`users/${record.id}/notifications`, {
                body: JSON.stringify({
                  transmitter_id: transmitterId,
                  destination,
                  template_id: templateId,
                  message,
                  subject,
                }),
              })
              .then(() => {
                setIsNotificationDialogOpened(false);
                setRefreshedAt(Date.now());
              })
              .catch(error => {
                setIsNotificationDialogOpened(false);
                notify(`Error: ${error.message}`, 'error');
              });
          }}
          defaultPhoneNumber={record.phone_number}
          defaultEmailAddress={record.email}
        />
      ) : null}
      {permissions.includes('CAN_COLLECTION_CALL_EDIT') &&
      permissions.includes('CAN_PHONE_BOOK_VIEW') &&
      isCollectionCallDialogOpened ? (
        <CollectionCallFormDialog
          userId={record.id}
          isOpened={isCollectionCallDialogOpened}
          onClose={handleCloseCollectionCallDialog}
          onSubmit={handleSubmitCollectionCallDialog}
          clickToCallPhone={clickToCallPhone}
        />
      ) : null}
      {permissions.includes('CAN_PHONE_BOOK_EDIT') && isUserContactsDialogOpened ? (
        <AddUserContactsDialog
          isOpened={isUserContactsDialogOpened}
          onClose={() => setIsUserContactsDialogOpened(false)}
          onSubmit={(phone_number, name, type, note, relation, source, status, validation_state) => {
            setIsUserContactsDialogOpened(false);
            dataProvider
              .create('phone_books', {
                data: {
                  user: record.id,
                  phone_number,
                  name,
                  type,
                  note: note?.trim() || null,
                  relation,
                  source,
                  status: status || 'active',
                  validation_state,
                },
              })
              .then(() => setRefreshedAt(Date.now()))
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
          defaultPhoneNumber={record.phone_number}
        />
      ) : null}
      {permissions.includes('CAN_NOTE_EDIT') ? (
        <AddNoteDialog
          isOpened={isNoteDialogOpened}
          onClose={() => setIsNoteDialogOpened(false)}
          onSubmit={(label, message) => {
            setIsNoteDialogOpened(false);
            dataProvider
              .create('notes', { data: { user_id: record.id, label, message } })
              .then(() => setRefreshedAt(Date.now()))
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      ) : null}
      {permissions.includes('CAN_USER_API_DATA_EDIT') && (
        <AddUserApiDataDialog
          userId={record.id}
          isOpened={isUserApiDataDialogOpened}
          onClose={() => setIsUserApiDataDialogOpened(false)}
          onSubmit={() => {
            setIsUserApiDataDialogOpened(false);
            setRefreshedAt(Date.now());
          }}
        />
      )}
      {permissions.includes('CAN_ACCOUNT_MESSAGES_EDIT') && isAccountMessageDialogOpened && (
        <AddAccountMessageDialog
          userId={record.id}
          onClose={() => setIsAccountMessageDialogOpened(false)}
          onSubmit={() => {
            setIsAccountMessageDialogOpened(false);
            setRefreshedAt(Date.now());
          }}
        />
      )}
    </>
  );
};

UserEditAdditionalInfo.propTypes = {
  record: PropTypes.object,
};

export default UserEditAdditionalInfo;
