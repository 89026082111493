import React from 'react';
import PropTypes from 'prop-types';

import CollectionEmailReportParamsInput from '../report_inputs/CollectionEmailReportParamsInput';
import PromoCodeSmsReportParamsInput from '../report_inputs/PromoCodeSmsReportParamsInput';
import AffiliateCreditsParamsInput from '../report_inputs/AffiliateCreditsParamsInput';
import SummaryReportParamsInput from '../report_inputs/SummaryReportParamsInput';
import IncomeReportParamsInput from '../report_inputs/IncomeReportParamsInput';
import IncomeOutsourcingReportParamsInput from '../report_inputs/IncomeOutsourcingReportParamsInput';
import LoanReportParamsInput from '../report_inputs/LoanReportParamsInput';
import ClosedLoanReportParamsInput from '../report_inputs/ClosedLoanReportParamsInput';
import CollectionLoanReportParamsInput from '../report_inputs/CollectionLoanReportParamsInput';
import ComplianceReportParamsInput from '../report_inputs/ComplianceReportParamsInput';
import ApplicationReportParamsInput from '../report_inputs/ApplicationReportParamsInput';
import CashFlowReportParamsInput from '../report_inputs/CashFlowReportParamsInput';
import SecurityReportParamsInput from '../report_inputs/SecurityReportParamsInput';
import ClientParamsInput from '../report_inputs/ClientParamsInput';
import TransactionReportParamsInput from '../report_inputs/TransactionReportParamsInput';
import ExtensionReportParamsInput from '../report_inputs/ExtensionReportParamsInput';
import NotificationReportParamsInput from '../report_inputs/NotificationReportParamsInput';
import LocalNotificationReportParamsInput from '../report_inputs/LocalNotificationReportParamsInput';
import SecurityApplicationReportParamsInput from '../report_inputs/SecurityApplicationReportParamsInput';
import PromoCodesReportParamsInput from '../report_inputs/PromoCodesReportParamsInput';
import UnfinishedRegistrationReportParamsInput from '../report_inputs/UnfinishedRegistrationReportParamsInput';
import AffiliateEventReportParamsInput from '../report_inputs/AffiliateEventReportParamsInput';
import VerificationStaffStatisticsReportParamsInput from '../report_inputs/VerificationStaffStatisticsReportParamsInput';
import CollectionAssignReportParamsInput from '../report_inputs/CollectionAssignReportParamsInput';
import ExternalAgencyAssignmentReportParamsInput from '../report_inputs/ExternalAgencyAssignmentReportParamsInput';
import CollectionCallReportParamsInput from '../report_inputs/CollectionCallReportParamsInput';
import AccountingLoanReportParamsInput from '../report_inputs/AccountingLoanReportParamsInput';
import InvoicingLoanReportParamsInput from '../report_inputs/InvoicingLoanReportParamsInput';
import PaidLoanReportParamsInput from '../report_inputs/PaidLoanReportParamsInput';
import StpBankAccountByLoanIdReportParamsInput from '../report_inputs/StpBankAccountByLoanIdReportParamsInput';
import VerificationReportParamsInput from '../report_inputs/VerificationReportParamsInput';
import AudienceReportParamsInput from '../report_inputs/AudienceReportParamsInput';
import AccountingPaidReportParamsInput from '../report_inputs/AccountingPaidReportParamsInput';
import AccountingUnpaidReportParamsInput from '../report_inputs/AccountingUnpaidReportParamsInput';
import SalesReportParamsInput from '../report_inputs/SalesReportParamsInput';
import PaymentReportParamsInput from '../report_inputs/PaymentReportParamsInput';
import BalanceReportParamsInput from '../report_inputs/BalanceReportParamsInput';
import CreditBureauReportParamsInput from '../report_inputs/CreditBureauReportParamsInput';
import CreditBureauConfirmationReportParamsInput from '../report_inputs/CreditBureauConfirmationReportParamsInput';
import AccountingTotalReportParamsInput from '../report_inputs/AccountingTotalReportParamsInput';
import OutsourcingRegisterReportParamsInput from '../report_inputs/OutsourcingRegisterReportParamsInput';
import InvoicingAccountingLoansGrantedReportParamsInput from '../report_inputs/InvoicingAccountingLoansGrantedReportParamsInput';
import InvoicingAccountingExtensionReportParamsInput from '../report_inputs/InvoicingAccountingExtensionReportParamsInput';
import InvoicingAccountingPastDueInterestReportParamsInput from '../report_inputs/InvoicingAccountingPastDueInterestReportParamsInput';
import InvoicingAccountingDiscountReportParamsInput from '../report_inputs/InvoicingAccountingDiscountReportParamsInput';
import InvoicingAccountingPaidLoanReportParamsInput from '../report_inputs/InvoicingAccountingPaidLoanReportParamsInput';
import LoanInconsistencyReportParamsInput from '../report_inputs/LoanInconsistencyReportParamsInput';
import InvoicingAccountingCancellationReportParamsInput from '../report_inputs/InvoicingAccountingCancellationReportParamsInput';
import IncomeEmailReportParamsInput from '../report_inputs/IncomeEmailReportParamsInput';

const InputBuilder = ({ builderId }) => {
  switch (builderId) {
    case 'affiliate_credits':
      return <AffiliateCreditsParamsInput />;
    case 'summary_report':
      return <SummaryReportParamsInput />;
    case 'income_report':
      return <IncomeReportParamsInput />;
    case 'income_outsourcing_report':
      return <IncomeOutsourcingReportParamsInput />;
    case 'loan_report':
      return <LoanReportParamsInput />;
    case 'collection_loan_report':
      return <CollectionLoanReportParamsInput />;
    case 'compliance_report':
      return <ComplianceReportParamsInput />;
    case 'application_report':
      return <ApplicationReportParamsInput />;
    case 'cashflow_report':
      return <CashFlowReportParamsInput />;
    case 'security_report':
      return <SecurityReportParamsInput />;
    case 'client_report':
      return <ClientParamsInput />;
    case 'transaction_report':
      return <TransactionReportParamsInput />;
    case 'extension_report':
      return <ExtensionReportParamsInput />;
    case 'notification_report':
      return <NotificationReportParamsInput />;
    case 'local_notification_report':
      return <LocalNotificationReportParamsInput />;
    case 'security_application_report':
      return <SecurityApplicationReportParamsInput />;
    case 'promo_codes_report':
      return <PromoCodesReportParamsInput />;
    case 'closed_loan_report':
      return <ClosedLoanReportParamsInput />;
    case 'unfinished_registration_report':
      return <UnfinishedRegistrationReportParamsInput />;
    case 'affiliate_event_report':
      return <AffiliateEventReportParamsInput />;
    case 'verification_staff_statistics_report':
      return <VerificationStaffStatisticsReportParamsInput />;
    case 'promo_code_sms_report':
      return <PromoCodeSmsReportParamsInput />;
    case 'collection_email_report':
      return <CollectionEmailReportParamsInput />;
    case 'collection_assign_report':
      return <CollectionAssignReportParamsInput />;
    case 'external_agency_assignment_report':
      return <ExternalAgencyAssignmentReportParamsInput />;
    case 'collection_call_report':
      return <CollectionCallReportParamsInput />;
    case 'accounting_loan_report':
      return <AccountingLoanReportParamsInput />;
    case 'accounting_paid_report':
      return <AccountingPaidReportParamsInput />;
    case 'accounting_unpaid_report':
      return <AccountingUnpaidReportParamsInput />;
    case 'accounting_total_report':
      return <AccountingTotalReportParamsInput />;
    case 'credit_bureau_report':
      return <CreditBureauReportParamsInput />;
    case 'credit_bureau_confirmation_report':
      return <CreditBureauConfirmationReportParamsInput />;
    case 'sales_report':
      return <SalesReportParamsInput />;
    case 'payment_report':
      return <PaymentReportParamsInput />;
    case 'balance_report':
      return <BalanceReportParamsInput />;
    case 'invoicing_loan_report':
      return <InvoicingLoanReportParamsInput />;
    case 'paid_loan_report':
      return <PaidLoanReportParamsInput />;
    case 'stp_bank_account_by_loan_id_report':
      return <StpBankAccountByLoanIdReportParamsInput />;
    case 'audience_report':
      return <AudienceReportParamsInput />;
    case 'verification_report':
      return <VerificationReportParamsInput />;
    case 'outsourcing_register_report':
      return <OutsourcingRegisterReportParamsInput />;
    case 'invoicing_accounting_loans_granted_report':
      return <InvoicingAccountingLoansGrantedReportParamsInput />;
    case 'invoicing_accounting_extension_report':
      return <InvoicingAccountingExtensionReportParamsInput />;
    case 'invoicing_accounting_past_due_interest_report':
      return <InvoicingAccountingPastDueInterestReportParamsInput />;
    case 'invoicing_accounting_discount_report':
      return <InvoicingAccountingDiscountReportParamsInput />;
    case 'invoicing_accounting_paid_loan_report':
      return <InvoicingAccountingPaidLoanReportParamsInput />;
    case 'invoicing_accounting_cancellation_report':
      return <InvoicingAccountingCancellationReportParamsInput />;
    case 'loan_inconsistency_report':
      return <LoanInconsistencyReportParamsInput />;
    case 'income_email_report':
      return <IncomeEmailReportParamsInput />;
    default:
      return null;
  }
};

InputBuilder.propTypes = {
  builderId: PropTypes.string,
};

export default InputBuilder;
