import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  NumberInput,
  useDataProvider,
  useNotify,
  DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS } from '../../../constants';

import { getFormStyles, ALLOCATION_CHOICES } from './constants';
import Autocomplete from '../../autocomplete/Autocomplete';

const useStyles = makeStyles(theme => getFormStyles(theme));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [daysMode, setDaysMode] = useState('onetime');
  const [agenciesList, setAgenciesList] = useState([]);
  const [allocationOption, setAllocationOption] = useState(null);
  const [params, setParams] = useState([]);

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const onAddAgency = () => setParams([...params, { key: Date.now(), id: null, percent: null }]);
  const onRemoveAgency = id => setParams([...params.filter(item => item.key !== id)]);

  const setSelectAgency = (id, type, value) => {
    const itemIndex = params.findIndex(param => param.key === id);
    if (itemIndex !== -1) {
      const newParams = [...params];
      newParams[itemIndex][type] = value;
      setParams(newParams);
    }
  };

  const AGENCIES_CHOICES = [
    ...agenciesList?.filter(agency => agency.status === 'active').map(item => ({ id: item.id, name: item.name })),
  ];

  const validatePercentage = () => {
    const percentage = params.map(param => param.percent);
    const sum = percentage.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    return sum > 100;
  };

  return (
    <FormWithRedirect
      save={({ audience_id, name, is_enabled, allocated_days, allocated_date, one_time_allocations }, ...rest) => {
        save(
          ...[
            {
              name,
              audience_id,
              is_enabled,
              params: {
                week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
                month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
                one_time_allocations: daysMode === 'onetime' ? [one_time_allocations] : null,
                allocated_days,
                allocated_date,
                external_agencies: params.map(item => ({ id: item.id, percent: item.percent })),
              },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" gutterBottom={false}>
                      Create external agency assignment campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput label="Enabled" source="is_enabled" defaultValue={false} />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} key="name">
                    <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} key="audience_id">
                    <Autocomplete
                      resource="mass_sending_audiences"
                      source="audience_id"
                      optionValueProp="id"
                      optionLabelProp="name"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} key="allocation_date">
                    <SelectInput
                      label="Allocation period"
                      helperText={false}
                      onChange={e => setAllocationOption(e.target.value)}
                      source="allocation_date_option"
                      validate={[required()]}
                      choices={ALLOCATION_CHOICES}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {allocationOption === 2 && (
                      <NumberInput label="Days" source="allocated_days" fullWidth validate={[required()]} />
                    )}
                    {allocationOption === 3 && (
                      <DateInput label="Date" source="allocated_date" fullWidth validate={[required()]} />
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Typography variant="body1" className={classes.mb2}>
                  Agencies list
                </Typography>
                {params.map(param => (
                  <Grid container alignItems="center" spacing={2} key={param.key} className={classes.mb2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        label="Agency"
                        helperText={false}
                        source={`agency_id->${param.key}`}
                        validate={[required()]}
                        choices={AGENCIES_CHOICES}
                        value={param.id}
                        onChange={e => setSelectAgency(param.key, 'id', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput
                        label="Percent"
                        helperText={false}
                        source={`percent->${param.key}`}
                        validate={[required()]}
                        value={param.percent}
                        onChange={e => setSelectAgency(param.key, 'percent', +e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton onClick={() => onRemoveAgency(param.key)} color="primary" variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button onClick={onAddAgency} color="primary" variant="contained">
                  Add agency
                </Button>
                <Divider className={classes.my2} />
                <Grid item xs={12}>
                  <RadioGroup row className={classes.mb2}>
                    <FormControlLabel
                      value="onetime"
                      control={<Radio />}
                      label="One-time"
                      checked={daysMode === 'onetime'}
                      onChange={() => setDaysMode('onetime')}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOW}
                      control={<Radio />}
                      label="Days of week"
                      checked={daysMode === DAYS_MODE_DOW}
                      onChange={() => setDaysMode(DAYS_MODE_DOW)}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOM}
                      control={<Radio />}
                      label="Days of month"
                      checked={daysMode === DAYS_MODE_DOM}
                      onChange={() => setDaysMode(DAYS_MODE_DOM)}
                    />
                  </RadioGroup>
                  {daysMode === 'onetime' && (
                    <Grid item xs={12} sm={6}>
                      <DateInput
                        label="One-time assignment date"
                        source="one_time_allocations"
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                  )}
                  {daysMode === DAYS_MODE_DOW && (
                    <div>
                      {WEEK_DAYS.map((day, idx) => (
                        <FormControlLabel
                          key={day}
                          control={
                            <Checkbox onChange={() => onChangeWeekDay(idx + 1)} checked={weekDays.includes(idx + 1)} />
                          }
                          label={day}
                        />
                      ))}
                    </div>
                  )}
                  {daysMode === DAYS_MODE_DOM && (
                    <div className={classes.domList}>
                      {MONTH_DAYS.map(({ value, label }) => (
                        <div key={value} className={classes.domItem}>
                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => onChangeMonthDay(value)} checked={monthDays.includes(value)} />
                            }
                            label={label}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <SaveButton
                  disabled={validatePercentage()}
                  label="Submit"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                {validatePercentage() && (
                  <FormHelperText error>The amount of percent across all agencies is more than 100</FormHelperText>
                )}
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ExternalAgencyCampaignCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default ExternalAgencyCampaignCreate;
