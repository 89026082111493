import React, { useEffect, useState } from 'react';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import { formatDatetime } from '../../../utils/formatter';
import EngineDataDialog from '../EngineDataDialog';
import openIntoNewTab from '../../../utils/openIntoNewTab';

const useStyles = makeStyles(() => ({
  errorWrapper: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    maxHeight: 60,
    padding: 0,
    overflow: 'hidden',
  },

  errorWrapperOpened: {
    maxHeight: 'none',
  },
}));

const UserApiData = ({ userId, refreshedAt }) => {
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showedError, setShowedError] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .getList('user_api_datas', {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { 'user.id': userId },
      })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        notify(`Error: ${error.message || 'User api data not found'}`, 'error');
        setLoading(false);
      });
  }, [notify, userId, refreshedAt, dataProvider, page, perPage]);
  if (loading) return <Loading />;

  const downloadFile = (url, err) => {
    setLoading(true);
    dataProvider
      .downloadFile(url, {})
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => {
        notify(`Error: ${error.message || err}`, 'error');
      })
      .finally(() => {
        setLoading(() => false);
      });
  };
  return (
    <Box width="100%">
      <Table size="small">
        <TableHead>
          <TableRow>
            {['ID', 'Engine', 'State', 'Error', 'Created at', 'Actions'].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(i => (
            <TableRow key={i.id}>
              <TableCell>{i.id}</TableCell>
              <TableCell>
                <Chip size="small" label={i.service} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={i.state} />
              </TableCell>
              <TableCell width="40%">
                <div className={`${classes.errorWrapper} ${showedError === i.id ? classes.errorWrapperOpened : ''}`}>
                  {i.error}
                </div>

                {i.error && i.error.length > 300 && (
                  <div>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => setShowedError(showedError !== i.id ? i.id : null)}
                      endIcon={showedError === i.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                      {showedError === i.id ? 'Hide' : 'Show more'}
                    </Button>
                  </div>
                )}
              </TableCell>
              <TableCell>{formatDatetime(i.created_at)}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => downloadFile(`user_api_datas/${i.id}/response`, 'user api data download error')}
                  disabled={!i.response}>
                  <Tooltip title="download user api data" arrow>
                    <GetAppIcon />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      <EngineDataDialog
        array={(() => {
          return Object.keys(dialog).map(i => ({ name: i, value: dialog[i] }));
        })()}
        isOpened={!!dialog}
        onClose={() => setDialog(state => !state)}
      />
    </Box>
  );
};
UserApiData.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
export default UserApiData;
