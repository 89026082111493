import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from '../styles';

const TransferMoneyTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [gateway, setGateway] = useState('');

  const classes = useStyles();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Money transfer</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, select payment gateway</DialogContentText>
        <FormControl className={classes.fullWidth}>
          <InputLabel id="gateway-select-label">Gateway</InputLabel>
          <Select labelId="gateway-select-label" value={gateway} onChange={e => setGateway(e.target.value)}>
            <MenuItem value="fake">Fake</MenuItem>
            <MenuItem value="openpay">Openpay</MenuItem>
            <MenuItem value="conekta">Conekta</MenuItem>
            <MenuItem value="stp">STP</MenuItem>
            <MenuItem value="unlimit">Unlimit</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(gateway)} color="primary" disabled={!gateway}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TransferMoneyTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TransferMoneyTransitionDialog;
