import React from 'react';
import {
  Edit,
  SaveButton,
  DeleteButton,
  Toolbar,
  FormWithRedirect,
  BooleanInput,
  TextInput,
  RadioButtonGroupInput,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import servicePermissions from '../../../permissions/service.json';
import permissionGroups from './permissionGroups';
import TabPanel from './TabPanel';
import CheckboxListInput from './CheckboxListInput';
import { levelChoices } from './constants';
import { isProduction } from '../../../utils/env';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  root: {
    maxWidth: '1370px !important',
  },
  tabLink: {
    minWidth: 100,
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const FormWrapper = props => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [showServiceTab, setShowServiceTab] = React.useState(!isProduction);
  const [shiftPressed, setShiftPressed] = React.useState(false);

  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const isEditable = permissions.includes('CAN_ROLE_EDIT');
  const serviceGroup = { name: 'Service', permissions: servicePermissions };

  React.useEffect(() => {
    if (isProduction) {
      const handleKeyDown = event => {
        if (event.key === 'Shift') {
          setShiftPressed(true);
        }

        if (shiftPressed && event.key === '^') {
          setShowServiceTab(prev => !prev);
        }
      };

      const handleKeyUp = event => {
        if (event.key === 'Shift') {
          setShiftPressed(false);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [shiftPressed]);

  const handleChange = (e, value) => setActiveTab(value);

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={2} className={classes.root}>
            <Grid item xs={12}>
              <Paper>
                <Box p={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom={false}>
                      {`Role code: ${formProps.record.code}`}
                    </Typography>
                    <TextInput source="name" fullWidth={true} helperText={false} />
                    <BooleanInput label="Is enabled" source="is_enabled" helperText={false} />
                    <Box>
                      <RadioButtonGroupInput
                        source="level"
                        label="Role level"
                        helperText={false}
                        choices={levelChoices}
                      />
                    </Box>
                    <Typography variant="caption">Set permissions for this role in each tab and save them</Typography>
                  </Grid>
                </Box>
                <Divider />
                <Box>
                  <Tabs value={activeTab} variant="scrollable" scrollButtons="auto" onChange={handleChange}>
                    {permissionGroups.map(group => (
                      <Tab key={group.name} label={group.name} className={classes.tabLink} />
                    ))}
                    {isProduction ? (
                      showServiceTab ? (
                        <Tab label={serviceGroup.name} className={classes.tabLink} />
                      ) : null
                    ) : (
                      <Tab label={serviceGroup.name} className={classes.tabLink} />
                    )}
                  </Tabs>
                </Box>
                {permissionGroups.map((group, index) => (
                  <TabPanel key={group.name} value={activeTab} index={index}>
                    <CheckboxListInput source="permissions" choices={group.permissions} />
                  </TabPanel>
                ))}
                <TabPanel value={activeTab} index={permissionGroups.length}>
                  <CheckboxListInput source="permissions" choices={serviceGroup.permissions} />
                </TabPanel>
                <Toolbar {...props} className={classes.toolbar}>
                  <SaveButton
                    disabled={!isEditable}
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton undoable={false} disabled={!isEditable} />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const RoleEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default RoleEdit;
