import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  // Filter,
  // NumberInput,
  // SelectInput,
  // TextInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
// import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

// import Pagination from '../../Pagination';
// import { marginZeroStyles } from '../../../constants';

// const useStyles = makeStyles(() => ({
//   ...marginZeroStyles,
// }));

// const ListFilter = props => {
//   const classes = useStyles();
//   return (
//     <Filter {...props} classes={{ form: classes.marginZero }}>
//       <NumberInput label="Id" source="id" alwaysOn />
//       <SelectInput
//         label="Product state"
//         alwaysOn
//         source="product->is_actual"
//         choices={[
//           { id: 'true', name: 'Actual' },
//           { id: 'false', name: 'Inactual' },
//         ]}
//       />
//       <TextInput label="Product code" source="product->code" />
//       <NumberInput label="Product ID" source="product->id" />
//     </Filter>
//   );
// };

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ProductList = props => {
  const getRowStyle = record => {
    if (!record.product_is_actual) {
      return { backgroundColor: grey[100] };
    }
  };

  return (
    <List
      pagination={false}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      // filters={<ListFilter />}
      filterDefaultValues={{ is_actual: true }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show" rowStyle={getRowStyle}>
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="strategy_id" />
        <BooleanField source="is_enabled" />
        <TextField source="min_principal" />
        <TextField source="max_principal" />
        <TextField source="first_loan_min_principal" />
        <TextField source="first_loan_max_principal" />
        <TextField source="principal_step" />
        <TextField source="min_tenor" />
        <TextField source="max_tenor" />
        <TextField source="first_loan_min_tenor" />
        <TextField source="first_loan_max_tenor" />
        <TextField source="tenor_step" />
        <TextField source="first_loan_commission" />
        <TextField source="commission" />
        <TextField source="daily_interest_rate" />
        <TextField source="first_loan_daily_interest_rate" />
        <TextField source="late_payment_fee_add_max_term" />
        <TextField source="grace_period" />
        <TextField source="grace_sum" />
        <TextField source="min_days_to_allow_request_extension" />
        <TextField source="max_days_to_allow_request_extension" />
        <TextField source="mark_loan_as_defaulted_after_days" />
        <TextField source="loan_agreement_template_code" />
        <TextField source="extension_loan_agreement_template_code" />
        <TextField source="dpd_threshold" />
        <TextField source="extension_dpd_threshold" />
      </Datagrid>
    </List>
  );
};

export default ProductList;
