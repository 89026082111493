import React from 'react';
import { useField } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import { timezone } from '../../../../utils/adapter';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const IncomeOutsourcingReportParamsInput = () => {
  const [agenciesList, setAgenciesList] = React.useState([]);
  const [showColumnsInput, setShowColumnsInput] = React.useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('income_outsourcing_report');

  React.useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => {
        const agencies = data
          .filter(agency => agency.status === 'active')
          .map(item => ({ id: item.id, name: item.name }));
        setAgenciesList(agencies);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  const reportColumns = [
    { name: 'Income ID', key: 'income_id', private: false },
    { name: 'Loan ID', key: 'loan_id', private: false },
    { name: 'CURP', key: 'curp', private: true },
    { name: 'Payment Date', key: 'payment_date', private: true },
    { name: 'Loan Status', key: 'loan_status', private: true },
    { name: 'Bank Name', key: 'paid_via', private: true },
    { name: 'Gateway', key: 'gateway', private: false },
    { name: 'Processed At', key: 'processed_at', private: true },
    { name: 'Amount paid', key: 'paid_amount', private: true },
    { name: 'Payment type', key: 'type', private: true },
    { name: 'Paid at day', key: 'paid_day', private: true },
    { name: 'Principal', key: 'principal', private: true },
    { name: 'Commission', key: 'commission', private: true },
    { name: 'Interest', key: 'interest', private: true },
    { name: 'Late payment fee', key: 'late_payment_fee', private: true },
    { name: 'Overpayment', key: 'overpayment', private: true },
    { name: 'Payment result', key: 'payment_result', private: true },
    { name: 'Outsourcing company', key: 'outsourcing_company', private: true },
    { name: 'Assigned to company from', key: 'assigned_to_start', private: true },
    { name: 'Assigned to company until', key: 'assigned_to_end', private: true },
    { name: 'Days past due when assigned to company', key: 'dpd', private: true },
  ];

  React.useEffect(() => {
    onChange({
      ...(value || {}),
      impersonalized_potfolio: showColumnsInput,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.outsourcing_company)}>
            <InputLabel id="outsourcing-company-select-label">Outsourcing company</InputLabel>
            <Select
              multiple
              fullWidth
              labelId="outsourcing-company-select-label"
              value={value && value.outsourcing_company ? [...value.outsourcing_company] : []}
              onChange={e => onChange({ ...(value || {}), outsourcing_company: e.target.value })}
              renderValue={selected => (
                <Box className={classes.chipBox}>
                  {selected.map(id => (
                    <Chip
                      className={classes.chip}
                      key={id}
                      label={agenciesList.find(agency => agency.id === id)?.name}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{ getContentAnchorEl: () => null }}>
              {agenciesList.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {touched && error && error.outsourcing_company && (
              <FormHelperText>{error.outsourcing_company}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Assigned at period start date"
            className={classes.fullWidth}
            value={value && value.assigned_start ? timezone.shift(value.assigned_start) : null}
            onChange={v => onChange({ ...(value || {}), assigned_start: timezone.unshift(v) })}
            error={!!(touched && error && error.assigned_start)}
            helperText={touched && error && error.assigned_start}
            format="MMMM dd, yyyy"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Assigned at period end date"
            className={classes.fullWidth}
            value={value && value.assigned_end ? timezone.shift(value.assigned_end) : null}
            onChange={v => onChange({ ...(value || {}), assigned_end: timezone.unshift(v) })}
            error={!!(touched && error && error.assigned_end)}
            helperText={touched && error && error.assigned_end}
            format="MMMM dd, yyyy"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <ReportDate
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          startLabel="Start date of issued loans"
          endLabel="End date of issued loans"
          required={false}
        />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Payment start date"
            className={classes.fullWidth}
            value={value && value.payment_start ? timezone.shift(value.payment_start) : null}
            onChange={v => onChange({ ...(value || {}), payment_start: timezone.unshift(v) })}
            error={!!(touched && error && error.payment_start)}
            helperText={touched && error && error.payment_start}
            format="MMMM dd, yyyy"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Payment end date"
            className={classes.fullWidth}
            value={value && value.payment_end ? timezone.shift(value.payment_end) : null}
            onChange={v => onChange({ ...(value || {}), payment_end: timezone.unshift(v) })}
            error={!!(touched && error && error.payment_end)}
            helperText={touched && error && error.payment_end}
            format="MMMM dd, yyyy"
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
      <Grid container spacing={1} className={classes.mt1}>
        <Grid item xs={12}>
          <FormControlLabel
            label="Masked columns"
            control={
              <Checkbox
                checked={showColumnsInput}
                onChange={e => {
                  setShowColumnsInput(e.target.checked);
                  onChange({
                    ...(value || {}),
                    impersonalized_potfolio: e.target.checked,
                    columns: [],
                  });
                }}
              />
            }
          />
        </Grid>
        {showColumnsInput && (
          <Grid item xs={12}>
            <FormControl className={classes.fullWidth} error={!!(touched && error && error.columns)}>
              <InputLabel id="last-call-result-select-label">Report columns</InputLabel>
              <Select
                multiple
                fullWidth
                labelId="last-call-result-select-label"
                value={value && value.columns ? [...value.columns] : []}
                onChange={e => onChange({ ...(value || {}), columns: e.target.value })}
                renderValue={() => (
                  <Box className={classes.chipBox}>
                    {value.columns.map(key => (
                      <Chip className={classes.chip} key={key} label={reportColumns.find(c => c.key === key)?.name} />
                    ))}
                  </Box>
                )}>
                {reportColumns.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {touched && error && error.columns && <FormHelperText>{error.columns}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default IncomeOutsourcingReportParamsInput;
