import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import JsonParamsInput from '../../input/JsonParamsInput';

export default props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="reject_go_to_url" />
      <JsonParamsInput source="target_params" label="Target url params" />
    </SimpleForm>
  </Create>
);
