import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';
import Autocomplete from '../../autocomplete/Autocomplete';
import { Delete } from '@material-ui/icons';

import { PHONE_CONTACT, TRANSMITTER } from '../../../utils/dictionary';
import DateTimePickerInput from '../../input/DateTimePickerInput';
import { useHandbook } from '../../../hooks/useHandbook';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [params, setParams] = useState([]);
  const [usePhoneBook, setUsePhoneBook] = useState(false);
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [templateFilter, setTemplateFilter] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
  }, [dataProvider]);

  const addItem = () => {
    setParams([
      ...params,
      {
        id: Date.now(),
        type: null,
        relation: null,
        source: null,
      },
    ]);
  };

  const removeItem = id => {
    setParams([...params.filter(item => item.id !== id)]);
  };
  const showOptions = value => {
    setUsePhoneBook(value);
    setParams([]);
  };
  const setSelectValue = (id, type, value) => {
    setParams([
      ...params.map(item => {
        if (item.id === id && type === 'type' && value !== 'contact_person') {
          item[type] = value;
          item.relation = null;
        } else if (item.id === id) {
          item[type] = value;
        }
        return item;
      }),
    ]);
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create mass sending process
          </Typography>
          <Divider className={classes.my2} />
          <FormWithRedirect
            save={(
              { audience, transmitter_id, phone_contact, scheduled_at, name, alpha_name, template, channel },
              ...rest
            ) => {
              save(
                ...[
                  {
                    audience,
                    name,
                    transmitter_id,
                    phone_contact,
                    template,
                    channel,
                    scheduled_at,
                    params: {
                      use_phone_book: usePhoneBook,
                      phone_book_filters: params,
                      alpha_name,
                    },
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={formProps => {
              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        resource="mass_sending_audiences"
                        source="audience"
                        optionValueProp="id"
                        optionLabelProp="name"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        helperText={false}
                        source="transmitter_id"
                        validate={[required()]}
                        choices={TRANSMITTER}
                        fullWidth
                        onChange={e => {
                          formProps.form.change('template', null);
                          formProps.form.resetFieldState('template');
                          formProps.form.change('channel', null);
                          setIsCmSelected(e.target.value === 'cm');
                          if (e.target.value === 'amazon_sns' || e.target.value === 'airmovil') {
                            setTemplateFilter({ category: 'SMS' });
                          } else if (e.target.value === 'mailer') {
                            setTemplateFilter({ category: 'Email' });
                          } else {
                            setTemplateFilter(null);
                          }
                        }}
                      />
                    </Grid>
                    {isCmSelected ? (
                      <FormDataConsumer>
                        {() => (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                resource="cm_templates"
                                source="template"
                                optionValueProp="template_id"
                                optionLabelProp="name"
                                label="Template"
                                filter={{ is_active: true, is_pending: false }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <SelectInput
                                helperText={false}
                                source="channel"
                                validate={[required()]}
                                choices={cmProfilesList}
                                optionText="channel_id"
                                optionValue="channel_id"
                                fullWidth
                              />
                            </Grid>
                          </>
                        )}
                      </FormDataConsumer>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="templates"
                          source="template"
                          optionValueProp="id"
                          optionLabelProp="key"
                          label="Template"
                          filter={templateFilter}
                          required
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput
                        label="Scheduled at"
                        source="scheduled_at"
                        inputVariant="filled"
                        fullWidth
                        margin="dense"
                        required
                        initial={new Date()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput helperText={false} source="name" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="phone_contact" choices={PHONE_CONTACT} label="Phone results" fullWidth />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <FormControlLabel
                    control={<Checkbox value={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />}
                    label="Use alternative contacts"
                  />
                  {usePhoneBook ? (
                    <Fragment>
                      <Button onClick={addItem} color="primary" variant="contained">
                        add
                      </Button>
                      {params.map(item => (
                        <Grid container alignItems={'center'} spacing={2} key={item.id}>
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              key={`contact-sources-input-${phoneSourceChoices.length}`}
                              helperText={false}
                              label={'Contact source'}
                              source={`contact_source->${item.id}`}
                              validate={[required()]}
                              choices={phoneSourceChoices}
                              onChange={e => setSelectValue(item.id, 'source', e.target.value)}
                              value={item.source}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SelectInput
                              key={`contact-types-input-${phoneTypeChoices.length}`}
                              helperText={false}
                              label={'Contact type'}
                              source={`contact_type->${item.id}`}
                              validate={[required()]}
                              choices={phoneTypeChoices}
                              onChange={e => setSelectValue(item.id, 'type', e.target.value)}
                              value={item.type}
                              fullWidth
                            />
                          </Grid>
                          {item.type === 'contact_person' ? (
                            <Grid item xs={12} sm={3}>
                              <SelectInput
                                key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                                emptyValue={null}
                                helperText={false}
                                validate={[required()]}
                                label={'Contact relation'}
                                source={`contact_relation->${item.id}`}
                                choices={contactRelationshipChoices}
                                value={item.relation}
                                onChange={e => setSelectValue(item.id, 'relation', e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={12} sm={2}>
                            <Button onClick={() => removeItem(item.id)} color="primary" variant="contained">
                              <Delete />
                            </Button>
                          </Grid>
                          <Divider className={classes.my2} />
                        </Grid>
                      ))}
                    </Fragment>
                  ) : null}
                  <Divider className={classes.my2} />

                  <SaveButton
                    label="Submit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const MassSendingProcessCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default MassSendingProcessCreate;
