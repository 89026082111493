import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const UnassignAgencyTransitionDialog = ({ isOpened, onClose, onSubmit }) => (
  <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Unassign external collection agency</DialogTitle>
    <DialogContent>
      <DialogContentText>Do you really want to return a loan from a collection agency?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onSubmit(null)} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
);

UnassignAgencyTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UnassignAgencyTransitionDialog;
