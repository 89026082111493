import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import { formatCurrency, formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const ApplicationTable = ({ userId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [applications, setApplications] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('applications', {
        filter: { 'user.id': userId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setApplications(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(applications)) return null;
  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Request id</TableCell>
            <TableCell>Request date</TableCell>
            <TableCell>Сonfirmed at</TableCell>
            <TableCell>Repayment date</TableCell>
            <TableCell>Principal</TableCell>
            <TableCell>Repaid by client</TableCell>
            <TableCell>Max DPD</TableCell>
            <TableCell>Request decision</TableCell>
            <TableCell>Verifier</TableCell>
            <TableCell>Rejection reason</TableCell>
            <TableCell>Verification page</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map(row => (
            <TableRow key={row.id}>
              <TableCell>
                <RouterLink to={`/applications/${row.id}/show`}>{row.id}</RouterLink>
              </TableCell>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
              <TableCell>{row.confirmed_at && formatDatetime(row.confirmed_at)}</TableCell>
              <TableCell>{row.repayment_date && formatDatetime(row.repayment_date)}</TableCell>
              <TableCell>
                {formatCurrency(row.max_approved_principal ? row.max_approved_principal : row.requested_principal)}
              </TableCell>
              <TableCell>{formatCurrency(row.repaid_by_client)}</TableCell>
              <TableCell>{row.max_paid_day}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>{row.verifier_username}</TableCell>
              <TableCell>{row.rejection_reason}</TableCell>
              <TableCell>
                <Link
                  href={`#applications_verification/${row.id}/show`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton onClick={e => e.stopPropagation()}>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

ApplicationTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default ApplicationTable;
