import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Filter, NumberInput, useDataProvider, useNotify, useRefresh, useListContext } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { ListActions } from '../user/UserList';
import Pagination from '../../Pagination';
import { formatDatetime, formatPhoneNumber } from '../../../utils/formatter';
import { marginZeroStyles } from '../../../constants';

import { DropdownDialog } from './Dialog';

const useStyles = makeStyles(() => ({
  preloader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  ...marginZeroStyles,
}));

export const deleteReasons = [
  'Invalid number',
  'Client did not register',
  'Client is blocked',
  'Client under 18 years old',
  'Military service',
  'No answer',
];

export const changeReason = async (dataProvider, notify, setOpenDropdownDialog, refresh, query, body) => {
  return dataProvider
    .query(query, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(result => {
      notify('Success: user was delated', 'success');
      setOpenDropdownDialog(state => !state);
      return result;
    })
    .catch(error => notify(`Error: ${error.message || 'Error'}`, 'error'))
    .finally(() => refresh());
};

export const DuplicatesFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

export const DuplicatesListData = ({ displayedData, getData, isLoading, setOrder, handleDirection, permissions }) => {
  const classes = useStyles();
  const notify = useNotify();
  const { data } = useListContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [userId, setUserId] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const showFullPhone = !permissions?.includes('CAN_SEE_FULL_PHONE');
  const getFormattedData = data => (Array.isArray(data) ? data : Object.values(data));

  if (isLoading || !Object.keys(data).length) {
    return (
      <div className={classes.preloader}>
        <span>Please wait</span>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <DropdownDialog
        title="Select delete reason"
        contentText={'Reason'}
        isOpened={openDropdown}
        onClose={() => setOpenDropdown(state => !state)}
        submitDisabled={submitDisabled}
        selectOptions={deleteReasons}
        onSubmit={reason => {
          setLoading(true);
          setSubmitDisabled(true);
          changeReason(dataProvider, notify, setOpenDropdown, refresh, `duplicates/${userId}/remove`, {
            delete_reason: reason,
          })
            .then(() => {
              getData();
            })
            .finally(() => {
              setSubmitDisabled(false);
              setLoading(false);
            });
        }}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              onClick={() => {
                setOrder('id');
                handleDirection();
              }}>
              Client ID
            </TableCell>
            <TableCell>Duplicate Number</TableCell>
            <TableCell>Old Client ID</TableCell>
            <TableCell>Old Number</TableCell>
            <TableCell>Reason auto-gluing is not possible</TableCell>
            <TableCell
              onClick={() => {
                setOrder('created_at');
                handleDirection('ASC');
              }}>
              Date of duplicate registration
            </TableCell>
            <TableCell>Merge duplicates</TableCell>
            <TableCell>Delete duplicate</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getFormattedData(displayedData.length ? displayedData : data).map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {row.client_phone_number && formatPhoneNumber(row.client_phone_number, showFullPhone)}
                </TableCell>
                <TableCell>{row.old_client_id}</TableCell>
                <TableCell>
                  {row.old_client_phone_number && formatPhoneNumber(row.old_client_phone_number, showFullPhone)}
                </TableCell>
                <TableCell>
                  {(() => {
                    if (Array.isArray(row?.auto_gluing_rejection_reasons)) {
                      return row?.auto_gluing_rejection_reasons?.map(reason => (
                        <Box key={reason} display="flex" flexDirection="column">
                          <Box>{reason}</Box>
                        </Box>
                      ));
                    } else {
                      return Object.keys(row?.auto_gluing_rejection_reasons).map(reason => (
                        <Box key={reason} display="flex" flexDirection="column">
                          <Box>{row?.auto_gluing_rejection_reasons[reason]}</Box>
                        </Box>
                      ));
                    }
                  })()}
                </TableCell>
                <TableCell>
                  {row.date_of_duplicate_registration && formatDatetime(row.date_of_duplicate_registration)}
                </TableCell>
                <TableCell>
                  <Box>
                    <Button
                      disabled={loading}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setLoading(true);
                        dataProvider
                          .query(`duplicates/${row.id}/merge`, { method: 'POST', body: JSON.stringify({}) })
                          .then(() => {
                            notify('Success: Merge was successful', 'success');
                          })
                          .catch(error => {
                            notify(`Error: ${error.message || 'Merge was failure'}`, 'error');
                          })
                          .finally(() => {
                            setLoading(false);
                            refresh();
                          });
                      }}>
                      Merge
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Button
                      disabled={loading}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setUserId(row.id);
                        setOpenDropdown(state => !state);
                      }}>
                      Delete
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Link href={`#users/${row.id}`} underline="none" target="_blank" rel="noreferrer">
                    <IconButton onClick={e => e.stopPropagation()}>
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

DuplicatesListData.propTypes = {
  displayedData: PropTypes.array,
  getData: PropTypes.func,
  isLoading: PropTypes.bool,
  setOrder: PropTypes.func,
  handleDirection: PropTypes.func,
  permissions: PropTypes.array,
};

export const DuplicatesList = ({ ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState('id');
  const [direction, setDirection] = useState('ASC');

  const handleDirection = () => {
    direction === 'ASC' ? setDirection('DESC') : setDirection('ASC');
  };

  const getData = useCallback(() => {
    setIsLoading(true);

    dataProvider
      .fetch(`duplicates?order[${order}]=${direction}&page=${page}&items_per_page=${perPage}`, {
        method: 'GET',
      })
      .then(res => {
        setDisplayedData(res?.data);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsLoading(false));
  }, [dataProvider, notify, page, perPage, order, direction]);

  useEffect(() => {
    getData();
  }, [page, perPage, order, direction, getData]);

  const paginationEl = document.getElementById('pagination');

  const handleChangePage = e => {
    if (e.target.classList.contains('previous-page')) {
      setPage(prev => prev - 1);
      return;
    }

    if (e.target.classList.contains('next-page')) {
      setPage(prev => prev + 1);
      return;
    }

    setPage(e.target.dataset.page + 1);
  };

  useEffect(() => {
    if (paginationEl) {
      paginationEl.addEventListener('click', handleChangePage);
    }

    return () => {
      if (paginationEl) {
        paginationEl.removeEventListener('click', handleChangePage);
      }
    };
  }, [paginationEl, getData, page]);

  return (
    <List
      pagination={
        <Pagination
          rowsPerPage={perPage}
          id="pagination"
          onRowsPerPageChange={e => {
            setPerPage(parseInt(e.target.value, 10));
            setPage(1);
          }}
        />
      }
      bulkActionButtons={false}
      filters={<DuplicatesFilter />}
      actions={<ListActions />}
      {...props}>
      <DuplicatesListData
        displayedData={displayedData}
        getData={getData}
        isLoading={isLoading}
        setOrder={setOrder}
        handleDirection={handleDirection}
        permissions={props.permissions}
      />
    </List>
  );
};

DuplicatesList.propTypes = {
  permissions: PropTypes.array,
};
