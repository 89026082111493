import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  BooleanInput,
  SaveButton,
  FormWithRedirect,
  required,
  maxLength,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import DateTimePickerInput from '../../input/DateTimePickerInput';

import ConditionsInput from './ConditionsInput';
import DiscountsInput from './DiscountsInput';

const useStyles = makeStyles(() => ({
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.includes('CAN_PROMO_CODE_EDIT') && props.record.type === 'manual';

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        const conditions = {};
        for (const p in data.conditions || {}) {
          if (data.conditions[p] !== null) {
            conditions[p] = data.conditions[p];
          }
        }
        return save(...[{ ...data, conditions, _params: { method: 'PATCH' } }, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Edit promo code
                    </Typography>
                    <BooleanInput
                      disabled={!isEditable}
                      source="is_enabled"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                  {props.record.type !== 'manual' && (
                    <Typography variant="caption" color="textSecondary" gutterBottom={false}>
                      This promocode is created automatically and is not editable
                    </Typography>
                  )}
                  <Divider />
                </Box>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        disabled={!isEditable}
                        source="code"
                        validate={[required(), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput disabled={!isEditable} source="campaign" validate={maxLength(255)} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput disabled={!isEditable} label="Valid from" source="valid_from" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput disabled={!isEditable} label="Valid till" source="valid_till" />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Conditions
                  </Typography>
                  <Divider />
                  <ConditionsInput disabled={!isEditable} source="conditions" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Discounts
                  </Typography>
                  <Divider />
                  <DiscountsInput disabled source="discounts" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="space-between">
                <SaveButton
                  disabled={!isEditable}
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const PromoCodeEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default PromoCodeEdit;
