import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import WebitelButton from '../webitel_button/WebitelButton';
import AudioPlayerDialog from '../audio_player/AudioPlayerDialog';
import { formatDate, formatPhoneNumber, formatTime } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const AllCallsTable = ({ userId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [calls, setCalls] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [record, setRecord] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  useEffect(() => {
    dataProvider
      .getList('calls', {
        filter: { 'user.id': userId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setCalls(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  const handleOpenPlayer = recordId => {
    setRecord(recordId);
    setOpenPlayer(true);
  };

  const handleClosePlayer = () => {
    setOpenPlayer(false);
    setRecord(null);
  };

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(calls)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>From user</TableCell>
            <TableCell>To user</TableCell>
            <TableCell>Queue</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Answered at</TableCell>
            <TableCell>Hangup</TableCell>
            <TableCell>Cause</TableCell>
            <TableCell>Dur.</TableCell>
            <TableCell>Wait time</TableCell>
            <TableCell>Talk time</TableCell>
            <TableCell>Record</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calls.map(record => (
            <TableRow key={record.id}>
              <TableCell>{record.id}</TableCell>
              <TableCell>
                <Chip size="small" label={record.direction} />
              </TableCell>
              <TableCell>
                {/^\d{10,11}$/.test(record.destination) ? (
                  <Box className={classes.flexRow}>
                    <WebitelButton userPhone={record.destination} />
                    {formatPhoneNumber(record.destination)}
                  </Box>
                ) : (
                  record.destination
                )}
              </TableCell>
              <TableCell>
                <div>
                  <b>Type:</b>
                  <br />
                  {record.from_user?.type ?? '---'}
                </div>
                <div>
                  <b>Number:</b>
                  <br />
                  <Box className={classes.flexRow}>
                    <WebitelButton userPhone={record.from_user?.number} />
                    {formatPhoneNumber(record.from_user?.number)}
                  </Box>
                </div>
                <div>
                  <b>Name:</b>
                  <br />
                  {record.from_user?.name ?? '---'}
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <b>Type:</b>
                  <br />
                  {record.to_user?.type ?? '---'}
                </div>
                <div>
                  <b>Number:</b>
                  <br />
                  <Box className={classes.flexRow}>
                    <WebitelButton userPhone={record.to_user?.number} />
                    {formatPhoneNumber(record.to_user?.number)}
                  </Box>
                </div>
                <div>
                  <b>Name:</b>
                  <br />
                  {record.to_user?.name ?? '---'}
                </div>
              </TableCell>
              <TableCell>{record.queue_name}</TableCell>
              <TableCell>
                {record.created && (
                  <>
                    {formatDate(record.created)}
                    <br />
                    {formatTime(record.created)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {record.answered && (
                  <>
                    {formatDate(record.answered)}
                    <br />
                    {formatTime(record.answered)}
                  </>
                )}
              </TableCell>
              <TableCell>
                <div>
                  <b>Hangup at:</b>
                  <br />
                  {formatDate(record.hangup)}
                  <br />
                  {formatTime(record.hangup)}
                </div>
                <div>
                  <b>Hangup by:</b>
                  <br />
                  {record.hangup_by}
                </div>
              </TableCell>
              <TableCell>
                <b>#{record.sip_code}</b>
                <br />
                {record.cause}
              </TableCell>
              <TableCell>{record.duration}</TableCell>
              <TableCell>{record.wait_sec}</TableCell>
              <TableCell>{record.bill_sec}</TableCell>
              <TableCell>
                <IconButton
                  disabled={record.user_files.length === 0}
                  onClick={() => handleOpenPlayer(record.user_files[0])}
                  color="secondary">
                  <PlayCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {openPlayer && <AudioPlayerDialog onClose={handleClosePlayer} recordId={record} />}
    </div>
  );
};

AllCallsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};

export default AllCallsTable;
