import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useHandbook } from '../../../hooks/useHandbook';
import { formatDatetime } from '../../../utils/formatter';
import { getApiEntrypoint } from '../../../utils/env';
import { getUserAttachments } from '../../../utils/getUserImages';
import { getUserId } from '../../../utils/authentication';

import MailSend from './MailSend';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
}));

const FormWrapper = ({ record }) => {
  const [communicationCategory, setCommunicationCategory] = useState(record.communication.category?.id ?? '');
  const [communicationResult, setCommunicationResult] = useState(record.communication.result?.id ?? '');

  const { data: communicationCategories } = useHandbook('communication_categories');
  const { data: communicationResults } = useHandbook('communication_results');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const userId = getUserId();
  const refresh = useRefresh();

  const token = localStorage.getItem('admin_access_token');
  const apiEntrypoint = getApiEntrypoint();
  const postSrc = `${apiEntrypoint}/emails/${record.id}/content?access_token=${token}`;
  const attachments = getUserAttachments(record.attachments);
  const hasRelationship = record.communication?.user || record.communication?.application || record.communication?.loan;
  const enableAssignments = record.communication.direction === 'income';

  const handleCommunicationCategorySelect = e => {
    dataProvider
      .query('communication/attach-category', {
        method: 'POST',
        body: JSON.stringify({ category_id: e.target.value, communication_id: record.communication.id }),
      })
      .then(() => {
        setCommunicationCategory(e.target.value);
        notify('Success: communication category attached', 'success');
        refresh();
      })
      .catch(error => {
        setCommunicationCategory('');
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleCommunicationResultSelect = e => {
    dataProvider
      .query('communication/process', {
        method: 'POST',
        body: JSON.stringify({ result_id: e.target.value, communication_id: record.communication.id }),
      })
      .then(() => {
        setCommunicationResult(e.target.value);
        notify('Success: communication result assigned', 'success');
        refresh();
      })
      .catch(error => {
        setCommunicationResult('');
        notify(`Error: ${error.message}`, 'error');
      });
  };

  return (
    <CardContent>
      {enableAssignments && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          className={classes.fullWidth}>
          <Grid item xs={4} md={3}>
            <FormControl
              fullWidth
              variant="filled"
              margin="dense"
              disabled={record.communication.state === 'completed'}>
              <InputLabel id="communication-category-select-label">Communication category</InputLabel>
              <Select
                labelId="communication-category-select-label"
                id="communication-category-select"
                value={communicationCategory}
                onChange={handleCommunicationCategorySelect}>
                {communicationCategories.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3}>
            <FormControl
              fullWidth
              variant="filled"
              margin="dense"
              disabled={record.communication.state !== 'processing' || record.communication.admin?.id !== userId}>
              <InputLabel id="communication-result-select-label">Communication result</InputLabel>
              <Select
                labelId="communication-result-select-label"
                id="communication-result-select"
                value={communicationResult}
                onChange={handleCommunicationResultSelect}>
                {communicationResults.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <Typography>
            <b>From email: </b>
            {record.from_email}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>To email(s): </b>
            {record.to_email.join(', ')}
          </Typography>
        </Grid>
        {hasRelationship && (
          <Grid item xs={12}>
            <Typography className={classes.flex}>
              <b>Related to:</b>
              {record.communication?.user && (
                <>
                  <Link
                    href={`#users/${record.communication.user.id}`}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    {`User #${record.communication.user.id}`}
                  </Link>
                  {'/'}
                </>
              )}
              {record.communication?.application && (
                <>
                  <Link
                    href={`#applications/${record.communication.application}/show`}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    {`Application #${record.communication.application}`}
                  </Link>
                  {'/'}
                </>
              )}
              {record.communication?.loan && (
                <Link
                  href={`#loans/${record.communication.loan}/show`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  {`Loan #${record.communication.loan}`}
                </Link>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography>
            <b>Created at: </b>
            {formatDatetime(record.created_at)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Subject: </b>
            {record.subject}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Attachments</b>
            {record.attachments.length
              ? attachments.map(a => (
                  <Link
                    key={a.src}
                    href={a.src}
                    underline="none"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.flex}>
                    <AttachmentIcon color="secondary" fontSize="small" />
                    {a.file_name}
                  </Link>
                ))
              : ': No attachments'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <iframe id="post" title="Mail content" width="100%" height="500" src={postSrc}></iframe>
        </Grid>
        <Grid item xs={12}>
          <MailSend record={record} />
        </Grid>
      </Grid>
    </CardContent>
  );
};

FormWrapper.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    from_email: PropTypes.string,
    to_email: PropTypes.arrayOf(PropTypes.string),
    communication: PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
      type: PropTypes.string,
      direction: PropTypes.string,
      assigned_at: PropTypes.string,
      completed_at: PropTypes.string,
      created_at: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
      }),
      admin: PropTypes.shape({
        id: PropTypes.number,
        username: PropTypes.string,
      }),
      loan: PropTypes.number,
      application: PropTypes.number,
      result: PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
      }),
      category: PropTypes.shape({
        id: PropTypes.number,
        group: PropTypes.string,
        username: PropTypes.string,
      }),
    }),
    subject: PropTypes.string,
    preview: PropTypes.string,
    created_at: PropTypes.string,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        hash: PropTypes.number,
        size: PropTypes.number,
        mime_type: PropTypes.string,
        file_name: PropTypes.string,
        storage_name: PropTypes.string,
        created_at: PropTypes.string,
      }),
    ),
  }),
};

const MailEdit = ({ ...props }) => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default MailEdit;
