import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  TextInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  BooleanInput,
  RadioButtonGroupInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import servicePermissions from '../../../permissions/service.json';
import TabPanel from './TabPanel';
import CheckboxListInput from './CheckboxListInput';
import permissionGroups from './permissionGroups';
import { levelChoices } from './constants';
import { isProduction } from '../../../utils/env';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  root: {
    maxWidth: '1370px !important',
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  tabLink: {
    minWidth: 100,
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const validateRoleCode = value => {
  if (!value.startsWith('ROLE_')) {
    return 'Role code must start with "ROLE_"';
  }
  if (!/^[A-Z0-9_]+$/.test(value)) {
    return 'Role code must consist only of upper case Latin letters, numbers and underscores';
  }
  if (/__/.test(value)) {
    return 'Role code must not have more than one underscore in a row';
  }
  if (value.endsWith('_')) {
    return 'Role code must not end with an underscore';
  }
  return undefined;
};

const FormWrapper = ({ save, ...props }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [showServiceTab, setShowServiceTab] = React.useState(!isProduction);
  const [shiftPressed, setShiftPressed] = React.useState(false);

  const classes = useStyles();
  const serviceGroup = { name: 'Service', permissions: servicePermissions };

  React.useEffect(() => {
    if (isProduction) {
      const handleKeyDown = event => {
        if (event.key === 'Shift') {
          setShiftPressed(true);
        }

        if (shiftPressed && event.key === '^') {
          setShowServiceTab(prev => !prev);
        }
      };

      const handleKeyUp = event => {
        if (event.key === 'Shift') {
          setShiftPressed(false);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [shiftPressed]);

  const handleChange = (e, value) => setActiveTab(value);

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        data.is_enabled = data.is_enabled || false;
        save(...[data, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4} className={classes.root}>
            <Grid item xs={12}>
              <Paper>
                <Grid item xs={6}>
                  <Box p={2}>
                    <Typography variant="h6" gutterBottom={false}>
                      Create a role
                    </Typography>
                    <TextInput
                      className={classes.mt4}
                      label="Role code"
                      source="code"
                      fullWidth={true}
                      helperText={false}
                      validate={[required('This field is required'), validateRoleCode]}
                      initialValue="ROLE_"
                    />
                    <Typography variant="caption">
                      Important: the role code must begin with &apos;ROLE_&apos;
                    </Typography>
                    <TextInput
                      label="Role name"
                      source="name"
                      fullWidth={true}
                      helperText={false}
                      validate={[required('This field is required')]}
                    />
                    <BooleanInput label="Is enabled" source="is_enabled" defaultValue={true} helperText={false} />
                    <RadioButtonGroupInput
                      source="level"
                      label="Role level"
                      defaultValue="regular"
                      helperText={false}
                      choices={levelChoices}
                    />
                  </Box>
                </Grid>
                <Divider />
                <Tabs value={activeTab} variant="scrollable" scrollButtons="auto" onChange={handleChange}>
                  {permissionGroups.map(group => (
                    <Tab key={group.name} label={group.name} className={classes.tabLink} />
                  ))}
                  {isProduction ? (
                    showServiceTab ? (
                      <Tab label={serviceGroup.name} className={classes.tabLink} />
                    ) : null
                  ) : (
                    <Tab label={serviceGroup.name} className={classes.tabLink} />
                  )}
                </Tabs>
                {permissionGroups.map((group, index) => (
                  <TabPanel key={group.name} value={activeTab} index={index}>
                    <CheckboxListInput source="permissions" choices={group.permissions} />
                  </TabPanel>
                ))}
                <TabPanel value={activeTab} index={permissionGroups.length}>
                  <CheckboxListInput source="permissions" choices={serviceGroup.permissions} />
                </TabPanel>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const RoleCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default RoleCreate;
