import React from 'react';
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin';

const HolidayCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Holiday" source="title" />
        <DateInput label="Date" source="day" />
      </SimpleForm>
    </Create>
  );
};

export default HolidayCreate;
