import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(() => ({
  nonClickableBackdrop: {
    pointerEvents: 'none',

    '& .MuiPaper-root': {
      pointerEvents: 'auto',
    },
  },
}));

export const DraggableDialogPaperComponent = props => {
  const nodeRef = useRef(null);

  return (
    <Draggable handle={`#${props.handler}`} cancel={'[class*="MuiDialogContent-root"]'} nodeRef={nodeRef}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
};

DraggableDialogPaperComponent.propTypes = {
  handler: PropTypes.string,
};

const DialogPaperComponent = props => <DraggableDialogPaperComponent {...props} handler={props['aria-labelledby']} />;

DialogPaperComponent.propTypes = {
  'aria-labelledby': PropTypes.string,
};

export const DraggableDialog = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog
      hideBackdrop
      disableScrollLock
      PaperComponent={DialogPaperComponent}
      className={classes.nonClickableBackdrop}
      {...props}>
      {children}
    </Dialog>
  );
};

DraggableDialog.propTypes = {
  children: PropTypes.node,
};
