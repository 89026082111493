import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDataProvider, useNotify } from 'react-admin';

import { USER_API_DATA_SERVICES } from '../../../utils/dictionary';
import noop from '../../../utils/noop';
import { isProduction } from '../../../utils/env';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const AddUserApiDataDialog = ({ userId, applicationId, isOpened, onClose, onSubmit }) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isLocked, setIsLocked] = useState(false);
  const [service, setService] = useState(null);

  useEffect(() => {
    setIsLocked(false);
    setService(null);
  }, [userId, applicationId, isOpened]);

  const handleClose = e => {
    if (isLocked && ['click', 'mouseup'].includes(e.type) && e.target?.matches('.MuiDialog-container')) {
      return;
    }

    onClose();
  };

  const handleSubmit = () => {
    const options = {};

    if (service === 'hyperverge') {
      options.transaction_id = isProduction ? `user_prod_${userId}` : `user_dev_${userId}`;
    }

    setIsLocked(true);

    dataProvider
      .query('user_api_datas', {
        method: 'POST',
        body: JSON.stringify({
          user_id: userId,
          application_id: applicationId,
          service,
          options,
        }),
      })
      .then(() => {
        setTimeout(() => {
          setIsLocked(false);
          onSubmit();
        }, 1000);
      })
      .catch(error => {
        setIsLocked(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  return (
    <Dialog open={isOpened} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add user api data request.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form.</DialogContentText>

        <FormControl className={classes.fullWidth}>
          <InputLabel id="api-data-service-input-label">Api data service</InputLabel>
          <Select
            labelId="api-data-service-input-label"
            value={service || ''}
            onChange={e => setService(e.target.value)}>
            {USER_API_DATA_SERVICES.map(option => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLocked}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!service || isLocked}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserApiDataDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  applicationId: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddUserApiDataDialog.defaultProps = {
  onClose: noop,
  onSubmit: noop,
};

export default AddUserApiDataDialog;
