import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import { formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  headerColor: {
    backgroundColor: 'rgb(0 0 0 / 3%)',
  },
  errorColor: {
    backgroundColor: 'red',
    textAlign: 'center',
    '& a': {
      color: 'white',
    },
  },
}));

const ShortLinkTable = ({ userId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [shortLinks, setShortLinks] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    const filter = { 'functionalLink.user.id': userId };
    dataProvider
      .getList('functional_link_logs', {
        filter,
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(response => {
        setShortLinks(response.data);
        setLoading(false);
        setTotal(response.total);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [userId, dataProvider, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(shortLinks)) return null;
  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {['Id', 'Type', 'Slug', 'Template ID', 'Campaign ID', 'User ip', 'User agent', 'Created at'].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {shortLinks.map(row => {
            return (
              <TableRow key={`${row.id}-short-link-table`}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.functional_link.type}</TableCell>
                <TableCell>{row.functional_link.slug}</TableCell>
                <TableCell>{row.template_id}</TableCell>
                <TableCell>{row.campaign_id}</TableCell>
                <TableCell>{row.client_ip}</TableCell>
                <TableCell>{row.user_agent}</TableCell>
                <TableCell>{formatDatetime(row.redirected_at)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

ShortLinkTable.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};

ShortLinkTable.defaultProps = {
  userId: null,
  refreshedAt: 0,
};

export default ShortLinkTable;
