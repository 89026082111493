import React from 'react';
import {
  Create,
  TextInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  required,
  maxLength,
  BooleanInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const FormWrapper = props => {
  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6">Create short link</Typography>
                  <Divider />
                  <Box pt={3}>
                    <TextInput source="slug" validate={[required(), maxLength(255)]} fullWidth />
                    <TextInput source="link" validate={[required(), maxLength(255)]} fullWidth />
                    <BooleanInput label="Enabled" source="is_enabled" />
                  </Box>
                </Box>
                <Toolbar>
                  <SaveButton
                    label="Save"
                    redirect="edit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
