import React, { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, useListContext, sanitizeListRestProps } from 'react-admin';

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter, setFilters } = useListContext();
  useEffect(() => {
    setFilters({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default ListActions;
