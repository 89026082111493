import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  pxy0: {
    padding: 0,
  },
  pr20: {
    paddingRight: theme.spacing(20),
  },
  manualVerificationResultSelect: {
    width: theme.spacing(18),
    right: 0,
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  nonClickableBackdrop: {
    pointerEvents: 'none',

    '& .MuiPaper-root': {
      pointerEvents: 'auto',
    },
  },
  modalTitle: {
    cursor: 'move',
  },
  minValueInput: {
    width: theme.spacing(10),
    marginLeft: theme.spacing(1),
  },
  my1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '6px 24px 6px 16px',
    width: 'fit-content',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  breakable: {
    wordBreak: 'break-all',
  },
}));
