import React from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(theme => ({
  stack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
  },
  card: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: '500px',
    zIndex: 100,
  },
  figure: {
    margin: 0,
  },
  audio: {
    width: '100%',
  },
  recordInfo: {
    paddingTop: theme.spacing(2),
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    marginTop: theme.spacing(1),
  },
}));

const AudioPlayerDialog = ({ onClose, recordId, recordData }) => {
  const [record, setRecord] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setLoading(true);

    dataProvider
      .downloadFile(`files/${recordId}/get_file`)
      .then(({ data }) => setRecord(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [recordId]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Paper elevation={24} onClose={onClose} className={classes.card}>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <figure className={classes.figure} id="audio-container">
        {loading ? (
          <Typography className={classes.stack} variant="body2" component="div">
            Loading record...
            <CircularProgress size={20} />
          </Typography>
        ) : (
          <audio id="audio-rec" autoPlay className={classes.audio} controls>
            <source src={record} type="audio/mpeg" />
          </audio>
        )}
      </figure>
      {recordData && (
        <div className={classes.recordInfo}>
          <Typography variant="body2">
            <b>Record information:</b>
          </Typography>
          <ul className={classes.list}>
            <li>
              <Typography variant="body2">{`Call ID: ${recordData.id}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Destination: ${recordData.destination}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Created at: ${formatDatetime(recordData.created_at)}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Duration, sec.: ${recordData.duration}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Wait, sec.: ${recordData.wait_sec}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Hangup at: ${formatDatetime(recordData.hangup)}`}</Typography>
            </li>
            <li>
              <Typography variant="body2">{`Hangup by: ${recordData.hangup_by}`}</Typography>
            </li>
          </ul>
        </div>
      )}
    </Paper>
  );
};

AudioPlayerDialog.propTypes = {
  onClose: PropTypes.func,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordData: PropTypes.shape({
    id: PropTypes.number,
    destination: PropTypes.string,
    created_at: PropTypes.string,
    duration: PropTypes.number,
    wait_sec: PropTypes.number,
    hangup: PropTypes.string,
    hangup_by: PropTypes.string,
    webitel_call_destination: PropTypes.string,
    webitel_call_duration: PropTypes.number,
    webitel_call_wait_sec: PropTypes.number,
  }),
};

export default AudioPlayerDialog;
