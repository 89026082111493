import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
  useDataProvider,
  useRefresh,
  SelectInput,
  BooleanInput,
  usePermissions,
  AutocompleteInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import { formatDatetime } from '../../../utils/formatter';
import { marginZeroStyles } from '../../../constants';
import { useHandbook } from '../../../hooks/useHandbook';
import { getUserId } from '../../../utils/authentication';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  fixedWidth: {
    width: '200px',
    display: 'block',
  },
}));

export const IncomeMailListFilter = props => {
  const classes = useStyles();
  const { data: communicationCategories } = useHandbook('communication_categories');
  const { data: communicationResults } = useHandbook('communication_results');

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" />
      <TextInput source="from_email" label="From" alwaysOn />
      <TextInput source="toEmail" label="To" alwaysOn />
      <TextInput source="subject" label="Subject" />
      <AutocompleteInput alwaysOn label="Admin" source="communication->admin" choices={props.admins} resettable />
      <BooleanInput source="exists|communication->admin" label="Has admin" defaultValue={false} />
      <SelectInput label="Category" source="communication->category" choices={communicationCategories} />
      <BooleanInput source="exists|communication->category" label="Has category" defaultValue={false} />
      <SelectInput label="Result" source="communication->result" choices={communicationResults} />
      <BooleanInput source="exists|communication->result" label="Has result" defaultValue={false} />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
    </Filter>
  );
};

IncomeMailListFilter.propTypes = {
  admins: PropTypes.array,
};

export const OutcomeMailListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <TextInput source="from_email" label="From" alwaysOn />
      <TextInput source="toEmail" label="To" alwaysOn />
      <NumberInput label="Id" source="id" />
      <SelectInput
        label="Send mode"
        source="communication->mode"
        choices={[
          { id: 'automatic', name: 'Automatic' },
          { id: 'manual', name: 'Manual' },
        ]}
      />
      <TextInput source="subject" label="Subject" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
    </Filter>
  );
};

export const MailListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

MailListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const IncomeMailList = props => {
  const [admins, setAdmins] = useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { permissions = [] } = usePermissions();

  const userId = getUserId();

  useEffect(() => {
    dataProvider
      .query('admins?page=1&items_per_page=500&is_blocked=false', { method: 'GET' })
      .then(({ data }) => {
        const admins = data.map(admin => {
          return { ...admin, name: admin.username };
        });
        setAdmins(admins);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  const handleAdminSelect = communicationId => {
    dataProvider
      .query('communication/assign-operator', {
        method: 'POST',
        body: JSON.stringify({ operator_id: userId, communication_id: communicationId }),
      })
      .then(() => {
        notify('Success: operator assigned', 'success');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<IncomeMailListFilter admins={admins} />}
      filter={{ 'communication.direction': 'income' }}
      actions={<MailListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="from_email" label="From" className={[classes.breakableCell, classes.fixedWidth].join(' ')} />
        <FunctionField
          label="To"
          source="to_email"
          className={[classes.breakableCell, classes.fixedWidth].join(' ')}
          render={row => {
            return row.to_email.map(email => <div key={email}>{email}</div>);
          }}
        />
        {permissions.includes('CAN_ASSIGN_COMMUNICATION') && (
          <FunctionField
            render={record => (
              <Button
                disabled={record.communication.state === 'completed' || record.communication.admin !== null}
                variant="contained"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  handleAdminSelect(record.communication.id);
                }}>
                Assign
              </Button>
            )}
          />
        )}
        <TextField source="subject" className={classes.breakableCell} />
        <TextField source="preview" className={[classes.breakableCell, classes.fixedWidth].join(' ')} />
        <FunctionField label="State" render={row => <Chip size="small" label={row.communication.state} />} />
        <FunctionField label="Admin" render={row => row.communication.admin?.username} />
        <FunctionField label="Category" render={row => row.communication.category?.name} />
        <FunctionField label="Result" render={row => row.communication.result?.name} />
        <FunctionField label="Created at" render={row => formatDatetime(row.created_at)} />
        <FunctionField label="Assigned at" render={row => formatDatetime(row.communication.assigned_at)} />
        <FunctionField label="Replied at" render={row => formatDatetime(row.communication.replied_at)} />
      </Datagrid>
    </List>
  );
};

export const OutcomeMailList = props => {
  const classes = useStyles();

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<OutcomeMailListFilter />}
      filter={{ 'communication.direction': 'outcome' }}
      actions={<MailListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="communication.mode" label="Send mode" />
        <TextField source="from_email" label="From" className={[classes.breakableCell, classes.fixedWidth].join(' ')} />
        <FunctionField
          label="To"
          source="to_email"
          className={[classes.breakableCell, classes.fixedWidth].join(' ')}
          render={row => {
            return row.to_email.map(email => <div key={email}>{email}</div>);
          }}
        />
        <TextField source="subject" className={classes.breakableCell} />
        <TextField source="preview" className={classes.breakableCell} />
        <FunctionField label="State" render={row => <Chip size="small" label={row.communication.state} />} />
        <FunctionField label="Admin" render={row => row.communication.admin?.username} />
        <FunctionField label="Category" render={row => row.communication.category?.name} />
        <FunctionField label="Result" render={row => row.communication.result?.name} />
        <FunctionField label="Created at" render={row => formatDatetime(row.created_at)} />
      </Datagrid>
    </List>
  );
};
