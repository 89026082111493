import React from 'react';
import PropTypes from 'prop-types';
import { Edit, SaveButton, DeleteButton, FormWithRedirect, TextInput, Toolbar, required, email } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      save={({ name, email }, ...rest) => {
        save(
          ...[
            {
              name,
              email,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit outgoing mailbox
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <TextInput source="email" validate={[required(), email()]} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput source="name" validate={[required()]} fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={false}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const OutgoingMailboxEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default OutgoingMailboxEdit;
