import React from 'react';
import { Button, useListContext, setListSelectedIds } from 'react-admin';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';

const MarkAllButton = () => {
  const dispatch = useDispatch();
  const { ids } = useListContext();

  return (
    <Box pr={2}>
      <Button
        label="Mark all"
        variant="contained"
        onClick={() => dispatch(setListSelectedIds('loans', ids))}
        color="primary"
      />
    </Box>
  );
};

export default MarkAllButton;
