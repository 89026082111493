import React from 'react';
import {
  Create,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  NumberInput,
  useDataProvider,
  required,
  minValue,
  maxValue,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ruleTip: {
    marginTop: '0.5rem',
  },
}));

const FormWrapper = props => {
  const [productList, setProductList] = React.useState([]);
  const [productSelected, setProductSelected] = React.useState(null);
  const [typeSelected, setTypeSelected] = React.useState(null);
  const [minTerm, setMinTerm] = React.useState(0);
  const [maxTerm, setMaxTerm] = React.useState(60);
  const [minAmount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(50000);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .query('products?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setProductList(data));
  }, [dataProvider]);

  React.useEffect(() => {
    if (productSelected && typeSelected && productList.length) {
      const product = productList.find(p => p.id === productSelected);
      const minTerm = typeSelected === 'first' ? product.first_loan_min_tenor : product.min_tenor;
      const maxTerm = typeSelected === 'first' ? product.first_loan_max_tenor : product.max_tenor;
      const minAmount = typeSelected === 'first' ? product.first_loan_min_principal : product.min_principal;
      const maxAmount = typeSelected === 'first' ? product.first_loan_max_principal : product.max_principal;
      setMinTerm(minTerm);
      setMaxTerm(maxTerm);
      setMinAmount(minAmount);
      setMaxAmount(maxAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected, typeSelected]);

  const PRODUCT_CHOICES = productList.filter(item => item.is_actual).map(item => ({ id: item.id, name: item.name }));
  const TYPE_CHOICES = [
    { id: 'first', name: 'First' },
    { id: 'repeat', name: 'Repeat' },
  ];

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create rule
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="product_id"
                        validate={[required()]}
                        choices={PRODUCT_CHOICES}
                        fullWidth
                        onChange={e => {
                          setProductSelected(e.target.value);
                          formProps.form.change('min_term', null);
                          formProps.form.change('max_term', null);
                          formProps.form.change('min_amount', null);
                          formProps.form.change('max_amount', null);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="type"
                        validate={[required()]}
                        choices={TYPE_CHOICES}
                        fullWidth
                        onChange={e => {
                          setTypeSelected(e.target.value);
                          formProps.form.change('min_term', null);
                          formProps.form.change('max_term', null);
                          formProps.form.change('min_amount', null);
                          formProps.form.change('max_amount', null);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Min term"
                        source="min_term"
                        min={minTerm}
                        max={maxTerm}
                        step={1}
                        validate={[required(), minValue(minTerm), maxValue(maxTerm)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Max term"
                        source="max_term"
                        min={minTerm}
                        max={maxTerm}
                        step={1}
                        validate={[required(), minValue(minTerm), maxValue(maxTerm)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Min amount"
                        source="min_amount"
                        min={minAmount}
                        max={maxAmount}
                        step={1}
                        validate={[minValue(minAmount), maxValue(maxAmount)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        label="Max amount"
                        source="max_amount"
                        min={minAmount}
                        max={maxAmount}
                        step={1}
                        validate={[minValue(minAmount), maxValue(maxAmount)]}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.ruleTip} variant="body2" gutterBottom={false}>
                    {`Important: Min amount < Approved amount <= Max amount (so the value specified at the beginning of the range  is not subject to the rule)!`}
                  </Typography>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    disabled={!formProps.valid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const LoanTermRulesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default LoanTermRulesCreate;
