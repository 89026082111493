import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { isProduction, getApiEntrypoint } from './utils/env';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
Sentry.init({ dsn: SENTRY_DSN });
// eslint-disable-next-line no-console
!isProduction && console.info('App linked to backend', getApiEntrypoint());

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
